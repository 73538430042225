import React, {
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import {
  Button,
  HStack,
  Text,
  Icon,
  CloseButton,
  Flex,
  Center,
} from "@chakra-ui/react";
import {LuGanttChartSquare} from "react-icons/lu";
import {lightFormat} from "date-fns";
import {TruncatedTextWithTooltip} from "./CliniciansTable";
import {MdLocationOn} from "react-icons/md";
import ApptType from "./appointments/AppointmentModal.js/ApptType";
import {
  crud,
  updateApptMemb,
  applyPrepayment,
  axiosCrud,
} from "../crudRequests";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import swal from "sweetalert";
import {isoToApptDate} from "../additional_files/helpers";
import {notificationsData} from "../additional_files/notifications";
import Notes from "./appointments/AppointmentModal.js/notes";
import {
  IconButton,
  useDisclosure,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverAnchor,
  Box,
} from "@chakra-ui/react";
import {BsPersonCircle, BsClock} from "react-icons/bs";
import {MdModeEditOutline} from "react-icons/md";
import {FaAward, FaEdit, FaKey, FaWalking, FaEyeSlash} from "react-icons/fa";
import RescheduleAppointment from "./ApptRescheduleModal";
import {SocketContext} from "../additional_files/context";
import renderBlurredText from "../utils/renderBlurredText";
import verifyAccess from "../utils/verifyAccess";
import PaymentInformation from "./appointments/payment";
import {getJitsiJWT} from "./Patients/Appointments/helpers/jitsiFunctions";
import AidClaimModal from "./appointments/clinicAidClaimModal";
import CancelAppointmet from "./appointments/AppointmentModal.js/CancelAppointmet";
import cancelConfirmation from "./appointments/AppointmentModal.js/cancelConfirmation";
import MeetingLinks from "./appointments/AppointmentModal.js/MeetingLinks";
import DeleteRecurrence from "./appointments/AppointmentModal.js/DeleteRecurrence";
import {createHashTable} from "../utils/createHashTable";
import noShowConfirmation from "./appointments/AppointmentModal.js/noShowConfirmation";
import ProductsInformation from "./appointments/AppointmentModal.js/ProductsInformation";
import {KeyNoteModal} from "./Patients/Notes/KeyNoteModal";
import {getKeyNote} from "./Patients/Notes/helpers/getKeyNote";
import ChangeAmountModal from "./appointments/payment/ChangeAmountModal";
import {useWindowDimensions} from "../additional_files/custom";
import {
  TbCalendarStats,
  TbCalendarCheck,
  TbCalendarOff,
  TbCalendarRepeat,
} from "react-icons/tb";
import Prepayments from "./appointments/AppointmentModal.js/Prepayments";
import {PatientBalancesModalWithoutProducts} from "./PatientBalancesModal";
const weekday = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const MembershipBadge = ({
  patient,
  schState,
  handleBrowseToTab,
  isOpen,
  onClose,
  onToggle,
}) => {
  const navigate = useNavigate();
  const activeMemberships = patient?.memberships?.filter(
    (membership) => membership.status !== "Cancelled"
  );

  return (
    <Popover key={patient?.pid} isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          bg="transparent"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          _hover={{bg: "transparent"}}
          mr={-2}
          p={1}
          h="fit-content"
          w="1rem"
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
        >
          <FaAward
            className="cursor-pointer bg-transparent text-[1.2rem]"
            style={{
              color: activeMemberships?.length > 0 ? "orange" : "gray",
            }}
          />
        </Button>
      </PopoverTrigger>
      <Box zIndex="popover">
        <PopoverContent width="200px" fontSize="sm">
          <PopoverArrow />
          <PopoverHeader fontSize="md" textColor="black">
            <HStack
              w="100%"
              display="flex"
              direction="row"
              gap={1}
              onClick={() => {
                if (handleBrowseToTab) {
                  handleBrowseToTab(14);
                } else {
                  navigate(`/patients`, {
                    state: {
                      ...schState,
                      selectedPid: patient?.pid,
                      navigateToTab: 16,
                    },
                  });
                }
              }}
              style={{cursor: "pointer"}}
            >
              <Text>
                {activeMemberships?.length > 0
                  ? "Active memberships"
                  : "Add Membership"}
              </Text>
              <FaEdit
                style={{
                  margin: "2px",
                  fontSize: "1rem",
                }}
              />
            </HStack>
          </PopoverHeader>
          <PopoverBody>
            <ul>
              {activeMemberships
                ?.filter((memb) => memb.active)
                .map((membership, index) => (
                  <li key={index} style={{color: "black"}}>
                    {membership.name}
                  </li>
                ))}
            </ul>
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  );
};

export default function AppointmentModal({
  dispatch,
  schState,
  InsurersMap,
  isFromPatient,
  handleBrowseToTab,
}) {
  const {width} = useWindowDimensions();
  const [isMembOpen, setIsMembOpen] = useState(false);
  const navigate = useNavigate();
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [cancellationModalData, setCancellationModalData] = useState({
    title: "",
    status: "",
  });
  const patients = useMemo(
    () => createHashTable(schState.patients, "pid"),
    [schState.patients.length]
  );
  const [amountModal, setAmountModal] = useState(false);
  const [currentPatientName, setCurrentPatientName] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [sendingAidClaim, setSendingAidClaim] = useState();
  const [doctor, setDoctor] = useState();
  const apptModalRef = useRef(null);
  const [currentApptLink, setCurrentApptLink] = useState();
  const [right, setRight] = useState(`-448px`);
  const [hideList, setHideList] = useState(`100%`);
  const [keyNote, setKeyNote] = useState(null);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [admin, setAdmin] = useState([]);
  const socket = useContext(SocketContext);
  const toast = useToast();
  const blurredName = useMemo(
    () => renderBlurredText(currentPatientName),
    [currentPatientName]
  );
  const [currentAppt, setCurrentAppt] = useState(null);
  const [patient, setPatient] = useState(null);
  let identifier = currentAppt;
  useEffect(() => {
    const fetchData = async (appt) => {
      try {
        const response = await crud(schState, [
          {
            db: schState.db,
            collection: "appointments",
            parameters: [{aid: appt?.aid}],
            method: "findOne",
          },
          {
            db: schState.db,
            collection: "patients",
            parameters: [{pid: appt?.pid}],
            method: "findOne",
          },
        ]);

        const [data, patientData] = response.data;
        setCurrentAppt(data);
        setPatient(patientData);
        if (patientData) {
          setCurrentPatientName(`${patientData.lName}, ${patientData.fName}`);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (schState.selectedAppointment) fetchData(schState.selectedAppointment);
    else setCurrentAppt(null);
  }, [schState.selectedAppointment]);

  const service = schState.services.find(
    (s) => s.serviceId === identifier?.serviceId
  );

  const cliniciansMap = useMemo(() => {
    let clins = schState.doctor ? [schState.doctor] : schState.doctors;
    return clins.reduce((acc, ele) => {
      acc[ele.did] = ele;
      return acc;
    }, {});
  }, [schState.doctor?.did, schState.doctors?.length]);

  useLayoutEffect(() => {
    setIsMembOpen(false);
    let width = apptModalRef.current.offsetWidth;
    apptModalRef.current.style.opacity = 1;
    if (identifier) {
      setRight("0px");
    } else {
      apptModalRef.current.style.opacity = 0;
      setRight(`-${width}px`);
    }
  }, [identifier, width]);

  useEffect(
    function () {
      function apptUpdate(appt) {
        let {time} = appt.ISOdate
          ? isoToApptDate(appt)
          : {date: appt.date, time: appt.time};

        setCurrentTime(time);
        setCurrentApptLink(appt["clinicApptLink"]);

        if (schState.userType === "admin") {
          let index = schState.doctorsPayload[appt["did"]].index;

          setDoctor(schState.doctors[index]);
        } else {
          setDoctor(schState.selectedDoctor);
        }
      }

      if (identifier) {
        apptUpdate(identifier);
        setHideList(true);
      }
    },
    [
      identifier,
      schState.userType,
      schState.doctorsPayload,
      schState.doctors,
      schState.selectedDoctor,
    ]
  );

  useEffect(() => {
    const fetchKeyNote = async () => {
      const [note, admin] = await getKeyNote(schState, identifier?.pid);
      if (note) {
        setKeyNote(note);
        setAdmin(admin);
      } else {
        setKeyNote(null);
        setAdmin(null);
      }
    };
    fetchKeyNote();
  }, [identifier?.pid]);
  return (
    <section
      className=" h-full bg-[#F7FAFC] shadow-md fixed slide transition-all duration-300 ease-in w-full md:w-[28rem] top-0 bottom-0 z-[999]"
      ref={apptModalRef}
      style={{
        opacity: 1,
        right,
      }}
      onClick={(e) => {
        e.stopPropagation();
        setIsMembOpen(false);
      }}
    >
      <Flex justifyContent="flex-end" p="2">
        <CloseButton
          color="#2D3748"
          onClick={() => {
            dispatch({type: "SELECT_APPOINTMENT", payload: null});
          }}
        />
      </Flex>
      {amountModal && identifier && !identifier?.cancelled && (
        <ChangeAmountModal
          state={schState}
          appointment={identifier}
          stateChanger={setAmountModal}
          dispatch={dispatch}
          socket={socket}
        />
      )}
      {showCancellationModal && (
        <CancelAppointmet
          {...{
            onConfirm: async ({
              reason,
              allowAction,
              status,
              noShowFeeOverride,
            }) => {
              try {
                let updateBody = null;
                if (status === "no-show") {
                  updateBody = await noShowConfirmation({
                    toast,
                    allowAction,
                    reason,
                    appt: identifier,
                    state: schState,
                    dispatch,
                    socket,
                    noShowFeeOverride,
                  });
                } else {
                  updateBody = await cancelConfirmation({
                    toast,
                    allowAction,
                    reason,
                    appt: identifier,
                    state: schState,
                    socket,
                    dispatch,
                  });
                }

                await Promise.all([
                  updateApptMemb({
                    state: schState,
                    appointmentId: identifier.aid,
                    serviceId: identifier.serviceId,
                    membershipForServiceId: identifier.paidByMembership,
                    patientId: identifier.pid,
                    productsToAssign: [],
                  }),
                  /*applyPrepayment({
                    state: schState,
                    appointmentId: identifier?.aid,
                    patientId: identifier?.pid,
                  }),*/
                ]);
                if (updateBody) {
                  dispatch({
                    type: "UPDATE_APPOINTMENT",
                    payload: updateBody,
                  });
                }
              } catch (error) {
                toast({
                  title: "Update Error!",
                  description: error.response?.data || error.message,
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
            },
            onClose: setShowCancellationModal,
            appt: identifier,
            isOpen: showCancellationModal,
            noShowFee: service?.noShowFee,
            ...cancellationModalData,
          }}
        />
      )}
      <div className="h-full whitespace-nowrap flex flex-col p-2 pt-0 px-4 pr-2    space-y-3">
        <div className="h-[calc(100vh-95px)]  overflow-scroll p-2  sbar2">
          <div className="flex flex-col items-start space-y-1">
            <div className="flex items-end justify-between w-full pr-2">
              <div className="text-[#5754FF] text-[1.7rem] flex gap-1 items-center">
                <p className="font-semibold">
                  {verifyAccess(schState, "fName")
                    ? currentPatientName?.slice(0, 17) +
                      (currentPatientName?.length > 17 ? "..." : "")
                    : blurredName}
                </p>
                {keyNote && (
                  <IconButton
                    size="xs"
                    aria-label="Key note"
                    icon={<FaKey />}
                    variant="solid"
                    colorScheme="green"
                    onClick={() => onOpen()}
                  />
                )}
                <MembershipBadge
                  patient={patient}
                  schState={schState}
                  handleBrowseToTab={handleBrowseToTab}
                  isOpen={isMembOpen}
                  onClose={() => setIsMembOpen(false)}
                  onToggle={() => setIsMembOpen(!isMembOpen)}
                />
                <span>with</span>
              </div>
            </div>
            <div className="flex items-center p-0.5 rounded-md">
              {identifier?.assignedProfessional === "technician" ? (
                <p className="text-dark text-[1.2rem] leading-5 font-semibold">
                  {identifier?.technician || ""}
                </p>
              ) : (
                <>
                  <span className="flex justify-center h-10 items-center">
                    {doctor?.photo ? (
                      <img
                        src={doctor.photo}
                        alt=""
                        className="hover:cursor-pointer hover:bg-[] h-full rounded-md drop-shadow-lg"
                      />
                    ) : (
                      <BsPersonCircle className="text-[2.5rem] text-[#5754FF]/80" />
                    )}
                  </span>
                  <div className="ml-2 flex flex-col">
                    <p className="text-dark text-[16px] leading-5 font-semibold">
                      {`${doctor?.lastName}, ${doctor?.firstName}` || ""}
                    </p>
                    <span className="font-light text-[10px] text-dark">
                      {doctor?.accreditations}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>

          {
            <DateComponent
              {...{time: currentTime, identifier, dispatch, schState, socket}}
            />
          }
          {identifier?.recurrenceId && (
            <DeleteRecurrence
              appt={identifier}
              state={schState}
              dispatch={dispatch}
            />
          )}
          {
            <Status
              appt={identifier}
              patient={patient}
              {...{
                schState,
                dispatch,
                hideList,
                setHideList,
                socket,
                openCancellationModal: ({status, title}) => {
                  setCancellationModalData({status, title});
                  setShowCancellationModal(true);
                },
              }}
            />
          }

          <Services
            appt={identifier}
            {...{schState, dispatch, hideList, setHideList, socket, patient}}
          />

          {identifier && InsurersMap[identifier?.tpp] && (
            <div className="space-y-1 mt-5">
              <p className="text-[#A1A1A1] text-xs">Third Party Payer</p>
              <div className="flex items-start space-x-1 relative">
                <div className="bg-[#97A1FF] leading-6 font-medium rounded-lg p-2 px-3 text-sm flex flex-col text-white relative w-full">
                  <span>{InsurersMap[identifier?.tpp]?.name || "N/A"}</span>
                </div>
              </div>
            </div>
          )}
          <PatientBalancesModalWithoutProducts
            patient={patient}
            state={schState}
            products={[]}
            services={schState.services}
          />
          <Notes
            appt={identifier}
            {...{schState, dispatch, hideList, setHideList, socket}}
          />
          <ProductsInformation appt={identifier} {...{schState, dispatch}} />

          <PaymentInformation
            appointment={identifier}
            {...{
              state: schState,
              dispatch,
              hideList,
              setHideList,
              socket,
              sendingAidClaim,
              setSendingAidClaim,
            }}
          />
          <MeetingLinks
            appointment={identifier}
            state={schState}
            dispatch={dispatch}
          />
          <Button
            display={{base: "none", md: "block"}}
            w="full"
            size={"md"}
            variant={"outline"}
            bg={"transparent"}
            color={"#3D50FF"}
            borderColor={"#3D50FF"}
            borderWidth={"2px"}
            cursor={"pointer"}
            mb="6"
            mt="4"
            isDisabled={identifier?.cancelled || identifier?.noShow}
            onClick={(e) => {
              if (!identifier?.cancelled && !identifier?.noShow)
                setAmountModal(true);
            }}
          >
            Edit Appointment Details
          </Button>
          {sendingAidClaim && identifier && (
            <AidClaimModal
              appt={identifier}
              action={() => setSendingAidClaim(false)}
              state={schState}
              dispatch={dispatch}
              patient={patient}
              clinician={doctor}
            />
          )}

          <button className=" flex-1 focus:outline-none justify-center flex space-x-2 items-center bg-[#3D50FF]/80 rounded-md p-1 px-3 w-full text-white whitespace-nowrap">
            <span className="text-xs  h-6 w-6  flex justify-center items-center">
              <img
                alt=""
                src="/images/Doctor/video.png"
                className="w-[1.1rem]"
              />
            </span>
            <span
              className="font-medium text-sm "
              onClick={async () => {
                if (schState.userType === "admin")
                  toast({
                    title: "You are not authorized to join this call.",
                    status: "info",
                    description:
                      "Only the patient and clinician associated with this appointment are allowed to go in the call.",
                    duration: 3000,
                    isClosable: true,
                    position: "top",
                  });
                else {
                  if (identifier?.clinicApptLink) {
                    // Will display the loading toast until the promise is either resolved
                    // or rejected.
                    toast.promise(
                      getJitsiJWT(
                        schState,
                        identifier.aid,
                        identifier.pid,
                        schState.db,
                        schState.selectedDoctor,
                        identifier.clinicApptLink
                      ),
                      {
                        success: {
                          title: "Authenticated",
                          description: "Successfully joined meeting!",
                        },
                        error: {
                          title: "Please try again",
                          description:
                            "Something wrong authenticating you to the meeting",
                        },
                        loading: {
                          title: "Authenticating...",
                          description: "Please wait",
                        },
                      }
                    );
                  }
                }
              }}
            >
              Join Call
            </span>
          </button>
        </div>
        {sendingAidClaim && identifier && (
          <AidClaimModal
            appt={identifier}
            action={() => setSendingAidClaim(false)}
            state={schState}
            dispatch={dispatch}
            patient={patient}
            clinician={doctor}
          />
        )}

        <div className="absolute  bottom-3 flex w-full pl-1 pr-8">
          <button className="ml-1 flex-1 focus:outline-none justify-center flex space-x-2 items-center bg-[#3D50FF]/60 rounded-md p-1 px-3 w-full text-white whitespace-nowrap">
            <span className="text-xs  h-6 w-6  flex justify-center items-center">
              <Icon as={LuGanttChartSquare} fontSize="20" />
            </span>
            <span
              className="font-medium text-sm "
              onClick={() => {
                navigate(`/patients`, {
                  state: {
                    ...schState,
                    selectedPid: identifier?.pid,
                    patientChartAid: identifier?.aid,
                    patientChartTab: 8,
                  },
                });
              }}
            >
              Create Chart
            </span>
          </button>

          {!isFromPatient && (
            <button className="ml-3 flex-1 focus:outline-none flex space-x-2 items-center bg-[#3D50FF]/60 rounded-md p-1 px-3 w-full text-white whitespace-nowrap">
              <span className="text-sm  h-6 w-6  flex justify-center items-center">
                <img
                  alt=""
                  src="/images/Doctor/patient.png"
                  className="w-[1.1rem]"
                />
              </span>
              <span
                className="font-medium text-sm"
                onClick={() => {
                  navigate(`/patients`, {
                    state: {
                      firstLoading: true,
                      ...schState,
                      selectedPid: identifier?.pid,
                    },
                  });
                }}
              >
                Go to Patient
              </span>
            </button>
          )}
        </div>
        {keyNote && (
          <KeyNoteModal
            isOpen={isOpen}
            onClose={onClose}
            note={keyNote}
            keyNote={keyNote}
            adminsMap={admin}
            cliniciansMap={cliniciansMap}
            dashState={schState}
          />
        )}
      </div>
    </section>
  );
}

export const consumeAppointmentMembershipProducts = async (
  schState,
  dispatch,
  appt
) => {
  const {data} = await crud(schState, [
    {
      db: schState.db,
      collection: "membershipLogs",
      parameters: [{appointmentId: appt.aid, status: "reserved"}],
      method: "find",
    },
  ]);
  const patientRes = await crud(schState, [
    {
      db: schState.db,
      collection: "patients",
      parameters: [{pid: appt.pid}],
      method: "findOne",
    },
  ]);
  const patient = patientRes.data[0];
  const productLogs = data[0] || [];
  if (productLogs?.length) {
    let updatedMembershipData = patient?.memberships || [];
    productLogs.forEach((log) => {
      updatedMembershipData = updatedMembershipData.map((membership) => {
        if (membership.uuid === log.membershipId) {
          crud(schState, [
            {
              db: schState.db,
              collection: "membershipLogs",
              parameters: [
                {
                  membershipId: membership.uuid,
                  productId: log.productId,
                  appointmentId: appt.aid,
                },
                {$set: {status: "consumed"}},
              ],
              method: "updateOne",
            },
          ]);
          return {
            ...membership,
            selectedProducts: {
              ...membership.selectedProducts,
              [log.productId]: {
                ...membership.selectedProducts[log.productId],
                reserved:
                  (membership.selectedProducts[log.productId].reserved || 0) -
                  log.quantity,
                consumed:
                  (membership.selectedProducts[log.productId].consumed || 0) +
                  log.quantity,
              },
            },
          };
        }
        return membership;
      });
    });
    await crud(schState, [
      {
        db: schState.db,
        collection: "patients",
        parameters: [
          {pid: patient?.pid},
          {$set: {memberships: updatedMembershipData}},
        ],
        method: "updateOne",
      },
    ]);
    dispatch({
      type: "UPDATE_PATIENT",
      payload: {memberships: updatedMembershipData},
      pid: patient?.pid,
    });
  }
};

export const cancelAppointmentMembershipProducts = async (
  schState,
  dispatch,
  appt
) => {
  const {data} = await crud(schState, [
    {
      db: schState.db,
      collection: "membershipLogs",
      parameters: [{appointmentId: appt.aid}],
      method: "find",
    },
  ]);
  const patientRes = await crud(schState, [
    {
      db: schState.db,
      collection: "patients",
      parameters: [{pid: appt.pid}],
      method: "findOne",
    },
  ]);
  const patient = patientRes.data[0];
  const productLogs = data[0] || [];
  if (productLogs?.length) {
    let updatedMembershipData = patient?.memberships || [];
    productLogs.forEach((log) => {
      updatedMembershipData = updatedMembershipData.map((membership) => {
        if (membership.uuid === log.membershipId) {
          crud(schState, [
            {
              db: schState.db,
              collection: "membershipLogs",
              parameters: [
                {
                  membershipId: membership.uuid,
                  productId: log.productId,
                  appointmentId: appt.aid,
                },
                {$set: {status: "cancelled"}},
              ],
              method: "updateOne",
            },
          ]);
          return {
            ...membership,
            selectedProducts: {
              ...membership.selectedProducts,
              [log.productId]: {
                ...membership.selectedProducts[log.productId],
                reserved:
                  (membership.selectedProducts[log.productId].reserved || 0) -
                  log.quantity,
              },
            },
          };
        }
        return membership;
      });
    });
    await crud(schState, [
      {
        db: schState.db,
        collection: "patients",
        parameters: [
          {pid: patient?.pid},
          {$set: {memberships: updatedMembershipData}},
        ],
        method: "updateOne",
      },
    ]);
    dispatch({
      type: "UPDATE_PATIENT",
      payload: {memberships: updatedMembershipData},
      pid: patient?.pid,
    });
  }
};

function DateComponent({time, identifier: appt, dispatch, schState, socket}) {
  const [rescheduleRef, setRescheduleRef] = useState(false);
  const [date, setDate] = useState(null);
  const [duration, setDuration] = useState(null);

  useLayoutEffect(() => {
    if (appt) {
      setDate(new Date(appt.ISOdate));
      setDuration(appt.duration);
    }
  }, [appt]);

  let dateString = date?.toDateString();

  return (
    <div className=" w-full mt-6 flex justify-between items-start pr-2">
      <div className="">
        <div className="flex space-x-3 items-center relative w-full">
          <div>
            <p className="text-[#A1A1A1] text-xs  mb-1">booked for</p>
            <div className="rounded-lg overflow-hidden bg-[#E9E8FB] text-center">
              <p className="bg-[#6271FF] text-xs font-medium p-[2px] text-white">
                {weekday[date?.getDay() || 0]}
              </p>
              <p className="text-[#6271FF] font-bold text-lg px-5 py-1 space-x-1">
                <span>{dateString?.split(" ")[1]}</span>
                <span>{dateString?.split(" ")[2]}</span>
              </p>
            </div>
          </div>
          <div className="flex  relative items-start py-1">
            <p className="flex flex-col leading-3 font-semibold text-center text-xs bg-[#F6F5FE] rounded-t-lg pt-1 -mr-4 z-10">
              <span className="bg-[#6271FF]  w-16 rounded-full leading-4 text-white px-2 p-1 mb-0.5">
                {time?.toLowerCase() || ""}
              </span>
              <span className="text-[#6271FF] text-[10px]">
                {duration || ""}
              </span>
            </p>
            <p className="">
              <BsClock className="text-[#6271FF] text-[3rem] stroke-[0.1]" />
            </p>
            {!appt?.cancelled && !appt?.arrived && (
              <p className="absolute top-[-12px] right-[-12px]">
                <span
                  className="hover:cursor-pointer text-[#A1A1A1] h-[1.5rem] w-[1.5rem] overflow-hidden group rounded-lg flex justify-center items-center drop-shadow-md"
                  onClick={() => {
                    setRescheduleRef(true);
                  }}
                >
                  <MdModeEditOutline />
                </span>
              </p>
            )}
          </div>
        </div>
        {appt?.appointmentType === "live" && appt?.address && (
          <div className="flex  items-center mt-4">
            <MdLocationOn className="text-[#3182ce]" />
            <div className="w-[12rem]">
              <TruncatedTextWithTooltip
                color={"blue.500"}
                w="full"
                fontSize={"sm"}
              >
                {appt?.appointmentType === "live" ? appt?.address || "" : ""}
              </TruncatedTextWithTooltip>
            </div>
          </div>
        )}
      </div>
      <ApptType full appt={appt} />

      {rescheduleRef && appt && (
        <RescheduleAppointment
          schState={schState}
          appointment={appt}
          stateChanger={setRescheduleRef}
          dispatch={dispatch}
          socket={socket}
        />
      )}
    </div>
  );
}

function Services({appt, schState, dispatch, hideList, patient, socket}) {
  const [service, setService] = useState(null);
  const [show, setShow] = useState(false);
  const toast = useToast();

  const membership = patient?.memberships?.find(
    (memb) => memb.uuid === appt?.paidByMembership
  );

  useLayoutEffect(() => {
    if (appt) {
      setService(appt.service);
      setShow(false);
    }
  }, [appt]);

  useLayoutEffect(() => {
    if (hideList) {
      setShow(false);
    }
  }, [hideList]);

  const updateService = ({
    appt,
    service,
    schState,
    dispatch,
    socket,
    toast,
  }) => {
    changeAppointmentService({
      appt,
      service: service,
      schState,
      dispatch,
      socket,
      toast,
    });
  };

  return (
    <div className="space-y-1 justify-between w-full">
      <div className="space-y-1 mt-5">
        <p className="text-[#A1A1A1] text-xs">service</p>
        <div className="flex items-start space-x-1 relative">
          <div className="bg-[#97A1FF] leading-6 font-medium rounded-lg p-2 px-3 text-sm flex text-white relative w-full items-center">
            <span
              className="inline-block w-[40%] whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold mx-2"
              title={service}
            >
              {service}
            </span>
            {membership && (
              <div className="flex flex-col w-[60%] text-center text-xs mx-2">
                <span>Service included in membership:</span>
                <span
                  className="whitespace-nowrap overflow-hidden overflow-ellipsis"
                  title={membership.name}
                >
                  {membership.name}
                </span>
              </div>
            )}
            {show && (
              <ServiceList
                {...{
                  appt,
                  schState,
                  dispatch,
                  setShow,
                  updateService,
                  socket,
                  toast,
                }}
              />
            )}
          </div>
          {/*<span
            className="text-[#A1A1A1] hover:cursor-pointer relative"
            onClick={(e) => {
              e.stopPropagation();
              setShow(true);
              setHideList(false);
            }}
          >
            <MdModeEditOutline />
          </span>*/}
        </div>
      </div>
    </div>
  );
}

function ServiceList({
  appt,
  schState,
  dispatch,
  setShow,
  updateService,
  socket,
  toast,
}) {
  return (
    <div className="w-full h-20 absolute top-0 left-0 show  p-1 overflow-hidden rounded-lg bg-[#97A1FF] py-2">
      <ul className="overflow-y-scroll overflow-x-hidden space-y-1 sbar2 h-full text-xs">
        {schState.services.map((service, i) => {
          return (
            <li
              className="hover:bg-[#a8aff2] text-white cursor-pointer"
              key={i}
              onClick={(e) => {
                if (appt?.service !== service.serviceName) {
                  updateService({
                    appt,
                    service: service.serviceName,
                    schState,
                    dispatch,
                    socket,
                    toast,
                  });
                }
                setShow(false);
              }}
            >
              {service.serviceName}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function changeAppointmentService({
  appt,
  service,
  schState,
  dispatch,
  socket,
  toast,
}) {
  if (appt?.aid && appt.service !== service)
    swal({
      title: "Are you sure you want to change the service of this appointment?",
      text: `The servicefor this appointment will change to ${service}.`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((value) => {
      if (value) {
        const updateBody = {
          ...appt,
          service,
        };
        let {_id, ...data} = updateBody;
        let org = schState.organization;
        let location = schState.locations.find(
          (l) => l.lid === schState.selectedLocation
        );
        let sender = location?.name;

        crud(
          schState,
          [
            {
              db: schState.db,
              collection: "appointments",
              parameters: [{aid: data.aid}, {$set: data}],
              method: "updateOne",
            },
          ],
          {
            email: {
              type: "update_appointment",
              content: data,
              options: {service},
              sender,
              org,
              location,
            },
          }
        )
          .then(async (res) => {
            dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});
            let {ntf, ntfList} = notificationsData(
              schState.userType,
              "Updated appointment",
              updateBody,
              schState
            );
            socket?.emit?.("update_appt", updateBody, {ntf});

            let requestObjs = [
              {
                db: schState.db,
                collection: "notifications",
                parameters: [
                  {userType: ntfList[0].userType},
                  {$push: {[ntfList[0].id]: ntfList[0].notification}},
                  {upsert: true},
                ],
                method: "findOneAndUpdate",
              },
              {
                db: schState.db,
                collection: "notifications",
                parameters: [
                  {userType: ntfList[1].userType},
                  {$push: {[ntfList[1].id]: ntfList[0].notification}},
                  {upsert: true},
                ],
                method: "findOneAndUpdate",
              },
            ];
            await crud(schState, requestObjs);

            toast({
              title: "The appointment has been successfully booked!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
}

function Status({
  appt,
  schState,
  hideList,
  setHideList,
  dispatch,
  socket,
  openCancellationModal,
  patient,
}) {
  const [st, setSt] = useState("Unconfirmed");
  const [updating, setUpdating] = useState(false);
  const toast = useToast();
  let colorsMap = {
    Unconfirmed: "#8F8CFF",
    Confirmed: "#5754FF",
    Rescheduled: "#FF9900",
    Arrived: "#2dd4bf",
    "No Show": "#000000",
    Cancelled: "#FF0000",
  };

  let statusMap = {
    noShow: "No Show",
    cancelled: "Cancelled",
    arrived: "Arrived",
    pConfirmed: "Confirmed",
    rescheduled: "Rescheduled",
  };
  function status(appt) {
    for (let [key, val] of Object.entries(statusMap)) {
      if (appt[key]) return val;
    }
    return "Unconfirmed";
  }

  useLayoutEffect(() => {
    appt && setSt(status(appt));
  }, [appt]);

  let statusIcon = {
    Unconfirmed: <TbCalendarStats className="text-[14px]" />,
    Confirmed: <TbCalendarCheck className="text-[14px]" />,
    Rescheduled: <TbCalendarRepeat className="text-[14px]" />,
    Arrived: <FaWalking className="text-[14px]" />,
    "No Show": <FaEyeSlash className="text-[14px]" />,
    Cancelled: <TbCalendarOff className="text-[14px]" />,
  };

  const onCancel = async () => {
    appt &&
      !appt?.cancelled &&
      appt?.paymentStatus !== "paid" &&
      openCancellationModal({
        status: "cancel-appt",
        title: "Are you sure you want to cancel this appointment?",
      });
  };

  const onNoShow = (evt) => {
    appt &&
      !appt?.noShow &&
      appt?.paymentStatus !== "paid" &&
      openCancellationModal({
        status: "no-show",
        title: "Are you sure you want to change the status to No Show?",
      });
  };
  const onArrived = async () => {
    if (appt?.cancelled || appt?.arrived) return;
    setUpdating(true);
    /*if (appt?.paidByMembership) {
      const apptPatient = schState.patients.find(
        (patient) => patient.pid === appt.pid
      );
      const membershipData = apptPatient.memberships?.find((membershipData) => {
        if (membershipData.uuid === appt.paidByMembership) {
          return true;
        }
        return false;
      });
      membershipData.selectedServices[appt?.serviceId] = {
        ...membershipData.selectedServices[appt?.serviceId],
        consumed:
          parseInt(
            membershipData.selectedServices[appt.serviceId].consumed || 0
          ) + 1,
        reserved:
          parseInt(membershipData.selectedServices[appt.serviceId].reserved) -
          1,
      };
      crud(
        schState,
        [
          {
            db: schState.db,
            collection: "patients",
            parameters: [
              {pid: appt.pid},
              {
                $set: {
                  "memberships.$[membership].selectedServices":
                    membershipData.selectedServices,
                },
              },
              {
                arrayFilters: [{"membership.uuid": appt.paidByMembership}],
              },
            ],
            method: "updateOne",
          },
        ],
        null
      );
      const updatedMemberships = (apptPatient.memberships || []).map(
        (mData) => {
          if (mData.uuid === appt.paidByMembership) {
            return {
              ...mData,
              selectedServices: membershipData.selectedServices,
            };
          }
          return mData;
        }
      );
      dispatch({
        type: "UPDATE_PATIENT",
        pid: appt.pid,
        payload: {
          memberships: updatedMemberships,
        },
      });
    }*/
    await changeAppointmentStatus(
      appt,
      "Arrived",
      schState,
      dispatch,
      socket,
      toast
    );
    setUpdating(false);
  };

  const onConfirm = async () => {
    setUpdating(true);

    await changeAppointmentStatus(
      appt,
      "Confirmed",
      schState,
      dispatch,
      socket,
      toast
    );
    setUpdating(false);
  };

  let onClickActions = {
    Unconfirmed: () => {},
    Confirmed: onConfirm,
    Rescheduled: () => {},
    Arrived: onArrived,
    "No Show": onNoShow,
    Cancelled: onCancel,
  };

  let allowAction = {
    Unconfirmed: false,
    Confirmed: !(
      appt?.cancelled ||
      appt?.pConfirmed ||
      appt?.arrived ||
      appt?.noShow
    ),
    /*Rescheduled: !(
      appt?.cancelled ||
      appt?.arrived ||
      appt?.noShow ||
      appt?.paymentStatus === "paid"
    ),*/
    Rescheduled: false,
    Arrived: !(appt?.cancelled || appt?.arrived || appt?.noShow),
    "No Show":
      !appt?.noShow && appt?.paymentStatus !== "paid" && !appt?.cancelled,
    Cancelled:
      !appt?.cancelled &&
      !appt?.arrived &&
      !appt?.noShow &&
      appt?.paymentStatus !== "paid",
  };

  return (
    <div className="mt-6 space-y-1">
      <p className="text-[#A1A1A1] text-xs">status</p>
      <div className="w-full grid grid-cols-3 gap-2">
        {Object.keys(colorsMap).map((status, i) => (
          <CustomButton
            key={i}
            isSolid={st === status}
            bgColor={colorsMap[status]}
            onClick={onClickActions[status]}
            isCursorPointer={!updating && allowAction[status]}
          >
            <HStack gap="1">
              <Text>{status}</Text>

              {statusIcon[status]}
            </HStack>
          </CustomButton>
        ))}
      </div>
    </div>
  );
}

async function changeAppointmentStatus(
  appt,
  status,
  schState,
  dispatch,
  socket,
  toast
) {
  let generatingToast = null;
  try {
    const restStatus = (status) => {
      switch (status) {
        case "No Show":
          return {cancelled: false, arrived: false};
        case "Cancelled":
          return {noShow: false, arrived: false};
        case "Arrived":
          return {noShow: false, cancelled: false};
        case "Confirmed":
          return {noShow: false, cancelled: false, arrived: false};
      }
    };

    let statusMap = {
      "No Show": "noShow",
      Cancelled: "cancelled",
      Arrived: "arrived",
      Confirmed: "pConfirmed",
      Rescheduled: "rescheduled",
    };

    if (appt?.aid && !appt[statusMap[status]]) {
      {
        generatingToast = toast({
          title: "Saving status",
          description: "The status for this appointment is being updated.",
          status: "loading",
          variant: "subtle",
          duration: null,
          isClosable: true,
        });

        const updateBody = {
          ...appt,
          [statusMap[status]]: true,
          ...restStatus(status),
        };

        if (status === "Confirmed") {
          let note = updateBody.note || "";
          let name = schState.doctor
            ? schState.doctor.name
            : schState.admin.name;
          let date = lightFormat(new Date(), "dd/MM/yyyy h:mm aaa");
          note += `${note && "\n"}Confirmed by ${name} at ${date}.`;
          updateBody.note = note;
        }

        let {_id, ...data} = updateBody;
        let location = await axiosCrud(schState, [
          {
            db: schState.db,
            collection: "locations",
            parameters: [{lid: schState.selectedLocation}],
            method: "findOne",
          },
        ]);
        location = location.data[0];
        let sender = location?.name;

        await axiosCrud(
          schState,
          [
            {
              db: schState.db,
              collection: "appointments",
              parameters: [{aid: data.aid}, {$set: data}],
              method: "updateOne",
            },
          ],
          {
            email: {
              type: "update_appointment",
              content: data,
              options: {status},
              sender,
              org: schState?.organization,
              location,
            },
          }
        );
        let {ntf, ntfList} = notificationsData(
          schState.userType,
          "Updated appointment",
          updateBody,
          schState
        );

        socket?.emit?.("update_appt", updateBody, {ntf});

        let requestObjs = [
          {
            db: schState.db,
            collection: "notifications",
            parameters: [
              {userType: ntfList[0].userType},
              {$push: {[ntfList[0].id]: ntfList[0].notification}},
              {upsert: true},
            ],
            method: "findOneAndUpdate",
          },
          {
            db: schState.db,
            collection: "notifications",
            parameters: [
              {userType: ntfList[1].userType},
              {$push: {[ntfList[1].id]: ntfList[0].notification}},
              {upsert: true},
            ],
            method: "findOneAndUpdate",
          },
        ];

        await axiosCrud(schState, requestObjs);
        if (status == "Arrived") {
          try {
            await updateApptMemb({
              state: schState,
              appointmentId: appt.aid,
              serviceId: appt.serviceId,
              membershipForServiceId: appt.paidByMembership,
              patientId: appt.pid,
              productsToAssign: (appt.products || []).map((p) => {
                return {
                  quantity: p.quantity,
                  covered: p.covered || 0,
                  id: p.id,
                };
              }),
            });
          } catch (e) {
            await updateApptMemb({
              state: schState,
              appointmentId: appt.aid,
              serviceId: appt.serviceId,
              membershipForServiceId: appt.paidByMembership,
              patientId: appt.pid,
              productsToAssign: (appt.products || []).map((p) => {
                return {
                  quantity: p.quantity,
                  covered: p.covered || 0,
                  id: p.id,
                };
              }),
            });
          }
        }

        toast.close(generatingToast);
        dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});
        toast({
          title: "The appointment has been successfully updated!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  } catch (error) {
    toast.close(generatingToast);
    toast({
      title: "Update error.",
      description: error.response?.data || error.message,
      status: "error",
      isClosable: true,
    });
  }
}

const CustomButton = ({
  isSolid,
  isCursorPointer = true,
  bgColor,
  onClick,
  children,
}) => {
  return (
    <Button
      size={"xs"}
      variant={isSolid ? "solid" : "outline"}
      bg={isSolid ? bgColor : "transparent"}
      color={isSolid ? "white" : bgColor}
      borderColor={bgColor}
      borderWidth={isSolid ? "0" : "2px"}
      onClick={() => isCursorPointer && onClick()}
      cursor={isCursorPointer ? "pointer" : "not-allowed"}
    >
      {children}
    </Button>
  );
};
