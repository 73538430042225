import React, {useState, useEffect, useContext} from "react";
import {
  sendCardReqLink,
  getPaymentMethods,
  executePrepaymentPayment,
} from "../../../crudRequests";
import {loadStripe} from "@stripe/stripe-js";
import {BiLink} from "react-icons/bi";
import {
  useToast,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
} from "@chakra-ui/react";
import {PatientContext} from "../../../pages/Patients";

export default function PrepaymentCharge({
  state,
  formValues,
  isOpen,
  onClose,
  createPrepayment,
  patient,
}) {
  const [sendingLink, setSendingLink] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const toast = useToast();

  useEffect(function () {
    if (state.db) {
      getPaymentMethods(state, patient.pid)
        .then((paymentMethods) => {
          setCards(paymentMethods.data);
          setSelectedCard(paymentMethods.data.find((c) => c?.default));
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: error.response?.data || error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          console.log(error);
        });
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>Payment</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack gap={4}>
            <CardsList {...{cards, selectedCard, setSelectedCard}} />
            <Box
              className="hover:text-yellow-400 cursor-pointer self-end text-blue-500 mr-9 flex justify-end items-center"
              onClick={() => {
                setSendingLink(true);
                sendCardReqLink(state, patient)
                  .then(() => {
                    setSendingLink(false);
                    toast({
                      title: "Success",
                      description: "The card request link has been sent!",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                    });
                  })
                  .catch((error) => {
                    setSendingLink(false);
                    toast({
                      title: "Error",
                      description: error.response?.data?.toString(),
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  });
              }}
            >
              <span>
                <BiLink />
              </span>
              <span className="text-xs">Send Card Request</span>
              {sendingLink && (
                <span className="">
                  <svg
                    className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25 stroke-[4px]"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </Box>
            <SendFormChakra
              state={state}
              patient={patient}
              selectedCard={selectedCard}
              formValues={formValues}
              onClose={onClose}
              createPrepayment={createPrepayment}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function SendFormChakra({
  state,
  patient,
  selectedCard,
  formValues,
  onClose,
  createPrepayment,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    setIsLoading(true);
    const location = state.locations.find(
      (l) => l.lid === state.selectedLocation
    );
    const stripe = await loadStripe(location?.stpPublicKey || "Invalid key");
    if (patient && stripe) {
      const handleServerResponse = async (response) => {
        try {
          if (response.error) {
            toast({
              title: "Error",
              description: response.error,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else if (response.requires_action) {
            const {error: errorAction, paymentIntent} =
              await stripe.handleCardAction(
                response.payment_intent_client_secret
              );
            if (errorAction) {
              toast({
                title: "Error",
                description: errorAction.message,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            } else {
              const paymentResponse = await executePrepaymentPayment(
                state,
                formValues.amount,
                patient.pid,
                selectedCard,
                paymentIntent.id
              );
              handleServerResponse(paymentResponse.data);
            }
          } else {
            if (response?.success) {
              await createPrepayment();
              onClose();
            }
          }
        } catch (error) {
          toast({
            title: "Error",
            description: error.response?.data || error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      };

      const paymentResponse = await executePrepaymentPayment(
        state,
        formValues.amount,
        patient.pid,
        selectedCard
      );

      handleServerResponse(paymentResponse.data);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} w="full" px={8}>
      <Button
        colorScheme="blue"
        fontWeight="medium"
        onClick={() => {
          if (selectedCard) {
            handleSubmit();
          } else {
            toast({
              title: "Please select a card",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }}
        w="full"
        h="50px"
        isLoading={isLoading}
      >
        Confirm Charge
        {` ($${formValues.amount} CAD)`}
      </Button>
    </Box>
  );
}

function CardsList({cards, selectedCard, setSelectedCard}) {
  return (
    <div className="space-y-2 mt-4 w-full px-8">
      <div
        className="rounded-[10px] py-2 text-background font-semibold text-center mr-2"
        style={{backgroundColor: "#4A90E2"}}
      >
        <ul className="flex justify-center items-center  lg:text-base">
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Card
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Exp Date
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Selected Card
          </li>
          {/*<li className="flex justify-center items-center flex-1 text-[16px]">Action</li>*/}
        </ul>
      </div>
      <div className="overflow-hidden flex justify-center max-h-[6rem]">
        <div className="overflow-y-scroll  w-full space-y-2 pr-2 pb-2 sbar2">
          {cards.length > 0 ? (
            cards.map((c, i) => {
              return (
                <div
                  key={i}
                  className=" rounded-[10px] mt-0 py-2 bg-[#DFDEFF] text-sm text-gray-600 text-opacity-75 font-semibold text-center h-fit"
                  style={{
                    backgroundColor: c === selectedCard ? "#A6C8F0" : "#D0E4F7",
                  }}
                >
                  <ul className="flex justify-center items-center md:text-sm w-full">
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {"****" + c.card.last4}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {`${c.card.exp_month}/${c.card.exp_year}`}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      <div
                        className="bg-[#5754FF] rounded-full p-2 w-20 cursor-pointer transition duration-500"
                        onClick={() => {
                          setSelectedCard(c);
                        }}
                        style={{
                          backgroundColor:
                            c === selectedCard ? "#1E88E5" : "#FFFFFF",
                          boxShadow:
                            c !== selectedCard && "0 0 0 2px inset #1E88E5",
                        }}
                      >
                        <p
                          className="w-3 h-3 rounded-full bg-white transition duration-500"
                          style={{
                            transform:
                              c === selectedCard
                                ? "translateX(55px)"
                                : "translateX(0)",
                            backgroundColor:
                              c === selectedCard ? "#FFFFFF" : "#1E88E5",
                          }}
                        ></p>
                      </div>
                    </li>
                  </ul>
                </div>
              );
            })
          ) : (
            <div className=" rounded-[10px] mt-0 py-2 bg-[#D0E4F7] text-sm text-gray-600 text-opacity-75 font-semibold text-center h-fit">
              <span>No registered card</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
