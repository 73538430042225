import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {BsArrowRight} from "react-icons/bs";
export default function BookNowHome({dispatch, bookState}) {
  return (
    !bookState.loader && (
      <section className="w-full h-full space-y-4 flex items-center justify-center flex-col relative px-4">
        <div className="flex justify-center items-center ">
          <span className="h-14 w-14 rounded-full shadow-md  flex justify-center items-center">
            <img
              src="/images/Doctor/logo_black.png"
              alt=""
              className="object-contain"
            />
          </span>
        </div>

        <h1 className="text-[#636363] font-semibold sm:text-2xl text-xl text-center">
          Welcome to the
        </h1>
        <p className="text-black sm:text-5xl text-3xl font-semibold text-center">
          <span className="block">Brightlight</span>
          <span className="block">Appointment</span>
          <span className="block">Booking Service</span>
        </p>
        <p className="text-center mx-auto max-w-[25rem] text-[#636363] sm:text-lg text-base  font-medium">
          {bookState.organization ? (
            <span>
              {`Here, you can book an appointment for any desired service offered by ${
                bookState.location?.name || ""
              } with practitioners of your choosing.`}
            </span>
          ) : (
            <span className="text-orange-500">
              There is no location for this partner organization at this URL!
            </span>
          )}
        </p>

        {bookState.organization && (
          <div
            className="mt-[2rem_!important] cursor-pointer group"
            onClick={() => {
              if (bookState.organization)
                dispatch({type: "SELECT_THREAD", payload: "selections"});
            }}
          >
            <p className="flex justify-center items-center space-x-2">
              <span className="text-[#9C9C9C] font-medium transition group-hover:text-[#636363]">
                Book Now
              </span>
              <span className="h-7 w-7 flex justify-center transition items-center rounded-full group-hover:bg-[#636363] group-hover:text-[#E4E4E4]  bg-[#E4E4E4] text-[#A2A2A2]">
                <BsArrowRight />
              </span>
            </p>
          </div>
        )}
      </section>
    )
  );
}
