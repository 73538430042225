import {
  Stack,
  TableCaption,
  Table,
  Tr,
  Td,
  Thead,
  Th,
  Tbody,
  Text,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import fetchProducts from "../../Products/helpers/fetchProducts";

export function PatientMembershipBalancesTable(props) {
  const [productMap, setProductMap] = useState({});
  const serviceMap = {};

  useEffect(() => {
    fetchProducts(
      props.schState,
      () => {},
      (products) => {
        const productMapping = {};
        products.forEach((product) => {
          if (props.membership.selectedProducts[product.id]) {
            productMapping[product.id] = product;
          }
        });
        setProductMap(productMapping);
      },
      () => {}
    );
  }, [props.schState, props.membership.selectedProducts]);

  for (const service of props.schState?.services) {
    if (
      Object.keys(props.membership.selectedServices).includes(service.serviceId)
    ) {
      serviceMap[service.serviceId] = service;
    }
  }

  return (
    <Stack overflow="scroll">
      <Table height="100%" width="100%">
        <TableCaption>
          Patient's remaining balances for this membership
        </TableCaption>
        <Thead bg="gray.100">
          <Tr>
            <Th>Service/Product</Th>
            <Th>Description</Th>
            <Th>Category</Th>
            <Th>Included</Th>
            <Th>Reserved</Th>
            <Th>Consumed</Th>
            <Th>Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.keys(props.membership.selectedServices).map((service) => {
            const serviceData = props.membership.selectedServices[service];
            return (
              <Tr key={service}>
                <Td fontSize={"15px"}>
                  <Text>{serviceMap[service]?.serviceName}</Text>
                </Td>
                <Td fontSize={"15px"}>
                  <Text>{serviceMap[service]?.description}</Text>
                </Td>
                <Td fontSize={"15px"}>
                  <Text>{serviceMap[service]?.category}</Text>
                </Td>
                <Td fontSize={"15px"}>
                  <Text>{serviceData.quantity}</Text>
                </Td>
                <Td fontSize={"15px"}>
                  <Text>{serviceData.reserved || 0}</Text>
                </Td>
                <Td fontSize={"15px"}>
                  <Text>{serviceData.consumed || 0}</Text>
                </Td>
                <Td fontSize={"15px"}>
                  {serviceData.quantity -
                    (serviceData.consumed || 0) -
                    (serviceData.reserved || 0)}
                </Td>
              </Tr>
            );
          })}
          {props.membership.selectedProducts &&
            Object.keys(props.membership.selectedProducts).map((productId) => {
              const productData = props.membership.selectedProducts[productId];
              const product = productMap[productId];
              return (
                <Tr key={productId}>
                  <Td fontSize={"15px"}>
                    <Text>{product?.name}</Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    <Text>{product?.description}</Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    <Text>{product?.category}</Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    <Text>{productData.quantity}</Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    <Text>{productData.reserved || 0}</Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    <Text>{productData.consumed || 0}</Text>
                  </Td>
                  <Td fontSize={"15px"}>
                    {productData.quantity -
                      (productData.consumed || 0) -
                      (productData.reserved || 0)}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </Stack>
  );
}
