import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Badge,
  Text,
  Button,
  Stack,
  Icon,
  Flex,
  Tooltip,
  Checkbox,
} from "@chakra-ui/react";
import {AiOutlineDelete} from "react-icons/ai";
import {BiSolidEdit} from "react-icons/bi";
import {deleteItem} from "./helpers/deleteItem";

export default function ProductsTable({
  dashState,
  products,
  setProducts,
  fields,
  searchText,
  selectedItem,
  setSelectedItem,
  setShowEditOrCreateModal,
  toast,
  allowSelection,
  selections,
  setSelections,
}) {
  return (
    <TableContainer h="full" flex={1} overflowY="auto">
      <Table size="md">
        <Thead position="sticky" top="0" bg="gray.100" zIndex={10}>
          <Tr>
            {allowSelection && <Th fontSize={"11px"}>Selection</Th>}
            {Object.keys(fields).map((field) => {
              return <Th fontSize={"11px"}>{field}</Th>;
            })}
            <Th fontSize={"11px"}>{"Action"}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products?.map((product, i) => {
            return (
              <>
                <Tr key={i}>
                  {allowSelection && (
                    <Td fontSize={"15px"}>
                      <Checkbox
                        colorScheme="blue"
                        isChecked={selections.has(product)}
                        isDisabled={product.stock === 0}
                        onChange={() => {
                          setSelections((prev) => {
                            let newSet = new Set(prev);
                            if (prev.has(product)) {
                              newSet.delete(product);
                            } else newSet.add(product);
                            return newSet;
                          });
                        }}
                      ></Checkbox>
                    </Td>
                  )}
                  {Object.values(fields).map((v) => (
                    <Td fontSize={"15px"}>{v(product) ?? "N/A"}</Td>
                  ))}
                  <Td fontSize={"15px"}>
                    <Flex gap="2">
                      <Tooltip hasArrow label="Update" rounded={"sm"}>
                        <Badge
                          colorScheme="blue"
                          rounded="md"
                          p="1"
                          cursor={"pointer"}
                          onClick={() => {
                            setSelectedItem(product);
                            setShowEditOrCreateModal(true);
                          }}
                        >
                          <Icon as={BiSolidEdit} fontSize="15px" mt="-0.5" />
                        </Badge>
                      </Tooltip>
                      <Tooltip hasArrow label="Delete" rounded={"sm"}>
                        <Badge
                          colorScheme="red"
                          rounded="md"
                          p="1"
                          cursor={"pointer"}
                          onClick={() =>
                            deleteItem({
                              dashState,
                              product,
                              toast,
                              setProducts,
                            })
                          }
                        >
                          <Icon
                            as={AiOutlineDelete}
                            fontSize="15px"
                            mt="-0.5"
                          />
                        </Badge>
                      </Tooltip>
                    </Flex>
                  </Td>
                </Tr>
              </>
            );
          })}
        </Tbody>
        {products.length === 0 && (
          <TableCaption mb="4">No products to display.</TableCaption>
        )}
      </Table>
    </TableContainer>
  );
}
