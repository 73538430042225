import {Box, Text, Stat, StatLabel, StatNumber} from "@chakra-ui/react";

export const BillingTotal = ({billingTotal, browseToBilling}) => {
  return (
    <Box
      flex={1}
      p={5}
      borderRadius="lg"
      boxShadow="md"
      bg="white"
      borderWidth="1px"
      borderColor="gray.200"
      cursor="pointer"
      onClick={browseToBilling}
      _hover={{
        transform: "translateY(-2px)",
        transition: "transform 0.2s",
        boxShadow: "lg",
      }}
      height="209px"
    >
      <Stat textAlign="center">
        <StatLabel fontSize="lg" fontWeight="bold">
          Billing Total
        </StatLabel>
        <StatNumber color={billingTotal > 0 ? "red.500" : "green.500"}>
          ${billingTotal.toFixed(2)}
        </StatNumber>
        <Text fontSize="sm" mt={2} color="gray.500">
          Click to view details
        </Text>
      </Stat>
    </Box>
  );
};
