export function ProductsInMemberships(memberships, prodLogs = []) {
  const availableSet = new Set(
    memberships.flatMap((ele) => Object.keys(ele.selectedProducts ?? {}))
  );
  //console.log(availableSet);
  const reserved = {reservedQty: {}, qtyByMembership: {}};
  prodLogs.forEach((log) => {
    let productId = log.productId;
    let membershipId = log.membershipId;
    if (membershipId && availableSet.has(membershipId)) {
      reserved.reservedQty[productId] ??= 0;
      reserved.reservedQty[productId] += log.quantity;

      reserved.qtyByMembership[membershipId] ??= {};
      reserved.qtyByMembership[membershipId][productId] = log.quantity;
    }
  });

  let productsFromMemb = memberships.reduce((acc, ele) => {
    let pdts = ele.selectedProducts ?? {};
    Object.entries(pdts).forEach(([productId, data]) => {
      const productBalance =
        (data?.quantity || 0) - (data?.consumed || 0) - (data?.reserved || 0);

      acc[productId] ??= {
        availableQty: reserved.reservedQty[productId] ?? 0,
        relatedMemberships: [],
      };
      acc[productId].availableQty += productBalance;
      acc[productId].relatedMemberships.push(ele);
    });
    return acc;
  }, {});

  return {productsFromMemb, reservedForAppt: reserved};
}
export function ServicesInMemberships(
  memberships,
  reservedService,
  reservedMemb
) {
  const availableSet = new Set(
    memberships.flatMap((ele) => Object.keys(ele.selectedServices ?? {}))
  );
  //console.log(availableSet);
  const reserved =
    reservedMemb && availableSet.has(reservedMemb)
      ? {
          reservedQty: {[reservedService]: 1},
          qtyByMembership: {[reservedMemb]: {[reservedService]: 1}},
        }
      : {reservedQty: {}, qtyByMembership: {}};

  let servicesFromMemb = memberships.reduce((acc, ele) => {
    let pdts = ele.selectedServices ?? {};
    Object.entries(pdts).forEach(([serviceId, data]) => {
      const serviceBalance =
        (data?.quantity || 0) - (data?.consumed || 0) - (data?.reserved || 0);

      acc[serviceId] ??= {
        availableQty: reserved.reservedQty[serviceId] ?? 0,
        relatedMemberships: [],
      };
      acc[serviceId].availableQty += serviceBalance;
      acc[serviceId].relatedMemberships.push(ele);
    });
    return acc;
  }, {});
  Object.entries(servicesFromMemb).forEach(([serviceId, data]) => {
    data.relatedMemberships.sort((a, b) => {
      let aEnd = a.membership ? a.endDate : Number.MAX_SAFE_INTEGER;
      let bEnd = b.membership ? b.endDate : Number.MAX_SAFE_INTEGER;

      return aEnd - bEnd;
    });
  });

  return {servicesFromMemb, reservedForAppt: reserved, memberships};
}
