import {crud} from "../../../crudRequests";

export default async function createDiscount({
  dashState,
  newDiscount,
  toast,
  action,
}) {
  try {
    const generatingToast = toast({
      title: "Creating Discount...",
      status: "loading",
      variant: "subtle",
      duration: null,
      isClosable: true,
    });

    const res = await crud(dashState, [
      {
        db: dashState.db,
        collection: "discounts",
        parameters: [newDiscount],
        method: "insertOne",
      },
    ]);

    toast.close(generatingToast);
    action?.();
  } catch (error) {
    toast({
      title: "Failed to create new Discount",
      description:
        error?.message || "An error occurred while creating new discount.",
      status: "error",
      isClosable: true,
    });
  }
}
