import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Button,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import {FaPlus} from "react-icons/fa6";
import {PrepaymentsTable} from "./PrepaymentsTable";
import {AddPrepaymentModal} from "./AddPrepaymentModal";
import {
  DashStateContext,
  DispatchContext,
  PatientContext,
} from "../../../pages/Patients";
import {useContext, useEffect, useState} from "react";
import {crud} from "../../../crudRequests";

export const Prepayments = () => {
  const [patient, setPatient] = useContext(PatientContext);
  const [prepayments, setPrepayments] = useState({
    open: [],
    consumed: [],
  });
  const [totalBalance, setTotalBalance] = useState(0);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    const fetchPrepayments = async () => {
      const resPrepayments = await crud(dashState, [
        {
          db: dashState.db,
          collection: "prepayments",
          parameters: [{linkedPatientId: patient.pid}],
          method: "find",
        },
      ]);
      const prepayments = resPrepayments?.data[0];
      const openPrepayments = prepayments.filter(
        (prepayment) => prepayment.balanceAvailable > 0
      );
      const consumedPrepayments = prepayments.filter(
        (prepayment) => prepayment.balanceAvailable === 0
      );
      setPrepayments({open: openPrepayments, consumed: consumedPrepayments});
      setTotalBalance(
        prepayments.reduce(
          (acc, prepayment) => acc + parseFloat(prepayment.balanceAvailable),
          0
        )
      );
    };
    fetchPrepayments();
  }, []);

  return (
    <Box position="relative">
      <Text fontSize="xl" fontWeight="bold" mb={4} px={4}>
        Total Balance: ${parseFloat(totalBalance).toFixed(2)}
      </Text>
      <Tabs variant="soft-rounded" colorScheme="blue" w="full">
        <Box position="relative" mb={4}>
          <Flex w="full" justify="center">
            <TabList>
              <Tab>Open</Tab>
              <Tab>Consumed</Tab>
            </TabList>
          </Flex>
          <Button
            position="absolute"
            right={0}
            top={0}
            variant="ghost"
            leftIcon={<FaPlus />}
            colorScheme="blue"
            onClick={onOpen}
          >
            Add Pre-Payment
          </Button>
        </Box>
        <AddPrepaymentModal
          isOpen={isOpen}
          onClose={onClose}
          patient={patient}
          dispatch={dispatch}
          state={dashState}
          prepayments={prepayments}
          setPrepayments={setPrepayments}
          setTotalBalance={setTotalBalance}
        />
        <TabPanels>
          <TabPanel>
            <PrepaymentsTable
              prepayments={prepayments.open}
              patientId={patient.pid}
              state={dashState}
            />
          </TabPanel>
          <TabPanel>
            <PrepaymentsTable
              prepayments={prepayments.consumed}
              patientId={patient.pid}
              state={dashState}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
