import {useState, useCallback, useEffect, useRef, useMemo} from "react";
import {
  Box,
  Button,
  Tooltip,
  FormControl,
  FormLabel,
  Heading,
  Input,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  HStack,
  Text,
  InputLeftElement,
  Flex,
  InputGroup,
  Icon,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import {HiOutlinePlus} from "react-icons/hi";
import sendNotification from "./helpers/sendNotification";
import {IoSearch} from "react-icons/io5";
import {ViewIcon, EmailIcon, EditIcon, DeleteIcon} from "@chakra-ui/icons";
import CreateDiscount from "./CreateDiscount";
import fetchDiscounts from "./helpers/fetchDiscounts";
import updateDiscount from "./helpers/updateDiscount";
import deleteDiscount from "./helpers/deleteDiscount";
export default function DiscountsModule({dashState}) {
  const [waiting, setWaiting] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [searchText, setSearchText] = useState("");
  const toast = useToast();

  useEffect(() => {
    fetchDiscounts(dashState, setDiscounts, toast);
  }, []);

  let filteredList = useMemo(() => {
    function filterText(element, text) {
      try {
        let regex = new RegExp(text, "i");
        if (text.length === 0) {
          return element;
        }
        return (
          element["name"]?.match(regex) ||
          String(element["discountRate"]).match(regex)
        );
      } catch (error) {
        return false;
      }
    }

    return discounts.filter((p) => filterText(p, searchText));
  }, [searchText, discounts]);

  return (
    <Box
      w="full"
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      overflowY={"hidden"}
      p={4}
    >
      <Box
        w="full"
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        overflowY={"hidden"}
      >
        {createModal && (
          <CreateDiscount
            {...{
              setCreateModal,
              createModal,
              dashState,
              setDiscounts,
            }}
          />
        )}
        <Heading as="h2" color={"blue.500"} size="lg" my={4}>
          Discounts Module
        </Heading>

        <Flex
          justify={"space-between"}
          w="full"
          borderBottom={"1px solid "}
          borderColor={"gray.200"}
          align={"center"}
          p="5"
          pl="0"
        >
          <Flex align={"center"} gap={4}>
            <InputGroup p="1" w="60">
              <InputLeftElement>
                <Icon as={IoSearch} color="gray.500" mt="2" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearchText(e.target.value.toLowerCase());
                }}
              />
            </InputGroup>
          </Flex>

          <Button
            leftIcon={<HiOutlinePlus />}
            colorScheme="blue"
            pl={2}
            onClick={() => setCreateModal(true)}
          >
            Create
          </Button>
        </Flex>

        <TableContainer h="full" flex={1} overflowY="auto">
          <Table variant="simple">
            <Thead position="sticky" top="0" bg="gray.100" zIndex={10}>
              <Tr>
                <Th>Name</Th>
                {/*<Th>Description</Th>*/}
                <Th>Rate</Th>
                <Th>Creation Date</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredList.map((element, i) => (
                <Tr key={i}>
                  <Td>{`${element.name || ""}`}</Td>
                  {/*<Td>
                    <Text w="25rem">
                      <TruncatedTextWithTooltip>{`${
                        element.description || ""
                      }`}</TruncatedTextWithTooltip>
                    </Text>
                  </Td>*/}
                  <Td>${Number(element.discountRate).toFixed(2)}</Td>
                  <Td>{new Date(element.createdAt).toLocaleDateString()}</Td>
                  <Td>
                    <Button
                      leftIcon={<ViewIcon />}
                      size="sm"
                      onClick={() => {
                        setSelectedDiscount(element);
                        setIsEditing(false);
                        onOpen();
                      }}
                    >
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Discount Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedDiscount &&
                (isEditing ? (
                  <VStack spacing={4} align="stretch">
                    <FormControl>
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <Input
                        id="name"
                        type="name"
                        value={selectedDiscount.name}
                        onChange={(e) =>
                          setSelectedDiscount({
                            ...selectedDiscount,
                            name: e.target.value,
                          })
                        }
                        required
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="rate">Discount Rate</FormLabel>
                      <Input
                        onBlur={() => {
                          if (
                            /^0.+/.test(
                              String(selectedDiscount.discountRate)
                            ) &&
                            !isNaN(Number(selectedDiscount.discountRate))
                          )
                            setSelectedDiscount((prev) => {
                              return {
                                ...prev,
                                discountRate: String(
                                  Number(selectedDiscount.discountRate)
                                ),
                              };
                            });
                        }}
                        id="rate"
                        type="text"
                        value={selectedDiscount.discountRate}
                        onChange={(e) => {
                          const validNumber = /^\d+(\.\d{0,2})?$/;
                          let n = Number(e.target.value);
                          if (
                            (validNumber.test(e.target.value) ||
                              e.target.value === "") &&
                            n >= 0 &&
                            n <= 100
                          ) {
                            setSelectedDiscount({
                              ...selectedDiscount,
                              discountRate: e.target.value,
                            });
                          }
                        }}
                        required
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel htmlFor="description">Description</FormLabel>
                      <Textarea
                        id="description"
                        type="text"
                        value={selectedDiscount.description}
                        onChange={(e) =>
                          setSelectedDiscount({
                            ...selectedDiscount,
                            description: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </VStack>
                ) : (
                  <VStack align="stretch" spacing={2}>
                    <Text>
                      <strong>Name:</strong> {`${selectedDiscount.name}`}
                    </Text>
                    <Text>
                      <strong>Description:</strong>{" "}
                      {`${selectedDiscount.description}`}
                    </Text>

                    <Text>
                      <strong>Creation Date:</strong>{" "}
                      {new Date(selectedDiscount.createdAt).toLocaleString()}
                    </Text>
                  </VStack>
                ))}
            </ModalBody>
            <ModalFooter>
              <HStack spacing={2}>
                {!isEditing && (
                  <>
                    <Button
                      leftIcon={<EditIcon />}
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      leftIcon={<DeleteIcon />}
                      isDisabled={waiting}
                      onClick={async () => {
                        if (!waiting && selectedDiscount) {
                          setWaiting(true);

                          let action = () => {
                            setDiscounts((prev) =>
                              prev.filter((e) => e.id !== selectedDiscount.id)
                            );

                            setSelectedDiscount(null);
                            setIsEditing(false);
                            onClose();
                          };
                          await deleteDiscount({
                            dashState,
                            selectedDiscount,
                            toast,
                            action,
                          });
                          setWaiting(false);
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </>
                )}
                {isEditing && (
                  <Button
                    isDisabled={
                      waiting ||
                      isNaN(selectedDiscount.discountRate) ||
                      selectedDiscount.discountRate === ""
                    }
                    colorScheme="blue"
                    onClick={async () => {
                      if (!waiting && selectedDiscount) {
                        setWaiting(true);

                        let action = () => {
                          setDiscounts((prev) =>
                            prev.map((ds) =>
                              ds.id === selectedDiscount.id
                                ? selectedDiscount
                                : ds
                            )
                          );

                          setWaiting(false);
                          setSelectedDiscount(null);
                          setIsEditing(false);
                          onClose();
                        };
                        await updateDiscount({
                          dashState,
                          selectedDiscount,
                          toast,
                          action,
                        });
                      }
                    }}
                  >
                    Save Changes
                  </Button>
                )}
                <Button variant="ghost" onClick={onClose}>
                  Close
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}

export function TruncatedTextWithTooltip({children, ...props}) {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTruncated(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);

    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [children]);

  return (
    <Tooltip label={children} isDisabled={!isTruncated}>
      <Text ref={textRef} isTruncated {...props}>
        {children}
      </Text>
    </Tooltip>
  );
}
