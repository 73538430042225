import {Box, FormControl, HStack, Badge, FormLabel} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import Select from "react-select";
import fetchDiscounts from "./helpers/fetchDiscounts";
export default function SearchableDiscounts({
  state,
  labelFontSize = "sm",
  fontWeight = "500",
  selectedDiscount,
  setSelectedDiscount,
  selectOptions = {},
}) {
  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    fetchDiscounts(state, (data) => {
      let d = selectedDiscount;
      setDiscounts(
        data.map((e) => {
          let payload = {
            label: `${e.name} (${e.discountRate}%)`,
            id: e.id,
            value: e.discountRate,
          };
          if (d?.id === e.id && d?.value === e.discountRate) {
            d = payload;
          }
          return payload;
        })
      );
      setSelectedDiscount(d);
    });
  }, []);

  const handleChange = (option) => {
    setSelectedDiscount(option);
  };

  return (
    <Box>
      <FormControl my="0">
        <FormLabel
          mb="1"
          ml="2"
          color={"#A2A2A2"}
          fontSize={labelFontSize}
          fontWeight={fontWeight}
        >
          Discount
        </FormLabel>
        <HStack>
          <Select
            {...selectOptions}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              singleValue: (base) => ({
                ...base,
                fontWeight: "500",
                color: "#7f7b91",
              }),
              control: (base) => ({
                ...base,
                borderRadius: "12px",
                outline: "none",
                paddingTop: "2px",
                paddingBottom: "2px",
                marginTop: "0px",
                color: "#7f7b91",
              }),
            }}
            className="w-[16rem] mt-0 "
            options={discounts}
            value={selectedDiscount}
            onChange={handleChange}
            placeholder="-"
            isClearable
            isSearchable
          />
          <Badge
            cursor={"not-allowed"}
            colorScheme="gray"
            color={"gray.600"}
            rounded="md"
            textAlign={"center"}
            p="3"
          >
            {`${Number(selectedDiscount?.value || 0)}%`}
          </Badge>
        </HStack>
      </FormControl>
    </Box>
  );
}
