import {
  Box,
  Flex,
  TableContainer,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Button,
  Badge,
  Tooltip,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import {useContext, useState, useRef, useEffect} from "react";
import {DashStateContext, DashDispatchContext} from "../../pages/Billing";
import {RiFileExcel2Line} from "react-icons/ri";
import {
  DashStateContext as PatientsDashStateContext,
  PatientContext,
  DispatchContext,
} from "../../pages/Patients";
import {fetchTransactions} from "./helpers/fetchTransactions";
import {fetchBillingQueries} from "./helpers/billingQueries";
import {
  loadDataForAdminLogin,
  loadDataForDoctorLogin,
} from "../../crudRequests";
import {useNavigate} from "react-router-dom";
import {IoSearch} from "react-icons/io5";
import CustomFields from "./CustomFields";
import SimpleTransactionsList from "./SimpleTransactionsList";
import GroupingFields from "./GroupingFields";
import BillingQueries from "./BillingQueries";
import ApplyPayment from "./ApplyPayment";
import Filters from "./Filters";
import PaymentsTable from "../Payments/PaymentsTable";
import {MdClose} from "react-icons/md";
import {cancelTransactions} from "./helpers/cancelTransactions";
import getExcel from "./helpers/getExcel";
import fieldValueFactory from "./helpers/fieldValueFactory";
import Calc from "./Calculation/Calc";
import CancelAppointmet from "../appointments/AppointmentModal.js/CancelAppointmet";
import TransactionGrouper from "./TransactionGrouper";
export default function TransactionsTable({
  renderedFromPatient,
  stateFromBilling,
  dispatchFromBilling,
}) {
  const p = useContext(PatientContext);
  let patient = p?.[0];

  const dashState = useContext(
    renderedFromPatient ? PatientsDashStateContext : DashStateContext
  );
  const dispatch = useContext(
    renderedFromPatient ? DispatchContext : DashDispatchContext
  );
  const toast = useToast();

  const [fields, setFields] = useState({
    Status: fieldValueFactory.Transaction["Status"],
    "Patient Name": fieldValueFactory.Transaction["Patient Name"],
    "Clinician Name": fieldValueFactory.Transaction["Clinician Name"],
    "Service Name": fieldValueFactory.Transaction["Service Name"],
    "Service Date & Time": fieldValueFactory.Transaction["Service Date & Time"],
  });
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [groupings, setGroupings] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [applyPayment, setApplyPayment] = useState({amount: 0, from: ""});
  const [page, setPage] = useState(0);
  const [prevLocation, setPrevLocation] = useState(dashState.selectedLocation);
  const limit = 250;
  let loadingRef = useRef(null);
  let rootRef = useRef(null);
  const [searchText, setSearchText] = useState("");

  const [allowSelection, setAllowSelection] = useState("");
  const [selections, setSelections] = useState(new Set());
  const [waiting, setWaiting] = useState(false);
  const [paymentType, setPaymentType] = useState("total");

  const isLoadingRef = useRef(null);
  const mirrorTable = useRef([]);

  const [cellSelection, setCellSelection] = useState(new Map());
  const [rowCalcValues, setRowCalcValues] = useState([]);
  const [colCalcValues, setColCalcValues] = useState([]);
  const [query, setQuery] = useState({});
  const [billingQueries, setBillingQueries] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchBillingQueries(dashState, toast, setBillingQueries);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingRef.current) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: rootRef.current,
      }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchTransactions(
      dashState,
      page * limit,
      limit,
      setTransactions,
      (v) => {
        isLoadingRef.current = v;
      },
      toast,
      patient
    );
  }, [page]);

  const reloadState = function (destination) {
    if (dashState.userType === "admin")
      loadDataForAdminLogin(
        {
          ...dashState,
          destination,
        },
        navigate,
        null,
        null
      );
    else
      loadDataForDoctorLogin(
        {
          ...dashState,
          destination,
        },
        navigate,
        null,
        null
      );
  };

  if (prevLocation !== dashState.selectedLocation) {
    setPrevLocation(dashState.selectedLocation);
    dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
    reloadState("../billing");
    if (page === 0) {
      fetchTransactions(
        dashState,
        0,
        limit,
        setTransactions,
        (v) => {
          isLoadingRef.current = v;
        },
        toast,
        patient
      );
    } else setPage(0);
  }

  return (
    <>
      {dashState.changingLocation && (
        <div className="fixed  inset-0 bg-white/80 z-[99999]  flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
        </div>
      )}

      <Box
        w="full"
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        overflowY={"hidden"}
        ref={rootRef}
        mb="1"
        p={4}
      >
        {showCancellationModal && (
          <CancelAppointmet
            {...{
              onConfirm: async ({reason, allowAction}) => {
                setWaiting(true);
                let generatingToast = toast({
                  title: "Canceling Transactions",
                  description: "Please wait a few seconds...",
                  status: "loading",
                  variant: "subtle",

                  isClosable: true,
                });

                let trs = await Promise.all(
                  [...selections].map((tr) =>
                    cancelTransactions(
                      tr,
                      dashState,
                      dispatch,
                      null,
                      toast,
                      reason.trim(),
                      allowAction
                    )
                  )
                );

                let trMap = trs.reduce((ac, e) => {
                  ac[e.tid] = e;
                  return ac;
                }, {});

                setTransactions((prev) => {
                  return prev.map((tr) => trMap[tr.tid] || tr);
                });

                setSelections(new Set());
                setAllowSelection("");
                toast.close(generatingToast);
                setWaiting(false);
              },
              onClose: setShowCancellationModal,
              isOpen: showCancellationModal,
              status: "cancel-billing",
              title:
                "Are you sure you want to delete the selected transactions?",
            }}
          />
        )}
        <Tabs
          variant="soft-rounded"
          flex={1}
          display={"flex"}
          flexDirection={"column"}
          overflow={"hidden"}
          colorScheme="blue"
        >
          <TabList fontSize={"sm"} gap={5} ml={5} mb={3}>
            <Tab py="1">Bills</Tab>
            <Tab py="1">Payments</Tab>
          </TabList>

          <TabPanels flex={1} w="full" overflow={"hidden"} paddingRight={0}>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowX={"hidden"}
              h={"full"}
              padding={0}
            >
              <Accordion
                mb="4"
                allowToggle
                w={{
                  base: "calc(100vw - 70px)",
                  md: "calc(100vw - 300px)",
                }}
              >
                <AccordionItem border="none">
                  <Flex gap={4}>
                    <h2>
                      <AccordionButton
                        boxShadow={"md"}
                        w={"192px"}
                        border="1px solid"
                        borderColor={"gay.400"}
                        rounded={"md"}
                        color={"blue.500"}
                        fontWeight={500}
                        fontSize={"16px"}
                        _expanded={{bg: "blue.500", color: "white"}}
                      >
                        <Box as="span" flex="1" textAlign="left">
                          Filters
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <Calc
                      {...{
                        rowCalcValues,
                        setRowCalcValues,
                        cellSelection,
                        mirrorTable,
                        colCalcValues,
                        setColCalcValues,
                      }}
                    />
                  </Flex>
                  <AccordionPanel pl="0">
                    {
                      <Box p="4" gap="8">
                        <Box mb="4">
                          <BillingQueries
                            {...{
                              dashState,
                              setBillingQueries,
                              billingQueries,
                              setQuery,
                              query,
                              fields,
                              setFields,
                              groupings,
                              setGroupings,
                            }}
                          />
                        </Box>
                        <Box mb="4">
                          <GroupingFields
                            {...{
                              groupings,
                              setGroupings,
                              setTransactions,
                              patient,
                              dashState,
                            }}
                          />
                        </Box>
                        <Filters {...{query, setQuery}} />
                      </Box>
                    }
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              {!(groupings.length > 0) && (
                <Flex mb="2" align={"center"}>
                  <CustomFields {...{setFields, fields}} />
                  <ApplyPayment
                    {...{
                      setTransactions,
                      applyPayment,
                      setApplyPayment,
                      allowSelection,
                      setAllowSelection,
                      selections,
                      setSelections,
                      setPaymentType,
                      paymentType,
                      dispatch,
                      dashState,
                      waiting,
                      setWaiting,
                    }}
                  />
                  <Box ml="4">
                    <Button
                      size={"md"}
                      colorScheme="red"
                      cursor={
                        !waiting &&
                        (allowSelection === "" ||
                          (allowSelection === "cancel" && selections.size > 0))
                          ? "pointer"
                          : "not-allowed"
                      }
                      onClick={async () => {
                        let generatingToast;
                        try {
                          if (allowSelection === "") {
                            setAllowSelection("cancel");
                          } else {
                            if (
                              allowSelection === "cancel" &&
                              selections.size > 0 &&
                              !waiting
                            ) {
                              if (selections.size > 0) {
                                setShowCancellationModal(true);
                              } else {
                                toast({
                                  title: "Transactions Seletions",
                                  description:
                                    "Please select the transactions to delete!",
                                  status: "warning",
                                  isClosable: true,
                                  duration: 2000,
                                });
                              }
                            }
                          }
                        } catch (error) {
                          setSelections(new Set());
                          setAllowSelection("");
                          toast.close(generatingToast);
                          setWaiting(false);
                        }
                      }}
                    >
                      Cancel Transactions
                    </Button>
                    {allowSelection === "cancel" && (
                      <Icon
                        as={MdClose}
                        color={"gray.500"}
                        _hover={{color: "gray.700"}}
                        w={6}
                        h={6}
                        cursor={"pointer"}
                        onClick={() => {
                          setApplyPayment({amount: 0, from: ""});
                          setAllowSelection("");
                          setSelections(new Set());
                        }}
                      />
                    )}
                  </Box>
                  <InputGroup ml="5" p="1" pt="0" w="60">
                    <InputLeftElement>
                      <Icon as={IoSearch} color="gray.500" ml="2" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      placeholder="Search transactions"
                      onChange={(e) =>
                        setSearchText(e.target.value.toLowerCase())
                      }
                    />
                  </InputGroup>
                  {mirrorTable.current?.length > 0 && (
                    <Button
                      ml="4"
                      leftIcon={<RiFileExcel2Line />}
                      onClick={() => {
                        let location = dashState.locations.find(
                          (loc) => (loc.lid = dashState.selectedLocation)
                        );
                        console.log(mirrorTable.current);
                        getExcel({
                          state: dashState,
                          table: mirrorTable.current,
                          location,
                          toast,
                        });
                      }}
                      colorScheme="teal"
                      variant="solid"
                    >
                      Export to excel
                    </Button>
                  )}
                </Flex>
              )}
              {groupings.length > 0 ? (
                <TransactionGrouper
                  transactions={transactions}
                  groupings={groupings}
                  fields={fields}
                  setFields={setFields}
                  dashState={dashState}
                />
              ) : (
                <TableContainer
                  maxW="8xl"
                  borderRadius="md"
                  boxShadow="md"
                  flex={1}
                  display="flex"
                  flexDirection={"column"}
                  className="sbar3"
                  minH="480px"
                  h="full"
                  overflowY={"scroll"}
                  w={{
                    base: "calc(100vw - 70px)",
                    md: "calc(100vw - 300px)",
                  }}
                >
                  <SimpleTransactionsList
                    {...{
                      paymentType,
                      transactions,
                      fields,
                      query: query.filters || {},
                      searchText,
                      dispatch,
                      dashState,
                      allowSelection,
                      setAllowSelection,
                      selections,
                      setSelections,
                      applyPayment,
                      mirrorTable,
                      cellSelection,
                      setCellSelection,
                      rowCalcValues,
                      setRowCalcValues,
                      colCalcValues,
                      setColCalcValues,
                    }}
                  />
                  <div ref={loadingRef} />
                </TableContainer>
              )}
            </TabPanel>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowX={"hidden"}
              h={"full"}
              padding={0}
            >
              <PaymentsTable
                renderedFromBilling
                dispatchFromBilling={dispatch}
                stateFromBilling={dashState}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
