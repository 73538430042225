import React, {useMemo, useState} from "react";
import {
  Box,
  Text,
  Badge,
  Flex,
  IconButton,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import {CheckIcon} from "@chakra-ui/icons";
import {getTaskDate} from "./helpers/getTaskDate";
import UpdateTask from "./UpdateTask";
import DeleteButton from "../DeleteButton";
import Pagination from "./PaginationTaskBar";
import {updateTasks} from "./helpers/updateTasks";
import {deleteTask} from "./helpers/deleteTask";
import {status, statusColor} from "./helpers/const";

export default function TaskCardsMobile({
  tasks,
  patients,
  dashState,
  setTasks,
  patient,
  doctors,
  groupedOptions,
  patientsArr,
  admins,
  fetchTasks,
  searchQuery,
  showCompletedRef,
  sort,
  isLoading,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastFetchedPage, setLastFetchedPage] = useState(0);
  const [loadingTasks, setLoadingTasks] = useState({});
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const toast = useToast();
  const itemsPerPage = patient ? 6 : 7;

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    if (page % 5 === 0 && page !== lastFetchedPage && page > lastFetchedPage) {
      await fetchTasks(
        true,
        searchQuery,
        page,
        showCompletedRef.current,
        sort.column,
        sort.direction
      );
      setLastFetchedPage(page);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedData = useMemo(
    () => tasks?.slice(startIndex, startIndex + itemsPerPage),
    [tasks, startIndex, itemsPerPage]
  );

  return (
    <>
      {isLoading ? (
        <Flex
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          justifyContent="center"
          alignItems="center"
          bg="rgba(255, 255, 255, 0.8)"
          zIndex="1"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      ) : (
        <Box position="relative" pb="60px" height="100%">
          <Box height="calc(100vh - 240px)" overflowY="auto" pb="4">
            {selectedData?.length === 0 ? (
              <Text p={4} textAlign="center">
                No tasks to display.
              </Text>
            ) : (
              selectedData?.map((task) => (
                <Box
                  key={task.taskId}
                  p={3}
                  mb={3}
                  borderWidth="1px"
                  borderRadius="md"
                  boxShadow="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTaskId(task.taskId);
                  }}
                >
                  <Flex justify="space-between" align="center" mb={2}>
                    <Text fontWeight="bold" fontSize="15px">
                      {task.task}
                    </Text>
                    <Badge
                      colorScheme={statusColor[task.status]}
                      rounded="md"
                      px="2"
                      py="0.5"
                    >
                      {status[task.status]}
                    </Badge>
                  </Flex>

                  {task.description && (
                    <Box mb={2}>
                      <Text fontSize="13px" color="gray.600">
                        Description:
                      </Text>
                      <Text
                        fontSize="14px"
                        whiteSpace="pre-wrap"
                        wordBreak="break-word"
                        maxHeight="60px"
                        overflowY="auto"
                      >
                        {task.description}
                      </Text>
                    </Box>
                  )}

                  <Box mb={2}>
                    <Text fontSize="13px" color="gray.600">
                      Patient:
                    </Text>
                    <Text fontSize="14px" fontWeight="semibold">
                      {task.patient}
                    </Text>
                  </Box>

                  <Flex flexWrap="wrap" mb={2}>
                    <Box width="50%" pr={2} mb={1}>
                      <Text fontSize="13px" color="gray.600">
                        Assigned to:
                      </Text>
                      <Text fontSize="14px" noOfLines={1} fontWeight="semibold">
                        {task.assignedToName}
                      </Text>
                    </Box>
                    <Box width="50%" mb={1}>
                      <Text fontSize="13px" color="gray.600">
                        Created by:
                      </Text>
                      <Text fontSize="14px" noOfLines={1}>
                        {task.createdByName
                          ? task.createdByName
                          : task.createdById}
                      </Text>
                    </Box>
                  </Flex>

                  <Flex flexWrap="wrap" mb={2}>
                    <Box
                      width={task.completedDate ? "33.3%" : "50%"}
                      pr={2}
                      mb={1}
                    >
                      <Text fontSize="13px" color="gray.600">
                        Need By:
                      </Text>
                      <Text fontSize="14px" noOfLines={1} fontWeight="semibold">
                        {getTaskDate(task.due)}
                      </Text>
                    </Box>
                    <Box
                      width={task.completedDate ? "33.3%" : "50%"}
                      pr={task.completedDate ? 2 : 0}
                      mb={1}
                    >
                      <Text fontSize="13px" color="gray.600">
                        Created:
                      </Text>
                      <Text fontSize="14px" noOfLines={1}>
                        {getTaskDate(task.createdDate)}
                      </Text>
                    </Box>
                    {task.completedDate && (
                      <Box width="33.3%" mb={1}>
                        <Text fontSize="13px" color="gray.600">
                          Completed:
                        </Text>
                        <Text fontSize="14px" noOfLines={1}>
                          {getTaskDate(task.completedDate)}
                        </Text>
                      </Box>
                    )}
                  </Flex>

                  <Flex justify="flex-end" mt={1}>
                    <UpdateTask
                      dashState={dashState}
                      taskData={task}
                      patients={patients}
                      setTasks={setTasks}
                      patientId={patient?.pid}
                      doctors={doctors}
                      groupedOptions={groupedOptions}
                      patientsArr={patientsArr}
                      admins={admins}
                      isMobile={true}
                      openFromMobile={selectedTaskId === task.taskId}
                      setOpenFromMobile={() => setSelectedTaskId(null)}
                    />
                    <IconButton
                      colorScheme="green"
                      icon={<CheckIcon />}
                      isLoading={loadingTasks[task.taskId]}
                      size="sm"
                      m={1}
                      isDisabled={task.status === 2}
                      aria-label="Complete task"
                      onClick={async (e) => {
                        e.stopPropagation();
                        setLoadingTasks({
                          ...loadingTasks,
                          [task.taskId]: true,
                        });
                        const updatedTask = {...task, status: "2"};
                        let assignedToName = admins.find(
                          (admin) => admin.id === task.assignedToId
                        );
                        assignedToName = assignedToName
                          ? `${assignedToName.lastName}, ${assignedToName.firstName}`
                          : `${doctors[task.assignedToId]?.lastName}, ${
                              doctors[task.assignedToId]?.firstName
                            }`;
                        await updateTasks(
                          updatedTask,
                          dashState,
                          setTasks,
                          assignedToName,
                          `${patients[task.pid]?.lName}, ${
                            patients[task.pid]?.fName
                          }`,
                          toast
                        );
                        setLoadingTasks({
                          ...loadingTasks,
                          [task.taskId]: false,
                        });
                      }}
                    />
                    <DeleteButton
                      onDelete={(e) => {
                        deleteTask(task.taskId, dashState, setTasks, toast);
                      }}
                      isMobile={true}
                    />
                  </Flex>
                </Box>
              ))
            )}
          </Box>
          <Box
            position="fixed"
            bottom="0"
            left="0"
            right="0"
            bg="white"
            borderTopWidth="1px"
            borderTopColor="gray.200"
            zIndex="10"
          >
            <Pagination
              totalItems={tasks?.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              isMobile={true}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
