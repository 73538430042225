import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Badge,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import {AiOutlineDelete} from "react-icons/ai";
export default function SelectionsTable({
  selectedProducts: products,
  setSelectedProducts,
  loading,
  setSelections,
  closeAcion,
  state,
  patient,
}) {
  const patientMemberships = patient?.memberships;
  const filteredMemberships = (patientMemberships || []).filter(
    (membership) => {
      const currentDate = new Date();
      const isCurrentMembership =
        currentDate >= new Date(membership.startDate) &&
        currentDate <= new Date(membership.endDate);
      const isCancelledMembership = membership.status === "Cancelled";
      const isFutureMembership = currentDate < new Date(membership.startDate);
      return (
        (isCurrentMembership && !isCancelledMembership) || isFutureMembership
      );
    }
  );

  const fields = {
    "Product Name": (p) => p.name,
    "In Membership": (product) => {
      return product.includedInMembership.totalQuantity || 0;
    },
    Price: (p) => {
      const isIncludedInMembership =
        (p.includedInMembership?.totalQuantity || 0) >= p.quantity;

      if (isIncludedInMembership) {
        return "Covered by membership/package";
      }
      return p.onSale ? p.salePrice : p.price;
    },
    Quantity: (p, key) => {
      const quantityFromMembership = p.includedInMembership?.totalQuantity || 0;

      return (
        <NumberInput
          key={key}
          size="sm"
          maxW={20}
          min={1}
          value={p.quantity}
          max={quantityFromMembership > 0 ? quantityFromMembership : p.stock}
          onChange={(ele) => {
            if (!loading) {
              setSelectedProducts((prev) =>
                prev.map((e) =>
                  e.id === p.id
                    ? {...p, quantity: ele === "" ? 0 : parseInt(ele)}
                    : e
                )
              );
            }
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      );
    },
  };

  return (
    <TableContainer overflowY="auto" maxHeight={"250px"} mb="5">
      <Table size="md">
        <Thead position="sticky" top="0" bg="gray.100">
          <Tr>
            {Object.keys(fields).map((field, i) => {
              return (
                <Th key={field} fontSize={"11px"}>
                  {field}
                </Th>
              );
            })}
            <Th fontSize={"11px"}>{"Action"}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products?.map((product, i) => {
            return (
              <Tr key={i}>
                {Object.values(fields).map((v, i) => (
                  <Td key={i} fontSize={"15px"}>
                    {v(product, i) ?? "N/A"}
                  </Td>
                ))}
                <Td fontSize={"15px"}>
                  <Tooltip hasArrow label="Remove" rounded={"sm"}>
                    <Badge
                      colorScheme="red"
                      rounded="md"
                      p="1"
                      cursor={"pointer"}
                      onClick={() => {
                        setSelectedProducts((prev) =>
                          prev.filter((p) => p.id !== product.id)
                        );
                        setSelections((prev) => {
                          let arr = [...prev];

                          return new Set(
                            arr.filter((p) => p.id !== product.id)
                          );
                        });
                        if (products.length === 1) closeAcion();
                      }}
                    >
                      <Icon as={AiOutlineDelete} fontSize="15px" mt="-0.5" />
                    </Badge>
                  </Tooltip>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
