import {Image, ListItem, Text, useToast} from "@chakra-ui/react";
import {membershipFileNameFormat} from "../../appointments/payment/uploads";
import {getFileData} from "../../appointments/payment/helpers/getFileData";
import {crudStorage} from "../../appointments/payment/helpers/crudStorage";
import {useState} from "react";

export const InvoiceOrReceiptButton = ({
  transaction,
  type,
  i,
  setSelectedTr,
  state,
  setPreview,
  receiptFile,
}) => {
  const [fetchingFileData, setFetchingFileData] = useState(false);
  const toast = useToast();
  let path = "";
  if (transaction) {
    path =
      type === "invoice"
        ? `${
            transaction?.pid || ""
          }/Invoices/Memberships/${membershipFileNameFormat(transaction)}`
        : `${
            transaction?.pid || ""
          }/Receipts/Memberships/${membershipFileNameFormat(transaction)}/`;
  }

  const getPreviewFile = async (transaction, type) => {
    setSelectedTr(transaction);
    if (type === "invoice") {
      const files = await crudStorage(
        state,
        "view",
        "",
        "brightlighthealth",
        path
      )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return [];
        });

      if (transaction && files.length > 0) {
        const file = files[0];
        getFileData(
          transaction,
          state,
          path,
          file.name,
          setPreview,
          toast,
          setFetchingFileData,
          "full",
          type
        );
      } else {
        toast({
          title: "This file cannot be previewed.",
          description: "Invoice could not be found!",
          status: "warning",
          isClosable: true,
        });
        setFetchingFileData(false);
      }
    }
    if (type === "receipt") {
      getFileData(
        transaction,
        state,
        path,
        receiptFile.name,
        setPreview,
        toast,
        setFetchingFileData,
        "full",
        type
      );
    }
  };

  return (
    <ListItem
      onClick={() => getPreviewFile(transaction, type)}
      key={i}
      bgColor="blue.500"
      m="2px"
      rounded="lg"
      p="2px"
      px="4px"
      cursor="pointer"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Image alt="" src="/images/Clinic/inv.png" w="2rem" mt="-2px" />
      <Text fontSize="10px" mt="-2px" lineHeight="11px">
        view
      </Text>
    </ListItem>
  );
};
