import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Badge,
  Flex,
} from "@chakra-ui/react";
import {lightFormat} from "date-fns";

export const RelatedGiftCards = ({relatedGiftCards, isOpen, onClose}) => {
  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp);
    return lightFormat(date, "dd/MM/yyyy");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Related Gift Cards</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {relatedGiftCards && relatedGiftCards.length > 0 ? (
            <Box overflowX="auto">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th textAlign="center" whiteSpace="nowrap">
                      Purchased by
                    </Th>
                    <Th textAlign="center" whiteSpace="nowrap">
                      Amount
                    </Th>
                    <Th textAlign="center" whiteSpace="nowrap">
                      Balance
                    </Th>
                    <Th textAlign="center" whiteSpace="nowrap">
                      Phone
                    </Th>
                    <Th textAlign="center" whiteSpace="nowrap">
                      Creation Date
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {relatedGiftCards.map((card) => (
                    <Tr key={card.gcId}>
                      <Td textAlign="center">
                        <Text fontWeight="medium" noOfLines={1}>
                          {card.fName} {card.lName}
                        </Text>
                      </Td>
                      <Td textAlign="center">
                        <Text fontWeight="medium" noOfLines={1}>
                          ${card.amount.toFixed(2)}
                        </Text>
                      </Td>
                      <Td textAlign="center">
                        <Flex justifyContent="center" alignItems="center">
                          <Badge
                            colorScheme={card.balance > 0 ? "green" : "orange"}
                            fontSize="sm"
                          >
                            ${card.balance.toFixed(2)}
                          </Badge>
                        </Flex>
                      </Td>
                      <Td textAlign="center">
                        <Text noOfLines={1}>{card.phone || "N/A"}</Text>
                      </Td>
                      <Td textAlign="center">
                        <Text noOfLines={1}>
                          {formatDate(card.creationDate)}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Text>No gift cards found.</Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" colorScheme="gray" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
