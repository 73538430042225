import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
} from "react";
import {Button, useToast, Flex, Text, Badge} from "@chakra-ui/react";
import {TiShoppingCart} from "react-icons/ti";
import {BsInfoCircleFill} from "react-icons/bs";
import {crud, updateApptMemb} from "../../../crudRequests";
import {BiChevronDown, BiLink} from "react-icons/bi";
import DefineApptServiceCriteria from "../../DefineApptServiceCriteria";
import deleteFiles from "./helpers/deleteFiles";
import {crudStorageUpload} from "../../Patients/Files/helpers/crudStorageUpload";
import InvRecpDF from "../../Invoice";
import {pdf} from "@react-pdf/renderer";
import SelectedProducts from "../../Products/SelectedProducts";
import {lightFormat} from "date-fns";
import {PiWarningBold} from "react-icons/pi";
import fetchProducts from "../../Products/helpers/fetchProducts";
import ExternalSales from "../boockAppointment/products/sales";
import SearchableDiscounts from "../../Discounts/SearchableDiscounts";
import getAmount from "./helpers/getAmount";
import {
  fullInvoice,
  fullReceipt,
  patientInvoice,
  patientReceipt,
  insurerInvoice,
  insurerReceipt,
} from "./uploads";
import {createMembershipLog} from "./ProductPaymentModal";
import {populateProductMembershipData} from "../../Products/SalesModal";
import {
  ProductsInMemberships,
  ServicesInMemberships,
} from "../boockAppointment/products/helpers.js";
import {useWindowDimensions} from "../../../additional_files/custom";
let durations = [
  "15 min",
  "30 min",
  "45 min",
  "60 min",
  "75 min",
  "90 min",
  "105 min",
  "120 min",
];

function durationFormat(timeString) {
  let t = timeString.split(" ")[0];
  return Number(t) <= 45 ? timeString : `${(Number(t) / 60).toFixed(2)} hr`;
}

export default function ChangeAmount({
  state,
  patient,
  stateChanger,
  appt,
  dispatch,
  socket,
  setErrorMessage,
  setSuccess,
}) {
  const {width} = useWindowDimensions();
  if (width < 768) {
    stateChanger(false);
  }

  return (
    <div className="relative   rounded-[10px] py-4  flex flex-col items-start space-y-4">
      <div className="flex justify-start items-center  px-2">
        <h1 className="text-[rgba(87,84,255,0.75)] font-semibold text-2xl leading-[25px]">
          Payment Criteria
        </h1>
      </div>

      <SendForm
        state={state}
        patient={patient}
        appt={appt}
        stateChanger={stateChanger}
        dispatch={dispatch}
        socket={socket}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
}

function SendForm({
  state,
  patient,
  appt,
  stateChanger,
  dispatch,
  socket,
  setErrorMessage,
}) {
  const [duration, setDuration] = useState(appt.duration);
  const [serviceDiscount, setServiceDiscount] = useState({
    id: appt?.serviceDiscountId || null,
    value: appt?.serviceDiscountRate || 0,
  });

  const [sending, setSending] = useState(false);
  const toast = useToast();
  const criteriaKeyRef = useRef(null);
  const [service, setService] = useState(
    () =>
      state.services.find(
        (s) => appt.serviceId === s.serviceId || appt.service === s.serviceName
      ) || state.services[0]
  );

  const [membForService, setMembForService] = useState(
    appt.paidByMembership || null
  );

  const [showList, setShowList] = useState(false);
  const [showList2, setShowList2] = useState(false);
  const [serviceCriteriaKey, setServiceCriteriaKey] = useState(
    appt.serviceCriteriaKey || `Doctors|${appt.did}`
  );

  /////////////
  let criteria = useMemo(() => {
    let crt = state.serviceCriteria.filter((c) => c.type !== "Insurers");

    let additionalCriteria = [
      {type: "Insurers", list: state.insurers},
      {type: "Provider Types", list: state.providerTypes},
    ];
    let doctorCriteriaList = state?.doctors?.filter((e) => e.did === appt.did);
    if (doctorCriteriaList.length === 0 || !doctorCriteriaList) {
      if (state?.doctor) {
        doctorCriteriaList = [state?.doctor];
      }
    }
    additionalCriteria.push({
      type: "Clinicians",
      list: doctorCriteriaList,
    });

    return crt.concat(additionalCriteria);
  }, [state.serviceCriteria, state.insurers, state.doctors]);

  let initialCriteria = useMemo(() => {
    let initialCriteriaIdValues = criteriaFormatIdByKey(
      appt.serviceCriteriaKey,
      criteria
    );
    criteriaKeyRef.current = initialCriteriaIdValues;
    let initialCriteriaValues = appt.serviceCriteriaKey
      ? Object.fromEntries(
          criteriaFormatByKey(appt.serviceCriteriaKey, criteria)
        )
      : {};

    let initialSelectedCriteria = appt.serviceCriteriaKey
      ? criteria.filter((e) => {
          return appt.serviceCriteriaKey.match(new RegExp(e.type + "\\W"));
        })
      : [];

    return {
      initialCriteriaIdValues,
      initialCriteriaValues,
      initialSelectedCriteria,
    };
  }, [criteria]);

  ///////////////
  const [serviceTax, setServiceTax] = useState(() => {
    return appt.serviceTax || 0;
  });

  const [tppAmount, setTppAmount] = useState(
    Number.parseFloat(appt.tppAmount ?? 0)
  );

  const [serviceSubtotal, setServiceSubtotal] = useState(() => {
    return appt.serviceSubtotal || 0;
  });

  const list = useRef(showList);
  const list2 = useRef(showList2);

  list.current = showList;
  list2.current = showList2;

  const [products, setProducts] = useState([]);
  const [showSaleModal, setShowSaleModal] = useState(false);
  const [membershipLogs, setMembershipLogs] = useState([]);

  const [productSalesInformation, setProductSalesInformation] = useState({
    products: appt.products || [],
    tax: appt.productTax || 0,
    amount: appt.productAmount || 0,
    subtotal: appt.productSubtotal || 0,
    discountRate: appt.productDiscountRate || 0,
    discountId: appt.productDiscountId,
  });

  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productSaleClinicianId, setProductSaleClinicianId] = useState(null);
  useEffect(() => {
    fetchProducts(state, setLoadingProducts, setProducts, toast);
  }, []);

  const logsLoaded = useRef(false);
  useEffect(() => {
    const fetchMembershipLogs = async () => {
      if (appt) {
        const res = await crud(state, [
          {
            db: state.db,
            collection: "membershipLogs",
            parameters: [{appointmentId: appt.aid}],
            method: "find",
          },
        ]);
        setMembershipLogs(res.data[0]);
        logsLoaded.current = true;
      }
    };
    fetchMembershipLogs();
  }, [appt]);

  const patientTppInfo = useMemo(() => {
    if (Array.isArray(patient?.tpp)) {
      let tpp = criteriaKeyRef.current?.["Insurers"] || false;
      return patient?.tpp.find((ins) => ins.insurerId === tpp);
    }
    return null;
  }, [serviceCriteriaKey, patient]);

  let sessionDeductionAmoun = parseFloat(
    patientTppInfo?.sessionDeductionAmount ?? 0
  );

  const {productsFromMemb} = useMemo(() => {
    const apptDate = new Date();
    let memberships = (patient?.memberships || []).filter((memb) => {
      const startDate = new Date(memb.startDate);
      const endDate = new Date(memb.endDate);
      return (
        memb.active &&
        (!memb.membership || (apptDate >= startDate && apptDate <= endDate))
      );
    });
    return ProductsInMemberships(memberships, membershipLogs);
  }, [membershipLogs, patient]);

  //console.log(productsFromMemb);

  const {servicesFromMemb} = useMemo(() => {
    const apptDate = new Date();
    let memberships = (patient?.memberships || []).filter((memb) => {
      const startDate = new Date(memb.startDate);
      const endDate = new Date(memb.endDate);
      return (
        memb.active &&
        (!memb.membership || (apptDate >= startDate && apptDate <= endDate))
      );
    });
    return ServicesInMemberships(
      memberships,
      appt?.serviceId,
      appt.paidByMembership || null
    );
  }, [patient]);

  const getServiceSub = (service, duration, key, paidByMembership) => {
    if (paidByMembership) return 0;
    let costsByCriteria = service?.defaultCost || 100;
    if (key && !service?.isfixedCost)
      costsByCriteria = service?.costsByCriteria?.[key] ?? costsByCriteria;

    let hours = Number.parseFloat(duration.split(" ")[0]) / 60;

    let serviceSubtotal = roundUpToTwoDecimals(
      (service?.isfixedCost ? 1 : hours) * Number.parseFloat(costsByCriteria)
    );
    return serviceSubtotal;
  };

  const getTotal = ({serviceSubtotal, serviceTax, serviceDiscountRate}) => {
    let serviceAmount = getAmount({
      subtotal: serviceSubtotal,
      tax: serviceTax,
      discountRate: serviceDiscountRate,
    });
    let productAmount = parseFloat(productSalesInformation?.amount || 0);
    return serviceAmount + productAmount;
  };

  const updateTppAmount = (totalAmount) => {
    let tpp = criteriaKeyRef.current?.["Insurers"];
    if (tpp) {
      let patientTppInfo = null;
      if (Array.isArray(patient?.tpp) && tpp) {
        patientTppInfo = patient?.tpp.find((ins) => ins.insurerId === tpp);
      }
      if (
        patientTppInfo &&
        !isNaN(patientTppInfo.sessionDeductionAmount) &&
        parseFloat(patientTppInfo.sessionDeductionAmount) >= 0
      ) {
        let sessionDeductionAmount = parseFloat(
          patientTppInfo.sessionDeductionAmount
        );

        if (sessionDeductionAmount >= totalAmount) {
          setTppAmount(roundUpToTwoDecimals(totalAmount));
        } else setTppAmount(roundUpToTwoDecimals(sessionDeductionAmount));
      } else setTppAmount(0);
    } else setTppAmount(0);
  };

  const onServiceChange = (service) => {
    let newTax = serviceTax;
    if (!service?.taxable) {
      newTax = 0;
      setServiceTax(newTax);
    }
    let memb = servicesFromMemb[service.serviceId]?.relatedMemberships.find(
      (data) => {
        const serviceInfo = data.selectedServices[service.serviceId];
        const serviceBalance =
          (serviceInfo?.quantity || 0) -
          (serviceInfo?.consumed || 0) -
          (serviceInfo?.reserved || 0) +
          (data?.uuid === appt?.paidByMembership &&
          service.serviceId === appt?.serviceId
            ? 1
            : 0);

        return serviceInfo && serviceBalance > 0;
      }
    );

    setMembForService(memb ? memb.uuid : null);
    setService(service);
    let serviceSub = getServiceSub(service, duration, serviceCriteriaKey, memb);
    setServiceSubtotal(serviceSub);
    let total = getTotal({
      serviceSubtotal: serviceSub,
      serviceTax: newTax,
      serviceDiscountRate: serviceDiscount?.value || 0,
    });
    updateTppAmount(total);
  };

  const onDurationChange = (duration) => {
    let serviceSub = getServiceSub(
      service,
      duration,
      serviceCriteriaKey,
      membForService
    );
    let total = getTotal({
      serviceSubtotal: serviceSub,
      serviceTax,
      serviceDiscountRate: serviceDiscount?.value || 0,
    });
    updateTppAmount(total);
    setServiceSubtotal(serviceSub);
    setDuration(duration);
  };

  const onServiceCriteriaKeyChange = (key) => {
    setServiceCriteriaKey(key);
    let newTax = serviceTax;

    if (service?.taxable === true) {
      let typeId = criteriaKeyRef.current?.["Provider Types"];

      if (typeId) {
        let providerType = state.providerTypes.find((t) => t.typeId === typeId);
        newTax = providerType?.tax || 0;
        setServiceTax(newTax);
      }
    }

    let serviceSub = getServiceSub(service, duration, key, membForService);
    setServiceSubtotal(serviceSub);
    let total = getTotal({
      serviceSubtotal: serviceSub,
      serviceTax: newTax,
      serviceDiscountRate: serviceDiscount?.value || 0,
    });
    updateTppAmount(total);
  };

  if (serviceCriteriaKey === "") {
    onServiceCriteriaKeyChange(appt.serviceCriteriaKey);
  }
  const onServiceSubtotalChange = (value) => {
    setServiceSubtotal(value);
    let total = getTotal({
      serviceSubtotal: parseFloat(value),
      serviceTax,
      serviceDiscountRate: serviceDiscount?.value || 0,
    });
    updateTppAmount(total);
  };

  const onTaxChange = (tax) => {
    setServiceTax(tax);
    let serviceSub = getServiceSub(
      service,
      duration,
      serviceCriteriaKey,
      membForService
    );
    let total = getTotal({
      serviceSubtotal: serviceSub,
      serviceTax: tax,
      serviceDiscountRate: serviceDiscount?.value || 0,
    });
    updateTppAmount(total);
  };

  let serviceAmount = getAmount({
    subtotal: serviceSubtotal,
    tax: serviceTax,
    discountRate: serviceDiscount?.value || 0,
  });

  let total = serviceAmount + parseFloat(productSalesInformation?.amount || 0);

  let patientAmount = roundUpToTwoDecimals(total - tppAmount);

  const serviceCheked = useRef(false);
  const productCheked = useRef(false);

  useEffect(() => {
    onServiceChange(service);
    serviceCheked.current = true;
  }, []);

  useEffect(() => {
    if (logsLoaded.current) {
      let subtotal = productSalesInformation.products.reduce((acc, ele) => {
        let productId = ele.id;
        let quantityFromMembership =
          productsFromMemb[productId]?.availableQty ?? 0;
        let remainingQuantity = Math.abs(
          Math.min(quantityFromMembership - Number(ele.quantity), 0)
        );
        return (
          acc +
          parseFloat(ele.onSale ? ele.salePrice : ele.price) *
            parseFloat(remainingQuantity)
        );
      }, 0);

      let amount = getAmount({
        subtotal,
        tax: productSalesInformation.tax,
        discountRate: productSalesInformation.discountRate,
      });
      productCheked.current = true;
      setProductSalesInformation({
        ...productSalesInformation,
        amount,
        subtotal,
      });
    }
  }, [productsFromMemb]);

  const isNotified = useRef(false);
  if (productCheked.current && serviceCheked.current && !isNotified.current) {
    if (appt.amount > total && appt.paymentStatus !== "paid")
      toast({
        title: "Some costs may be covered by membership or packages!",
        description: 'Click "Update details" to update to the new costs shown.',
        status: "info",
        isClosable: true,
        duration: 5000,
        position: "top",
      });

    isNotified.current = true;
  }

  async function changeCriteria() {
    try {
      let tpp = criteriaKeyRef.current?.["Insurers"];
      if (
        patientTppInfo &&
        !isNaN(patientTppInfo.noOfInsuredSessions) &&
        parseFloat(patientTppInfo.noOfInsuredSessions) <= 0
      ) {
        toast({
          title: "Update error.",
          description: "There are no Insured Sessions for the selected insurer",
          status: "error",
          isClosable: true,
        });
        return;
      }

      if (service && patient) {
        setSending(true);
        setErrorMessage(false);
        let tppA = parseFloat(tppAmount);
        let ptAmount = parseFloat(patientAmount);

        let amountData = {
          amount: roundUpToTwoDecimals(total),
          serviceTax: parseFloat(serviceTax),
          serviceDiscountRate: serviceDiscount?.value || 0,
          serviceDiscountId: serviceDiscount?.id || null,
          serviceSubtotal: roundUpToTwoDecimals(serviceSubtotal),
          serviceAmount: roundUpToTwoDecimals(serviceAmount),
          products: productSalesInformation?.products || [],
          productTax: parseFloat(productSalesInformation?.tax || 0),
          productDiscountRate: parseFloat(
            productSalesInformation?.discountRate || 0
          ),
          productDiscountId: productSalesInformation?.discountId || null,
          productSubtotal: parseFloat(productSalesInformation?.subtotal || 0),
          productAmount: parseFloat(productSalesInformation?.amount || 0),
          tppAmount: tppA,
          patientAmount: ptAmount,
          paidByMembership: membForService || "",
        };
        let paymentStatus = amountData.amount > 0 ? "pending" : "paid";
        let updateBody = {
          ...appt,
          duration,
          service: service.serviceName,
          serviceId: service.serviceId,
          serviceCriteriaKey,
          tpp,
          ...amountData,
          paymentStatus,
        };

        if (ptAmount - updateBody.amountPaidByPatient > 0) {
          updateBody.patientPaymentStatus = "pending";
        }

        if (tppA - updateBody.amountPaidByTpp > 0) {
          updateBody.claim = updateBody.received = false;
        }

        let {_id, ...data} = updateBody;

        let res = await updateApptMemb({
          state,
          appointmentId: appt.aid,
          serviceId: service.serviceId,
          membershipForServiceId: membForService,
          patientId: patient.pid,
          productsToAssign: (productSalesInformation?.products || []).map(
            (p) => {
              let productId = p.id;
              let qty = productsFromMemb[productId]?.availableQty ?? 0;
              let covered = Math.min(qty, p.quantity);
              return {quantity: p.quantity, covered, id: p.id};
            }
          ),
        });

        crud(state, [
          {
            db: state.db,
            collection: appt?.nonSessionService
              ? "nonSessionServices"
              : "appointments",
            parameters: [{aid: updateBody.aid}, {$set: data}],
            method: "updateOne",
          },
          {
            db: state.db,
            collection: "billing",
            parameters: [
              {aid: updateBody.aid},
              {
                $set: {
                  serviceType: service.serviceName,
                  serviceId: service.serviceId,
                  time: duration,
                  patientPaymentStatus: updateBody.patientPaymentStatus,
                  tpp,
                  claim: updateBody.claim,
                  received: updateBody.received,
                  ...amountData,
                  productSaleClinicianId:
                    productSalesInformation.productSaleClinicianId,
                },
              },
              {returnNewDocument: true},
            ],
            method: "findOneAndUpdate",
          },
          {
            db: state.db,
            collection: "doctors",
            parameters: [{did: appt.did}],
            method: "findOne",
          },
          {
            db: state.db,
            collection: "patients",
            parameters: [{pid: appt?.pid}],
            method: "findOne",
          },
          {
            db: state.db,
            collection: "insurers",
            parameters: [{iid: tpp || ""}],
            method: "findOne",
          },
          {
            db: state.db,
            collection: "doctors",
            parameters: [{did: appt.supervisorId || ""}],
            method: "findOne",
          },
        ])
          .then(async (res) => {
            try {
              let invData = {
                state: state,
                transaction: res.data[1],
                doctor: res.data[2],
                patient: res.data[3],
                tpp: res.data[4],
                supervisor: res.data[5],
                nonSessionService: res.data[1]?.nonSessionService,
              };

              await Promise.all([
                patientInvoice(invData),
                fullInvoice(invData),
              ]);
              if (res.data[4]) await insurerInvoice(invData);
              dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});
              if (res.data[3])
                dispatch({
                  type: "UPDATE_PATIENT",
                  pid: res.data[3].pid,
                  payload: res.data[3],
                  options: null,
                });
              socket?.emit?.("update_appt", updateBody, {});
              stateChanger(false);
              setSending(false);
            } catch (error) {
              console.log(error);
            }
          })
          .catch(function (error) {
            console.log(error);
            setSending(false);
            setErrorMessage(error.response.data);
          });
      }
    } catch (error) {
      console.log(error);
      stateChanger(false);
      setSending(false);
      toast({
        title: "Update error.",
        description: error.response?.data || error.message,
        status: "error",
        isClosable: true,
      });
    }
  }

  return (
    <>
      {showSaleModal && (
        <ExternalSales
          {...{
            state,
            products,
            closeSalesModal: () => setShowSaleModal(false),
            productSalesInformation,
            callback: (productData) => {
              setProductSaleClinicianId(productData.productSaleClinicianId);

              setProductSalesInformation(productData);
            },
            selectedClinicianId: productSaleClinicianId || appt.did || null,
            patient,
            appt,
            membershipLogs,
            productsFromMemb,
          }}
        />
      )}
      <form
        className="w-full flex justify-start items-center"
        onSubmit={async (e) => {
          e.preventDefault();
        }}
      >
        <div className="bg-[#E7E6FF] rounded-2xl border flex flex-col w-full justify-between items-center  p-7 pt-14">
          <div className="w-full relative flex justify-between   space-x-3">
            <div className="relative  flex flex-col  justify-between">
              <div
                className="bg-white z-10 relative cursor-pointer rounded-xl  flex justify-between items-center p-2 pl-4 pr-10"
                onClick={(e) => {
                  // e.stopPropagation();
                  setShowList2((s) => false);
                  if (state.services.length > 0) setShowList((s) => !s);
                }}
              >
                {state.services.length > 0 && (
                  <>
                    <p className="relative  w-[12rem] text-[#8F8CFF]  font-semibold  h-8 flex items-center text-[18px]">
                      <span className="absolute text-[#A2A2A2] text-sm bottom-[2.7rem] -left-1">
                        Services
                      </span>
                      <input
                        type="text"
                        className="border-none focus:ring-0 w-full p-0 focus:outline-none"
                        contentEditable="false"
                        value={service?.serviceName || ""}
                        readOnly={true}
                      />
                    </p>
                    <span className="absolute cursor-pointer right-3 text-3xl stroke-2 text-[#8F8CFF]">
                      <BiChevronDown />
                    </span>
                    {showList && (
                      <div className="shadow-lg absolute bg-white rounded-xl p-1  w-full left-0 right-10  top-[3.5rem] max-h-[12.5rem]">
                        <ul className="overflow-y-scroll w-full sbar3 max-h-[12rem] divide-y-[1px] p-1 space-y-1">
                          <div className="flex flex-col flex-wrap">
                            {state.services.map((e, i) => (
                              <li
                                className="text=[#8F8CFF]  flex-wrap flex text-[#8F8CFF] font-semibold  pl-1 text-[1rem] hover:bg-[#aaa4f3] shadow-sm hover:text-white cursor-pointer"
                                onClick={(ev) => {
                                  // ev.stopPropagation();
                                  onServiceChange(e);
                                }}
                                key={i}
                              >
                                {e.serviceName}
                              </li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </div>
              <Details
                details={[
                  {
                    "Covered by Membership":
                      servicesFromMemb[service.serviceId]?.availableQty > 0
                        ? "Yes"
                        : "No",
                  },
                  {Category: service?.category || "-"},
                  {"Default Cost/hrs": service?.defaultCost || "-"},
                  {"Default Duration": service?.defaultDuration || "-"},
                ]}
              />
              {parseInt(patientTppInfo?.noOfInsuredSessions ?? -1) === 0 &&
                patientTppInfo?.insurerId !== appt.tpp && (
                  <div className="">
                    <span className="ml-2 text-yellow-500 text-sm space-x-1 flex items-center">
                      <PiWarningBold />{" "}
                      <span>
                        All sessions insured by this TPP have been used up!{" "}
                      </span>
                    </span>
                  </div>
                )}
              <div className="w-full my-2">
                <SearchableDiscounts
                  {...{
                    state,
                    labelFontSize: "xs",
                    selectedDiscount: serviceDiscount,
                    setSelectedDiscount: (d) => {
                      setServiceDiscount(d);

                      let total = getTotal({
                        serviceSubtotal,
                        serviceTax,
                        serviceDiscountRate: d?.value || 0,
                      });
                      updateTppAmount(total);
                    },
                  }}
                />
              </div>
              <div className="flex w-full my-3 mb-4">
                <Flex
                  align={"center"}
                  gap={1}
                  fontSize="sm"
                  bg="white"
                  rounded={"lg"}
                  p="2"
                >
                  <Text
                    fontSize={"2xl"}
                    color="gray.600"
                    fontWeight={700}
                  >{`Service Cost: `}</Text>
                  <Badge
                    bg="transparent"
                    color="gray.600"
                    fontSize={"xl"}
                    p="2"
                    rounded={"md"}
                  >{`$${serviceAmount}`}</Badge>
                </Flex>
              </div>
            </div>

            <div>
              <div
                className="bg-white relative z-10 cursor-pointer rounded-xl   flex justify-between items-center p-2 pl-4 pr-12"
                onClick={(e) => {
                  // e.stopPropagation();
                  setShowList2((s) => !s);
                  setShowList((s) => false);
                }}
              >
                {
                  <>
                    <p className="relative w-[4rem] px-2 pr-4 whitespace-nowrap text-[#8F8CFF] font-semibold text-center  h-8  flex items-center text-[18px]">
                      <span className="absolute text-[#A2A2A2] text-sm bottom-[2.7rem] -left-2">
                        Duration
                      </span>
                      {duration || ""}
                    </p>
                    <span className="absolute cursor-pointer right-2 text-3xl stroke-2 text-[#8F8CFF]">
                      <BiChevronDown />
                    </span>
                    {showList2 && (
                      <div className="shadow-lg absolute bg-white rounded-xl p-1  w-full left-0 right-10  top-[3.5rem] ">
                        <ul className="overflow-y-scroll w-full sbar3 max-h-[12rem] divide-y-[1px] pr-2 space-y-1">
                          {durations.map((e, i) => (
                            <li
                              className="text=[#8F8CFF] text-center rounded-lg text-[#8F8CFF] font-semibold  pl-2 text-[1rem] hover:bg-[#aaa4f3] shadow-sm hover:text-white cursor-pointer"
                              onClick={() => {
                                onDurationChange(e);
                              }}
                              key={i}
                            >
                              {durationFormat(e)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                }
              </div>
              <label className="block  mt-2">
                <span className="text-[12px] text-[#A2A2A2] text-sm font-medium ml-2">
                  Service Subtotal
                </span>
                <input
                  type="number"
                  className=" block w-[10rem] text-right px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  value={serviceSubtotal}
                  onBlur={(e) => {
                    if (
                      /^0.+/.test(String(serviceSubtotal)) &&
                      !isNaN(Number(serviceSubtotal))
                    )
                      setServiceSubtotal(String(Number(serviceSubtotal)));
                  }}
                  onChange={(e) => {
                    let value = e.target.value;

                    if (
                      !isNaN(value) &&
                      !value.match(/-/g) &&
                      !membForService
                    ) {
                      if (value === "") value = 0;
                      onServiceSubtotalChange(value);
                    }
                  }}
                ></input>
              </label>
              <label className="block  mt-2 relative">
                <span className="text-[12px] text-[#A2A2A2] text-sm font-medium ml-2">
                  Service Tax Rate (%)
                </span>
                <input
                  type="text"
                  readOnly={!service?.taxable}
                  className=" block w-[10rem] text-right px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  value={serviceTax}
                  onChange={(e) => {
                    if (!service?.taxable) return;
                    const validNumber = /^\d+(\.\d{0,2})?$/;
                    let n = Number(e.target.value);
                    if (
                      (validNumber.test(e.target.value) ||
                        e.target.value === "") &&
                      n >= 0 &&
                      n <= 100
                    ) {
                      onTaxChange(e.target.value);
                    }
                  }}
                ></input>
                {!service?.taxable && (
                  <div className="absolute  -bottom-[17px] w-full left-2  text-[11px]  text-blue-500 flex  items-center space-x-1">
                    <span className="">{"Non-taxable service."}</span>
                    <BsInfoCircleFill />
                  </div>
                )}
              </label>
            </div>
          </div>

          <div className="w-full flex flex-col items-end space-x-3 ">
            <div className="flex flex-col justify-start flex-1 overflow-hidden w-full space-y-1 ">
              <span className="text-[12px] text-[#A2A2A2] text-sm font-medium ml-2">
                Criteria for this service
              </span>
              <div className="border border-white  w-full rounded-xl overflow-hidden flex-1 p-1">
                <ul className="sbar2 space-y-1 h-full overflow-y-scroll  sbar2 w-full p-2 pt-3">
                  {serviceCriteriaKey?.length === 0 ? (
                    Array(1)
                      .fill(0)
                      .map((e, i) => (
                        <li
                          key={i}
                          className="rounded-md w-full bg-gray-100/80 h-7"
                        ></li>
                      ))
                  ) : (
                    <li className="relative rounded-md w-full  flex  text-sm font-medium space-x-2">
                      {criteriaFormatByKey(serviceCriteriaKey, criteria).map(
                        ([type, value], ind) => (
                          <div
                            className="flex-1 p-1 flex items-center flex-col rounded-md bg-white border border-gray-200"
                            key={ind}
                          >
                            <p className=" text-[#5754FF]">{type}</p>
                            <p className="text-off">{value}</p>
                          </div>
                        )
                      )}
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <DefineApptServiceCriteria
              {...{
                service,
                criteria,
                criteriaKeyRef,
                creating: sending,
                setServiceCriteriaKey: onServiceCriteriaKeyChange,
                initialCriteria,
              }}
            />
            <div className="w-full flex justify-between space-y-3 items-center px-3">
              <label className="block  mt-2">
                <span className="text-[12px] text-gray-800  text-sm font-medium ml-2">
                  TPP Amount
                </span>
                <input
                  type="number"
                  //  readOnly={!serviceCriteriaKey.match(/Insurers\|/)}
                  readOnly={sessionDeductionAmoun <= 0}
                  className=" block w-[10rem] text-right px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                  value={tppAmount}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setTppAmount(0);
                    }
                  }}
                  onChange={(e) => {
                    if (parseFloat(e.target.value || 0) <= total) {
                      setTppAmount(e.target.value);
                    }
                  }}
                ></input>
              </label>
              <label className="block  mt-2">
                <span className="text-[12px] text-gray-800  text-sm font-medium ml-2">
                  Patient Amount
                </span>
                <input
                  type="number"
                  readOnly={true}
                  className=" block w-[10rem] text-right px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl  focus:outline-none"
                  value={patientAmount}
                ></input>
              </label>
            </div>
          </div>
          <SelectedProducts
            {...{
              productSalesInformation,
              setProductSalesInformation,
              productsFromMemb,
            }}
          />
          <div className="flex w-full">
            <Flex
              align={"center"}
              gap={1}
              fontSize="sm"
              bg="white"
              rounded={"lg"}
              p="2"
            >
              <Text
                fontSize={"2xl"}
                color="gray.600"
                fontWeight={700}
              >{`Total:`}</Text>
              <Badge
                bg="transparent"
                color="gray.600"
                fontSize={"xl"}
                p="2"
                rounded={"md"}
              >{`$${total.toFixed(2)}`}</Badge>
            </Flex>
          </div>
          <div className="flex w-full mt-4">
            <Button
              leftIcon={<TiShoppingCart />}
              flex={1}
              form="buyerInfo"
              w="40"
              colorScheme="blue"
              mr={3}
              cursor={sending ? "not-allowed" : "pointer"}
              onClick={async () => {
                if (!sending) setShowSaleModal(true);
              }}
            >
              {productSalesInformation?.products.length > 0
                ? "Edit Products"
                : "Add Products"}
            </Button>
            <button
              type="submit"
              className="w-full flex-1 cursor-pointer text-white py-2 bg-med/80 hover:bg-med rounded-lg font-medium flex justify-center items-center"
              onClick={() => {
                changeCriteria();
              }}
              disabled={sending}
            >
              Update Details
              {sending && (
                <span className="ml-1">
                  <svg
                    className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25 stroke-[4px]"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

function criteriaFormatByKey(key, criteria) {
  if (key) {
    let pairs = key
      .split("||")
      .map((e) => {
        let pair = e.split("|");
        let type = pair[0];
        let crt = criteria.find((e) => e.type === type);
        if (crt) {
          switch (type) {
            case "Clinicians": {
              let name = crt.list?.find((d) => d.did === pair[1])?.name;
              if (name) return [type, name];
              return null;
            }

            case "Insurers": {
              let name = crt.list?.find((d) => d.iid === pair[1])?.name;
              if (name) return [type, name];
              return null;
            }

            case "Provider Types": {
              let name = crt.list?.find((d) => d.typeId === pair[1])?.name;
              if (name) return [type, name];
              return null;
            }
            default:
              return pair;
          }
        }
        return null;
      })
      .filter((e) => e);

    return pairs;
  }
  return [];
}
function criteriaFormatIdByKey(key, criteria) {
  if (key) {
    return key.split("||").reduce((acc, e) => {
      let pair = e.split("|");
      acc[pair[0]] = pair[1];
      return acc;
    }, {});
  }
  return {};
}

function Details({details, width}) {
  const boxRef = useRef();

  return (
    <div
      ref={boxRef}
      className="anim_height p-2 block  mt-2 shadow-inner whitespace-nowrap overflow-hidden  text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl "
      style={{width: width || "100%"}}
    >
      <ul className="flex-1 overflow-scroll h-full sbar2 overflow-x-hidden  text-off font-medium flex flex-col">
        {details.map((e, i) => {
          let [key, value] = Object.entries(e)[0];
          return (
            <li className="text-sm" key={i}>
              <span className="text-[#9F9DFA] font-medium">{`${key}: `}</span>
              <span>{value}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function roundUpToTwoDecimals(num) {
  num = Number(num);
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
