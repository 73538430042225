import {Flex} from "@chakra-ui/react";
import {Box, UnorderedList, Text, Spinner} from "@chakra-ui/react";
import {InvoiceOrReceiptButton} from "./InvoiceOrReceiptButton";
import {useEffect, useState} from "react";
import {membershipFileNameFormat} from "../../appointments/payment/uploads";
import {crudStorage} from "../../appointments/payment/helpers/crudStorage";

export const InvoiceOrReceiptMembership = ({
  state,
  transactions,
  setPreview,
  setSelectedTr,
  pendingTransaction,
}) => {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchReceipts = async () => {
      setLoading(true);
      try {
        let allReceipts = [];
        const paidTransactions = transactions?.filter(
          (transaction) =>
            parseFloat(transaction.amountPaidByPatient) >=
              parseFloat(transaction.amount) &&
            transaction.paymentStatus === "paid"
        );

        if (pendingTransaction) {
          const path = `${
            pendingTransaction.pid
          }/Receipts/Memberships/${membershipFileNameFormat(
            pendingTransaction
          )}/`;
          const files = await crudStorage(
            state,
            "view",
            "",
            "brightlighthealth",
            path
          );

          if (files?.data?.length > 0) {
            allReceipts = [
              ...allReceipts,
              ...files.data.map((file) => ({
                file,
                transaction: pendingTransaction,
              })),
            ];
          }
        }

        for (const transaction of paidTransactions || []) {
          const path = `${
            transaction.pid
          }/Receipts/Memberships/${membershipFileNameFormat(transaction)}/`;
          const files = await crudStorage(
            state,
            "view",
            "",
            "brightlighthealth",
            path
          );

          if (files?.data?.length > 0) {
            allReceipts = [
              ...allReceipts,
              ...files.data.map((file) => ({
                file,
                transaction,
              })),
            ];
          }
        }

        setReceipts(allReceipts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching receipts:", error);
        setLoading(false);
      }
    };

    if (state && (transactions?.length > 0 || pendingTransaction)) {
      fetchReceipts();
    }
  }, [transactions, pendingTransaction, state]);

  return (
    <Box
      h="100%"
      w="50%"
      ml={2}
      display="flex"
      flexDirection="column"
      alignItems="start"
      p={3}
      color="white"
    >
      <Flex w="100%" mb={10}>
        <Text color="gray.400" fontSize="sm" w="4rem">
          Invoices:
        </Text>
        <UnorderedList
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="start"
          overflowX="auto"
          h="full"
          className="sbar2"
          sx={{
            "::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
            },
            "::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "4px",
            },
            "::-webkit-scrollbar-track": {
              background: "transparent",
            },
          }}
        >
          {transactions?.map((transaction, i) => (
            <InvoiceOrReceiptButton
              key={`invoice-${transaction.tid}`}
              transaction={transaction}
              type="invoice"
              i={i}
              setSelectedTr={setSelectedTr}
              state={state}
              setPreview={setPreview}
            />
          ))}
        </UnorderedList>
      </Flex>
      <Flex w="100%">
        <Text color="gray.400" fontSize="sm" w="4rem">
          Receipts:
        </Text>
        <UnorderedList
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="start"
          overflowX="auto"
          h="full"
          className="sbar2"
          sx={{
            "::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
            },
            "::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "4px",
            },
            "::-webkit-scrollbar-track": {
              background: "transparent",
            },
          }}
        >
          {loading ? (
            <Flex justify="center" align="center" w="full" p={4}>
              <Spinner size="sm" color="blue.500" />
            </Flex>
          ) : (
            receipts.map((receipt, index) => (
              <InvoiceOrReceiptButton
                key={`receipt-${receipt.transaction.tid}-${index}`}
                transaction={receipt.transaction}
                type="receipt"
                i={index}
                setSelectedTr={setSelectedTr}
                state={state}
                setPreview={setPreview}
                receiptFile={receipt.file}
              />
            ))
          )}
        </UnorderedList>
      </Flex>
    </Box>
  );
};
