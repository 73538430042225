import React, {useState, useMemo, useRef, useEffect, useContext} from "react";
import {crud, sendAppointmentForms, updateApptMemb} from "../crudRequests";
import {lightFormat} from "date-fns";

import {TiShoppingCart} from "react-icons/ti";
import {DashStateContext, PatientContext} from "../pages/Patients";
import {PiWarningBold} from "react-icons/pi";
import {IoMdClose} from "react-icons/io";
import {
  fullInvoice,
  patientInvoice,
  insurerInvoice,
} from "./appointments/payment/uploads";
import {
  appointmentAvailable,
  areSetsEqual,
  generateAppointmentsIntervals,
} from "../additional_files/helpers";
import {v4 as uuidv4} from "uuid";
import {setToCurrentWeek} from "../additional_files/unavailable";
import swal from "sweetalert";

import {notificationsData} from "../additional_files/notifications";
import {months, weekday} from "../additional_files/helpers";
import SelectedProducts from "./Products/SelectedProducts";
import {SocketContext} from "../additional_files/context";
import getAmount from "./appointments/payment/helpers/getAmount";
import DefineApptServiceCriteria from "./DefineApptServiceCriteria";
import {
  Button,
  useToast,
  Text,
  FormControl,
  FormLabel,
  Switch,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
} from "@chakra-ui/react";

import ExternalSales from "./appointments/boockAppointment/products/sales";
import {AssignedProfessional} from "./appointments/boockAppointment/helpers/AssignedProfessional";
import Recurrent from "./appointments/boockAppointment/recurrent";
import {
  ProductsInMemberships,
  ServicesInMemberships,
} from "./appointments/boockAppointment/products/helpers.js";
import Selections from "./appointments/boockAppointment/Selections";
import NextAvailable from "./appointments/boockAppointment/NextAvailable";
import createRecurrence from "./appointments/boockAppointment/recurrent/helpers/createRecurrence";
import fetchProducts from "./Products/helpers/fetchProducts";
import InvitePatient from "./Patients/AddPatient";
import {nanoid} from "nanoid";
import {fetchPatient} from "./Patients/helpers/fetchPatient";
import {PatientBalancesModal} from "./PatientBalancesModal.jsx";
export default function BookAppointment(props) {
  const {
    schState,
    stateChanger,
    dispatch,
    patient: currentPatient,
    defaultData,
  } = props;
  const insurersMap = useMemo(() => {
    if (schState?.insurers.length > 0) {
      return schState?.insurers.reduce((ac, e) => {
        ac[e.iid] = e.name || "";
        return ac;
      }, {});
    }
    return {};
  }, [schState?.insurers]);

  const providerTypesMap = useMemo(() => {
    if (schState?.providerTypes.length > 0) {
      return schState?.providerTypes.reduce((ac, e) => {
        ac[e.typeId] = e.name || "";
        return ac;
      }, {});
    }
    return {};
  }, [schState?.providerTypes]);

  const [products, setProducts] = useState([]);
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState("");

  const [showSaleModal, setShowSaleModal] = useState(false);
  const [productSalesInformation, setProductSalesInformation] = useState({
    products: [],
    tax: 0,
    amount: 0,
    subtotal: 0,
    discountRate: 0,
    discountId: null,
  });

  const [productSaleClinicianId, setProductSaleClinicianId] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [customDuration, setCustomDuration] = useState(false);
  const [confirmationMeans, setConfirmationMeans] = useState({
    text: true,
    email: true,
  });

  useEffect(() => {
    fetchProducts(schState, setLoadingProducts, setProducts, toast);
  }, []);

  const [selectedDate, setSelectedDate] = useState(
    defaultData?.date
      ? lightFormat(defaultData.date, "yyyy-MM-dd")
      : lightFormat(new Date(), "yyyy-MM-dd")
  );
  const [selectedTime, setSelectedTime] = useState(
    defaultData?.date
      ? lightFormat(defaultData.date, "HH:mm")
      : lightFormat(new Date(), "HH:mm")
  );

  const toast = useToast();
  const searchRef = useRef(null);
  const bookDateRef = useRef(null);

  const bookTypeRef = useRef(null);
  const bookNoteRef = useRef(null);
  const bookError = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const bookSuccess = useRef(null);
  const clickRef = useRef(true);
  const notifySelectionsRef = useRef(new Set([1, 24]));
  const recurrentCriteriaRef = useRef({});

  const appointmentsIntervals = useRef(null);
  const unavailableDoctorIntervals = useRef(null);
  const [doctor, setDoctor] = useState(
    defaultData?.doctor || schState.selectedDoctor
  );
  const [recurrent, setRecurrent] = useState(false);

  const [bookingMedia, setBookingMedia] = useState({
    "in-person": true,
    phone: false,
  });
  const [appointmentType, setAppointmentType] = useState(
    schState.organization?.defaultAppointmentType || "teleconference"
  );
  const [assignedProfessional, setAssignedProfessional] = useState({
    clinician: true,
    technician: false,
  });
  const [clinicianSelectedByPatient, setClinicianSelectedByPatient] =
    useState(false);

  const [technician, setTechnician] = useState("");

  const [firstTime, setFirstTime] = useState(false);
  const supervisorRef = useRef(defaultData?.doctor || schState?.selectedDoctor);

  let supervisor = supervisorRef.current;

  const [services, setServices] = useState(() => {
    if (!doctor) return schState?.services.filter((e) => !e["non-sessions"]);
    let s = new Set(doctor?.services || []);
    return schState?.services.filter(
      (e) => s.has(e.serviceId) && !e["non-sessions"]
    );
  });
  const [service, setService] = useState("");

  const [patient, setPatient] = useState(
    currentPatient || schState.selectedPatient
  );

  const [duration, setDuration] = useState("15 min");

  const socket = useContext(SocketContext);
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");

  const [creating, setCreating] = useState(false);
  const criteriaKeyRef = useRef(null);
  const [serviceCriteriaKey, setServiceCriteriaKey] = useState("");

  const [tabIndex, setTabIndex] = useState(defaultData?.tabIndex ?? 0);
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };
  const [serviceDiscount, setServiceDiscount] = useState({value: 0, id: null});

  useEffect(() => {
    if (patient?.pid) fetchPatient(schState, patient?.pid, setPatient);
  }, [patient?.pid]);

  const [membForService, setMembForService] = useState(null);

  const onSelectAvailability = (date, clinician) => {
    setSelectedTime(lightFormat(date, "HH:mm"));
    setSelectedDate(lightFormat(date, "yyyy-MM-dd"));
    clinician && setDoctor(clinician);
    handleTabsChange(0);
  };

  let criteria = useMemo(() => {
    let crt = schState.serviceCriteria.filter((c) => c.type !== "Insurers");

    return crt.concat([
      {type: "Insurers", list: schState.insurers},
      {type: "Clinicians", list: schState?.doctors || [schState?.doctor]},
      {type: "Provider Types", list: schState.providerTypes},
    ]);
  }, [schState.serviceCriteria, schState.insurers, schState.doctors]);

  let initialCriteria = useMemo(() => {
    let initialCriteriaIdValues = {};
    let initialCriteriaValues = {};

    let tpp = "";

    if (Array.isArray(patient?.tpp)) {
      let insr = patient?.tpp.find(
        (ins) => parseInt(ins.noOfInsuredSessions ?? 0) > 0
      );

      insr =
        insr ||
        patient?.tpp.find((ins) => ins.noOfInsuredSessions === undefined);

      tpp = insr ? insr.insurerId : "";
    }

    if (tpp) {
      initialCriteriaIdValues["Insurers"] = tpp;
      initialCriteriaValues["Insurers"] = insurersMap[tpp] || "";
    }

    if (doctor) {
      initialCriteriaIdValues["Clinicians"] = doctor.did;
      initialCriteriaValues["Clinicians"] = doctor.name;
    }

    if (doctor && providerTypesMap[doctor.providerType]) {
      initialCriteriaIdValues["Provider Types"] = doctor.providerType;
      initialCriteriaValues["Provider Types"] =
        providerTypesMap[doctor.providerType];
    }

    let initialSelectedCriteria = criteria.filter((e) => {
      return (
        (doctor && e.type === "Clinicians") ||
        (doctor &&
          providerTypesMap[doctor.providerType] &&
          e.type === "Provider Types") ||
        (tpp && insurersMap[tpp] && e.type === "Insurers")
      );
    });

    return {
      initialCriteriaIdValues,
      initialCriteriaValues,
      initialSelectedCriteria,
    };
  }, [patient, patient?.tpp, doctor, criteria]);

  function criteriaFormatKey(data) {
    let en = Object.entries(data).filter(([k, v]) => v !== undefined);
    if (en.length > 0) {
      en = en.map((el) => el.join("|"));
      en.sort();
      return en.join("||");
    } else return "";
  }

  useEffect(() => {
    setServices(() => {
      return schState?.services.filter((e) => {
        if (!doctor) return !e["non-sessions"];
        else {
          let s = new Set(doctor?.services || []);
          return s.has(e.serviceId) && !e["non-sessions"];
        }
      });
    });
  }, [schState?.services, doctor]);

  useEffect(() => {
    setService((prev) => {
      let check = services.some((e) => {
        return e.serviceId === prev.serviceId;
      });
      return check ? prev : "";
    });
  }, [services]);

  useEffect(() => {
    if (schState.userType === "admin") {
      let {doctorsPayload} = schState;
      if (doctor) {
        let docAppointments = doctorsPayload[doctor.did].appointments;
        unavailableDoctorIntervals.current =
          doctorsPayload[doctor.did].unavailable?.intervals;
        appointmentsIntervals.current =
          generateAppointmentsIntervals(docAppointments);
      }
    } else {
      let docAppointments = schState.appointments;
      appointmentsIntervals.current =
        generateAppointmentsIntervals(docAppointments);
      unavailableDoctorIntervals.current =
        schState.selectedDoctor.unavailable?.intervals;
    }
  }, [schState, doctor]);

  useEffect(() => {
    getRoomsAndLocation();
  }, [schState.selectedLocation]);

  function getRoomsAndLocation() {
    let req = [
      {
        db: schState.db,
        collection: "rooms",
        parameters: [{lid: schState.selectedLocation}],
        method: "find",
      },
      {
        db: schState.db,
        collection: "locations",
        parameters: [{lid: schState.selectedLocation}],
        method: "findOne",
      },
    ];

    crud(schState, req).then((res) => {
      let lc = res?.data[1] || null;
      setRooms(res?.data[0] || []);
      setLocation(lc);
      setAddress(lc?.address || "");
      if (lc) {
        setProductSalesInformation((prev) => {
          return {...prev, tax: lc.productsTaxRate || prev.tax};
        });
      }
    });
  }

  const patientTppInfo = useMemo(() => {
    if (Array.isArray(patient?.tpp)) {
      let tpp = criteriaKeyRef.current?.["Insurers"] || false;
      return patient?.tpp.find((ins) => ins.insurerId === tpp);
    }
    return null;
  }, [serviceCriteriaKey, patient]);

  const {productsFromMemb} = useMemo(() => {
    const apptDate = new Date();
    let memberships = (patient?.memberships || []).filter((memb) => {
      const startDate = new Date(memb.startDate);
      const endDate = new Date(memb.endDate);
      return (
        memb.active &&
        (!memb.membership || (apptDate >= startDate && apptDate <= endDate))
      );
    });

    return ProductsInMemberships(memberships, []);
  }, [patient]);

  const {servicesFromMemb} = useMemo(() => {
    const apptDate = new Date();
    let memberships = (patient?.memberships || []).filter((memb) => {
      const startDate = new Date(memb.startDate);
      const endDate = new Date(memb.endDate);
      return (
        memb.active &&
        (!memb.membership || (apptDate >= startDate && apptDate <= endDate))
      );
    });
    return ServicesInMemberships(memberships, null, null);
  }, [patient]);

  useEffect(() => {
    let memb = servicesFromMemb[service.serviceId]?.relatedMemberships.find(
      (data) => {
        const serviceInfo = data.selectedServices[service.serviceId];
        const serviceBalance =
          (serviceInfo?.quantity || 0) -
          (serviceInfo?.consumed || 0) -
          (serviceInfo?.reserved || 0);
        return serviceInfo && serviceBalance > 0;
      }
    );

    setMembForService(memb || null);
  }, [service, servicesFromMemb]);

  useEffect(() => {
    let coveredMap = {};
    let subtotal = productSalesInformation.products.reduce((acc, ele) => {
      let productId = ele.id;
      let quantityFromMembership =
        productsFromMemb[productId]?.availableQty ?? 0;
      let remainingQuantity = Math.abs(
        Math.min(quantityFromMembership - Number(ele.quantity), 0)
      );
      coveredMap[productId] = ele.quantity - remainingQuantity;
      return (
        acc +
        parseFloat(ele.onSale ? ele.salePrice : ele.price) *
          parseFloat(remainingQuantity)
      );
    }, 0);

    let amount = getAmount({
      subtotal,
      tax: productSalesInformation.tax,
      discountRate: productSalesInformation.discountRate,
    });

    setProductSalesInformation({
      ...productSalesInformation,
      products: productSalesInformation.products.map((e) => ({
        ...e,
        covered: coveredMap[e.id],
      })),
      amount,
      subtotal,
    });
  }, [productsFromMemb]);

  async function bookAppointment() {
    try {
      if (!doctor.zoomLink && appointmentType === "teleconference") {
        setErrorMessage(
          "Please set up a zoom link in the clinician's profile before booking virtual appointments."
        );
        return;
      }

      setCreating(true);
      setErrorMessage("");
      const aid = uuidv4();
      const invoiceId = id(8);
      const receiptId = id(8);
      if (
        !service ||
        (!doctor && assignedProfessional["clinician"]) ||
        (technician.trim() === "" && assignedProfessional["technician"]) ||
        !patient ||
        !supervisor
      ) {
        setErrorMessage(
          "Please select a patient, clinician/technician, supervisor and service from the lists to proceed to book an appointment for them."
        );
        setCreating(false);
      } else {
        const inputTime = selectedTime;
        const time =
          parseInt(inputTime.split(":")[0]) > 12
            ? (parseInt(inputTime.split(":")[0]) - 12).toString() +
              ":" +
              inputTime.split(":")[1] +
              " PM"
            : parseInt(inputTime.split(":")[0]).toString() === "0"
            ? "12:" + inputTime.split(":")[1] + " AM"
            : parseInt(inputTime.split(":")[0]).toString() === "12"
            ? "12:" + inputTime.split(":")[1] + " PM"
            : parseInt(inputTime.split(":")[0]).toString() +
              ":" +
              inputTime.split(":")[1] +
              " AM";

        let dateArray = selectedDate.split("-").map((e) => parseInt(e));
        let h_min = inputTime.split(":").map((e) => parseInt(e));
        dateArray[1]--;
        const inputDate = new Date(...dateArray);
        const ISOdate = new Date(...dateArray.concat(h_min)).getTime();
        const date =
          weekday[inputDate.getDay()] +
          ", " +
          months[inputDate.getMonth()] +
          " " +
          inputDate.getDate() +
          ", " +
          inputDate.getFullYear();

        let serviceCriteriaKey = criteriaFormatKey(criteriaKeyRef.current);
        const serviceAvailableInMembership = membForService;
        let costsByCriteria = service.defaultCost || 100;

        if (serviceCriteriaKey && !service?.isfixedCost)
          costsByCriteria =
            service.costsByCriteria?.[serviceCriteriaKey] ?? costsByCriteria;

        let hours = Number.parseFloat(duration.split(" ")[0]) / 60;

        let serviceTax = 0;
        if (service?.taxable === true) {
          let typeId = criteriaKeyRef.current?.["Provider Types"];

          if (typeId) {
            let providerType = schState.providerTypes.find(
              (t) => t.typeId === typeId
            );
            serviceTax = parseFloat(providerType?.tax || 0);
          }
        }

        let serviceAmount = 0;
        let serviceSubtotal = 0;

        if (!serviceAvailableInMembership) {
          serviceSubtotal = Number.parseFloat(
            (service?.isfixedCost ? 1 : hours) *
              Number.parseFloat(costsByCriteria)
          );
          serviceAmount = getAmount({
            subtotal: serviceSubtotal,
            tax: serviceTax,
            discountRate: serviceDiscount.value,
          });
        }

        let amount = serviceAmount;
        let productSubtotal = 0;
        let productTax = 0;
        let productAmount = 0;
        let products = [];
        let productDiscountRate = 0;
        if (productSalesInformation?.products?.length > 0) {
          products = productSalesInformation?.products || [];
          productSubtotal = roundUpToTwoDecimals(
            productSalesInformation?.subtotal || 0
          );
          productTax = parseFloat(productSalesInformation?.tax || 0);
          productAmount = parseFloat(productSalesInformation?.amount || 0);
          productDiscountRate = parseFloat(
            productSalesInformation?.discountRate || 0
          );
          amount = roundUpToTwoDecimals(
            parseFloat(serviceAmount) + parseFloat(productAmount)
          );
        }

        let tpp = criteriaKeyRef.current?.["Insurers"] || false;
        let tppAmount = tpp ? amount : 0;

        if (
          patientTppInfo &&
          !serviceAvailableInMembership &&
          !isNaN(patientTppInfo.sessionDeductionAmount) &&
          parseFloat(patientTppInfo.sessionDeductionAmount) >= 0
        ) {
          let sessionDeductionAmount = parseFloat(
            patientTppInfo.sessionDeductionAmount
          );
          if (sessionDeductionAmount >= amount) {
            tppAmount = roundUpToTwoDecimals(amount);
          } else
            tppAmount = roundUpToTwoDecimals(
              patientTppInfo.sessionDeductionAmount
            );
        }

        let patientAmount = amount - tppAmount;

        const roomName = aid;

        let supervisorId = supervisor?.did;
        let supervisorName = supervisor?.name || schState.selectedDoctor?.name;
        let supervisorEmail =
          supervisor?.email || schState.selectedDoctor?.email;

        let locationName =
          schState.locations?.find((l) => l.lid === schState.selectedLocation)
            ?.name || "";

        let amountData = {
          serviceSubtotal,
          serviceDiscountRate: serviceDiscount?.value || 0,
          serviceDiscountId: serviceDiscount?.id || null,
          serviceTax: parseFloat(serviceTax),
          serviceAmount: roundUpToTwoDecimals(serviceAmount),
          products: products.map((p) => {
            let productId = p.id;
            let qty = productsFromMemb[productId]?.availableQty ?? 0;
            let covered = Math.min(qty, p.quantity);
            return {...p, covered};
          }),
          productSubtotal,
          productDiscountRate,
          productDiscountId: productSalesInformation?.discountId || null,
          productTax,
          productAmount,
          amount,
          tppAmount,
          patientAmount,
        };

        let paymentStatus = amount > 0 ? "pending" : "paid";
        let paymentMethod =
          paymentStatus === "paid" ? "Padi by Memberships" : "";
        const payload = {
          aid: aid,
          invoiceId,
          receiptId,
          confirmationMeans,
          room: selectedRoom,
          roomName: rooms.find((r) => r.rid === selectedRoom)?.roomName || "",
          lid: schState.selectedLocation,
          db: schState.db,
          locationName,
          pid: patient.pid,
          pName: patient.fName + " " + patient.lName,
          pEmail: patient.email,
          pPhone: patient.phone,
          pConfirmed: false,
          did: assignedProfessional["clinician"] ? doctor.did : supervisorId,
          oid: assignedProfessional["clinician"] ? doctor.oid : supervisor?.oid,
          dName: assignedProfessional["clinician"]
            ? doctor.name || schState.selectedDoctor.name
            : supervisorName,
          dEmail: assignedProfessional["clinician"]
            ? doctor.email || schState.selectedDoctor.email
            : supervisorEmail,
          supervisorId,
          supervisorName,
          supervisorEmail,
          teleconference: appointmentType === "teleconference",
          telephone: appointmentType === "telephone",
          appointmentType,
          address,
          // patientApptLink: `https://meet.brightlight.ai?room=${aid}&name=${patient.fName}&email=${patient.email}`,
          patientApptLink: `https://teleconference.brightlight.ai/${encodeURIComponent(
            roomName
          )}`,
          clinicApptLink: `https://teleconference.brightlight.ai/${encodeURIComponent(
            roomName
          )}`,
          date: date,
          time: time,
          duration,
          note: bookNoteRef.current.value,
          summary: "",
          cancelled: false,
          noShow: false,
          service: service?.serviceName,
          serviceDescription: service?.description || "",
          serviceId: service?.serviceId,
          serviceCodes: service?.serviceCodes || [],
          ISOdate,
          createdBy: `${schState.userType}|${
            schState.admin?.id || schState.doctor?.did
          }`,
          paymentStatus,
          notifyBefore: [...notifySelectionsRef.current],
          serviceCriteriaKey,
          claim: false,
          tpp,
          amountPaidByPatient: 0,
          amountPaidByTpp: 0,
          tppPaymentStatus: tppAmount > 0 ? "pending" : "paid",
          paymentMethod,
          patientPaymentStatus: patientAmount > 0 ? "pending" : "paid",
          apptCreatedBy: schState.userType === "doctor" ? "clinician" : "admin",
          apptBookingMedia: bookingMedia["in-person"] ? "in-person" : "phone",
          firstTime,
          assignedProfessional: assignedProfessional["clinician"]
            ? "clinician"
            : "technician",
          technician: assignedProfessional["technician"]
            ? technician.trim()
            : "",
          paidByMembership: membForService?.uuid || null,
          clinicianSelectedByPatient,
          ...amountData,
        };

        let sender = location?.name;
        let transaction = {
          type: "appointment",
          tid: uuidv4(),
          invoiceId: payload.invoiceId,
          receiptId: payload.receiptId,
          aid: payload.aid,
          oid: payload.oid,
          lid: payload.lid,
          db: schState.db,
          locationName,
          pid: patient.pid,
          pName: patient.fName + " " + patient.lName,
          pEmail: patient.email,
          did: payload.did,
          dName: payload.dName,
          dEmail: payload.dEmail,
          time: payload.duration,
          claim: payload.claim,
          supervisorId: payload.supervisorId,
          supervisorName: payload.supervisorName,
          paid: 0,
          pending: true,
          cancelled: false,
          serviceId: payload.serviceId,
          serviceType: payload.service.trim(),
          serviceDescription: payload.serviceDescription,
          serviceDate: payload.ISOdate,
          insurance: patient.insurance,
          dgCodes: [],
          serviceCodes: payload.serviceCodes,
          billingCodes: [],
          tpp,
          amountPaidByPatient: 0,
          amountPaidByTpp: 0,
          tppPaymentStatus: tppAmount > 0 ? "pending" : "paid",
          patientPaymentStatus: patientAmount > 0 ? "pending" : "paid",
          paymentStatus,
          paymentMethod,
          assignedProfessional: assignedProfessional["clinician"]
            ? "clinician"
            : "technician",
          technician: payload.technician,
          paidByMembership: membForService?.uuid || null,
          date: `${lightFormat(new Date(), "dd/MM/yy HH:mm")}`,
          ...amountData,
        };

        if (patient.pid && tpp) {
          const patientTppData = patient.tpp?.find((t) => t.insurerId === tpp);
          if (patientTppData) {
            transaction.insuranceType = patientTppData.insuranceType;
          }
        }

        if (recurrent) {
          try {
            setCreating(true);
            clickRef.current = false;
            await createRecurrence({
              recurrence: recurrentCriteriaRef.current,
              dispatch,
              state: schState,
              apptPayload: payload,
              transaction,
              doctor,
              patient,
              location,
              sender,
            });

            setCreating(false);
            clickRef.current = true;
            stateChanger(false);
            toast({
              title: "The appointments has been successfully booked!",
              status: "success",
              duration: 4000,
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Bookikg error.",
              description: error.message,
              status: "error",
              isClosable: true,
            });
            clickRef.current = true;
            setCreating(false);
          }

          return;
        }

        let {pid, did, lid, oid} = payload;
        let socketRooms = [pid, did, lid + oid];

        let sendInvEmail = async (schState, transaction) => {
          try {
            let invData = {
              state: schState,
              transaction,
              doctor,
              patient,
              tpp: transaction.tpp
                ? schState?.insurers.find(
                    (insurer) => insurer.iid === transaction.tpp
                  )
                : null,
              supervisor,
            };
            if (transaction.tpp) await insurerInvoice(invData);
            await patientInvoice(invData);
            await fullInvoice(invData);
          } catch (error) {
            console.log(error);
            toast({
              title: "Bookikg error.",
              description: error.message,
              status: "error",
              isClosable: true,
            });
          }
        };

        const updateInsuredSessions = () => {
          if (Array.isArray(patient?.tpp)) {
            let insr = patient?.tpp.find((ins) => ins.insurerId === tpp);

            if (insr && insr.noOfInsuredSessions) {
              let noOfInsuredSessions = parseInt(insr.noOfInsuredSessions) - 1;

              let newPatientTpp = patient?.tpp.map((ins) =>
                ins.insurerId === tpp ? {...ins, noOfInsuredSessions} : ins
              );

              crud(
                schState,
                [
                  {
                    db: schState.db,
                    collection: "patients",
                    parameters: [
                      {pid: payload.pid, "tpp.insurerId": tpp},
                      {
                        $set: {
                          "tpp.$.noOfInsuredSessions": noOfInsuredSessions,
                        },
                      },
                    ],
                    method: "updateOne",
                  },
                ],
                {
                  socket: {
                    rooms: socketRooms,
                    arguments: [
                      "update_pt",
                      payload.pid,
                      {tpp: newPatientTpp},
                      {},
                      [payload.did, payload.lid + payload.oid, payload.pid],
                    ],
                  },
                }
              ).then(() => {
                dispatch({
                  type: "UPDATE_PATIENT",
                  pid: payload.pid,
                  payload: {tpp: newPatientTpp},
                  options: null,
                });
              });
            }
          }
        };

        const sendEmailForms = async (schState, emailForms) => {
          if (emailForms && emailForms.length > 0) {
            try {
              await sendAppointmentForms(schState, {emailForms});
            } catch (error) {
              console.error("Error sending email forms:", error);
            }
          }
        };

        const fetchFormsRequest = {
          db: schState.db,
          collection: "forms",
          parameters: [{fid: {$in: service.forms}}],
          method: "find",
        };

        const formDataResponses = await crud(schState, [fetchFormsRequest]);
        let assignedForms = formDataResponses.data[0] || [];
        let patientFormsAssigned = patient.serviceFormsAssigned || [];
        const serviceForms = service.forms || [];
        const patientFormsSet = new Set(patientFormsAssigned);
        const serviceFormsSet = new Set(serviceForms);

        const notAssignedForms = new Set(
          [...serviceFormsSet].filter((formId) => !patientFormsSet.has(formId))
        );

        const serviceFormsStillNotAssigned = Array.from(notAssignedForms);
        patientFormsAssigned = [
          ...patientFormsAssigned,
          ...serviceFormsStillNotAssigned,
        ];

        if (service.firstAppointmentOnly) {
          const equalSets = areSetsEqual(patientFormsSet, serviceFormsSet);
          assignedForms = equalSets
            ? []
            : assignedForms.filter((form) =>
                serviceFormsStillNotAssigned.includes(form.fid)
              );
        }

        const formPayloads =
          assignedForms.length > 0
            ? assignedForms.map((formData) => ({
                fsid: nanoid(10),
                pid: patient.pid,
                fid: formData.fid,
                formTitle: formData.formTitle,
                formFields: formData.formFields,
                createdDate: formData.createdAt,
                assignedDate: new Date(),
                completed: false,
                createdBy: formData.creatorName,
                assignedBy: schState?.doctor
                  ? schState?.doctor.name
                  : schState?.admin?.name,
                senderId: schState?.doctor
                  ? schState?.doctor.did
                  : schState?.selectedLocation + schState?.admin?.oid,
                senderType: schState.userType,
                expirationLink: new Date(Date.now() + 48 * 60 * 60 * 1000),
                urlToken: nanoid(10),
              }))
            : [];

        let action = async () => {
          clickRef.current = false;

          let userType = schState.userType;
          let {ntf, ntfList} = notificationsData(
            userType,
            "New appointment",
            payload,
            schState
          );

          let pt = patient;

          const emailOption = confirmationMeans?.email
            ? {
                type: "new_appointment",
                content: payload,
                sender,
                org: schState.organization,
                location,
              }
            : null;

          const smsOption = confirmationMeans?.sms
            ? {
                sender,
                to: patient.phone,
                sms:
                  "Dear " +
                  payload.pName +
                  " , A new appointment has been scheduled with " +
                  payload.dName +
                  " for " +
                  new Date(payload.ISOdate).toString().replace(/\(.+\)/g, "") +
                  `. Sincerely, ${sender}.`,
              }
            : null;

          const requestObjs = [
            {
              db: schState.db,
              collection: "appointments",
              parameters: [payload],
              method: "insertOne",
            },
            {
              db: schState.db,
              collection: "patients",
              parameters: [
                {pid: patient.pid},
                {
                  $set: {
                    firstTimeAppt: false,
                    serviceFormsAssigned: patientFormsAssigned,
                  },
                },
              ],
              method: "updateOne",
            },
            {
              db: schState.db,
              collection: "notifications",
              parameters: [
                {userType: ntfList[0].userType},
                {$push: {[ntfList[0].id]: ntfList[0].notification}},
                {upsert: true},
              ],
              method: "findOneAndUpdate",
            },
            {
              db: schState.db,
              collection: "notifications",
              parameters: [
                {userType: ntfList[1].userType},
                {$push: {[ntfList[1].id]: ntfList[1].notification}},
                {upsert: true},
              ],
              method: "findOneAndUpdate",
            },
            {
              db: schState.db,
              collection: "billing",
              parameters: [transaction],
              method: "insertOne",
            },
          ];

          let emailForms = null;
          if (formPayloads.length > 0) {
            requestObjs.push({
              db: schState.db,
              collection: "form_submissions",
              parameters: [formPayloads],
              method: "insertMany",
            });
            emailForms = formPayloads.map((formPayload) => ({
              type: "new_form_submission",
              content: {
                ...formPayload,
                pEmail: patient.email,
                submittedBy: payload.submittedBy,
                pName: `${patient.fName} ${patient.lName}`,
              },
              options: {db: schState.db},
              org: schState.organization?.s3BucketName ?? "brightlight-clinic",
              sender: schState.organization.name,
              location: schState.selectedLocation,
            }));
          }

          if (Array.isArray(pt.did)) {
            let isNewClinician = false;
            let proceed = true;
            let updatePatient = new Set(pt.did);
            updatePatient.add(doctor.did);
            if (!pt.did.includes(doctor.did) && pt.did.length !== 0) {
              await swal({
                title: "Confirm new clinician!",
                text: `${payload.pName} is already a patient of another doctor. Are you sure he wants to make an appointment with ${payload.dName}?`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((value) => {
                if (value) {
                  isNewClinician = true;
                } else {
                  setCreating(false);
                  clickRef.current = true;
                  proceed = false;
                }
              });
            } else {
              if (pt.did.length === 0) {
                isNewClinician = true;
              }
            }
            if (!proceed) return;
            if (isNewClinician) {
              requestObjs.push({
                db: schState.db,
                collection: "patients",
                parameters: [{pid: pt.pid}, {$set: {did: [...updatePatient]}}],
                method: "updateOne",
              });
            }

            await updateApptMemb({
              state: schState,
              appointmentId: payload.aid,
              serviceId: payload.serviceId,
              membershipForServiceId: payload.paidByMembership || null,
              patientId: patient.pid,
              productsToAssign: (productSalesInformation?.products || []).map(
                (p) => {
                  let productId = p.id;
                  let qty = productsFromMemb[productId]?.availableQty ?? 0;
                  let covered = Math.min(qty, p.quantity);
                  return {quantity: p.quantity, covered, id: p.id};
                }
              ),
            });

            await crud(schState, requestObjs, {
              email: emailOption,
              sms: smsOption,
              apptReminderNotification: {
                appt: payload,
                sender,
                org: schState.organization,
                location,
              },
              socket: {
                rooms: socketRooms,
                arguments: [
                  "new_appt",
                  payload,
                  {
                    ntf,
                    updatePatient: isNewClinician
                      ? {did: [...updatePatient]}
                      : null,
                  },
                ],
              },
            })
              .then((res) => {
                isNewClinician &&
                  socket?.current.emit?.(
                    "update_pt",
                    payload.pid,
                    {...pt, did: [...updatePatient]},
                    {newDoctor: payload.did},
                    [payload.did, payload.lid + payload.oid, payload.pid]
                  );
                sendEmailForms(schState, emailForms);
                sendInvEmail(schState, transaction);
                updateInsuredSessions();
                dispatch({
                  type: "NEW_APPOINTMENT",
                  payload,
                  updatePatient: isNewClinician
                    ? {did: [...updatePatient]}
                    : null,
                });
                dispatch({
                  type: "UPDATE_PATIENT",
                  pid: payload.pid,
                  payload: {
                    serviceFormsAssigned: patientFormsAssigned,
                    did: [...updatePatient],
                  },
                  options: null,
                });
              })
              .catch(function (error) {
                setCreating(false);
                throw new error();
              });

            const response = await crud(schState, [
              {
                db: schState.db,
                collection: "patients",
                parameters: [{pid: patient.pid}],
                method: "findOne",
              },
            ]);

            const patientData = response.data[0];
            dispatch({
              type: "UPDATE_PATIENT",
              payload: patientData,
              pid: patient?.pid,
            });

            toast({
              title: "The appointment has been successfully booked!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            stateChanger(false);
            setCreating(false);
            clickRef.current = true;
          }
        };

        if (appointmentAvailable(appointmentsIntervals.current, payload)) {
          let u = (doctor.unavailable.untilRemoved || []).map(
            ([start, end]) => {
              return [
                setToCurrentWeek(new Date(payload.ISOdate), start),
                setToCurrentWeek(new Date(payload.ISOdate), end),
              ];
            }
          );

          if (
            !appointmentAvailable(
              unavailableDoctorIntervals.current,
              payload
            ) ||
            !appointmentAvailable(u, payload)
          )
            await swal({
              title: `This appointment falls at a time that is not available for ${payload.dName}. Are you sure you want to schedule it for that time?`,
              text: `This appointment will be rescheduled for ${payload.date} at ${payload.time} in your local time.`,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
              .then(async (value) => {
                if (value) {
                  setCreating(true);
                  clickRef.current = false;
                  await action();
                } else {
                  setCreating(false);
                  clickRef.current = true;
                }
              })
              .catch((error) => {
                console.log("error");
                throw error;
              });
          else {
            setCreating(true);
            clickRef.current = false;
            await action();
          }
        } else {
          setErrorMessage("The times for this appointment are already taken.");
          setCreating(false);
          clickRef.current = true;
          bookError.current && (bookError.current.className = "inline-block");
        }
      }
    } catch (error) {
      setCreating(false);
      clickRef.current = true;
      toast({
        description: error.response?.data || error.message,
        status: "error",
        isClosable: true,
      });
    }
  }

  return (
    <>
      {showSaleModal && (
        <ExternalSales
          {...{
            state: schState,
            products,
            closeSalesModal: () => setShowSaleModal(false),
            productSalesInformation,
            callback: (productData) => {
              setProductSaleClinicianId(productData.productSaleClinicianId);
              setProductSalesInformation(productData);
            },
            selectedClinicianId: productSaleClinicianId || doctor?.did,
            productsFromMemb,
          }}
        />
      )}
      <div
        className="fixed inset-0 show z-[999] overflow-hidden flex justify-center items-center"
        style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      >
        <div className="relative flex max-h-[800px] h-[90vh] items-center p-2 py-4 bg-white overflow-y-hidden rounded-3xl  sbar2 justify-center w-[840px]">
          <div className="relative h-full w-full overflow-y-scroll rounded-3xl p-5 sbar">
            <div
              className="relative inline-block w-full min-h-full  px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-md sm:my-8  sm:p-6 sm:align-middle"
              style={{backgroundColor: "#F6F5FF"}}
            >
              {errorMessage && (
                <div>
                  <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                    <div className="flex items-center justify-center w-12 bg-red-500">
                      <svg
                        className="w-6 h-6 mx-2 text-white fill-current"
                        viewBox="0 0 40 40"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                      </svg>
                    </div>
                    <div className="px-4 py-2 -mx-3">
                      <div className="mx-3">
                        <span className="font-semibold text-red-500 dark:text-red-400">
                          Error
                        </span>
                        <p className="text-sm text-gray-600 dark:text-gray-200">
                          {errorMessage}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="hidden" ref={bookSuccess}>
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-green-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-green-500 dark:text-green-400">
                        Appointment Booked
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200">
                        Appointment with the below specified logistics has been
                        successfully booked.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex relative">
                <h3
                  className="text-3xl font-medium text-gray-800 m-4 mb-6"
                  id="modal-title"
                  style={{color: "#C0BFFF"}}
                >
                  Book Appointment
                </h3>
                <div className="pl-5">
                  <span
                    className="absolute -top-2 -right-2 text-gray-400 bg-transparent rounded-lg cursor-pointer text-lg  ml-auto inline-flex items-center"
                    onClick={() => stateChanger(false)}
                  >
                    <IoMdClose />
                  </span>
                </div>
              </div>

              <Tabs index={tabIndex} onChange={handleTabsChange} isFitted>
                <TabList color={"#A1A1A1"} fontWeight={600} fontSize={"1rem"}>
                  <Tab>Appointment</Tab>
                  <Tab>Next Available</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p="0" pt="2">
                    <form className="" action="#">
                      <div className="flex justify-between pb-5 border-b border-gray-200">
                        <div className="flex-1 flex flex-col items-start pl-3.5">
                          <AssignedProfessional
                            {...{assignedProfessional, setAssignedProfessional}}
                          />
                          {assignedProfessional["clinician"] ? (
                            <Selections
                              {...{schState}}
                              currentDoctor={schState.selectedDoctor}
                              role="Doctor"
                              onSelect={setDoctor}
                              defaultDoctor={doctor}
                              doctor={doctor}
                              patient={patient}
                              label="DOC"
                            />
                          ) : (
                            <label
                              className="block mx-3.5 mt-1"
                              htmlFor="doctor"
                            >
                              <span className="text-[11px] text-off ml-2">
                                {`Define Technician`}
                              </span>

                              <input
                                onChange={(e) => {
                                  setTechnician(e.target.value);
                                }}
                                value={technician}
                                type="text"
                                className="drop-shadow block w-[17rem] whitespace-nowrap overflow-hidden px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                              />
                            </label>
                          )}
                          <Box
                            display="flex"
                            direction="row"
                            w={"17rem"}
                            mt="1"
                          >
                            <FormControl
                              display="flex"
                              alignItems="center"
                              justifyContent={"flex-end"}
                            >
                              <FormLabel
                                htmlFor="clinician-selected-by-patient"
                                mb="0"
                                fontSize="12"
                                color={
                                  clinicianSelectedByPatient
                                    ? "blue.500"
                                    : "#7f7b91"
                                }
                              >
                                Clinician selected by patient
                              </FormLabel>
                              <Switch
                                size={"sm"}
                                id="clinician-selected-by-patient"
                                onChange={() =>
                                  setClinicianSelectedByPatient(
                                    !clinicianSelectedByPatient
                                  )
                                }
                              />
                            </FormControl>
                          </Box>
                          <Selections
                            {...{schState}}
                            currentDoctor={schState.selectedDoctor}
                            role="Supervisor"
                            onSelect={(e) => (supervisorRef.current = e)}
                            defaultDoctor={defaultData?.doctor}
                            doctor={doctor}
                          />
                          <PatientContext.Provider
                            value={[patient, setPatient]}
                          >
                            <DashStateContext.Provider value={schState}>
                              <Box
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent="space-between"
                              >
                                <Selections
                                  {...{
                                    schState,
                                    currentPatient:
                                      currentPatient ||
                                      schState.selectedPatient,
                                  }}
                                  role="Patient"
                                  onSelect={setPatient}
                                  patient={patient}
                                  doctor={doctor}
                                  label="PATIENT"
                                />
                                <InvitePatient
                                  dispatch={dispatch}
                                  setPatient={(createdPatient) => {
                                    setPatient(createdPatient);
                                  }}
                                  shortCta={true}
                                />
                              </Box>
                            </DashStateContext.Provider>
                          </PatientContext.Provider>
                          <Box width={"17rem"}>
                            <PatientBalancesModal
                              patient={patient}
                              state={schState}
                              products={products}
                              services={services}
                            />
                          </Box>
                          {patient && (
                            <Details
                              width={"17rem"}
                              margin={"0 1px"}
                              details={[
                                {
                                  TPP: patientTppInfo
                                    ? insurersMap[patientTppInfo.insurerId]
                                    : "N/A",
                                },
                                {
                                  "Insured sessions": patientTppInfo
                                    ? patientTppInfo.noOfInsuredSessions ??
                                      "N/A"
                                    : "N/A",
                                },

                                {Email: patient.email || "N/A"},
                                {Phone: patient.phone || "N/A"},
                                {
                                  Age: patient.dob
                                    ? getAge(patient.dob)
                                    : "N/A",
                                },
                                {Gender: patient.gender || "N/A"},
                                {
                                  "Health Card":
                                    patient?.healthCard?.healthCardNo || "N/A",
                                },

                                {
                                  Membership: membForService
                                    ? membForService.name
                                    : "N/A",
                                },
                              ]}
                            />
                          )}
                          {parseInt(
                            patientTppInfo?.noOfInsuredSessions ?? -1
                          ) === 0 && (
                            <div className="mx-3.5">
                              <span className="ml-2 text-yellow-500 text-xs space-x-1 flex items-center">
                                <PiWarningBold />{" "}
                                <span>
                                  All sessions insured by this TPP have been
                                  used up!
                                </span>
                              </span>
                            </div>
                          )}

                          <label
                            className="block relative  mt-1"
                            htmlFor="doctor"
                          >
                            <span className="text-[11px] text-off ml-2">
                              Note
                            </span>
                            <span
                              className="absolute z-10 top-8 left-3 text-gray-400 text-xs"
                              ref={searchRef}
                              onClick={() => {
                                searchRef.current.className = "hidden";
                                bookNoteRef.current.focus();
                              }}
                            >
                              Write note...
                            </span>
                            <textarea
                              ref={bookNoteRef}
                              onFocus={() => {
                                searchRef.current.className = "hidden";
                              }}
                              onBlur={() => {
                                if (!bookNoteRef.current.value) {
                                  setTimeout(() => {
                                    if (searchRef.current)
                                      searchRef.current.className =
                                        "absolute z-10 top-8 left-3 text-gray-400 text-xs";
                                  }, 100);
                                }
                              }}
                              className="h-[4.5rem] drop-shadow block w-[17rem] px-3 py-2.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                            />
                          </label>
                          <label className="block  mt-1" htmlFor="type">
                            <span className="text-[11px] text-off ml-2">
                              Select Type
                            </span>
                            <select
                              onChange={(e) => {
                                if (e.target.value === "live")
                                  setAddress(location?.address || "");
                                else setAddress(null);
                                setAppointmentType(e.target.value);
                              }}
                              value={appointmentType}
                              type="type"
                              name="type"
                              id="type"
                              className="drop-shadow m-0 block w-[17rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                            >
                              <option value="teleconference">
                                🖥 &nbsp;Virtual
                              </option>
                              <option value="live">🎙 &nbsp;In Person</option>
                              <option value="telephone">
                                📞 &nbsp;Telephone
                              </option>
                            </select>
                          </label>
                          {appointmentType === "live" ? (
                            <Address {...{location, address, setAddress}} />
                          ) : null}
                        </div>
                        <div className="flex-1 flex flex-col items-end pr-2">
                          <label className="block mx-1 mb-1" htmlFor="service">
                            <span className="text-[11px] text-off ml-2">
                              Select Service
                            </span>
                            <select
                              onChange={(e) => {
                                let srv = services.find(
                                  (s) => s.serviceId === e.target.value
                                );
                                setService(srv || "");
                                setDuration(srv?.defaultDuration || "15 min");
                              }}
                              value={service?.serviceId || ""}
                              type="service"
                              name="service"
                              id="service"
                              className="drop-shadow m-0 block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                            >
                              <option value="">-</option>
                              {services.map((s, i) => {
                                return (
                                  <option key={i} value={s.serviceId}>
                                    {s.serviceName}
                                  </option>
                                );
                              })}
                            </select>
                          </label>
                          {service && (
                            <Details
                              width={"10rem"}
                              margin={"0 3px"}
                              details={[
                                {Category: service.category || "-"},
                                {
                                  "Default Cost/hrs":
                                    service.defaultCost || "-",
                                },
                                {
                                  "Default Duration":
                                    service.defaultDuration || "-",
                                },
                                {
                                  "In memberships":
                                    servicesFromMemb[service.serviceId]
                                      ?.availableQty ?? 0,
                                },
                              ]}
                            />
                          )}

                          <label className="block mx-1 mt-1" htmlFor="service">
                            <span className="text-[11px] text-off ml-2">
                              Select Room
                            </span>
                            <select
                              onChange={(e) => {
                                setSelectedRoom(e.target.value);
                              }}
                              value={selectedRoom}
                              type="room"
                              name="room"
                              id="room"
                              className="drop-shadow m-0 block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                            >
                              <option value="">-</option>
                              {rooms.map((r, i) => {
                                return (
                                  <option key={i} value={r.rid}>
                                    {r.roomName}
                                  </option>
                                );
                              })}
                            </select>
                          </label>

                          <label className="block mx-1 mt-1" htmlFor="date">
                            <span className="text-[11px] text-off ml-2">
                              Select Date
                            </span>
                            <input
                              ref={bookDateRef}
                              type="date"
                              name="date"
                              id="date"
                              value={selectedDate}
                              onChange={(e) => setSelectedDate(e.target.value)}
                              className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                            />
                          </label>
                          <label className="block mx-1 mt-1" htmlFor="time">
                            <span className="text-[11px] text-off ml-2">
                              Select Time
                            </span>
                            <input
                              type="time"
                              name="time"
                              id="time"
                              value={selectedTime}
                              onChange={(e) => setSelectedTime(e.target.value)}
                              className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                            />
                          </label>

                          <label className="block mx-1 mt-1" htmlFor="duration">
                            <span className="text-[11px] text-off ml-2">
                              {customDuration
                                ? "Define Duration (min)"
                                : "Select Duration"}
                            </span>
                            {customDuration ? (
                              <input
                                onClick={(e) => {}}
                                className="drop-shadow block w-[10rem]  px-4 py-3 pr-8 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                                type="text"
                                value={duration.split(" ")[0]}
                                onBlur={() => {
                                  if (!Number(duration.split(" ")[0])) {
                                    setDuration(`15 min`);
                                  }
                                }}
                                onChange={(e) => {
                                  if (
                                    !isNaN(e.target.value) &&
                                    Number(e.target.value) <= 480
                                  ) {
                                    let v = e.target.value
                                      .replace(/[-+., ]/g, "")
                                      .slice(0, 10);
                                    setDuration(`${v} min`);
                                  }
                                }}
                              />
                            ) : (
                              <select
                                onChange={(e) => {
                                  setDuration(e.target.value);
                                }}
                                value={duration || "15 min"}
                                type="duration"
                                name="duration"
                                id="duration"
                                className="drop-shadow block w-[10rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                              >
                                <option value="15 min">15 minutes</option>
                                <option value="30 min">30 minutes</option>
                                <option value="45 min">45 minutes</option>
                                <option value="60 min">60 minutes</option>
                                <option value="75 min">75 minutes</option>
                                <option value="90 min">90 minutes</option>
                                <option value="120 min">120 minutes</option>
                              </select>
                            )}
                          </label>
                          {/*<label className="block mx-1 mt-1  w-[10rem]">
                    <span className="text-[11px] text-off ml-2">
                      Auto-email invoice
                    </span>
                    <AutoEmailInvoice
                      {...{sendInvoiceEmail, setSendInvoiceEmail}}
                    />
                  </label>*/}
                          <FormControl
                            mt="1.5"
                            display="flex"
                            alignItems="center"
                            pl="1.5"
                            w="10rem"
                          >
                            <FormLabel
                              cursor={"pointer"}
                              fontSize={"11px"}
                              htmlFor="fixed-cost"
                              mb="0"
                              fontWeight={"normal"}
                              color={customDuration ? "#5754FF" : "#7F7B91"}
                            >
                              Custom Duration
                            </FormLabel>
                            <Switch
                              isChecked={customDuration}
                              onChange={(e) => {
                                setCustomDuration((prev) => !prev);
                              }}
                              size="sm"
                              id="fixed-cost"
                            />
                          </FormControl>
                          <div className="block relative pl-2  mt-4  w-[10rem] ">
                            <span className="text-[11px] text-off ">
                              Confirmation via
                            </span>
                            <div className="flex  flex-col justify-start">
                              <FormControl
                                mt="1.5"
                                display="flex"
                                alignItems="center"
                                pl="1.5"
                              >
                                <FormLabel
                                  mr="1"
                                  mb="0"
                                  cursor={"pointer"}
                                  fontSize={"11px"}
                                  htmlFor="via-email"
                                  fontWeight={"normal"}
                                  w={"30px"}
                                  color={
                                    confirmationMeans.email
                                      ? "#5754FF"
                                      : "#7F7B91"
                                  }
                                >
                                  Email
                                </FormLabel>
                                <Switch
                                  isChecked={confirmationMeans.email}
                                  onChange={(e) => {
                                    setConfirmationMeans((prev) => ({
                                      ...prev,
                                      email: !prev.email,
                                    }));
                                  }}
                                  size="sm"
                                  id="via-email"
                                />
                              </FormControl>
                              <FormControl
                                mt="1.5"
                                display="flex"
                                alignItems="center"
                                pl="1.5"
                              >
                                <FormLabel
                                  mr="1"
                                  mb="0"
                                  w={"30px"}
                                  cursor={"pointer"}
                                  fontSize={"11px"}
                                  htmlFor="via-text"
                                  fontWeight={"normal"}
                                  color={
                                    confirmationMeans.text
                                      ? "#5754FF"
                                      : "#7F7B91"
                                  }
                                >
                                  Text
                                </FormLabel>
                                <Switch
                                  isChecked={confirmationMeans.text}
                                  onChange={(e) => {
                                    setConfirmationMeans((prev) => ({
                                      ...prev,
                                      text: !prev.text,
                                    }));
                                  }}
                                  size="sm"
                                  id="via-text"
                                />
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>

                      {
                        <Recurrent
                          {...{
                            selectedDate,
                            setSelectedDate,
                            recurrentCriteriaRef,
                            setRecurrent,
                            recurrent,
                          }}
                        />
                      }

                      <NotifyBefore notifySelectionsRef={notifySelectionsRef} />
                      <DefineApptServiceCriteria
                        {...{
                          service,
                          criteria,
                          initialCriteria,
                          criteriaKeyRef,
                          creating,
                          action: (crt) => {
                            setServiceCriteriaKey(criteriaFormatKey(crt));
                          },
                        }}
                      />
                      <SelectedProducts
                        {...{
                          productSalesInformation,
                          setProductSalesInformation,
                          productsFromMemb,
                        }}
                      />
                      <div className="flex">
                        <Button
                          leftIcon={<TiShoppingCart />}
                          flex={1}
                          type="submit"
                          form="buyerInfo"
                          w="40"
                          colorScheme="blue"
                          mr={3}
                          cursor={creating ? "not-allowed" : "pointer"}
                          onClick={async () => {
                            if (!creating) setShowSaleModal(true);
                          }}
                        >
                          {productSalesInformation?.products.length > 0
                            ? "Edit Products"
                            : "Add Products"}
                        </Button>

                        <button
                          type="button"
                          className="w-full flex-1 text-white py-2 bg-med/80 hover:bg-med rounded-lg font-medium flex justify-center items-center"
                          onClick={() => {
                            if (!creating) bookAppointment();
                          }}
                          disabled={creating}
                        >
                          Book Appointment
                          {creating && (
                            <span className="">
                              <svg
                                className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25 stroke-[4px]"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  </TabPanel>
                  <TabPanel p="0" pt="2">
                    <NextAvailable
                      {...{
                        schState,
                        currentPatient,
                        setPatient,
                        patient,
                        setDuration,
                        duration,
                        setService,
                        service,
                        services,
                        defaultData,
                        setDoctor,
                        doctor,
                        onSelectAvailability,
                      }}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function NotifyBefore({notifySelectionsRef}) {
  const [selections, setSelections] = useState(new Set([1, 24]));

  return (
    <div className="flex flex-col items-start space-y-2 mx-4 mt-3">
      <p className="text-off font-normal text-sm text-start">
        Remind the patient of this appointment before
      </p>
      <div className="flex space-x-10">
        {[1, 2, 6, 12, 24, 48, 72, 168].map((d, i) => (
          <div
            key={i}
            className="flex flex-col justify-center items-center space-y-3"
          >
            <div
              className=" h-7 w-7 rounded-md shadow02 bg-white hover:cursor-pointer"
              style={{
                backgroundColor: selections.has(d) ? "#9F9DFA" : null,
              }}
              onClick={() => {
                !selections.has(d)
                  ? setSelections((prev) => {
                      prev.add(d);
                      let n = new Set(prev);
                      notifySelectionsRef.current = n;
                      return n;
                    })
                  : setSelections((prev) => {
                      if (prev.size === 1) return prev;
                      prev.delete(d);
                      let n = new Set(prev);
                      notifySelectionsRef.current = n;
                      return n;
                    });
              }}
            ></div>
            <p className="text-slate-400 text-sm">
              {d === 168 ? "week" : `${d}/h`}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function BookingMedia({setBookingMedia, bookingMedia}) {
  let entries = Object.entries(bookingMedia);

  return (
    <div className="flex flex-col items-start space-y-2 mx-4 my-7">
      <p className="text-off font-normal text-sm text-start">Booking Media</p>
      <div className="flex space-x-5">
        {entries.map(([key, value], i) => (
          <div
            key={i}
            className="flex flex-col justify-center items-center space-y-2"
          >
            <div
              className=" h-7 w-7 rounded-md shadow02 bg-white hover:cursor-pointer"
              style={{
                backgroundColor: value ? "#9F9DFA" : null,
              }}
              onClick={() => {
                setBookingMedia((prev) => {
                  let n = Object.entries(prev).map(([k, v]) => [k, k == key]);
                  return Object.fromEntries(n);
                });
              }}
            ></div>
            <p className="text-slate-400 text-sm">{key}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

function FirstTime({firstTime, setFirstTime, patient}) {
  return (
    <div className="flex flex-col items-start space-y-2 mx-4 my-7">
      <p className="text-off font-normal text-sm text-start">First Time</p>
      <div className="flex space-x-5">
        <div className="flex flex-col justify-center items-center space-y-2">
          <div
            className=" h-7 w-7 rounded-md shadow02 bg-white"
            style={{
              backgroundColor:
                patient?.firstTimeAppt === false ? null : "#9F9DFA",
            }}
            onClick={() => {
              //setFirstTime((prev) => !prev);
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function AutoEmailInvoice({sendInvoiceEmail, setSendInvoiceEmail}) {
  return (
    <div
      className="bg-[#9F9DFA] ml-2 rounded-full p-2 w-20 cursor-pointer transition duration-500"
      onClick={() => {
        setSendInvoiceEmail((prev) => !prev);
      }}
      style={{
        backgroundColor: sendInvoiceEmail ? "#9F9DFA" : "#FFFFFF",
        boxShadow: !sendInvoiceEmail && "0 0 0 2px inset #9F9DFA",
      }}
    >
      <p
        className="w-3 h-3 rounded-full bg-white transition duration-500"
        style={{
          transform: sendInvoiceEmail ? "translateX(55px)" : "translateX(0)",
          backgroundColor: sendInvoiceEmail ? "#FFFFFF" : "#9F9DFA",
        }}
      ></p>
    </div>
  );
}

function Details({details, width, margin}) {
  const boxRef = useRef();

  return (
    <div
      ref={boxRef}
      className="anim_height p-2 block mx-3.5 mt-1 shadow-inner whitespace-nowrap overflow-hidden  text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl "
      style={{width: width || "100%", margin: margin || null}}
    >
      <ul className="flex-1 overflow-scroll h-full sbar2 overflow-x-auto  text-off font-medium flex flex-col">
        {details.map((e, i) => {
          let [key, value] = Object.entries(e)[0];
          return (
            <li className="text-xs" key={i}>
              <Text>
                <span className="text-[#9F9DFA] font-medium">{`${key}: `}</span>
                {value}
              </Text>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function Address({location, address, setAddress}) {
  let additionalAddress = [location?.address || ""].concat(
    location?.additionalAddress || []
  );

  return (
    <div className="anim_height  block w-[17rem]  mt-2  whitespace-nowrap  text-sm text-off font-medium ">
      <label className="block " htmlFor="type">
        <span className="text-[11px] text-off ml-2">Select Address</span>
        <select
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          value={address}
          type="address"
          id="address"
          className="drop-shadow m-0 block w-[17rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
        >
          {additionalAddress.map((e, i) => (
            <option key={i} value={e}>
              {e}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}

function getAge(dateString) {
  try {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getUTCFullYear() - birthDate.getUTCFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  } catch (error) {
    return "-";
  }
}
function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}
function roundUpToTwoDecimals(num) {
  num = Number(num);
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
