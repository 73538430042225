import {crud} from "../../../crudRequests";
import {notificationsData} from "../../../additional_files/notifications";
import {initialInvoices} from "../payment/uploads";
export default async function cancelConfirmation({
  toast,
  allowAction,
  reason,
  appt,
  state,
  dispatch,
  socket,
}) {
  try {
    const updateBody = {
      ...appt,
      cancelled: true,
      cancellationReason: reason.trim(),
      noShow: false,
      arrived: false,
    };

    let allowCharging =
      allowAction ||
      parseFloat(appt?.amountPaidByTpp ?? 0) > 0 ||
      parseFloat(appt?.amountPaidByPatient ?? 0) > 0;

    updateBody.allowCharging = allowCharging;

    let {_id, ...data} = updateBody;
    let location = await crud(state, [
      {
        db: state.db,
        collection: "locations",
        parameters: [{lid: state.selectedLocation}],
        method: "findOne",
      },
    ]);
    location = location.data[0];
    let sender = location?.name;
    let {ntf, ntfList} = notificationsData(
      state.userType,
      "Updated appointment",
      updateBody,
      state
    );
    let reqObj = [
      {
        db: state.db,
        collection: "appointments",
        parameters: [{aid: data.aid}, {$set: data}],
        method: "updateOne",
      },
      {
        db: state.db,
        collection: "notifications",
        parameters: [
          {userType: ntfList[0].userType},
          {$push: {[ntfList[0].id]: ntfList[0].notification}},
          {upsert: true},
        ],
        method: "findOneAndUpdate",
      },
    ];

    if (!allowCharging) {
      reqObj.push({
        db: state.db,
        collection: "billing",
        parameters: [
          {aid: appt.aid},
          {
            $set: {
              cancelled: true,
              cancellationReason: data.cancellationReason,
            },
          },
        ],
        method: "findOneAndUpdate",
      });
    }

    await crud(state, reqObj, {
      email: {
        type: "update_appointment",
        content: data,
        options: {status: "Cancelled"},
        sender,
        org: state?.organization,
        location,
      },
    });

    if (!allowCharging) {
      await initialInvoices(appt, state);
    }
    socket?.emit?.("update_appt", updateBody, {ntf});
    return updateBody;

    /* if (appt.paidByMembership) {
      const apptPatient = state.patients.find(
        (patient) => patient.pid === appt.pid
      );
      const membershipData = apptPatient.memberships?.find(
        (membershipData) => {
          if (membershipData.uuid === appt.paidByMembership) {
            return true;
          }
          return false;
        }
      );
      membershipData.selectedServices[appt.serviceId] = {
        ...membershipData.selectedServices[appt.serviceId],
        reserved:
          parseInt(membershipData.selectedServices[appt.serviceId].reserved) -
          1,
      };
      await crud(
        state,
        [
          {
            db: state.db,
            collection: "patients",
            parameters: [
              {pid: appt.pid},
              {
                $set: {
                  "memberships.$[membership].selectedServices":
                    membershipData.selectedServices,
                },
              },
              {
                arrayFilters: [{"membership.uuid": appt.paidByMembership}],
              },
            ],
            method: "updateOne",
          },
        ],
        null
      );
      const updatedMemberships = (apptPatient.memberships || []).map(
        (mData) => {
          if (mData.uuid === appt.paidByMembership) {
            return {
              ...mData,
              selectedServices: membershipData.selectedServices,
            };
          }
          return mData;
        }
      );

      dispatch({
        type: "UPDATE_PATIENT",
        pid: appt.pid,
        payload: {
          memberships: updatedMemberships,
        },
      });
    }*/
  } catch (e) {
    throw e;
  }
}
