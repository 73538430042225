import React, {useState, useEffect} from "react";

import {
  Stack,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";

import PatientMembershipRow from "./PatientMembershipRow";

export default function PatientMembershipTable({
  patientMemberships,
  schState,
  dispatch,
  patient,
  setPatientRefetch,
}) {
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [updatingMembershipActiveStatus, setUpdatingMembershipActiveStatus] =
    useState(false);
  const currentDate = new Date();
  const filteredMemberships = patientMemberships?.filter((membership) => {
    const isCurrentMembership =
      currentDate >= new Date(membership.startDate) &&
      currentDate <= new Date(membership.endDate);
    const isCancelledMembership = membership.status === "Cancelled";
    const isFutureMembership = currentDate < new Date(membership.startDate);
    if (activeTab === 0) {
      return (
        (isCurrentMembership && !isCancelledMembership) || isFutureMembership
      );
    } else if (activeTab === 1) {
      return !isCurrentMembership && !isFutureMembership;
    } else if (activeTab === 2) {
      return isCancelledMembership;
    }
  });

  useEffect(() => {
    setUpdatingMembershipActiveStatus(false);
  }, [patientMemberships]);

  return (
    <>
      <Stack direction="column" spacing={4} alignItems="center" w="100%">
        <Tabs
          mt="1rem"
          variant="soft-rounded"
          colorScheme="blue"
          onChange={(index) => setActiveTab(index)}
        >
          <TabList>
            <Tab>Current</Tab>
            <Tab>Expired</Tab>
            <Tab>Cancelled</Tab>
          </TabList>
        </Tabs>
        <Table height="100%">
          <TableCaption>Patient's membership history</TableCaption>
          <Thead bg="gray.100">
            <Tr>
              <Th>Membership name</Th>
              <Th>Start date</Th>
              <Th>End date</Th>
              <Th>Price</Th>
              {activeTab === 0 && <Th>Active</Th>}
              <Th>Paid</Th>
              <Th>Pending</Th>
              {activeTab === 0 && <Th>Actions</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {filteredMemberships.map((membership) => (
              <PatientMembershipRow
                key={membership.uuid}
                membership={membership}
                schState={schState}
                patient={patient}
                dispatch={dispatch}
                setPatientRefetch={setPatientRefetch}
                setSelectedMembership={setSelectedMembership}
                updatingMembershipActiveStatus={updatingMembershipActiveStatus}
                setUpdatingMembershipActiveStatus={
                  setUpdatingMembershipActiveStatus
                }
                selectedMembership={selectedMembership}
                showActiveColumn={activeTab === 0}
              />
            ))}
          </Tbody>
        </Table>
      </Stack>
    </>
  );
}
