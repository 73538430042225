import {
  Box,
  Flex,
  Icon,
  useToast,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Text,
  Link,
  Editable,
  EditableInput,
  EditablePreview,
  IconButton,
  useEditableControls,
  ButtonGroup,
  Input,
} from "@chakra-ui/react";

import {useState, useEffect} from "react";
import {BiCopy} from "react-icons/bi";
import {MdEmail} from "react-icons/md";
import axios from "axios";
import {
  url,
  jwtDecode,
  crud,
  resendAppointmentNotificationController,
} from "../../../crudRequests";
import {TempLinkDialog} from "./TempLinkDialog";
import {EditIcon, CheckIcon, CloseIcon} from "@chakra-ui/icons";

export default function MeetingLinks({appointment, state, dispatch}) {
  const [open, setOpen] = useState(false);
  const toast = useToast();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [link, setLink] = useState(appointment?.patientApptLink || "");
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm" color="black">
        <IconButton
          icon={<CheckIcon />}
          size="xs"
          variant="ghost"
          {...getSubmitButtonProps()}
        />
        <IconButton
          icon={<CloseIcon />}
          size="xs"
          variant="ghost"
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center" gap={2} color="black">
        <Icon
          as={EditIcon}
          boxSize={4}
          cursor="pointer"
          {...getEditButtonProps()}
        />
        <Icon
          as={BiCopy}
          boxSize={4}
          cursor="pointer"
          onClick={() => {
            navigator.clipboard.writeText(appointment?.patientApptLink || "");
            toast({
              title: "Link copied to clipboard",
              status: "info",
              duration: 2000,
            });
          }}
        />
      </Flex>
    );
  }

  const resendAppointmentNotification = async (type) => {
    try {
      const location = state.locations.find(
        (l) => l.lid === state.selectedLocation
      );
      await resendAppointmentNotificationController(
        state,
        appointment,
        type,
        location
      );

      toast({
        title: "Appointment notification resent",
        description:
          "The appointment notification has been resent to the patient.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error resending appointment notification:", error);
      toast({
        title: "Error",
        description:
          "Failed to resend appointment notification. Please try again.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updatePatientApptLink = async (newValue) => {
    try {
      await crud(state, [
        {
          db: state.db,
          collection: "appointments",
          parameters: [
            {aid: appointment.aid},
            {$set: {patientApptLink: newValue, clinicApptLink: newValue}},
          ],
          method: "updateOne",
        },
      ]);
      dispatch({
        type: "UPDATE_APPOINTMENT",
        payload: {
          ...appointment,
          patientApptLink: newValue,
        },
      });
      toast({
        title: "Appointment link updated",
        description: "The appointment link has been updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating patient appointment link:", error);
      toast({
        title: "Error",
        description: "Failed to update appointment link. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setLink(appointment?.patientApptLink || "");
    if (!appointment) {
      setOpen(false);
    }
  }, [appointment]);

  return (
    <>
      <Accordion index={open ? [0] : []} my="4" allowToggle>
        <AccordionItem border="none">
          <h2>
            <AccordionButton
              color={"#5754FF"}
              borderBottom={"1px solid"}
              borderBottomColor={"#5754FF"}
              fontWeight={500}
              fontSize={"13px"}
              onClick={() => {
                setOpen((prev) => !prev);
              }}
            >
              <Box as="span" flex="1" textAlign="left">
                Meeting access
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>

          <AccordionPanel rounded={"sm"} p="2" bg="white">
            {appointment && (
              <VStack justify={"start"} flexWrap={"wrap"}>
                {appointment?.patientApptLink && (
                  <Box
                    w="full"
                    fontSize={"13px"}
                    display="flex"
                    alignItems="center"
                    gap={2}
                  >
                    <Text fontWeight={600} flexShrink={0}>
                      Link:
                    </Text>
                    <Flex flex={1} alignItems="center" minWidth={0} pr={2}>
                      <Editable
                        value={link}
                        color="blue.500"
                        cursor={"pointer"}
                        fontSize={"13px"}
                        isPreviewFocusable={false}
                        submitOnBlur={false}
                        onChange={(value) => {
                          setLink(value);
                        }}
                        onSubmit={(newValue) => {
                          updatePatientApptLink(newValue);
                        }}
                        display="flex"
                        alignItems="center"
                        width="full"
                      >
                        <Box position="relative" flex="1" minWidth={0}>
                          <Flex
                            width="full"
                            overflow="hidden"
                            alignItems="center"
                            height="28px"
                          >
                            <Link
                              href={appointment?.patientApptLink}
                              isExternal
                              width="full"
                              _hover={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              style={{cursor: "pointer"}}
                              display="flex"
                              alignItems="center"
                            >
                              <EditablePreview
                                width="full"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                style={{cursor: "pointer"}}
                              />
                            </Link>
                          </Flex>
                          <Input
                            as={EditableInput}
                            fontSize={"13px"}
                            position="absolute"
                            top="0"
                            left="0"
                            width="full"
                            height="28px"
                            borderRadius="md"
                            my="0"
                          />
                        </Box>
                        <Flex flexShrink={0} ml={2}>
                          <EditableControls />
                        </Flex>
                      </Editable>
                    </Flex>
                  </Box>
                )}
                <Box
                  fontSize={"13px"}
                  gap="2"
                  justify={"start"}
                  flexWrap={"wrap"}
                  whiteSpace={"pre-wrap"}
                  w="full"
                  mb="2"
                >
                  <Text mr="1.5" as="span" fontWeight={600}>
                    Patient phone:
                  </Text>
                  <Text mr="" as="span">
                    {appointment?.pPhone || "N/A"}
                  </Text>

                  {appointment?.pPhone && (
                    <Icon
                      onClick={() => {
                        navigator.clipboard.writeText(
                          appointment?.pPhone || ""
                        );
                        toast({
                          title: "Phone copied to clipboard",
                          status: "info",
                          duration: 2000,
                        });
                      }}
                      boxSize={4}
                      cursor={"pointer"}
                      as={BiCopy}
                      ml="2"
                    />
                  )}
                </Box>
                <Button
                  leftIcon={<MdEmail />}
                  colorScheme="blue"
                  variant="outline"
                  size="sm"
                  w="full"
                  onClick={() => resendAppointmentNotification("patient")}
                >
                  Resend Patient Notification
                </Button>
                <Button
                  leftIcon={<MdEmail />}
                  colorScheme="blue"
                  variant="outline"
                  size="sm"
                  w="full"
                  onClick={() => resendAppointmentNotification("clinician")}
                >
                  Resend Clinician Notification
                </Button>
                <TempLinkDialog
                  isOpen={isDialogOpen}
                  onClose={() => setDialogOpen(false)}
                  appointment={appointment}
                  state={state}
                />
              </VStack>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}
