import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Divider,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import {MembershipForm} from "./MembershipsCreateModal";
import React from "react";
import {EditIcon} from "@chakra-ui/icons";
import {useState} from "react";
import {crud} from "../../crudRequests";

export function MembershipsEditModal(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedServices, setSelectedServices] = useState(
    props.membership.selectedServices || {}
  );
  const [selectedProducts, setSelectedProducts] = useState(
    props.membership.selectedProducts || {}
  );
  const toast = useToast();
  const locationData = props.schState.locations.find(
    (loc) => loc.lid === props.schState.selectedLocation
  );
  const [formValues, setFormValues] = useState({
    ...props.membership,
    searchValue: "",
    sessionServicesFilter: true,
    nonSessionServicesFilter: true,
    active: props.membership.status === "Active",
    taxable: props.membership.taxable,
  });

  return (
    <>
      <IconButton
        variant="outline"
        aria-label="open menu"
        icon={<EditIcon />}
        bg="blue.400"
        opacity="75%"
        boxShadow="lg"
        onClick={onOpen}
        size="sm"
      />

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.400">Update membership/package</ModalHeader>
          <Divider /> <ModalCloseButton />
          <ModalBody>
            <MembershipForm
              schState={props.schState}
              formValues={formValues}
              setFormValues={setFormValues}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              isEdit={true}
              locationData={locationData}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={async () => {
                await crud(
                  props.schState,
                  [
                    {
                      db: props.schState.db,
                      collection: "locations",
                      parameters: [
                        {lid: props.selectedLocation.lid},
                        {
                          $set: {
                            "memberships.$[membership].description":
                              formValues.description,
                            "memberships.$[membership].name": formValues.name,
                            "memberships.$[membership].price": formValues.price,
                            "memberships.$[membership].status":
                              formValues.active ? "Active" : "Inactive",
                            "memberships.$[membership].duration":
                              formValues.duration,
                            "memberships.$[membership].durationPeriod":
                              formValues.durationPeriod,
                            "memberships.$[membership].selectedServices":
                              selectedServices,
                            "memberships.$[membership].selectedProducts":
                              selectedProducts,
                            "memberships.$[membership].taxable":
                              formValues.taxable,
                          },
                        },
                        {
                          arrayFilters: [
                            {"membership.mid": props.membership.mid},
                          ],
                        },
                      ],
                      method: "updateOne",
                    },
                  ],
                  null
                );
                await props.dispatch({
                  type: "UPDATE_MEMBERSHIP",
                  payload: {
                    mid: props.membership.mid,
                    membership: {
                      ...formValues,
                      status: formValues.active ? "Active" : "Inactive",
                    },
                  },
                });
                const updatedMemberships = props.membershipsData.map(
                  (membership) =>
                    membership.mid === props.membership.mid
                      ? {
                          ...membership,
                          ...formValues,
                          status: formValues.active ? "Active" : "Inactive",
                        }
                      : membership
                );
                props.setMembershipsData(updatedMemberships);
                toast({
                  title: "Membership updated.",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
                onClose();
              }}
            >
              Save changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
