import {nanoid} from "nanoid";
import {crud} from "../../../../crudRequests";
import {notificationsData} from "../../../../additional_files/notifications";

export function assignForm(
  e,
  forms,
  selectedForm,
  setAssignedForms,
  patient,
  dashState,
  setIsLoading,
  toast
) {
  setIsLoading(true);
  e.preventDefault();
  const location = dashState.locations.find(
    (l) => l.lid === dashState.selectedLocation
  );
  selectedForm = forms.find((form) => form.fid === selectedForm);
  if (selectedForm) {
    let payload = {
      fsid: nanoid(10),
      pid: patient.pid,
      fid: selectedForm.fid,
      formTitle: selectedForm.formTitle,
      formFields: selectedForm.formFields,
      createdDate: selectedForm.createdAt,
      assignedDate: new Date(),
      completed: false,
      createdBy: selectedForm.creatorName,
      assignedBy: dashState?.doctor
        ? dashState?.doctor.name
        : dashState?.admin?.name,
      senderId: dashState?.doctor
        ? dashState?.doctor.did
        : dashState?.selectedLocation + dashState?.admin?.oid,
      senderType: dashState.userType,
      expirationLink: new Date(Date.now() + 48 * 60 * 60 * 1000),
      urlToken: nanoid(10),
    };

    let {ntf} = notificationsData(
      dashState.userType,
      "New Form Submission",
      {...payload, pName: patient.fName + " " + patient.lName},
      dashState
    );

    let smsOption = {
      to: patient.phone,
      sms:
        "Dear " +
        patient.fName +
        " " +
        patient.lName +
        ". A new form has been assigned to you by " +
        payload.submittedBy +
        ". Please complete it as soon as possible." +
        " Sincerely, Brightlight Health Inc.",
    };

    crud(
      dashState,
      [
        {
          db: dashState.db,
          collection: "form_submissions",
          parameters: [payload],
          method: "insertOne",
        },
        {
          db: dashState.db,
          collection: "notifications",
          parameters: [
            {userType: "patient"},
            {$push: {[patient.pid]: ntf}},
            {upsert: true},
          ],
          method: "findOneAndUpdate",
        },
      ],
      {
        emailForm: {
          type: "new_form_submission",
          content: {
            ...payload,
            pEmail: patient.email,
            submittedBy: payload.submittedBy,
            pName: patient.fName + " " + patient.lName,
          },
          options: {db: dashState.db},
          org: dashState.organization?.s3BucketName ?? "brightlight-clinic",
          sender: dashState.organization.name,
          location: location,
        },
        sms: smsOption,
        socket: {
          rooms: [payload.pid],
          arguments: ["new_form_submission", payload, {ntf}],
        },
      }
    )
      .then(() => {
        setIsLoading(false);
        setAssignedForms((prev) => {
          return prev.concat(payload);
        });
        toast({
          title: "Form Assigned",
          description: selectedForm.formTitle + " successfully assigned",
          status: "success",
          isClosable: true,
        });
      })
      .catch(function (err) {
        setIsLoading(false);
        toast({
          title: "Form Not Assigned",
          description: err?.message || "An error occurred.",
          status: "error",
          isClosable: true,
        });
        console.log(err);
      });
  } else {
    setIsLoading(false);
    toast({
      title: "Form Not Assigned",
      description: "Please select a form to assign",
      status: "error",
      isClosable: true,
    });
  }
}
