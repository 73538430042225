import {useContext, useMemo} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import StripeAddCCForm from "./StripeAddCCForm";
import {Box, Heading} from "@chakra-ui/react";

export default function AddCCForm({
  setErrorMessage,
  setSuccessMessage,
  setCards,
  dashState,
  patient,
}) {
  const stripePromise = useMemo(() => {
    const selectedLocation = dashState.locations?.find(
      (loc) => loc.lid === dashState.selectedLocation
    );
    const stpPublicKey = selectedLocation?.stpPublicKey;

    if (!stpPublicKey || !stpPublicKey.match(/^(pk_live_|pk_test_)/)) {
      console.warn("Invalid Stripe public key for location:", stpPublicKey);
      return null;
    }
    return loadStripe(stpPublicKey);
  }, [dashState.locations, dashState.selectedLocation]);

  const appearance = {
    theme: "stripe",
    rules: {
      ".Tab": {
        border: "1px solid #E0E6EB",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
      },
      ".Label": {color: "#A7A7A7", fontSize: "13px"},
      ".Error": {color: "#f87171", fontSize: "12px"},
      ".Tab:hover": {
        color: "var(--colorText)",
      },
    },
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#A7A7A7",
      colorDanger: "#df1b41",
      borderRadius: "6px",
    },
  };
  return (
    <Box textAlign={"center"} w="100%">
      {stripePromise ? (
        <Elements
          stripe={stripePromise}
          options={{
            mode: "setup",
            currency: "cad",
            paymentMethodCreation: "manual",
            appearance,
            locale: "en",
          }}
        >
          <StripeAddCCForm
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            setCards={setCards}
            dashState={dashState}
            patient={patient}
          />
        </Elements>
      ) : (
        <Box p={4} color="red.500">
          No valid Stripe key found for this location. Please contact support.
        </Box>
      )}
    </Box>
  );
}
