import {Box, Flex, Text, Checkbox} from "@chakra-ui/react";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function WeekdayPicker({
  wDays,
  setWdays,
  start,
  setStart,
  end,
  setEnd,
  note,
  setNote,
}) {
  return (
    <Box mt="6">
      <Flex gap="2" w={"full"} flexWrap={"wrap"}>
        {weekday.map((e, i) => {
          let key = i;

          return (
            <Checkbox
              size={"lg"}
              colorScheme="blue"
              key={i}
              isChecked={wDays.has(key)}
              onChange={() => {
                setWdays((prev) => {
                  let d = new Set(prev);
                  d.has(key) ? d.delete(key) : d.add(key);

                  return d;
                });
              }}
              value={key}
            >
              <Text fontSize={"sm"}>{e.slice(0, 3)}</Text>
            </Checkbox>
          );
        })}
      </Flex>
      <UnavailableRange {...{start, setStart, end, setEnd, note, setNote}} />
    </Box>
  );
}

function UnavailableRange({start, setStart, end, setEnd, note, setNote}) {
  return (
    <div
      className="space-y-2   mt-4  w-full"
      onClick={(e) => e.stopPropagation()}
    >
      <p className="text-slate-400 ml-1 font-normal text-xs text-start">
        Select interval
      </p>

      <div className="flex items-center space-x-3">
        <input
          type="time"
          name="time"
          id="time"
          pattern="[0-9]{2}:[0-9]{2}"
          required
          value={start}
          className="drop-shadow block p-2 px-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          onChange={(e) => {
            setStart(e.target.value);
          }}
        />
        <span>-</span>
        <input
          type="time"
          name="time"
          id="time"
          pattern="[0-9]{2}:[0-9]{2}"
          required
          value={end}
          className="drop-shadow block  p-2 px-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          onChange={(e) => {
            setEnd(e.target.value);
          }}
        />
      </div>
      <div className="w-full mt-4">
        <p className="text-slate-400 ml-1 font-normal text-xs text-start">
          Note
        </p>
        <input
          type="text"
          className="w-full drop-shadow block p-2 px-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
