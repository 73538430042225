import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  FormControl,
  Icon,
  Text,
  VStack,
  Textarea,
  Button,
  HStack,
  Select,
} from "@chakra-ui/react";
import React, {useState, useRef, useEffect} from "react";
import {TbReportMoney} from "react-icons/tb";
import SelectGiftCard from "./SelectGiftCard";
import {v4 as uuidv4} from "uuid";
import saleProducts from "../../Products/helpers/saleProducts";
import {ProductCardPaymentModal} from "./ProductCardPaymentModal";
import {crud} from "../../../crudRequests";
import {populateProductMembershipData} from "../../Products/SalesModal";

async function updateGiftCardBalance(state, selectedGiftCard, amount) {
  if (!selectedGiftCard) {
    throw new Error("No gift card selected");
  }

  const newBalance = selectedGiftCard.balance - amount;
  if (newBalance < 0) {
    throw new Error("Insufficient balance on the gift card");
  }

  await crud(state, [
    {
      db: state.db,
      collection: "giftCards",
      parameters: [
        {gcId: selectedGiftCard.gcId},
        {$set: {balance: newBalance}},
        {returnNewDocument: true},
      ],
      method: "findOneAndUpdate",
    },
  ]);
}
const id = (n) => {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
};

export const consumeMembershipProducts = async (
  state,
  patient,
  products,
  dispatch,
  setPatient
) => {
  const res = await crud(state, [
    {
      db: state.db,
      collection: "patients",
      parameters: [{pid: patient.pid}],
      method: "findOne",
    },
  ]);
  const latestPatientData = res.data[0];
  let updatedMembershipData = latestPatientData.memberships;
  const updatedProductData = populateProductMembershipData(
    latestPatientData.memberships,
    products
  );
  const membershipUpdates = [];
  for (const purchasedProduct of updatedProductData) {
    const productMembershipData = purchasedProduct.includedInMembership;
    let coveredQuantity = 0;
    for (const membership of productMembershipData.membershipsWithProduct) {
      const membershipProductData =
        membership.selectedProducts[purchasedProduct.id];
      const productBalance =
        (membershipProductData?.quantity || 0) -
        (membershipProductData?.consumed || 0) -
        (membershipProductData?.reserved || 0);
      if (productBalance >= purchasedProduct.quantity - coveredQuantity) {
        const requestedQuantity = purchasedProduct.quantity - coveredQuantity;
        membershipUpdates.push({
          membershipId: membership.uuid,
          productId: purchasedProduct.id,
          quantity: requestedQuantity,
          status: "consumed",
        });

        coveredQuantity += requestedQuantity;
      } else {
        const requestedQuantity = productBalance;
        membershipUpdates.push({
          membershipId: membership.uuid,
          productId: purchasedProduct.id,
          quantity: requestedQuantity,
          status: "consumed",
        });

        coveredQuantity += requestedQuantity;
      }
      if (coveredQuantity >= purchasedProduct.quantity) {
        break;
      }
    }
  }

  if (membershipUpdates.length > 0) {
    for (const membershipUpdate of membershipUpdates) {
      updatedMembershipData = updatedMembershipData.map((membership) => {
        if (membership.uuid === membershipUpdate.membershipId) {
          return {
            ...membership,
            selectedProducts: {
              ...membership.selectedProducts,
              [membershipUpdate.productId]: {
                ...membership.selectedProducts[membershipUpdate.productId],
                consumed:
                  (membership.selectedProducts[membershipUpdate.productId]
                    .consumed || 0) + membershipUpdate.quantity,
              },
            },
          };
        }
        return membership;
      });
    }
  }

  crud(state, [
    {
      db: state.db,
      collection: "patients",
      parameters: [
        {pid: patient.pid},
        {$set: {memberships: updatedMembershipData}},
      ],
      method: "updateOne",
    },
  ]);
  dispatch({
    type: "UPDATE_PATIENT",
    pid: patient.pid,
    payload: {memberships: updatedMembershipData},
    options: null,
  });
  if (setPatient) {
    setPatient((prev) => {
      return {...prev, memberships: updatedMembershipData};
    });
  }
};

export const reserveMembershipProducts = async (
  state,
  patient,
  requestedMembership,
  product,
  requestedQuantity,
  dispatch,
  setPatient
) => {
  let updatedMembershipData = patient.memberships;
  if (product.includedInMembership) {
    updatedMembershipData = updatedMembershipData.map((membership) => {
      if (membership.uuid === requestedMembership.uuid) {
        return {
          ...membership,
          selectedProducts: {
            ...membership.selectedProducts,
            [product.id]: {
              ...membership.selectedProducts[product.id],
              reserved:
                (membership.selectedProducts[product.id].reserved || 0) +
                requestedQuantity,
            },
          },
        };
      }
      return membership;
    });
  }
  crud(state, [
    {
      db: state.db,
      collection: "patients",
      parameters: [
        {pid: patient.pid},
        {$set: {memberships: updatedMembershipData}},
      ],
      method: "updateOne",
    },
  ]);
  dispatch({
    type: "UPDATE_PATIENT",
    pid: patient.pid,
    payload: {memberships: updatedMembershipData},
    options: null,
  });
  if (setPatient) {
    setPatient((prev) => {
      return {...prev, memberships: updatedMembershipData};
    });
  }
};

export const createMembershipLog = async (
  state,
  dispatch,
  setPatient,
  patient,
  membershipData,
  product,
  requestedQuantity,
  status,
  appointmentId
) => {
  const membershipLog = {
    patientId: patient.pid,
    membershipId: membershipData.uuid,
    productId: product.id,
    quantity: requestedQuantity,
    status,
    appointmentId,
    date: new Date().getTime(),
  };
  return await crud(state, [
    {
      db: state.db,
      collection: "membershipLogs",
      method: "insertOne",
      parameters: [membershipLog],
    },
  ]);
};

export const processProductPayment = async (
  {
    amount,
    subtotal,
    paymentMethod,
    description,
    selectedGiftCard,
    state,
    toast,
    selectedProducts,
    patient,
    productSaleClinicianId,
    discount,
    productTax,
    prepaymentsApplied,
    updatePrepayments,
  },
  dispatch,
  setPatient
) => {
  const invoiceId = id(8);
  const receiptId = id(8);

  let amountData = {
    products: selectedProducts,
    productSubtotal: subtotal,
    productDiscountRate: Number(discount.value),
    productDiscountId: discount.id || null,
    productTax,
    productAmount: amount,
    amount,
  };

  const transaction = {
    type: "product",
    tid: uuidv4(),
    invoiceId,
    receiptId,
    lid: state.selectedLocation,
    pid: patient?.pid,
    pName: `${patient?.fName} ${patient?.lName}`,
    pEmail: patient?.email,
    pending: false,
    cancelled: false,
    paid: roundUpToTwoDecimals(amount),
    products: selectedProducts,
    tppAmount: 0,
    patientAmount: roundUpToTwoDecimals(amount),
    amountPaidByPatient: roundUpToTwoDecimals(amount),
    amountPaidByTpp: 0,
    patientPaymentStatus: "paid",
    paymentStatus: "paid",
    serviceDate: new Date().getTime(),
    serviceType: "Product Sale",
    paymentMethod: prepaymentsApplied ? "prepayment" : paymentMethod,
    description,
    note: description,
    giftCardId: selectedGiftCard?.id,
    productSaleClinicianId,
    prepaymentsApplied,
    ...amountData,
  };

  if (state.userType === "doctor") {
    transaction.did = state.doctor?.did;
  }

  if (paymentMethod === "giftCard") {
    await updateGiftCardBalance(state, selectedGiftCard, amount);
  }

  let receiptPath = null;

  await saleProducts(
    transaction,
    () => {},
    () => {},
    state.patient,
    state,
    () => {},
    toast,
    (path) => {
      receiptPath = path;
    }
  );

  await consumeMembershipProducts(
    state,
    patient,
    selectedProducts,
    dispatch,
    setPatient
  );

  if (prepaymentsApplied && receiptPath && updatePrepayments) {
    await updatePrepayments(receiptPath);
  }

  return transaction;
};

function roundUpToTwoDecimals(num) {
  num = Number(num);
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function ProductPaymentModal({
  isOpen,
  onClose,
  amount,
  subtotal,
  discount,
  productTax,
  state,
  toast,
  selectedProducts,
  patient,
  productSaleClinicianId,
  dispatch,
  setPatient,
  prepaymentsApplied,
  updatePrepayments,
}) {
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [method, setMethod] = useState("cash");
  const [selectedGiftCard, setSelectedGiftCard] = useState(null);
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const initialRef = useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb="0">
          <HStack gap={1}>
            <Icon
              className="show-icon"
              boxSize={6}
              color="blue.500"
              as={TbReportMoney}
            />
            <Text
              fontSize={"2xl"}
              color="gray.800"
              textAlign={"center"}
              fontWeight={600}
            >
              {"Product Payment"}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ProductCardPaymentModal
          patient={patient}
          appointment={{}}
          state={state}
          open={cardModalOpen}
          setOpen={setCardModalOpen}
          onClose={onClose}
          transaction={{
            type: "product",
            amount: amount,
            paymentMethod: "creditCard",
            products: selectedProducts,
            subtotal,
            productSaleClinicianId,
            discount,
            productTax,
          }}
          setPatient={setPatient}
          dispatch={dispatch}
        />
        <ModalBody pt="0">
          <form
            id="product-payment-form"
            onSubmit={async (e) => {
              try {
                e.preventDefault();
                if (method === "creditCard") {
                  setCardModalOpen(true);
                  return;
                }
                setIsLoading(true);
                const paymentDescription = description || "Product Payment";
                const transaction = await processProductPayment(
                  {
                    amount,
                    subtotal,
                    paymentMethod: method,
                    description: paymentDescription,
                    selectedGiftCard,
                    state,
                    toast,
                    selectedProducts,
                    patient,
                    productSaleClinicianId,
                    discount,
                    productTax,
                    prepaymentsApplied,
                    updatePrepayments,
                  },
                  dispatch,
                  setPatient
                );

                transaction.patientPaymentStatus = "paid";
                transaction.paymentStatus = "paid";

                setDescription("");
                setMethod("cash");
                setSelectedGiftCard(null);
                onClose();
              } catch (e) {
                toast({
                  title: "Payment Error",
                  description: e.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              } finally {
                setIsLoading(false);
              }
            }}
          >
            <VStack gap={3}>
              <Textarea
                ref={initialRef}
                resize={"vertical"}
                value={description || ""}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={"Type the description..."}
                size="sm"
              />
              <HStack w="full" align={"self-start"} gap={4}>
                <FormControl id="method" isRequired flex={1}>
                  <FormLabel fontSize={"sm"} ml={"1"} color={"gray.700"}>
                    Method
                  </FormLabel>
                  <Select
                    variant={"outline"}
                    size={"sm"}
                    rounded={"md"}
                    w={"full"}
                    value={prepaymentsApplied ? "Pre-Payment" : method}
                    isDisabled={prepaymentsApplied}
                    onChange={(e) => {
                      let mtd = e.target.value;
                      if (mtd !== "giftCard") setSelectedGiftCard(null);
                      setMethod(mtd);
                    }}
                  >
                    {prepaymentsApplied && (
                      <option value={"prepayment"}>Pre-Payment</option>
                    )}
                    <option value={"cash"}>Cash</option>
                    <option value={"check"}>Check</option>
                    <option value={"giftCard"}>Gift Card</option>
                    <option value={"creditCard"}>Credit Card</option>
                  </Select>
                </FormControl>
              </HStack>

              {method === "giftCard" && (
                <>
                  <AssociatedGiftCards
                    {...{
                      state,
                      setSelectedGiftCard,
                      selectedGiftCard,
                      patientEmail: patient.email,
                    }}
                  />
                  <SelectGiftCard
                    {...{state, setSelectedGiftCard, selectedGiftCard}}
                  />
                </>
              )}
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            type="submit"
            form="product-payment-form"
            colorScheme="blue"
            mr={3}
          >
            Confirm Payment
          </Button>
          <Button onClick={onClose} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function AssociatedGiftCards(props) {
  const [associatedGiftCards, setAssociatedGiftCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  useEffect(() => {
    if (props.selectedGiftCard?.secretCode !== selectedCard) {
      setSelectedCard("");
    }
  }, [props.selectedGiftCard?.secretCode]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchAssociatedGiftCards = async () => {
      setLoading(true);
      const res = await crud(props.state, [
        {
          db: props.state.db,
          collection: "giftCards",
          parameters: [{email: props.patientEmail}],
          method: "find",
        },
      ]);
      setAssociatedGiftCards(res.data[0] || []);
      setLoading(false);
    };
    fetchAssociatedGiftCards();
  }, [props.patientEmail]);
  return (
    <FormControl id="associatedGiftCards" flex={1}>
      <FormLabel fontSize={"sm"} ml={"1"} color={"gray.700"}>
        Associated Gift Cards
      </FormLabel>
      <Select
        variant={"outline"}
        size={"sm"}
        rounded={"md"}
        w={"full"}
        value={selectedCard}
        onChange={(evt) => {
          setSelectedCard(evt.target.value);
          props.setSelectedGiftCard(
            associatedGiftCards.find((gc) => gc.secretCode === evt.target.value)
          );
        }}
      >
        <option key={"empty"} value={""}>
          {loading ? "Loading..." : "-"}
        </option>
        {associatedGiftCards.map((gc) => {
          return (
            <option key={gc._id} value={gc.secretCode}>
              {gc.secretCode} - ${parseFloat(gc.balance).toFixed(2)} -{" "}
              {new Date(gc.creationDate).toLocaleString("en-CA")}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
}
