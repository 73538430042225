import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  TableContainer,
  Image,
} from "@chakra-ui/react";
import {useRef, useState} from "react";
import {PrepaymentsTable} from "./PrepaymentsTable";
import PreviewFile from "../../appointments/payment/helpers/PreviewFile";
import {fileProxy} from "../Files/helpers/fileProxy";

export const PrepaymentDetails = ({
  prepayment,
  isOpen,
  onClose,
  patientId,
  state,
}) => {
  const btnRef = useRef();
  const [fetchingFileData, setFetchingFileData] = useState(false);
  const [preview, setPreview] = useState(null);
  const receiptsUrl = prepayment?.receiptsUrl || [];

  const toast = useToast();

  const extractDateFromPath = (filePath) => {
    try {
      const match = filePath.match(/prefix_(\d+)-/);
      if (match && match[1]) {
        return new Date(parseInt(match[1])).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });
      }
      return null;
    } catch (error) {
      console.error("Error extracting date from path:", error);
      return null;
    }
  };

  const getReceipt = async (receiptPath) => {
    try {
      toast({
        title: `Loading Receipt`,
        description: "Please wait while we retrieve the document...",
        status: "loading",
        variant: "subtle",
        duration: null,
        isClosable: true,
      });
      setFetchingFileData(true);

      const lastSlashIndex = receiptPath.lastIndexOf("/");
      const path = receiptPath.substring(0, lastSlashIndex + 1);
      const selectedFile = receiptPath.substring(lastSlashIndex + 1);
      const file = await fileProxy(
        state,
        "brightlighthealth",
        path,
        selectedFile
      );

      if (file) {
        const blob = new Blob([file.data], {
          type: file.headers["content-type"],
        });
        const blobUrl = URL.createObjectURL(blob);
        setPreview({
          data: blobUrl,
          path,
          fileName: selectedFile,
        });
      }
    } catch (error) {
      console.error("Error fetching receipts:", error);
      toast({
        title: "Error",
        description: "Failed to fetch receipts",
        status: "error",
        duration: 5000,
      });
    } finally {
      toast.closeAll();
      setFetchingFileData(false);
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Pre-Payment Balance</DrawerHeader>

        <DrawerBody>
          <Box my={4}>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Pre-Payment Information
            </Text>
            <PrepaymentsTable
              prepayments={[prepayment]}
              patientId={patientId}
              state={state}
              isIndividualPrepayment
            />
          </Box>
          <Box my={6}>
            <Text fontSize="xl" fontWeight="bold">
              Applied Receipts
            </Text>
            {receiptsUrl.length === 0 ? (
              <Text color="gray.500" textAlign="center" py={4}>
                No Receipts have been applied to this pre-payment.
              </Text>
            ) : (
              <TableContainer
                mt={4}
                flex="1"
                borderColor="gray.50"
                borderRadius="md"
                boxShadow="md"
                overflowX="auto"
                height="auto"
                overflowY="auto"
                position="relative"
              >
                <Table variant="simple" size="sm">
                  <Thead bg="gray.100" position="sticky" top={0} zIndex={2}>
                    <Tr>
                      <Th textAlign="center">No.</Th>
                      <Th textAlign="center">Date</Th>
                      <Th textAlign="center">Document</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {receiptsUrl.map((receiptPath, index) => (
                      <Tr key={index}>
                        <Td textAlign="center">{index + 1}</Td>
                        <Td textAlign="center">
                          {extractDateFromPath(receiptPath)}
                        </Td>
                        <Td textAlign="center">
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              getReceipt(receiptPath);
                            }}
                            key={index}
                            bgColor="blue.500"
                            color="gray.100"
                            m="2px"
                            rounded="lg"
                            p="2px"
                            px="4px"
                            cursor="pointer"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            position="relative"
                            zIndex="1"
                            _hover={{bgColor: "blue.600"}}
                            style={{pointerEvents: "auto", margin: "0 auto"}}
                            isDisabled={fetchingFileData}
                          >
                            <Image
                              alt=""
                              src="/images/Clinic/inv.png"
                              w="2rem"
                              mt="-2px"
                            />
                            <Text fontSize="10px" mt="-2px" lineHeight="11px">
                              view
                            </Text>
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose} colorScheme="blue">
            Go Back
          </Button>
        </DrawerFooter>
        {preview && (
          <PreviewFile
            preview={preview}
            setPreview={setPreview}
            state={state}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
};
