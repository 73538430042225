import React, {useState} from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  IconButton,
} from "@chakra-ui/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";

export default function Pagination({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  isMobile,
}) {
  const [inputPage, setInputPage] = useState(currentPage);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePreviousPage = () => {
    const newPage = Math.max(currentPage - 1, 1);
    onPageChange(newPage);
    setInputPage(newPage);
  };

  const handleNextPage = () => {
    const newPage = Math.min(currentPage + 1, totalPages);
    onPageChange(newPage);
    setInputPage(newPage);
  };

  const handlePageInputChange = (e) => {
    setInputPage(e.target.value);
  };

  const handlePageInputSubmit = () => {
    const newPage = Math.max(1, Math.min(totalPages, parseInt(inputPage) || 1));
    onPageChange(newPage);
  };

  const startItem = totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(startItem + itemsPerPage - 1, totalItems);

  if (isMobile) {
    return (
      <Flex p="2" justifyContent="space-between" alignItems="center">
        <IconButton
          icon={<ChevronLeftIcon />}
          onClick={handlePreviousPage}
          isDisabled={currentPage === 1}
          size="sm"
          aria-label="Previous page"
        />

        <Text fontSize="sm" textAlign="center">
          {currentPage} / {totalPages === 0 ? 1 : totalPages}
        </Text>

        <IconButton
          icon={<ChevronRightIcon />}
          onClick={handleNextPage}
          isDisabled={currentPage === (totalPages === 0 ? 1 : totalPages)}
          size="sm"
          aria-label="Next page"
        />
      </Flex>
    );
  }

  return (
    <Flex p="4">
      <Center>
        <Text>
          Showing {startItem} to {endItem} of {totalItems} items
        </Text>
      </Center>
      <Spacer />
      <Button onClick={handlePreviousPage} disabled={currentPage === 1} mr={2}>
        Previous
      </Button>
      <Button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        mr={4}
      >
        Next
      </Button>
      <Spacer />
      <Center>
        <InputGroup size="sm">
          <InputLeftElement fontSize="12px">pg.</InputLeftElement>
          <Input
            w="5.5rem"
            ml="-1"
            type="number"
            value={inputPage}
            onChange={handlePageInputChange}
          />
          <Button ml="1" variant="ghost" onClick={handlePageInputSubmit}>
            Go
          </Button>
        </InputGroup>
      </Center>
    </Flex>
  );
}
