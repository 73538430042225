import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Button,
  useDisclosure,
  Text,
  HStack,
  Center,
  useToast,
  VStack,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import {AddPrepaymentModal} from "../../../Patients/Prepayments/AddPrepaymentModal";
import {crud, applyPrepayment} from "../../../../crudRequests";
import {useContext, useEffect, useState, useMemo} from "react";
import {prepaymentConfirm} from "../../payment/actions";
import {LuCreditCard} from "react-icons/lu";
import {FiPlusCircle} from "react-icons/fi";
import {TfiReload} from "react-icons/tfi";
export default function Prepayments({appointment, state, dispatch}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [prepayments, setPrepayments] = useState({
    open: [],
    consumed: [],
  });
  const [totalBalance, setTotalBalance] = useState(0);
  const [prepaymentLogs, setPrepaymentLogs] = useState([]);
  const [patient, setPatient] = useState(null);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchPrepayments = async () => {
      const res = await crud(state, [
        {
          db: state.db,
          collection: "prepayments",
          parameters: [{linkedPatientId: appointment.pid}],
          method: "find",
        },
        {
          db: state.db,
          collection: "patients",
          parameters: [{pid: appointment.pid}],
          method: "findOne",
        },
        {
          db: state.db,
          collection: "prepaymentLogs",
          parameters: [
            {appointmentId: appointment.aid, patientId: appointment.pid},
          ],
          method: "find",
        },
      ]);

      const prepayments = res?.data?.[0] || [];

      const openPrepayments = prepayments.filter(
        (prepayment) => prepayment.balanceAvailable > 0
      );
      const consumedPrepayments = prepayments.filter(
        (prepayment) => prepayment.balanceAvailable === 0
      );
      setPrepayments({open: openPrepayments, consumed: consumedPrepayments});
      setTotalBalance(
        openPrepayments.reduce(
          (acc, prepayment) => acc + parseFloat(prepayment.balanceAvailable),
          0
        )
      );

      setPrepaymentLogs(res?.data[2] || []);
      setPatient(res?.data[1] || null);
      setIsLoading(false);
    };
    if (appointment) fetchPrepayments();
    else {
      setTotalBalance(0);
      setPrepayments({open: [], consumed: []});
      setPrepaymentLogs([]);
      setPatient(null);
    }
  }, [appointment]);

  const refundAmount = useMemo(() => {
    return prepaymentLogs.reduce((acc, log) => acc + parseFloat(log.amount), 0);
  }, [prepaymentLogs]);
  let outstanding =
    parseFloat(appointment?.patientAmount ?? 0) -
    parseFloat(appointment?.amountPaidByPatient ?? 0);

  const apply = async (refund = false) => {
    let err = "";
    setIsLoading(true);
    let p = new Promise(async (resolve, reject) => {
      try {
        let res = await applyPrepayment({
          state,
          appointmentId: appointment?.aid,
          patientId: patient?.pid,
          refund,
        });
        res = res.data;

        if (res) {
          await prepaymentConfirm({
            appt: res.appointment,
            schState: state,
            partialAmount: res.receiptAmount,
            usedPrepayments: res.usedPrepayments,
          });
          dispatch({
            type: "UPDATE_APPOINTMENT",
            payload: res.appointment,
          });
        }

        resolve(null);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        err = error;
        reject(err);
      }
    });
    toast.promise(p, {
      success: {
        title: "",
        description: refund
          ? "The prepayment has been refunded!"
          : "The available prepayment has been applied!",
      },
      error: {title: "Prepayment", description: err},
      loading: {title: "Applying prepayment", description: "Please wait"},
    });
  };

  const refundPrepayment = async () => {
    await apply(true);
  };
  return (
    <Card className="w-full" my={5}>
      <CardBody pb={0}>
        <HStack gap={2}>
          <Heading color={"gray.600"} size="sm">
            Available Prepayment
          </Heading>
          <Center>
            <Text
              p={2}
              bg={"gray.100"}
              px={4}
              py={2}
              borderRadius={"lg"}
              fontSize={"xl"}
              fontWeight={"600"}
            >
              {`$${roundUpToTwoDecimals(totalBalance).toFixed(2)}`}
            </Text>
          </Center>
        </HStack>
      </CardBody>
      <CardFooter className="flex flex-col gap-3">
        <div className="grid w-full grid-cols-3 gap-2">
          <Button
            colorScheme="blue"
            onClick={onOpen}
            className="flex items-center gap-2"
          >
            <FiPlusCircle className="h-4 w-4" />
            Add
          </Button>
          <Button
            colorScheme="teal"
            onClick={() => apply()}
            variant="solid"
            className="flex items-center gap-2"
            isDisabled={isLoading || totalBalance <= 0 || outstanding <= 0}
          >
            <LuCreditCard className="h-4 w-4" />
            Apply
          </Button>
          <Button
            isDisabled={isLoading || refundAmount === 0}
            onClick={refundPrepayment}
            variant="outline"
            className="flex items-center gap-2"
          >
            <TfiReload className="h-4 w-4" />
            Refund
          </Button>
        </div>
      </CardFooter>
      {patient && (
        <AddPrepaymentModal
          isOpen={isOpen}
          onClose={onClose}
          patient={patient}
          dispatch={dispatch}
          state={state}
          prepayments={prepayments}
          setPrepayments={setPrepayments}
          setTotalBalance={setTotalBalance}
          defaultAmount={outstanding - totalBalance}
        />
      )}
    </Card>
  );
}

function roundUpToTwoDecimals(num) {
  num = Number(num);
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
