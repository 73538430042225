import {
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  lightFormat,
  getDay,
} from "date-fns";
import {crud} from "../../crudRequests";
import {mergeIntervals} from "../../additional_files/helpers";
import {setToCurrentWeek} from "../../additional_files/unavailable";
const shtortWeekday = ["Sun", "M", "T", "W", "Th", "F", "Sat"];
export function createIntervals({
  daysRange,
  timeInterval,
  selectedDays,
  note = "",
}) {
  try {
    let daysInInterval = [];
    if (daysRange && daysRange[0] && daysRange[1]) {
      daysInInterval = eachDayOfInterval({
        start: daysRange[0],
        end: daysRange[1],
      });
    } else if (selectedDays && selectedDays.size > 0) {
      let days = [...selectedDays];
      daysInInterval = days.map((day) => {
        let arr = day.split("/");
        return new Date(arr[2], arr[1] - 1, arr[0], 0, 0, 0);
      });
    }

    return daysInInterval.map((d) => {
      let start = new Date(d);
      let end = new Date(d);

      return [
        start.setHours(...timeInterval[0].split(":"), 0, 0),
        end.setHours(...timeInterval[1].split(":"), 0, 0),
        note,
      ];
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export function untilRemoveWeekIntervals({date, timeInterval, wDays, note}) {
  try {
    let startDate = startOfWeek(date, {weekStartsOn: 1});
    let endDate = endOfWeek(date, {weekStartsOn: 1});
    let daysInInterval = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    return daysInInterval
      .filter((e) => wDays.has(e.getDay()))
      .map((d) => {
        let start = new Date(d);
        let end = new Date(d);
        return [
          start.setHours(...timeInterval[0].split(":"), 0, 0),
          end.setHours(...timeInterval[1].split(":"), 0, 0),
          note,
        ];
      });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function updateClinicianUnavailableTimes({
  state,
  clinician,
  setClinician,
  intervals,
  dispatch,
  untilRemoved,
  date,
}) {
  try {
    let mergedIntervals, newUnavailable;
    let unavailable = clinician?.unavailable || {};
    let did = clinician?.did;

    const validIntervals = intervals.filter((interval) =>
      Array.isArray(interval)
    );

    if (untilRemoved) {
      let u = (unavailable.untilRemoved || [])
        .filter((interval) => Array.isArray(interval))
        .map((interval) => {
          const [start, end, note = ""] = interval;
          return [
            setToCurrentWeek(date, start),
            setToCurrentWeek(date, end),
            note,
          ];
        });

      mergedIntervals = mergeIntervals([...validIntervals, ...u]);
      newUnavailable = {...unavailable, untilRemoved: mergedIntervals};
    } else {
      const existingIntervals = (unavailable.intervals || []).filter(
        (interval) => Array.isArray(interval)
      );

      mergedIntervals = mergeIntervals([
        ...validIntervals,
        ...existingIntervals,
      ]);
      newUnavailable = {...unavailable, intervals: mergedIntervals};
    }

    await crud(state, [
      {
        db: state.db,
        collection: "doctors",
        parameters: [{did}, {$set: {unavailable: newUnavailable}}],
        method: "updateOne",
      },
    ]);

    setClinician((prev) => ({...prev, unavailable: newUnavailable}));
    dispatch({
      type: "ADD_UNAVAILABLE",
      payload: {
        did,
        unavailable: newUnavailable,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

export function groupIntervals(intervals) {
  let groups = [];
  let track = new Set();

  try {
    for (let i = 0; i < intervals.length; i++) {
      if (!Array.isArray(intervals[i])) continue;

      let current = intervals[i];
      let g = [current];
      let indexSet = new Set([i]);

      for (let j = i + 1; j < intervals.length; j++) {
        if (!Array.isArray(intervals[j])) continue;

        let last = intervals[j];
        if (track.has(j)) continue;

        const currentStart = current[0];
        const currentEnd = current[1];
        const lastStart = last[0];
        const lastEnd = last[1];

        if (lastStart - currentStart > 3600000 * 24) {
          break;
        }

        if (
          lastStart - currentStart === 3600000 * 24 &&
          currentEnd - currentStart === lastEnd - lastStart
        ) {
          indexSet.add(j);
          track.add(i);
          track.add(j);
          g.push(last);
          current = last;
        }
      }

      if (g.length > 1) {
        groups.push([g, indexSet]);
      }
    }

    return {groups, track};
  } catch (error) {
    console.error("Error in groupIntervals:", error);
    return {groups, track};
  }
}

export function groupUntilRemoved(intervals) {
  let timesMap = {};
  try {
    intervals.forEach((interval, i) => {
      if (!Array.isArray(interval)) {
        console.error("Invalid interval format (not an array):", interval);
        return;
      }

      const [start, end, note = ""] = interval;

      if (!start || !end) {
        console.error("Invalid interval values:", interval);
        return;
      }

      let f = `${lightFormat(start, "HH:mm")} - ${lightFormat(end, "HH:mm")}`;
      timesMap[f] ??= {weekdaysSet: new Set(), indexSet: new Set(), note: ""};
      timesMap[f].weekdaysSet.add(getDay(start));
      timesMap[f].indexSet.add(i);

      if (note && timesMap[f].note && note !== timesMap[f].note) {
        timesMap[f].note = `${timesMap[f].note}; ${note}`;
      } else if (note && !timesMap[f].note) {
        timesMap[f].note = note;
      }
    });

    return Object.entries(timesMap).map(
      ([format, {weekdaysSet, indexSet, note}]) => {
        return {
          format: `${format} (${[...weekdaysSet]
            .sort()
            .map((d) => shtortWeekday[d])
            .join(", ")})`,
          indexSet,
          note,
        };
      }
    );
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function removeIntervals({
  state,
  selected,
  type,
  clinician,
  intervals,
  radioValue,
  untilRemoved,
}) {
  let newIntervals = [];
  let newUnavailable = {};
  let unavailable = clinician?.unavailable || {};
  try {
    if (type === "individual") {
      if (radioValue === "weekdays") {
        let v = [...selected.values()].map((e) => [...e]).flat();
        let s = new Set(v);
        newIntervals = untilRemoved.filter((_, i) => !s.has(i));
      } else newIntervals = intervals.filter((_, i) => !selected.has(i));
    } else if (type === "range") {
      let v = [...selected.values()].map((e) => [...e]).flat();
      let s = new Set(v);
      newIntervals = intervals.filter((_, i) => !s.has(i));
    }

    newIntervals = newIntervals.filter((interval) => Array.isArray(interval));

    newUnavailable =
      type === "individual" && radioValue === "weekdays"
        ? {...unavailable, untilRemoved: newIntervals}
        : {...unavailable, intervals: newIntervals};

    await crud(state, [
      {
        db: state.db,
        collection: "doctors",
        parameters: [
          {did: clinician?.did},
          {$set: {unavailable: newUnavailable}},
        ],
        method: "updateOne",
      },
    ]);
  } catch (error) {
    console.error(error);
  } finally {
    return newUnavailable;
  }
}
