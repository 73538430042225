import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Stack,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Switch,
  IconButton,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import {FaArrowsSpin} from "react-icons/fa6";
import {useEffect, useState} from "react";
import fetchProducts from "../../Products/helpers/fetchProducts";
import {calculateMembershipEndDate, id} from "./PatientMembershipModal";
import {crud} from "../../../crudRequests";
import React from "react";
import {v4 as uuidv4} from "uuid";
import {
  fileNameFormat,
  membershipFileNameFormat,
  membershipInvoice,
} from "../../appointments/payment/uploads";
import {crudStorage} from "../../../storageRequests";

const MembershipRenewalTable = ({
  membership,
  schState,
  membershipConfig,
  onClose,
  patient,
  dispatch,
  setPatientRefetch,
  setPatient,
  transactions,
  fetchData,
  amountPaidByPatient,
}) => {
  const serviceMap = {};
  const [productMap, setProductMap] = useState({});
  const [switchStates, setSwitchStates] = useState({});
  const toast = useToast();
  for (const service of schState?.services) {
    if (Object.keys(membership.selectedServices).includes(service.serviceId)) {
      serviceMap[service.serviceId] = service;
    }
  }

  useEffect(() => {
    fetchProducts(
      schState,
      () => {},
      (products) => {
        const productMapping = {};
        products.forEach((product) => {
          if (
            membershipConfig?.selectedProducts &&
            membershipConfig.selectedProducts[product.id]
          ) {
            productMapping[product.id] = product;
          }
        });
        setProductMap(productMapping);
      },
      () => {}
    );
  }, [schState, membershipConfig?.selectedProducts]);

  const handleSwitchChange = (id, type) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const newExpirationDate = calculateMembershipEndDate(
    membership.endDate,
    membershipConfig.duration,
    membershipConfig.durationPeriod
  );
  const handleRenewal = async () => {
    try {
      const updatedServices = {...(membership?.selectedServices || {})};
      const updatedProducts = {...(membership?.selectedProducts || {})};

      for (const serviceId in membershipConfig.selectedServices) {
        const service = membership.selectedServices[serviceId] || {};
        const newQuantity = switchStates[serviceId]
          ? service.quantity || 0
          : (service.consumed || 0) + (service.reserved || 0);
        updatedServices[serviceId] = {
          ...service,
          quantity:
            newQuantity +
            (membershipConfig.selectedServices[serviceId].quantity || 0),
        };
      }

      for (const productId in membershipConfig.selectedProducts) {
        const product = membership.selectedProducts?.[productId] || {};
        const newQuantity = switchStates[productId]
          ? product.quantity || 0
          : (product.consumed || 0) + (product.reserved || 0);
        updatedProducts[productId] = {
          ...product,
          quantity:
            newQuantity +
            (membershipConfig.selectedProducts?.[productId].quantity || 0),
        };
      }

      const updatedMembershipData = {
        ...membership,
        endDate: newExpirationDate,
        selectedProducts: updatedProducts,
        selectedServices: updatedServices,
        acumulatedPrice: membership.acumulatedPrice
          ? parseFloat(membership.acumulatedPrice) +
            parseFloat(membershipConfig.price)
          : parseFloat(membershipConfig.price) +
            parseFloat(membershipConfig.price),
        status: "Pending",
      };
      const newPatientMembershipData = patient.memberships.map(
        (patientMembership) => {
          if (patientMembership.uuid === membership.uuid) {
            return updatedMembershipData;
          }
          return patientMembership;
        }
      );
      const pendingTransaction = transactions.find(
        (transaction) =>
          transaction.paymentStatus === "pending" &&
          transaction.cancelled === false
      );

      if (pendingTransaction) {
        const {_id, ...transactionWithoutId} = pendingTransaction;
        const startDatePayment = pendingTransaction.memberships[0]
          .startDatePayment
          ? pendingTransaction.memberships[0].startDatePayment
          : membership.startDate;
        const amount = (
          membership.acumulatedPrice
            ? parseFloat(membership.acumulatedPrice) +
              parseFloat(membershipConfig.price) -
              amountPaidByPatient
            : parseFloat(membershipConfig.price)
        ).toFixed(2);
        const newTransaction = {
          ...transactionWithoutId,
          tid: uuidv4(),
          memberships: [{...updatedMembershipData, startDatePayment}],
          membershipsAmount: amount,
          patientAmount: amount,
          amount: amount,
        };

        await crud(schState, [
          {
            db: schState.db,
            collection: "billing",
            parameters: [
              {tid: pendingTransaction.tid},
              {
                $set: {
                  cancelled: true,
                },
              },
            ],
            method: "updateOne",
          },
        ]);

        await crud(schState, [
          {
            db: schState.db,
            collection: "billing",
            parameters: [newTransaction],
            method: "insertOne",
          },
        ]);

        const path = pendingTransaction?.pid + `/Invoices/Memberships/`;

        const fileToDelete = await crudStorage(
          schState,
          "view",
          "",
          "brightlighthealth",
          path
        );

        const invoiceToDelete = fileToDelete.data.find((file) =>
          file.name.includes(membershipFileNameFormat(pendingTransaction))
        );

        await crudStorage(
          schState,
          "deleteFile",
          path + invoiceToDelete.name,
          "brightlighthealth",
          ""
        );
        await membershipInvoice({
          state: schState,
          transaction: {...pendingTransaction, cancelled: true},
          patient: patient,
        });
        await membershipInvoice({
          state: schState,
          transaction: newTransaction,
          patient: patient,
        });
      } else {
        const invoiceId = id(8);
        const receiptId = id(8);
        const amount = parseFloat(membershipConfig.price).toFixed(2);
        let transaction = {
          type: "membership",
          tid: uuidv4(),
          membershipUuid: membership.uuid,
          invoiceId: invoiceId,
          receiptId: receiptId,
          lid: schState.selectedLocation,
          locationName: schState.selectedLocation.name,
          pid: patient.pid,
          pName: patient.fName + " " + patient.lName,
          pEmail: patient.email,
          claim: false,
          amount,
          memberships: [
            {...updatedMembershipData, startDatePayment: membership.endDate},
          ],
          membershipsAmount: amount,
          serviceAmount: 0,
          serviceTax: 0,
          products: [],
          productTax: 0,
          productAmount: 0,
          paid: 0,
          pending: true,
          cancelled: false,
          insurance: patient.insurance,
          dgCodes: [],
          billingCodes: [],
          tppAmount: 0,
          patientAmount: amount,
          amountPaidByPatient: 0,
          amountPaidByTpp: 0,
          tppPaymentStatus: "paid",
          patientPaymentStatus: amount > 0 ? "pending" : "paid",
          paymentStatus: "pending",
          serviceDate: new Date().getTime(),
        };

        await crud(schState, [
          {
            db: schState.db,
            collection: "billing",
            parameters: [transaction],
            method: "insertOne",
          },
        ]);

        await membershipInvoice({
          state: schState,
          transaction,
          patient: patient,
        });
      }
      await crud(schState, [
        {
          db: schState.db,
          collection: "patients",
          parameters: [
            {pid: patient.pid},
            {
              $set: {
                memberships: newPatientMembershipData,
              },
            },
          ],
          method: "updateOne",
        },
      ]);
      fetchData();
      setPatient({
        ...patient,
        memberships: newPatientMembershipData,
      });
      dispatch({
        type: "SET_PATIENT",
        payload: {...patient, memberships: newPatientMembershipData},
      });
      dispatch({
        type: "UPDATE_PATIENT",
        pid: patient.pid,
        payload: {
          memberships: newPatientMembershipData,
        },
        options: null,
      });
      setPatientRefetch(true);
      onClose();
    } catch (error) {
      console.error("Error during membership renewal: ", error);
      toast({
        title: "An error occurred during membership renewal.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <ModalBody>
        <Stack>
          <Text>
            The membership is currently valid from{" "}
            {new Date(membership.startDate).toLocaleDateString("en-CA")} to{" "}
            {new Date(membership.endDate).toLocaleDateString("en-CA")}.
          </Text>
          <Text>
            After renewal, the membership will be valid until{" "}
            {new Date(newExpirationDate).toLocaleDateString("en-CA")}
          </Text>
          <Table height="100%">
            <TableCaption>Membership renewal details</TableCaption>
            <Thead bg="gray.100">
              <Tr>
                <Th>Service/Product</Th>
                <Th>Description</Th>
                <Th>Category</Th>
                <Th>Included</Th>
                <Th>Reserved</Th>
                <Th>Consumed</Th>
                <Th>Balance</Th>
                <Th>To be carried over</Th>
                <Th>New balance</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(membershipConfig.selectedServices).map((service) => {
                const serviceData = membership.selectedServices[service] || {};
                const serviceInfo =
                  serviceMap[service] ||
                  membershipConfig.selectedServices[service];
                const carryOverQuantity =
                  (serviceData.quantity || 0) -
                  (serviceData.consumed || 0) -
                  (serviceData.reserved || 0);
                const isSwitchOn = switchStates[service] || false;
                const includedQuantity =
                  serviceData.quantity ||
                  membershipConfig.selectedServices[service].quantity;
                return (
                  <Tr key={service}>
                    <Td fontSize={"15px"}>
                      <Text>
                        {serviceInfo.serviceName || "Unnamed Service"}
                      </Text>
                    </Td>
                    <Td fontSize={"15px"}>
                      <Text>{serviceInfo.description || "No description"}</Text>
                    </Td>
                    <Td fontSize={"15px"}>
                      <Text>{serviceInfo.category || "Uncategorized"}</Text>
                    </Td>
                    <Td fontSize={"15px"}>
                      <Text>{includedQuantity}</Text>
                    </Td>
                    <Td fontSize={"15px"}>
                      <Text>{serviceData.reserved || 0}</Text>
                    </Td>
                    <Td fontSize={"15px"}>
                      <Text>{serviceData.consumed || 0}</Text>
                    </Td>
                    <Td fontSize={"15px"}>
                      {includedQuantity -
                        (serviceData.consumed || 0) -
                        (serviceData.reserved || 0)}
                    </Td>
                    <Td fontSize={"15px"}>
                      <Switch
                        size="sm"
                        isChecked={isSwitchOn}
                        onChange={() => handleSwitchChange(service, "service")}
                      />
                    </Td>
                    <Td fontSize={"15px"}>
                      <Text>
                        {(isSwitchOn ? carryOverQuantity : 0) +
                          membershipConfig.selectedServices[service].quantity}
                      </Text>
                    </Td>
                  </Tr>
                );
              })}
              {membershipConfig.selectedProducts &&
                Object.keys(membershipConfig.selectedProducts).map(
                  (productId) => {
                    const productData =
                      membership.selectedProducts[productId] || {};
                    const productInfo =
                      productMap[productId] ||
                      membershipConfig.selectedProducts[productId];
                    const carryOverQuantity =
                      (productData.quantity || 0) -
                      (productData.consumed || 0) -
                      (productData.reserved || 0);
                    const isSwitchOn = switchStates[productId] || false;
                    const includedQuantity =
                      productData.quantity ||
                      membershipConfig.selectedProducts[productId].quantity;
                    return (
                      <Tr key={productId}>
                        <Td fontSize={"15px"}>
                          <Text>{productInfo.name || "Unnamed Product"}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>
                            {productInfo.description || "No description"}
                          </Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{productInfo.category || "Uncategorized"}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{includedQuantity}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{productData.reserved || 0}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>{productData.consumed || 0}</Text>
                        </Td>
                        <Td fontSize={"15px"}>
                          {includedQuantity -
                            (productData.consumed || 0) -
                            (productData.reserved || 0)}
                        </Td>
                        <Td fontSize={"15px"}>
                          <Switch
                            size="sm"
                            isChecked={isSwitchOn}
                            onChange={() =>
                              handleSwitchChange(productId, "product")
                            }
                          />
                        </Td>
                        <Td fontSize={"15px"}>
                          <Text>
                            {(isSwitchOn ? carryOverQuantity : 0) +
                              membershipConfig.selectedProducts[productId]
                                .quantity}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  }
                )}
            </Tbody>
          </Table>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="solid"
          colorScheme="blue"
          onClick={async () => {
            await handleRenewal();
            toast({
              title: "Membership renewal successful",
              description: "The membership has been renewed successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            onClose();
          }}
        >
          Confirm renewal
        </Button>
      </ModalFooter>
    </>
  );
};

const PatientMembershipRenewalModal = ({
  membership,
  schState,
  patient,
  dispatch,
  setPatientRefetch,
  setPatient,
  transactions,
  fetchData,
  amountPaidByPatient,
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [membershipConfig, setMembershipConfig] = useState(null);

  useEffect(() => {
    const getMembershipConfig = async () => {
      const locationResult = await crud(schState, [
        {
          db: schState.db,
          collection: "locations",
          parameters: [{lid: schState.selectedLocation}],
          method: "findOne",
        },
      ]);

      const selectedLocation = locationResult.data[0];

      if (!selectedLocation) {
        console.error("Location not found");
        return;
      }
      let membershipConfig = selectedLocation.memberships.find(
        (membershipConfig) => membershipConfig.mid === membership.mid
      );
      if (membershipConfig) {
        let {selectedServices, selectedProducts} = membershipConfig;
        selectedServices = Object.fromEntries(
          Object.entries(selectedServices || {}).filter(
            ([_, v]) => v.quantity > 0
          )
        );
        selectedProducts = Object.fromEntries(
          Object.entries(selectedProducts || {}).filter(
            ([_, v]) => v.quantity > 0
          )
        );
        membershipConfig = {
          ...membershipConfig,
          selectedServices,
          selectedProducts,
        };
      }

      setMembershipConfig(membershipConfig);
    };
    getMembershipConfig();
  }, [schState.selectedLocation]);

  const currentDate = Date.now();
  const isCurrentMembership =
    currentDate >= membership.startDate && currentDate <= membership.endDate;
  const outstandingBalance =
    (membership?.acumulatedPrice
      ? membership.acumulatedPrice
      : membership.price) - (amountPaidByPatient || 0);
  const isDisabled =
    outstandingBalance > 0 &&
    (membership?.subscriptionId || membership?.subscriptionStatus === "active");
  const isFutureMembership = currentDate < membership.startDate;
  const displayCta = isFutureMembership || isCurrentMembership;
  if (membership.status === "Cancelled" || !displayCta) {
    return null;
  }
  return (
    <>
      <IconButton
        variant="outline"
        aria-label="open menu"
        icon={<FaArrowsSpin />}
        bg="yellow.400"
        opacity="75%"
        boxShadow="lg"
        onClick={onOpen}
        size="sm"
        isDisabled={!isCurrentMembership || isDisabled}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="7xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.400">
            Patient summary for {membership.name}
          </ModalHeader>
          {membershipConfig && (
            <MembershipRenewalTable
              transactions={transactions}
              dispatch={dispatch}
              membership={membership}
              schState={schState}
              membershipConfig={membershipConfig}
              onClose={onClose}
              patient={patient}
              setPatientRefetch={setPatientRefetch}
              setPatient={setPatient}
              fetchData={fetchData}
              amountPaidByPatient={amountPaidByPatient}
            />
          )}
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PatientMembershipRenewalModal;
