import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useMemo,
  useLayoutEffect,
} from "react";
import InvRecpDF from "../../Invoice";
import {pdf} from "@react-pdf/renderer";
import {nanoid} from "nanoid";
import {lightFormat} from "date-fns";
import Swal from "sweetalert2";
import {
  fullInvoice,
  fullReceipt,
  patientInvoice,
  patientReceipt,
  insurerInvoice,
  insurerReceipt,
} from "./uploads";
import {
  generatePaymentLink,
  createCharge,
  crud,
  sendCardReqLink,
  sendInvRecEmail,
  getPaymentMethods,
  executePayment,
} from "../../../crudRequests";
import createPaymentRecord from "./helpers/createPaymentRecord";
import {isoToApptDate} from "../../../additional_files/helpers";
import {loadStripe} from "@stripe/stripe-js";
import jsPDFInvoice from "../../../additional_files/jsPDFInvoice";
import {BiChevronDown, BiLink} from "react-icons/bi";
import {crudStorageUpload} from "../../Patients/Files/helpers/crudStorageUpload";
import deleteFiles from "./helpers/deleteFiles";
import {Button, useToast} from "@chakra-ui/react";
import AddCC from "../../Patients/Information/CC/AddCC";
import {Box, Flex, Heading, Stack, Skeleton} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
let durations = [
  "15 min",
  "30 min",
  "45 min",
  "60 min",
  "75 min",
  "90 min",
  "105 min",
  "120 min",
];
let durationLabels = [
  "15 min",
  "30 min",
  "45 min",
  "1 hr",
  "1.25 h",
  "1.5 hr",
  "1.75 hr",
  "2 hr",
];

export default function CreateCharge({
  state,
  patient,
  stateChanger,
  appt,
  dispatch,
  socket,
}) {
  //console.log(state);
  const [sendingLink, setSendingLink] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loadingCards, setLoadingCards] = useState(false);
  const toast = useToast();
  useEffect(function () {
    if (state.db && state.organization?.stpPublicKey) {
      setLoadingCards(true);
      getPaymentMethods(state, patient.pid)
        .then((paymentMethods) => {
          setCards(paymentMethods.data);
          setLoadingCards(false);
        })
        .catch((error) => {
          setLoadingCards(false);
          console.log(error);
        });
    }
  }, []);
  useEffect(() => {
    if (cards.length > 0) {
      setSelectedCard(cards.find((c) => c?.default));
    }
  }, [cards]);

  return (
    <div className="relative   rounded-[10px] py-4  flex flex-col  items-center gap-2">
      <CardsList {...{cards, selectedCard, setSelectedCard, loadingCards}} />
      <Accordion allowToggle w="100%" mx="10" px="10" mt={8}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Heading
                fontWeight={500}
                flex="1"
                color="blue.500"
                textAlign="left"
                size="sm"
              >
                Add a new credit card
              </Heading>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} w="100%">
            <Flex w="100%" justifyContent="center" alignItems="center">
              <Box w="100%">
                <AddCC
                  setSuccessMessage={(message) => {
                    if (message) {
                      toast({
                        title: message,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                    }
                  }}
                  setErrorMessage={(message) => {
                    if (message) {
                      toast({
                        title: message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    }
                  }}
                  setCards={setCards}
                  dashState={state}
                  patient={patient}
                />
              </Box>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <div
        className="hover:text-blue-500 mt-8 cursor-pointer self-end text-gray-700 mr-9 flex justify-end items-center"
        onClick={() => {
          if (sendingLink) return;
          setSendingLink(true);

          sendCardReqLink(state, patient)
            .then(() => {
              setSendingLink(false);
              toast({
                title: "The card request link has been sent!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            })
            .catch((error) => {
              setSendingLink(false);

              toast({
                title: error.response?.data?.toString(),
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            });
        }}
      >
        <span>
          <BiLink />
        </span>
        <span className="text-xs">Send Card Request</span>
        {sendingLink && (
          <span className="">
            <svg
              className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25 stroke-[4px]"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        )}
      </div>
      <SendForm
        state={state}
        patient={patient}
        appt={appt}
        stateChanger={stateChanger}
        dispatch={dispatch}
        socket={socket}
        setErrorMessage={(message) => {
          if (message) {
            toast({
              title: message,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }}
        setSuccess={(message) => {
          if (message) {
            toast({
              title: message,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
        }}
        selectedCard={selectedCard}
      />
    </div>
  );
}

function SendForm({
  state,
  patient,
  appt,
  stateChanger,
  dispatch,
  socket,
  setErrorMessage,
  selectedCard,
  setSuccess,
}) {
  const [sending, setSending] = useState(false);

  async function generateCharge() {
    try {
      //console.log(state.organization)

      const {value: description, isConfirmed} = await Swal.fire({
        input: "textarea",
        title: "Payment Description",
        inputPlaceholder: "Type the description...",
        confirmButtonText: "Pay",
        confirmButtonColor: "#3182ce",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      });
      const paymentDescription = description || "";
      if (!isConfirmed) {
        return;
      }
      const stripe = await loadStripe(
        state.organization?.stpPublicKey || "Invalid key"
      );
      // const stripe = await loadStripe('pk_live_51JQK47EPLWeDUJKNV11VBcgI1FOqTT6vCvSiOuOSUJICouYfMxDh5H9KGLKD2M5IEdcSs3bBGesgPNoVru2Emnjt00jQxeXkj4');
      if (patient && stripe) {
        setSending(true);
        setSuccess(false);
        setErrorMessage(false);

        let sendInvEmail = async (schState, transaction, amountPaid) => {
          try {
            let doctor = null;
            if (schState.userType === "admin") {
              let index = schState.doctorsPayload[transaction["did"]].index;
              doctor = schState.doctors[index];
            } else {
              doctor = schState.selectedDoctor;
            }

            const response = await crud(schState, [
              {
                db: schState.db,
                collection: "insurers",
                parameters: [{iid: transaction?.tpp || ""}],
                method: "findOne",
              },
              {
                db: schState.db,
                collection: "doctors",
                parameters: [{did: transaction.supervisorId || ""}],
                method: "findOne",
              },
            ]);
            let res = response.data;

            let invData = {
              state: schState,
              transaction,
              doctor,
              patient,
              tpp: res[0],
              supervisor: res[1],
              receiptAmount:
                amountPaid ||
                (appt.patientAmount ?? appt.amount) -
                  (appt.amountPaidByPatient ?? 0),
              nonSessionService: appt.nonSessionService,
              paymentMethod: "credit card",
            };

            const {path: receiptPath, blob} = await patientReceipt(invData);
            await fullReceipt(invData);

            let dataURI = await new Promise((resolve, reject) => {
              try {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                  resolve(reader.result);
                };
              } catch (error) {
                reject(error);
              }
            });
            let {date, time} = isoToApptDate(transaction.serviceDate);
            let location = await crud(schState, [
              {
                db: schState.db,
                collection: "locations",
                parameters: [{lid: schState.selectedLocation}],
                method: "findOne",
              },
            ]);
            location = location.data[0];
            let sender = location?.name;
            sendInvRecEmail(schState, {
              invoice: false,
              date,
              time,
              pEmail: transaction.pEmail,
              pName: transaction.pName,
              dName: transaction.dName,
              sender: sender || schState?.organization?.name,
              attachments: [
                {
                  filename: "Receipt.pdf",
                  path: dataURI,
                },
              ],
            });
          } catch (error) {
            console.log(error);
          }
        };

        const handleServerResponse = async (response) => {
          try {
            if (response.error) {
              setSending(false);
              setErrorMessage(response.error);
            } else if (response.requires_action) {
              const {error: errorAction, paymentIntent} =
                await stripe.handleCardAction(
                  response.payment_intent_client_secret
                );
              /// console.log(paymentIntent)
              if (errorAction) {
                setSending(false);
                //  console.log(errorAction)
                setErrorMessage(errorAction.message);
              } else {
                const paymentResponset = await executePayment(
                  state,
                  appt,
                  patient.pid,
                  null,
                  paymentIntent.id
                );

                handleServerResponse(paymentResponset.data);
              }
            } else {
              if (response?.billing && response?.success) {
                await sendInvEmail(
                  state,
                  response?.billing,
                  response?.amountPaid
                );
                await createPaymentRecord({
                  state,
                  transaction: response?.billing,
                  paymentDescription,
                  date: Date.now(),
                  amount: response?.amountPaid,
                  payer: "patient",
                  paymentMethod: "credit card",
                });
                if (response?.appointment)
                  dispatch({
                    type: "UPDATE_APPOINTMENT",
                    payload: {...response?.appointment},
                  });
              }

              //socket?.emit?.("update_appt", updateBody, {});
              // stateChanger(true);

              setSuccess("Successful payment!");

              stateChanger(false);
              setSending(false);
            }
          } catch (error) {
            setErrorMessage(error.response?.data || error.message);
          }
        };

        const paymentResponset = await executePayment(
          state,
          appt,
          patient.pid,
          selectedCard
        );
        handleServerResponse(paymentResponset.data);
      }
    } catch (error) {
      setErrorMessage(error.response?.data || error.message);

      console.log(error);
      setSending(false);
    }
  }

  return (
    <form
      className="w-full flex justify-center items-center px-8"
      onSubmit={async (e) => {
        e.preventDefault();
      }}
    >
      <div className="  flex flex-col w-full justify-between items-center ">
        <div className="space-y-4 w-full">
          <button
            className={`${
              sending || !selectedCard ? "cursor-not-allowed" : ""
            } text-white relative p-4 w-full py-2 rounded-md text-[18px] bg-[#3182ce] font-medium  focus:outline-none border-none flex justify-center items-center`}
            onClick={generateCharge}
            disabled={sending || !selectedCard}
          >
            <span>{`Generate charge ($${(
              (appt.patientAmount ?? appt.amount) -
              (appt.amountPaidByPatient ?? 0)
            ).toFixed(2)})`}</span>

            {sending && (
              <span className="ml-1">
                <svg
                  className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-[4px]"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}

function CardsList({cards, selectedCard, setSelectedCard, loadingCards}) {
  return (
    <div className="space-y-2 mt-4 w-full px-8">
      <div className="rounded-md py-2 bg-[#4A5568] text-background font-semibold text-center mr-2">
        <ul className="flex justify-center items-center  lg:text-base">
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Card
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Exp Date
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Selected Card
          </li>
          {/*<li className="flex justify-center items-center flex-1 text-[16px]">Action</li>*/}
        </ul>
      </div>
      <div className="overflow-hidden flex justify-center max-h-44">
        <div className="overflow-y-scroll  w-full space-y-2 pr-2 pb-2 sbar2">
          {loadingCards ? (
            <Loader />
          ) : cards.length > 0 ? (
            cards.map((c, i) => {
              return (
                <div
                  key={i}
                  className=" rounded-md mt-0 border-2   py-2  text-sm text-opacity-75 font-semibold text-center h-fit"
                  style={{
                    borderColor: c === selectedCard ? "#3182ce" : "#4A5568",

                    color: c === selectedCard ? "#3182ce" : "#4A5568",
                  }}
                >
                  <ul className="flex justify-center items-center md:text-sm w-full">
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {"****" + c.card.last4}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {`${c.card.exp_month}/${c.card.exp_year}`}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      <div
                        className="bg-[#3182ce] rounded-full p-2 w-20 cursor-pointer transition duration-500"
                        onClick={() => {
                          setSelectedCard(c);
                        }}
                        style={{
                          backgroundColor:
                            c === selectedCard ? "#3182ce" : "#FFFFFF",
                          boxShadow:
                            c !== selectedCard && "0 0 0 2px inset #4A5568",
                        }}
                      >
                        <p
                          className="w-3 h-3 rounded-full bg-white transition duration-500"
                          style={{
                            transform:
                              c === selectedCard
                                ? "translateX(55px)"
                                : "translateX(0)",
                            backgroundColor:
                              c === selectedCard ? "#FFFFFF" : "#4A5568",
                          }}
                        ></p>
                      </div>
                    </li>
                  </ul>
                </div>
              );
            })
          ) : (
            <div
              className=" rounded-md mt-0 py-2 bg-[#DFDEFF] text-sm text-dark text-opacity-75 font-semibold text-center h-fit"
              style={{
                backgroundColor: "#DFDEFF",
              }}
            >
              <span>No registered card</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
function Loader() {
  return (
    <Stack>
      {[...Array(3)].map((_, i) => (
        <Skeleton key={i} height="2rem" rounded="md" />
      ))}
    </Stack>
  );
}
