import {
  Box,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Divider,
  Flex,
  Badge,
} from "@chakra-ui/react";

export const PatientBalance = ({
  balanceData,
  totalBalance,
  handleBrowseToTab,
  onOpen,
}) => {
  return (
    <Box
      flex={3}
      p={5}
      borderRadius="lg"
      boxShadow="md"
      bg="white"
      borderWidth="1px"
      borderColor="gray.200"
      mb={4}
    >
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Remaining Balance
      </Text>

      <StatGroup>
        <Stat>
          <Box
            onClick={() => handleBrowseToTab(6)}
            cursor="pointer"
            _hover={{color: "blue.500"}}
            transition="color 0.2s"
            display="inline-block"
          >
            <StatLabel>Pre-payments</StatLabel>
            <StatNumber fontSize="md">
              ${balanceData?.prePaymentsTotal?.toFixed(2)}
            </StatNumber>
          </Box>
        </Stat>

        <Stat>
          <Box
            onClick={() => handleBrowseToTab(16, 0)}
            cursor="pointer"
            _hover={{color: "blue.500"}}
            transition="color 0.2s"
            display="inline-block"
          >
            <StatLabel>Memberships</StatLabel>
            <StatNumber fontSize="md">
              ${balanceData?.membershipsTotal?.toFixed(2)}
            </StatNumber>
          </Box>
        </Stat>

        <Stat>
          <Box
            onClick={() => handleBrowseToTab(16, 1)}
            cursor="pointer"
            _hover={{color: "blue.500"}}
            transition="color 0.2s"
            display="inline-block"
          >
            <StatLabel>Packages</StatLabel>
            <StatNumber fontSize="md">
              ${balanceData?.packagesTotal?.toFixed(2)}
            </StatNumber>
          </Box>
        </Stat>

        <Stat>
          <Box
            onClick={onOpen}
            cursor="pointer"
            _hover={{color: "blue.500"}}
            transition="color 0.2s"
            display="inline-block"
          >
            <StatLabel>Gift Cards</StatLabel>
            <StatNumber fontSize="md">
              ${balanceData?.giftCardsTotal?.toFixed(2)}
            </StatNumber>
          </Box>
        </Stat>
      </StatGroup>

      <Divider my={4} />

      <Flex justify="space-between" align="center">
        <Text fontWeight="bold">Total Credit Balance</Text>
        <Badge colorScheme="blue" fontSize="lg" p={2} borderRadius="md">
          ${totalBalance}
        </Badge>
      </Flex>
    </Box>
  );
};
