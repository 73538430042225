import {crudStorage} from "./crudStorage";
import {checkStatusProgress, crud} from "../../../../crudRequests";
import {Box, Text, Progress} from "@chakra-ui/react";

export async function downloadFolder(dashState, path, folderName, toast) {
  const downloadingToast = toast({
    title: "Preparing Download",
    description: (
      <Box>
        <Text fontSize="sm">Initializing...</Text>
        <Progress isIndeterminate size="sm" colorScheme="blue" />
      </Box>
    ),
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: false,
  });

  let progressInterval;

  const updateDownloadProgress = (progress, status) => {
    toast.update(downloadingToast, {
      title: "Preparing Download",
      description: (
        <Box>
          <Text fontSize="sm">
            {status} ({progress}%)
          </Text>
          <Progress value={progress} size="sm" colorScheme="blue" />
        </Box>
      ),
      status: "loading",
      duration: null,
      isClosable: false,
    });
  };

  const getPatientName = async (dashState, directoryPath) => {
    try {
      const pid = directoryPath.split("/")[0];
      const patientRes = await crud(dashState, [
        {
          db: dashState.db,
          collection: "patients",
          parameters: [{pid: pid}],
          method: "findOne",
        },
      ]);
      const patient = patientRes.data[0];
      if (!patient.pid) {
        return null;
      }

      return `${patient.lName || ""}, ${patient.fName || ""}`.trim();
    } catch (error) {
      console.error("Error getting patient name:", error);
      return null;
    }
  };

  const handleDownloadComplete = async (downloadUrl) => {
    if (progressInterval) clearInterval(progressInterval);
    toast.close(downloadingToast);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = folderName + ".zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    await crud(dashState, [
      {
        db: dashState.db || "blh",
        collection: "auditLogs",
        parameters: [
          {
            collection: "files",
            action: "DOWNLOAD-FOLDER",
            triggeredBy: dashState.admin.id,
            payload: {
              folderName: folderName,
              path: path,
              timestamp: new Date().toISOString(),
            },
          },
        ],
        method: "insertOne",
      },
    ]);

    toast({
      title: "Download Started",
      description: "Your folder download should begin shortly.",
      status: "success",
      isClosable: true,
    });
  };

  const checkProgress = async (operationId) => {
    try {
      const response = await checkStatusProgress(dashState, operationId);

      if (
        response?.data?.status === "complete" &&
        response?.data?.downloadUrl
      ) {
        await handleDownloadComplete(response.data.downloadUrl);
        return true;
      } else if (response?.data?.status === "processing") {
        updateDownloadProgress(
          response.data.percentComplete || 0,
          response.data.progress || "Processing"
        );
        return false;
      }
    } catch (error) {
      console.error("Error checking progress:", error);
      if (progressInterval) clearInterval(progressInterval);
      toast.close(downloadingToast);
      handleError(error, toast);
      return true;
    }
  };

  try {
    const patientName = await getPatientName(dashState, path);
    const response = await crudStorage(
      dashState,
      "downloadDirectory",
      path + folderName,
      "brightlighthealth",
      "",
      "",
      patientName
    );

    if (
      response?.data?.status === "processing" &&
      response?.data?.operationId
    ) {
      progressInterval = setInterval(
        () => checkProgress(response.data.operationId),
        2000
      );
    } else {
      throw new Error("Invalid response from server");
    }
  } catch (err) {
    console.error("Download error:", err);
    if (progressInterval) clearInterval(progressInterval);
    toast.close(downloadingToast);
    handleError(err, toast);
  }
}

const handleError = (err, toast) => {
  const errorResponse = err.response?.data?.message || err.message;
  const errorName =
    typeof errorResponse === "object" ? errorResponse.name : null;

  if (errorName === "FileSizeLimitError") {
    const {zipSize, maxAllowed} = errorResponse.details;
    toast({
      title: "Folder too large to download",
      description: `The folder size (${zipSize}MB) exceeds the maximum allowed size (${maxAllowed}MB). Please try downloading individual folders instead.`,
      status: "error",
      isClosable: true,
      duration: 10000,
    });
  } else if (errorName === "TimeoutError" || errorName === "S3DownloadError") {
    toast({
      title: "Download failed",
      description: "The download timed out. Please try again.",
      status: "error",
      isClosable: true,
      duration: 6000,
    });
  } else {
    toast({
      title: "Failed to download folder",
      description:
        typeof errorResponse === "object"
          ? errorResponse.message ||
            "An error occurred while downloading folder."
          : errorResponse || "An error occurred while downloading folder.",
      status: "error",
      isClosable: true,
    });
  }
};
