import {crud} from "../../../crudRequests";

import Swal from "sweetalert2";

export default async function deleteDiscount({
  dashState,
  selectedDiscount,
  toast,
  action,
}) {
  Swal.fire({
    title: "Delete Discount?",
    text: "Are you sure you want to delete this Discount?",
    icon: "warning",
    showDenyButton: true,
    confirmButtonText: "Delete",
    denyButtonText: `Back`,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const res = await crud(dashState, [
          {
            db: dashState.db,
            collection: "discounts",
            parameters: [{id: selectedDiscount.id}],
            method: "deleteOne",
          },
        ]);
        action?.();
      } catch (error) {
        toast({
          title: "Failed to delete this discount",
          description: error?.message,
          status: "error",
          isClosable: true,
        });
      }
    }
  });
}
