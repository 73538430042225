import {useState, useContext} from "react";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Divider,
  Text,
  Flex,
  Box,
  Badge,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
} from "@chakra-ui/react";

import {crud} from "../../../crudRequests";
import PreviewFile from "../../appointments/payment/helpers/PreviewFile";
import CreateMembershipChargeModal from "./CreateMembershipChargeModal";
import {PatientMembershipPaymentModal} from "./PatientMembershipPayment";
import {PatientMembershipBalancesTable} from "./PatientMembershipBalancesTable";
import {PatientContext} from "../../../pages/Patients";
import {InvoiceOrReceiptMembership} from "./InvoiceOrReceiptMembership";

const MembershipPaymentInformation = (props) => {
  const [preview, setPreview] = useState(null);
  const [patient, setPatient] = useContext(PatientContext);
  const [selectedTr, setSelectedTr] = useState(null);
  const [chargeModal, setChargeModal] = useState(false);

  const outstandingBalance =
    (props.membership?.acumulatedPrice
      ? props.membership.acumulatedPrice
      : props.membership.price) - (props.totalAmountPaidByPatient || 0);

  const isDisabled =
    outstandingBalance === 0 ||
    props.membership?.subscriptionId ||
    props.membership?.subscriptionStatus === "active";

  const pendingTransaction = props.transactions
    ? props.transactions?.find(
        (transaction) =>
          transaction.paymentStatus === "pending" &&
          transaction.cancelled === false
      )
    : null;

  return (
    <Flex>
      {preview && (
        <PreviewFile
          preview={preview}
          setPreview={setPreview}
          appointment={selectedTr}
          state={props.dashState}
        />
      )}
      {chargeModal && (
        <CreateMembershipChargeModal
          state={props.dashState}
          appointment={pendingTransaction ? pendingTransaction : null}
          stateChanger={setChargeModal}
          dispatch={props.dispatch}
          socket={{}}
          fetchData={props.fetchData}
          transaction={pendingTransaction ? pendingTransaction : null}
          setPatientRefetch={props.setPatientRefetch}
        />
      )}
      <VStack padding="1rem" borderRadius="md" width="50%" mr={2}>
        <Box direction="row" marginBottom="1rem" width="100%">
          <Flex justify="space-between" align="center">
            <Button
              onClick={() => {
                setChargeModal(true);
              }}
              fontWeight="light"
              bgColor="blue.400"
              textColor="white"
              isDisabled={isDisabled}
            >
              Bill via CC
            </Button>
            <PatientMembershipPaymentModal
              state={props.dashState}
              transaction={pendingTransaction ? pendingTransaction : null}
              dispatch={props.dispatch}
              refetch={props.refetch}
              onClose={props.onClose}
              patient={patient}
              setPatient={setPatient}
              fetchData={props.fetchData}
              isDisabled={isDisabled}
            />
          </Flex>
        </Box>
        <VStack
          borderRadius="2xl"
          padding="1rem"
          bg="blue.400"
          textAlign="center"
          width="100%"
        >
          <Text fontSize="xs" textColor="white">
            outstanding balance:
            <span className="font-bold">{`$ ${outstandingBalance.toFixed(
              2
            )}`}</span>
          </Text>
          <Text fontSize="xs" textColor="white">
            amount paid:{" "}
            <span className="font-bold">
              ${parseFloat(props.totalAmountPaidByPatient || 0).toFixed(2)}
            </span>
          </Text>
        </VStack>
      </VStack>
      <InvoiceOrReceiptMembership
        state={props.dashState}
        transactions={props.transactions}
        setPreview={setPreview}
        setSelectedTr={setSelectedTr}
        pendingTransaction={pendingTransaction}
      />
    </Flex>
  );
};
export const PatientMembershipBillingAside = (props) => {
  const {isOpen, onOpen, onClose} = props;

  const outstandingBalance =
    (props.membership?.acumulatedPrice
      ? props.membership.acumulatedPrice
      : props.membership.price) - (props.totalAmountPaidByPatient || 0);
  async function fetchData() {
    let billing = await crud(props.schState, [
      {
        db: props.schState.db,
        collection: "billing",
        parameters: [{membershipUuid: props.membUuid}],
        method: "find",
      },
    ]);
    props.setTransactions(billing.data[0]);
  }

  if (!props.membership || !props.transactions) return <></>;
  const isPaid = outstandingBalance <= 0;
  const status = isPaid
    ? "paid"
    : props.membership?.subscriptionId
    ? props.totalAmountPaidByPatient > 0
      ? "Subscription Partially Paid"
      : "Subscription Pending"
    : "pending";
  const colorScheme = isPaid
    ? "green"
    : props.membership?.subscriptionId && props.totalAmountPaidByPatient > 0
    ? "yellow"
    : "red";

  return (
    <>
      <Drawer
        isOpen={isOpen}
        size="lg"
        placement="right"
        onClose={() => {
          return onClose();
        }}
      >
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            marginTop="1rem"
            marginRight="1rem"
            color="blue.500"
            fontSize="2xl"
            fontWeight="bold"
          >
            {props.membership.name}
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <Text
              marginTop="1rem"
              fontSize="xs"
              fontWeight={"thick"}
              color="gray.400"
            >
              description:
            </Text>
            <Text marginLeft="3">{props.membership.description}</Text>
            <Text
              marginTop="1rem"
              fontSize="xs"
              fontWeight={"thick"}
              color="gray.400"
            >
              current status:
            </Text>
            <Badge
              p="1"
              marginLeft="3"
              paddingX={"2rem"}
              borderRadius={"xl"}
              colorScheme={colorScheme}
              variant="solid"
              textColor={"white"}
            >
              {status}
            </Badge>
            <Accordion allowToggle mt={4}>
              <AccordionItem>
                <AccordionButton borderTop="none" px={0}>
                  <Box flex="1" textAlign="left" alignItems="center" py={2}>
                    <Text fontSize="xs" fontWeight={"thick"} color="gray.400">
                      payment information:
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <MembershipPaymentInformation
                    membership={props.membership}
                    transactions={props.transactions}
                    dashState={props.schState}
                    dispatch={props.dispatch}
                    fetchData={fetchData}
                    onClose={() => {
                      return onClose();
                    }}
                    totalAmountPaidByPatient={props.totalAmountPaidByPatient}
                    setPatientRefetch={props.setPatientRefetch}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Text
              marginTop="1rem"
              fontSize="xs"
              fontWeight={"thick"}
              color="gray.400"
            >
              balances:
            </Text>
            <PatientMembershipBalancesTable
              membership={props.membership}
              schState={props.schState}
            />
          </DrawerBody>
          <DrawerFooter>
            <Button
              colorScheme="blue"
              textColor="white"
              fontWeight="normal"
              onClick={() => {
                return onClose();
              }}
              w="full"
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
