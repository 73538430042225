import { crud } from "../../../crudRequests";

export const deleteTask = (
  taskId,
  dashState,
  setTasks,
  toast,
) => {
  return crud(dashState, [
    {
      db: dashState.db,
      collection: "tasks",
      parameters: [{ taskId: taskId }], // Assumes taskId is enough to identify the task
      method: "deleteOne",
    },
  ])
    .then((res) => {
      // Update the local state to reflect the deletion
      setTasks(prevTasks => prevTasks.filter(task => task.taskId !== taskId));
      // Show success message
      toast({
        title: "Task Deleted",
        description: "The task has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    })
    .catch(function (error) {
      console.log(error);
      // Handle errors, such as unauthorized access or network issues
      if (error.response && error.response.status === 403) {
        window.location.href = "../login";
      } else {
        toast({
          title: "An error occurred",
          description: error.response?.data || error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    });
};
