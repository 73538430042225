import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
  Button,
  Checkbox,
  useToast,
  Tfoot,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import AidClaimModal from "../appointments/clinicAidClaimModal";
import PreviewFile from "../appointments/payment/helpers/PreviewFile";
import InvoicOrReceipt from "./InvoiceOrReceipt";
import fieldValueFactory from "./helpers/fieldValueFactory";
import transactionFilter from "./helpers/transactionFilter";
const fixedFields = [
  [
    "Service Cost (including tax)",
    fieldValueFactory["Transaction Amounts"]["Service Cost (including tax)"],
  ],
  [
    "Total Amount Paid",
    fieldValueFactory["Transaction Amounts"]["Total Amount Paid"],
  ],
  [
    "Total Outstanding Balance",
    fieldValueFactory["Transaction Amounts"]["Total Outstanding Balance"],
  ],
];
export default function SimpleTransactionsList({
  paymentType,
  transactions,
  fields,
  query,
  searchText,
  dispatch,
  dashState,
  allowSelection,
  setAllowSelection,
  selections,
  setSelections,
  applyPayment,
  mirrorTable,
  cellSelection,
  setCellSelection,
  rowCalcValues,
  setRowCalcValues,
  colCalcValues,
  setColCalcValues,
}) {
  const [appt, setAppt] = useState(null);
  const toast = useToast();
  const [preview, setPreview] = useState(null);
  const [selectedTr, setSelectedTr] = useState(null);
  const [fetchingFileData, setFetchingFileData] = useState(false);
  const [prevFields, setPrevFields] = useState(fields);

  if (prevFields !== fields) {
    setPrevFields(fields);
    setCellSelection(new Map());
    setRowCalcValues([]);
    setColCalcValues([]);
  }

  useEffect(() => {
    const f = () => {
      setCellSelection(new Map());
    };
    window.addEventListener("click", f);
    return () => window.removeEventListener("click", f);
  }, []);

  function showCheckbox(transaction) {
    switch (allowSelection) {
      case "cancel":
        if (transaction.cancelled) return false;
        return (
          (transaction?.tpp &&
            transaction?.amountPaidByTpp !== transaction?.tppAmount) ||
          transaction?.amountPaidByPatient !== transaction?.patientAmount
        );

      case "payment":
        if (
          paymentType !== "total" &&
          selections.size > 0 &&
          !selections.has(transaction)
        ) {
          return false;
        }

        return (
          (applyPayment.from === "tpp" &&
            transaction?.tppAmount > 0 &&
            transaction?.tpp &&
            transaction?.amountPaidByTpp !== transaction?.tppAmount) ||
          (applyPayment.from === "patient" &&
            transaction?.patientAmount > 0 &&
            transaction?.amountPaidByPatient !== transaction?.patientAmount)
        );

      default:
        return true;
    }
  }

  const CellBg = (row, col) => {
    return cellSelection.get(col)?.has(row) ? {backgroundColor: "#E2E8F0"} : {};
  };

  return (
    <>
      {appt && (
        <AidClaimModal
          appt={appt}
          action={() => setAppt(null)}
          state={dashState}
          dispatch={dispatch}
        />
      )}
      {preview && (
        <PreviewFile
          preview={preview}
          setPreview={setPreview}
          appointment={selectedTr}
          state={dashState}
        />
      )}
      <Table size="md">
        <Thead position="sticky" zIndex={5} top="0" bg="gray.100">
          <Tr>
            {allowSelection && <Th fontSize={"11px"}>Selection</Th>}
            {(() => {
              mirrorTable.current = [[]];
            })()}
            {Object.entries(fields)
              .concat(fixedFields)
              .map(([displayKey, fieldFunction]) => {
                let fieldvalue = fieldFunction();
                let allowCalculations = !!fieldvalue.allowCalculations;
                mirrorTable.current[0] = mirrorTable.current[0].concat(
                  fieldvalue?.detailedTableHeader || displayKey
                );

                let col = mirrorTable.current[0].length - 1;
                let onClick = (e) => {
                  e.stopPropagation();
                  console.log(col);
                  if (allowCalculations && col >= 0) {
                    setCellSelection((prev) => {
                      let result = new Map(prev);

                      if (result.has(col)) {
                        result.delete(col);
                      } else {
                        let arr = Array.from({
                          length: transactions.length + 1,
                        }).map((_, i) => i);
                        result.set(col, new Set(arr));
                      }
                      return result;
                    });
                  }
                };
                return (
                  <Th
                    {...CellBg(0, col)}
                    cursor={allowCalculations ? "pointer" : "not-allowed"}
                    onClick={onClick}
                    fontSize={"11px"}
                    key={col}
                  >
                    {displayKey}
                  </Th>
                );
              })}
            {colCalcValues.length > 0 && (
              <Th fontSize={"11px"}>Calculations</Th>
            )}
            <Th fontSize={"11px"}>Invoice / Receipt</Th>
            {dashState.organization?.clinicAidUserId &&
              dashState.organization?.clinicAidUrl && (
                <Th fontSize={"11px"}>ClinicAid Claim</Th>
              )}
          </Tr>
        </Thead>
        <Tbody>
          {transactions
            ?.filter((transaction) => {
              return transactionFilter(query, transaction);
            })

            ?.filter((transaction) =>
              Object.entries(fields)
                .concat(fixedFields)
                .some(([displayKey, fieldFunction]) => {
                  let fieldvalue = fieldFunction(transaction);
                  return fieldvalue.uiValue
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase());
                })
            )
            ?.map((transaction, i) => {
              mirrorTable.current[i + 1] = [];
              return (
                <Tr key={i}>
                  {allowSelection &&
                    (showCheckbox(transaction) ? (
                      <Td fontSize={"15px"}>
                        <Checkbox
                          colorScheme="blue"
                          isChecked={selections.has(transaction)}
                          onChange={() => {
                            let values = [...selections.values()];
                            let firstSelection = values?.[0];

                            if (
                              allowSelection !== "cancel" &&
                              !selections.has(transaction)
                            ) {
                              if (applyPayment.from === "patient") {
                                if (
                                  firstSelection &&
                                  firstSelection.pid !== transaction.pid
                                ) {
                                  toast({
                                    title: "Payment Seletions",
                                    description:
                                      "Please select transactions that correspond to the same patient!",
                                    status: "error",
                                    isClosable: true,
                                    duration: 3000,
                                  });
                                  return;
                                }

                                let acumulative = values.reduce(
                                  (ac, e) =>
                                    ac +
                                    (e?.patientAmount - e?.amountPaidByPatient),
                                  0
                                );

                                if (
                                  acumulative +
                                    transaction?.patientAmount -
                                    transaction?.amountPaidByPatient >
                                    parseFloat(applyPayment.amount) &&
                                  paymentType === "total"
                                ) {
                                  toast({
                                    title: "Payment Seletions",
                                    description:
                                      "The accumulated amount exceeds the deposited amount!",
                                    status: "error",
                                    isClosable: true,
                                    duration: 3000,
                                  });
                                  return;
                                }
                              }

                              if (applyPayment.from === "tpp") {
                                if (
                                  firstSelection &&
                                  firstSelection.tpp !== transaction.tpp
                                ) {
                                  toast({
                                    title: "Payment Seletions",
                                    description:
                                      "Please select transactions that correspond to the same insurer!",
                                    status: "error",
                                    isClosable: true,
                                    duration: 3000,
                                  });
                                  return;
                                }

                                if (
                                  firstSelection &&
                                  firstSelection.pid !== transaction.pid
                                ) {
                                  toast({
                                    title: "Payment Seletions",
                                    description:
                                      "Please select transactions that correspond to the same patient!",
                                    status: "error",
                                    isClosable: true,
                                    duration: 3000,
                                  });
                                  return;
                                }

                                let acumulative = values.reduce(
                                  (ac, e) =>
                                    ac + (e?.tppAmount - e?.amountPaidByTpp),
                                  0
                                );

                                if (
                                  acumulative +
                                    parseFloat(
                                      transaction?.tppAmount -
                                        transaction?.amountPaidByTpp
                                    ) >
                                    parseFloat(applyPayment.amount) &&
                                  paymentType === "total"
                                ) {
                                  toast({
                                    title: "Payment Seletions",
                                    description:
                                      "The accumulated amount exceeds the deposited amount!",
                                    status: "error",
                                    isClosable: true,
                                    duration: 3000,
                                  });
                                  return;
                                }
                              }
                            }

                            setSelections((prev) => {
                              let newSet = new Set(prev);
                              if (prev.has(transaction)) {
                                newSet.delete(transaction);
                              } else newSet.add(transaction);
                              return newSet;
                            });
                          }}
                        ></Checkbox>
                      </Td>
                    ) : (
                      <Td fontSize={"15px"}></Td>
                    ))}

                  {Object.entries(fields)
                    .concat(fixedFields)
                    .map(([displayKey, fieldFunction], j) => {
                      transaction.pName = `${
                        transaction.patient?.lName || ""
                      }, ${transaction.patient?.fName || ""}`;
                      transaction.dName = `${
                        transaction.doctor?.lastName || ""
                      }, ${transaction.doctor?.firstName || ""}`;
                      let fieldvalue = fieldFunction(transaction);
                      mirrorTable.current[i + 1] = mirrorTable.current[
                        i + 1
                      ].concat(fieldvalue.exportTableEntries[1]);

                      return (
                        <Td {...CellBg(i + 1, j)} key={j} fontSize={"15px"}>
                          {fieldvalue.uiElements}
                        </Td>
                      );
                    })}

                  {colCalcValues.length > 0 && (
                    <Td bg={"gray.700"} fontSize={"15px"} color={"white"}>
                      {!isNaN(colCalcValues[0]?.value?.[i + 1])
                        ? Number(colCalcValues[0]?.value?.[i + 1]).toFixed(2)
                        : colCalcValues[0]?.value?.[i + 1]}
                    </Td>
                  )}
                  <Td fontSize="15px" gap={2}>
                    <InvoicOrReceipt
                      {...{
                        transaction,
                        dashState,
                        setPreview,
                        fetchingFileData,
                        setFetchingFileData,
                        setSelectedTr,
                        toast,
                      }}
                    />
                  </Td>

                  {dashState.organization?.clinicAidUserId &&
                    dashState.organization?.clinicAidUrl && (
                      <Td>
                        {!transaction?.invoiceAidClaimUuid ? (
                          <Button
                            onClick={() => {
                              if (!transaction?.invoiceAidClaimUuid)
                                setAppt(
                                  (dashState?.appointments || []).find(
                                    (a) => a.aid === transaction?.aid
                                  )
                                );
                            }}
                            size="sm"
                            colorScheme="blue"
                            disabled={transaction?.invoiceAidClaimUuid}
                          >
                            Create ClinicAid Claim
                          </Button>
                        ) : (
                          <Badge
                            colorScheme="green"
                            fontSize={"12px"}
                            rounded="md"
                            px="2"
                            py="0.5"
                          >
                            ClinicAid Claim Submitted
                          </Badge>
                        )}
                      </Td>
                    )}
                </Tr>
              );
            })}
        </Tbody>
        {rowCalcValues.length > 0 && (
          <Tfoot position="sticky" bottom="0" bg="gray.100">
            <Tr bg={"gray.700"} gap="1">
              {allowSelection && <Th fontSize={"11px"}></Th>}
              {Object.entries(fields)
                .concat(fixedFields)
                .map((_, i) => (
                  <Th
                    color={"white"}
                    borderRight={"1px solid white"}
                    fontWeight={"semibold"}
                    fontSize={"lg"}
                    fontStyle={"italic"}
                  >
                    {rowCalcValues[i] !== undefined
                      ? `Total = ${rowCalcValues[i]}`
                      : ""}
                  </Th>
                ))}
              {colCalcValues.length > 0 && <Th fontSize={"11px"}></Th>}
              <Th bg={"white"} fontSize={"11px"}></Th>
              <Th bg={"white"} fontSize={"11px"}></Th>
            </Tr>
          </Tfoot>
        )}
        {transactions.length == 0 && (
          <TableCaption mb="4">No transactions to display.</TableCaption>
        )}
      </Table>
    </>
  );
}
