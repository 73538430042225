import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef, useReducer} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForAdminLogin, loadDataForDoctorLogin} from "../crudRequests";
import Navbar from "../components/Navbar";
import ChangePassword from "../components/ChangePassword";
import TFA from "../components/TFA";
import LoginHistory from "../components/LoginHistory";
import {scheduleReducer, init} from "../additional_files/reducer";
import {SocketContext} from "../additional_files/context";
import {useSocket} from "../additional_files/custom";
import ToastNotification from "../components/toastNotification/ToastNotification";

import DoctorInformation from "../components/doctors/DoctorInformation";
import Navigation from "../components/Navigation";
import {Flex} from "@chakra-ui/react";
export default function User(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const loadRef = useRef(null);
  const {state} = useLocation();
  const [schState, dispatch] = useReducer(scheduleReducer, state, init);

  const [prevState, setPrevstate] = useState(state);
  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadRef);
  }, []);

  if (prevState !== state) {
    setPrevstate(state);
    dispatch({type: "UPDATE_STATE", payload: state});
  }
  const [tab, setTab] = useState("prs");

  //const socket = useSocket('https://webserver.brightlight.ai/', room, dispatch);
  let display = null;
  switch (tab) {
    case "prs":
      display = <DoctorInformation state={schState} dispatch={dispatch} />;
      break;

    case "sec":
      display = (
        <div className="max-w-xl">
          <ChangePassword schState={schState} dispatch={dispatch} />
          <TFA schState={schState} dispatch={dispatch} />
          <LoginHistory schState={schState} dispatch={dispatch} />
        </div>
      );
      break;

    default:
      display = <DoctorInformation state={schState} dispatch={dispatch} />;
      break;
  }

  const reloadingDataRef = useRef(null);

  function reloadState(destination, abortController, action) {
    action?.();

    if (schState.userType === "admin")
      loadDataForAdminLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
    else
      loadDataForDoctorLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
  }

  useEffect(() => {
    const abortController = new AbortController();
    if (!schState?.firstLoading) {
      reloadingDataRef.current = true;
      reloadState("../user", abortController, () => {
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
      });

      return () => {
        abortController.abort();
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
        reloadingDataRef.current = false;
      };
    }
  }, [schState.selectedLocation]);

  useEffect(() => {
    // console.log(schState.changingLocation, 2);
    if (schState?.firstLoading)
      navigate("../user", {
        state: {
          ...schState,
          changingLocation: reloadingDataRef.current,
          firstLoading: false,
        },
      });
  }, []);

  return (
    <Navigation state={schState} dispatch={dispatch}>
      <Flex
        position="relative"
        w="full"
        h="full"
        overflowY="hidden"
        overflowX="hidden"
        flexDirection="column"
        ref={loadRef}
        style={{opacity: 0}}
      >
        <ToastNotification appointments={schState.appointments || []} />

        <div className="relative w-auto h-auto flex flex-col overflow-hidden ml-8">
          <div className="flex space-x-8 mb-5 pt-8 font-semibold">
            <button
              className={
                tab === "prs"
                  ? "bg-dark h-14 w-32 flex justify-center items-center text-white p-1 text-[1rem] rounded-[10px] focus:outline-none"
                  : "bg-white h-14 w-32 flex justify-center items-center text-dark ring-4 ring-inset ring-dark p-1 text-[1rem] rounded-[10px] focus:outline-none"
              }
              onClick={() => {
                setTab("prs");
              }}
            >
              Personal
            </button>
            <button
              className={
                tab === "sec"
                  ? "bg-dark h-14 w-32 flex justify-center items-center text-white p-1 text-[1rem] rounded-[10px] focus:outline-none"
                  : "bg-white h-14 w-32 flex justify-center items-center text-dark ring-4 ring-inset ring-dark p-1 text-[1rem] rounded-[10px] focus:outline-none"
              }
              onClick={() => {
                setTab("sec");
              }}
            >
              Security
            </button>
          </div>
          <main className="flex w-auto overflow-hidden">
            <section className="w-auto overflow-y-auto overflow-x-hidden pr-4">
              {display}
            </section>
          </main>
        </div>
      </Flex>
    </Navigation>
  );
}
