import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Divider,
  Tooltip,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import {DeleteIcon} from "@chakra-ui/icons";
import {crud} from "../../crudRequests";

export function MembershipsDeleteModal(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();

  return (
    <>
      <Tooltip
        label="Membership is active for a patient"
        isDisabled={!props.membership.attachedToPatient}
      >
        <IconButton
          mx="1rem"
          variant="outline"
          aria-label="open menu"
          icon={<DeleteIcon />}
          bg="red.400"
          opacity="75%"
          onClick={onOpen}
          size="sm"
          isDisabled={props.membership.attachedToPatient}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.400">
            {" "}
            Are you sure you wish to disable this membership type?
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody></ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="red"
              onClick={async () => {
                await crud(
                  props.schState,
                  [
                    {
                      db: props.schState.db,
                      collection: "locations",
                      parameters: [
                        {lid: props.selectedLocation.lid},
                        {
                          $set: {
                            "memberships.$[membership].status": "Deleted",
                          },
                        },
                        {
                          arrayFilters: [
                            {"membership.mid": props.membership.mid},
                          ],
                        },
                      ],
                      method: "updateOne",
                    },
                  ],
                  null
                );
                let {locations} = props.schState;
                let locIndex = props.schState.locations.findIndex(
                  (d) => d.lid === props.selectedLocation.lid
                );
                const updatedMemberships = locations[locIndex].memberships.map(
                  (membership) => {
                    if (membership.mid === props.membership.mid) {
                      return {...membership, status: "Deleted"};
                    } else return membership;
                  }
                );
                await props.dispatch({
                  type: "UPDATE_LOCATION",
                  payload: {
                    lid: props.selectedLocation.lid,
                    memberships: updatedMemberships,
                  },
                });
                props.fetchData();
                toast({
                  title: "Membership deleted.",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
                onClose();
              }}
            >
              Confirm deletion
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
