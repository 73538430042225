import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {lightFormat} from "date-fns";
import {id} from "../PatientMembership/PatientMembershipModal";

const styles = StyleSheet.create({
  page: {
    padding: 50,
    fontSize: 12,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 40,
  },
  logo: {
    width: 150,
  },
  clinicInfo: {
    textAlign: "right",
    fontSize: 10,
    color: "#666",
  },
  title: {
    fontSize: 24,
    marginBottom: 30,
    fontWeight: "bold",
  },
  documentInfo: {
    textAlign: "right",
    marginBottom: 30,
  },
  patientSection: {
    marginBottom: 30,
  },
  patientTitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  patientInfo: {
    color: "#444",
  },
  patientName: {
    color: "#3182CE",
    fontWeight: "medium",
  },
  servicesSection: {
    marginTop: 20,
  },
  servicesTitle: {
    fontSize: 16,
    color: "#6B46C1",
    marginBottom: 10,
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    paddingVertical: 8,
    minHeight: 20,
  },
  tableHeader: {
    backgroundColor: "#fff",
    fontWeight: "bold",
  },
  tableCol: {
    flex: 1,
  },
  tableCell: {
    fontSize: 12,
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: "bold",
  },
});

export const PrepaymentPDF = ({state, prepayment, patient}) => {
  const location = state.locations?.find(
    (l) => l.lid === state.selectedLocation
  );
  const documentId = id(8);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          {location?.logo && (
            <Image
              style={{
                height: "30px",
              }}
              src={location.logo}
            ></Image>
          )}
          <View style={styles.clinicInfo}>
            <Text>{location?.name || "N/A"}</Text>
            <Text>{location?.address || "N/A"}</Text>
            <Text>
              Ph: {location?.phone || "N/A"} Fx:{location?.fax || "N/A"}
            </Text>
          </View>
        </View>

        <Text style={styles.title}>PRE-PAYMENT</Text>

        <View style={styles.documentInfo}>
          <Text>Document Num.: #{documentId}</Text>
          <Text>Issued: {lightFormat(new Date(), "dd/MM/yy HH:mm")}</Text>
        </View>

        <View style={styles.patientSection}>
          <Text style={styles.patientTitle}>Patient:</Text>
          <Text style={styles.patientName}>
            {`${patient?.fName}, ${patient?.lName}`}
          </Text>
          <Text style={styles.patientInfo}>
            Auth No: {patient?.authNo || "N/A"}
          </Text>
          <Text style={styles.patientInfo}>{formatAddress(patient)}</Text>
          <Text style={styles.patientInfo}>{patient?.email || "N/A"}</Text>
        </View>

        <View style={styles.servicesSection}>
          <Text style={styles.servicesTitle}>Services</Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHeader}>Description</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHeader}>Date</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHeader}>Amount</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {prepayment.description || "Pre-Payment"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {new Date(prepayment.createdAt).toLocaleDateString()}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  ${prepayment.amount.toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const formatAddress = (patient) => {
  if (!patient) return "No address available";

  const parts = [];
  if (patient.address1) parts.push(patient.address1);
  if (patient.address2) parts.push(patient.address2);
  if (patient.city) parts.push(patient.city);
  if (patient.province) parts.push(patient.province);
  if (patient.postalCode) parts.push(patient.postalCode);

  return parts.length > 0 ? parts.join(", ") : "No address available";
};
