import {render} from "@testing-library/react";
import React, {useState, useEffect, useRef, useReducer} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForAdminLogin, loadDataForDoctorLogin} from "../crudRequests";
import Navbar from "../components/Navbar";
import NewForm from "../components/NewForm";
import AllForms from "../components/AllForms";
import ToastNotification from "../components/toastNotification/ToastNotification";
import {scheduleReducer, init} from "../additional_files/reducer";
import {SocketContext} from "../additional_files/context";
import {useSocket} from "../additional_files/custom";
import InstanceBar from "../components/InstanceBar";
import Navigation from "../components/Navigation";
import {FixFormKeysScriptBtn} from "../components/FixFormKeysScriptBtn";
import {Flex} from "@chakra-ui/react";
export default function Forms(props) {
  const navigate = useNavigate();
  const [createEdit, setCreateEdit] = useState(false);
  const [loading, setLoading] = useState();
  const loadRef = useRef(null);
  const {state} = useLocation();
  const [schState, dispatch] = useReducer(scheduleReducer, state, init);
  const sessionStart = useRef(true);

  useEffect(
    function () {
      if (!state) {
        window.history.back();
      } else verifyUser(state ? state.jwt : "", loadRef);
    },
    [state]
  );

  const [prevState, setPrevstate] = useState(state);

  if (prevState !== state) {
    setPrevstate(state);
    dispatch({type: "UPDATE_STATE", payload: state});
  }

  const reloadingDataRef = useRef(null);

  function reloadState(destination, abortController, action) {
    action?.();

    if (schState.userType === "admin")
      loadDataForAdminLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
    else
      loadDataForDoctorLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
  }

  useEffect(() => {
    const abortController = new AbortController();
    if (!schState?.firstLoading) {
      reloadingDataRef.current = true;
      reloadState("../forms", abortController, () => {
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
      });

      return () => {
        abortController.abort();
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
        reloadingDataRef.current = false;
      };
    }
  }, [schState.selectedLocation]);

  useEffect(() => {
    // console.log(schState.changingLocation, 2);
    if (schState?.firstLoading)
      navigate("../forms", {
        state: {
          ...schState,
          changingLocation: reloadingDataRef.current,
          firstLoading: false,
        },
      });
  }, []);

  return (
    <Navigation state={schState} dispatch={dispatch}>
      <Flex
        position="relative"
        w="full"
        h="full"
        overflowY="hidden"
        overflowX="auto"
        flexDirection="column"
        ref={loadRef}
        style={{opacity: 0}}
      >
        <ToastNotification appointments={schState.appointments || []} />
        <div className="relative mt-4 w-[calc(100%-7rem)] overflow-hidden flex-1 pt-2 pb-12 pl-10 pr-2   ">
          {
            <div className="flex space-x-8  font-semibold pl-2">
              <button
                className={
                  !createEdit
                    ? "bg-[#5754FF] h-12 w-32 flex justify-center items-center text-white text-[1rem] rounded-[10px] focus:outline-none"
                    : "bg-white h-12 w-32 flex justify-center items-center text-[#5754FF] ring-4 ring-inset ring-[#5754FF] text-[1rem] rounded-[10px] focus:outline-none"
                }
                onClick={() => {
                  setCreateEdit(false);
                }}
              >
                All Forms
              </button>
              <button
                className={
                  createEdit
                    ? "bg-[#5754FF] h-12 w-32 flex justify-center items-center text-white text-[1rem] rounded-[10px] focus:outline-none"
                    : "bg-white h-12 w-32 flex justify-center items-center text-[#5754FF] ring-4 ring-inset ring-[#5754FF] text-[0/rem] rounded-[10px] focus:outline-none"
                }
                onClick={() => {
                  setCreateEdit("new");
                }}
              >
                Create
              </button>
              {/* <FixFormKeysScriptBtn state={state} /> */}
            </div>
          }
          {createEdit ? (
            <NewForm
              state={{...schState}}
              createEdit={createEdit}
              setCreateEdit={setCreateEdit}
            />
          ) : (
            state && (
              <AllForms state={{...schState}} setCreateEdit={setCreateEdit} />
            )
          )}
        </div>
      </Flex>
    </Navigation>
  );
}
