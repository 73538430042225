import Swal from "sweetalert2";
import {
  generatePaymentLink,
  createCharge,
  crud,
  sendCardReqLink,
  sendInvRecEmail,
  getPaymentMethods,
  executePayment,
} from "../../../crudRequests";
import createPaymentRecord from "../../appointments/payment/helpers/createPaymentRecord";
import deleteFiles from "../../appointments/payment/helpers/deleteFiles";
import {isoToApptDate} from "../../../additional_files/helpers";
import {crudStorageUpload} from "../../Patients/Files/helpers/crudStorageUpload";
import {lightFormat} from "date-fns";
import InvRecpDF from "../../Invoice";
import {pdf} from "@react-pdf/renderer";
import {
  fullInvoice,
  fullReceipt,
  patientInvoice,
  patientReceipt,
  insurerInvoice,
  insurerReceipt,
} from "../../appointments/payment/uploads";
import {
  fullReceipt as productFullReceipt,
  patientReceipt as productPatientReceipt,
} from "../../Products/helpers/uploads";

export async function patientApplyPayment(
  tr,
  schState,
  dispatch,
  socket,
  toast,
  paymentDescription,
  partialAmount
) {
  try {
    let result = tr;
    if (tr.type === "product") {
      let receiptAmount =
        partialAmount || tr?.patientAmount - tr?.amountPaidByPatient;

      let upTr = await crud(schState, [
        {
          db: schState.db,
          collection: "billing",
          parameters: [
            {tid: tr.tid},
            {
              $set: {
                patientPaymentStatus: "paid",
                amountPaidByPatient: partialAmount
                  ? tr?.amountPaidByPatient + partialAmount
                  : tr?.patientAmount,
                paid: (tr?.paid || 0) + receiptAmount,
                paymentDescription,
              },
            },
            {returnNewDocument: true},
          ],
          method: "findOneAndUpdate",
        },
        {
          db: schState.db,
          collection: "patients",
          parameters: [{pid: tr?.pid}],
          method: "findOne",
        },
      ]);

      await createPaymentRecord({
        state: schState,
        transaction: upTr.data[0],
        description: `Payment made by the patient. \n\n${
          paymentDescription || ""
        }`,
        date: Date.now(),
        amount: parseFloat(receiptAmount),
        payer: "patient",
      });

      let invData = {
        state: schState,
        transaction: upTr.data[0],
        patient: upTr.data[1],
        receiptAmount: parseFloat(receiptAmount),
      };

      await Promise.all([
        productFullReceipt(invData),
        productPatientReceipt(invData),
      ]);

      result = {...tr, ...upTr.data[0]};
    } else {
      let appt = await crud(schState, [
        {
          db: schState.db,
          collection: tr?.nonSessionService
            ? "nonSessionServices"
            : "appointments",
          parameters: [{aid: tr.aid}],
          method: "findOne",
        },
      ]);
      appt = appt.data[0];

      if (appt && appt?.patientPaymentStatus !== "paid") {
        let sendInvEmail = async (
          schState,
          transaction,
          doctor,
          patient,
          amountPaid,
          tpp,
          supervisor
        ) => {
          try {
            let invData = {
              state: schState,
              transaction: transaction,
              doctor,
              patient,
              tpp,
              receiptAmount: amountPaid,
              nonSessionService: transaction.nonSessionService,
            };

            const {path: receiptPath, blob} = await patientReceipt(invData);
            await fullReceipt(invData);

            let dataURI = await new Promise((resolve, reject) => {
              try {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                  resolve(reader.result);
                };
              } catch (error) {
                reject(error);
              }
            });

            let {date, time} = isoToApptDate(transaction.serviceDate);
            let location = await crud(schState, [
              {
                db: schState.db,
                collection: "locations",
                parameters: [{lid: schState.selectedLocation}],
                method: "findOne",
              },
            ]);
            location = location.data[0];
            let sender = location?.name;
            await sendInvRecEmail(schState, {
              invoice: false,
              date,
              time,
              pEmail: transaction.pEmail,
              pName: transaction.pName,
              dName: transaction.dName,
              sender: sender || schState?.organization?.name,
              attachments: [
                {
                  filename: "Receipt.pdf",
                  path: dataURI,
                },
              ],
            });
          } catch (err) {
            console.log(err);
          }
        };

        let receiptAmount =
          partialAmount || appt?.patientAmount - appt?.amountPaidByPatient;
        const updateBody = {
          ...appt,
          patientPaymentStatus: partialAmount
            ? appt?.patientPaymentStatus
            : "paid",
          amountPaidByPatient: partialAmount
            ? appt?.amountPaidByPatient + partialAmount
            : appt?.patientAmount,
          paymentDescription,
        };

        let {_id, ...data} = updateBody;

        let res = await crud(schState, [
          {
            db: schState.db,
            collection: tr?.nonSessionService
              ? "nonSessionServices"
              : "appointments",
            parameters: [{aid: data.aid}, {$set: data}],
            method: "updateOne",
          },
          {
            db: schState.db,
            collection: "billing",
            parameters: [
              {aid: data.aid},
              {
                $set: {
                  patientPaymentStatus: updateBody?.patientPaymentStatus,
                  amountPaidByPatient: updateBody?.amountPaidByPatient,
                  paymentDescription,
                },
              },
              {returnNewDocument: true},
            ],
            method: "findOneAndUpdate",
          },
          {
            db: schState.db,
            collection: "doctors",
            parameters: [{did: appt.did}],
            method: "findOne",
          },
          {
            db: schState.db,
            collection: "patients",
            parameters: [{pid: appt?.pid}],
            method: "findOne",
          },
          {
            db: schState.db,
            collection: "insurers",
            parameters: [{iid: appt?.tpp || ""}],
            method: "findOne",
          },
          {
            db: schState.db,
            collection: "doctors",
            parameters: [{did: appt.supervisorId || ""}],
            method: "findOne",
          },
        ]);

        dispatch({type: "UPDATE_APPOINTMENT", payload: updateBody});
        if (res.data[1])
          await sendInvEmail(
            schState,
            {
              ...res.data[1],
              paid: (res.data[1]?.paid || 0) + receiptAmount,
            },
            res.data[2],
            res.data[3],
            receiptAmount,
            res.data[4],
            res.data[5]
          );
        let upTr = await crud(schState, [
          {
            db: schState.db,
            collection: "billing",
            parameters: [
              {aid: data.aid},
              {
                $set: {
                  paid: (res.data[1]?.paid || 0) + receiptAmount,
                },
              },
              {returnNewDocument: true},
            ],
            method: "findOneAndUpdate",
          },
        ]);
        await createPaymentRecord({
          state: schState,
          transaction: res.data[1],
          description: `Payment made by the patient.\n\n${
            paymentDescription || ""
          }`,
          date: Date.now(),
          amount: parseFloat(receiptAmount),
          payer: "patient",
        });
        // toast.close(generatingToast);
        socket?.emit?.("update_appt", updateBody, {});
        result = {...tr, ...upTr.data[0]};
      }
    }

    return result;
  } catch (error) {
    console.log(error);
    toast({
      title: "Payment Error",
      description: error.message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
    return tr;
  }
}

export async function tppApplyPayment(
  tr,
  schState,
  dispatch,
  socket,
  toast,
  paymentDescription,
  partialAmount
) {
  try {
    let appt = await crud(schState, [
      {
        db: schState.db,
        collection: tr?.nonSessionService
          ? "nonSessionServices"
          : "appointments",
        parameters: [{aid: tr.aid}],
        method: "findOne",
      },
    ]);

    appt = appt.data[0];

    let result = tr;
    if (appt && appt?.tppPaymentStatus !== "paid") {
      let updateBody = {
        ...appt,
        paymentDescription,
        received: true,
        tppPaymentStatus: partialAmount ? appt?.tppPaymentStatus : "paid",
        amountPaidByTpp: partialAmount
          ? appt?.amountPaidByTpp + partialAmount
          : appt?.tppAmount,
        claim: true,
        paymentDescription,
      };

      let res = await crud(schState, [
        {
          db: schState.db,
          collection: tr?.nonSessionService
            ? "nonSessionServices"
            : "appointments",
          parameters: [
            {aid: appt.aid},
            {
              $set: {
                received: true,
                claim: true,
                tppPaymentStatus: updateBody.tppPaymentStatus,
                amountPaidByTpp: updateBody?.amountPaidByTpp,
                paymentDescription,
              },
            },
          ],
          method: "updateOne",
        },
        {
          db: schState.db,
          collection: "billing",
          parameters: [
            {aid: appt.aid},
            {
              $set: {
                received: true,
                claim: true,
                tppPaymentStatus: updateBody.tppPaymentStatus,
                amountPaidByTpp: updateBody?.amountPaidByTpp,
                paymentDescription,
              },
            },
            {returnNewDocument: true},
          ],
          method: "findOneAndUpdate",
        },
        {
          db: schState.db,
          collection: "doctors",
          parameters: [{did: appt.did}],
          method: "findOne",
        },
        {
          db: schState.db,
          collection: "patients",
          parameters: [{pid: appt?.pid}],
          method: "findOne",
        },
        {
          db: schState.db,
          collection: "insurers",
          parameters: [{iid: appt?.tpp}],
          method: "findOne",
        },
        {
          db: schState.db,
          collection: "doctors",
          parameters: [{did: appt.supervisorId || ""}],
          method: "findOne",
        },
      ]);

      let receiptAmount =
        partialAmount ||
        parseFloat(
          parseFloat(tr.tppAmount || 0) - parseFloat(tr.amountPaidByTpp || 0)
        );
      await createPaymentRecord({
        state: schState,
        transaction: res.data[1],
        description: `Payment made by Third Party Payer (${
          res.data[4].name || "N/A"
        }).\n\n${paymentDescription || ""}`,
        date: Date.now(),
        amount: receiptAmount,
        payer: "tpp",
      });

      let upTr = await crud(schState, [
        {
          db: schState.db,
          collection: "billing",
          parameters: [
            {aid: tr.aid},
            {
              $set: {
                paid: (res.data[1]?.paid || 0) + receiptAmount,
              },
            },
            {returnNewDocument: true},
          ],
          method: "findOneAndUpdate",
        },
      ]);
      let invData = {
        state: schState,
        transaction: upTr.data[0],
        doctor: res.data[2],
        patient: res.data[3],
        tpp: res.data[4],
        supervisor: res.data[5],
        receiptAmount: receiptAmount,
        nonSessionService: upTr.data[0]?.nonSessionService,
      };

      await insurerReceipt(invData);
      await fullReceipt(invData);

      dispatch({
        type: "UPDATE_APPOINTMENT",
        payload: {
          ...appt,
          received: true,

          tppPaymentStatus: updateBody.tppPaymentStatus,
          amountPaidByTpp: updateBody?.amountPaidByTpp,
          claim: true,
        },
      });

      socket?.emit?.("update_appt", updateBody, {});
      result = {...tr, ...upTr.data[0]};
    }
    return result;
  } catch (error) {
    toast({
      title: "Payment Error",
      description: error.message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
    console.log(error);
    return tr;
  }
}
