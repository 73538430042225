import React, {useState, useEffect, useContext} from "react";
import {
  Text,
  Switch,
  Td,
  Tr,
  Stack,
  IconButton,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import {EditIcon} from "@chakra-ui/icons";
import {crud} from "../../../crudRequests";
import PatientMembershipRenewalModal from "./PatientMemebershipRenewalModal";
import {CancelPatientMembership} from "./CancelPatientMembership";
import {PatientMembershipBillingAside} from "./PatientMembershipBillingAside";
import {PatientContext} from "../../../pages/Patients";

export default function PatientMembershipRow({
  membership,
  schState,
  dispatch,
  setPatientRefetch,
  setSelectedMembership,
  updatingMembershipActiveStatus,
  setUpdatingMembershipActiveStatus,
  selectedMembership,
  showActiveColumn,
}) {
  const toast = useToast();
  const [transactions, setTransactions] = useState(null);
  const [patient, setPatient] = useContext(PatientContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [totalAmountPaidByPatient, setTotalAmountPaidByPatient] = useState(0);

  async function fetchData() {
    let billing = await crud(schState, [
      {
        db: schState.db,
        collection: "billing",
        parameters: [{membershipUuid: membership.uuid}],
        method: "find",
      },
    ]);
    setTransactions(billing.data[0]);
  }
  useEffect(() => {
    fetchData();
  }, []);
  const startDate = new Date(membership.startDate).getTime();
  const endDate = new Date(membership.endDate);
  const existingPatientMemberships = {};
  const latestPatientState = schState?.patients.find(
    (pt) => pt.pid === patient.pid
  );
  for (const patientMembership of latestPatientState.memberships || []) {
    existingPatientMemberships[patientMembership.mid]
      ? existingPatientMemberships[patientMembership.mid].push(
          patientMembership
        )
      : (existingPatientMemberships[patientMembership.mid] = [
          patientMembership,
        ]);
  }
  let foundActiveMembership;
  if (existingPatientMemberships[membership.mid]) {
    foundActiveMembership = existingPatientMemberships[membership.mid].find(
      (memb) => {
        if (!memb.active) return false;
        return memb.startDate <= endDate && memb.endDate >= startDate;
      }
    );
  }
  const handleSwitchChange = async (evt) => {
    setUpdatingMembershipActiveStatus(true);
    try {
      const newPatientMembershipData = patient.memberships.map(
        (patientMembership) => {
          if (patientMembership.uuid === membership.uuid) {
            const newActiveState = !patientMembership.active;
            return {
              ...patientMembership,
              active: newActiveState,
            };
          }
          return patientMembership;
        }
      );
      await crud(schState, [
        {
          db: schState.db,
          collection: "patients",
          parameters: [
            {pid: patient.pid},
            {
              $set: {
                memberships: newPatientMembershipData,
              },
            },
          ],
          method: "updateOne",
        },
      ]);
      setPatient({
        ...patient,
        memberships: newPatientMembershipData,
      });
      dispatch({
        type: "UPDATE_PATIENT",
        pid: patient.pid,
        payload: {
          memberships: newPatientMembershipData,
        },
        options: null,
      });
      setPatientRefetch(true);

      const updatedMembership = newPatientMembershipData.find(
        (m) => m.uuid === membership.uuid
      );
      toast({
        title: `Membership ${membership.name} ${
          updatedMembership.active ? "activated" : "deactivated"
        }`,
        description: "Membership status updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating membership status: ", error);
      toast({
        title: "An error occurred while updating the membership status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setUpdatingMembershipActiveStatus(false);
    }
  };

  useEffect(() => {
    const amountPaidByPatient = transactions
      ? transactions
          .reduce(
            (sum, trans) => sum + (parseFloat(trans.amountPaidByPatient) || 0),
            0
          )
          .toFixed(2)
      : 0;
    setTotalAmountPaidByPatient(amountPaidByPatient);
  }, [transactions]);

  return (
    <Tr
      key={membership.uuid}
      bg={selectedMembership === membership.uuid ? "blue.100" : ""}
    >
      <Td fontSize={"15px"}>
        <Text>{membership.name}</Text>
      </Td>
      <Td fontSize={"15px"}>
        <Text>
          {new Date(membership.startDate).toLocaleDateString("en-CA")}
        </Text>
      </Td>
      <Td fontSize={"15px"}>
        <Text>{new Date(membership.endDate).toLocaleDateString("en-CA")}</Text>
      </Td>
      <Td fontSize={"15px"}>
        <Text>
          ${" "}
          {parseFloat(membership.acumulatedPrice || membership.price).toFixed(
            2
          )}
        </Text>
      </Td>
      {showActiveColumn && (
        <Td fontSize="15px">
          <Switch
            isChecked={membership.active}
            onChange={handleSwitchChange}
            isDisabled={updatingMembershipActiveStatus}
            title={
              foundActiveMembership && !membership.active
                ? "Another membership of the same type is already active for the selected date range."
                : ""
            }
          />
        </Td>
      )}
      <Td fontSize={"15px"}>
        <Text>$ {totalAmountPaidByPatient}</Text>
      </Td>
      <Td fontSize={"15px"}>
        <Text>
          ${" "}
          {(
            (membership.acumulatedPrice
              ? membership.acumulatedPrice - totalAmountPaidByPatient
              : membership.price - totalAmountPaidByPatient) || 0
          ).toFixed(2)}
        </Text>
      </Td>
      {showActiveColumn && (
        <Td>
          <Stack direction="row">
            <IconButton
              variant="outline"
              aria-label="open menu"
              icon={<EditIcon />}
              bg="blue.400"
              opacity="75%"
              boxShadow="lg"
              onClick={() => {
                onOpen();
              }}
              size="sm"
            />
            <PatientMembershipRenewalModal
              membership={membership}
              schState={schState}
              patient={patient}
              dispatch={dispatch}
              setPatientRefetch={setPatientRefetch}
              setPatient={setPatient}
              transactions={transactions}
              fetchData={fetchData}
              amountPaidByPatient={totalAmountPaidByPatient}
            />
            <CancelPatientMembership
              membership={membership}
              schState={schState}
              dispatch={dispatch}
              patient={patient}
              setPatientRefetch={setPatientRefetch}
              setPatient={setPatient}
              transactions={transactions}
            />
            <PatientMembershipBillingAside
              patient={patient}
              membership={membership}
              membUuid={membership.uuid}
              schState={schState}
              dispatch={dispatch}
              setSelectedMembership={setSelectedMembership}
              setPatientRefetch={setPatientRefetch}
              transactions={transactions}
              setTransactions={setTransactions}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              totalAmountPaidByPatient={totalAmountPaidByPatient}
            />
          </Stack>
        </Td>
      )}
    </Tr>
  );
}
