import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  useToast,
} from "@chakra-ui/react";

import {
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Stock from "./Stock.js";
import Sales from "./Sales";

export default function Products({patient, setPatient}) {
  return (
    <Tabs
      variant="soft-rounded"
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
      colorScheme="blue"
      mt="4"
      isLazy
    >
      <Center>
        <TabList>
          <Tab fontWeight={600} p="1" px="4">
            Stock
          </Tab>
          {<Tab>Sales</Tab>}
        </TabList>
      </Center>
      <TabPanels flex={1} overflow={"scroll"}>
        <TabPanel pt="2" display={"flex"}>
          <Stock patient={patient} setPatient={setPatient} />
        </TabPanel>
        {
          <TabPanel>
            <Sales setPatient={setPatient} />
          </TabPanel>
        }
      </TabPanels>
    </Tabs>
  );
}
