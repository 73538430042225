import {useContext, useState, useEffect} from "react";
import {
  getPaymentMethods,
  setDefaultPaymentMethods,
} from "../../../crudRequests";
import {DashStateContext, PatientContext} from "../../../pages/Patients";
import AddCC from "./CC/AddCC";
import {Box} from "@chakra-ui/react";
import {
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Switch,
  useToast,
  Heading,
} from "@chakra-ui/react";

export default function Payment() {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const toast = useToast();
  const [cards, setCards] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(function () {
    if (dashState.db && dashState.organization?.stpPublicKey) {
      getPaymentMethods(dashState, patient.pid)
        .then((paymentMethods) => {
          setCards(paymentMethods.data);
        })
        .catch((error) => {
          //setErrorMessage(error?.response?.data || error.message);
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    (errorMessage || successMessage) &&
      toast({
        title: successMessage ? "Success" : errorMessage && "Error",
        description: successMessage || errorMessage,
        status: successMessage ? "success" : errorMessage && "error",
        duration: 3000,
        isClosable: true,
      });
  }, [errorMessage, successMessage]);

  const [updating, setUpdating] = useState(false);
  return (
    <>
      <VStack justifyContent="center" alignItems="center">
        {" "}
        <Heading size="md" mb="4">
          Credit Card(s)
        </Heading>
        <Table borderRadius="lg" size="sm" maxW="md">
          <Thead>
            <Tr>
              <Th textAlign="center">Card</Th>
              <Th textAlign="center">Expiry Date</Th>
              <Th textAlign="center">Default</Th>
            </Tr>
          </Thead>
          <Tbody>
            {cards?.length > 0 ? (
              cards.map((c, i) => (
                <Tr key={i}>
                  <Td textAlign="center">{"****" + c.card.last4}</Td>
                  <Td textAlign="center">{`${c.card.exp_month}/${c.card.exp_year}`}</Td>
                  <Td textAlign="center">
                    <Switch
                      colorScheme="blue"
                      size="lg"
                      isChecked={c.default}
                      onChange={() => {
                        if (!updating) {
                          setSuccessMessage("");
                          setErrorMessage("");
                          setUpdating(true);
                          setDefaultPaymentMethods(dashState, patient.pid, c.id)
                            .then((res) => {
                              setErrorMessage("");
                              setCards((prev) => {
                                // console.log(res.data);

                                return prev.map((card) => {
                                  if (card.id === res.data) {
                                    return {...card, default: true};
                                  } else return {...card, default: false};
                                });
                              });
                              setUpdating(false);
                              setSuccessMessage(
                                "Successfully updated default credit card."
                              );
                            })
                            .catch((e) => {
                              setErrorMessage(e.response.data);
                              setUpdating(false);
                            });
                        }
                      }}
                    />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={3} textAlign="center">
                  No credit cards saved
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Heading size="sm" my="6">
          Add a new credit card
        </Heading>
        <Box>
          <AddCC
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            setCards={setCards}
            dashState={dashState}
            patient={patient}
          />
        </Box>
      </VStack>
    </>
  );
}
