import {useReducer, useState, useEffect, createContext, useRef} from "react";
import {useLocation} from "react-router-dom";
import {loadDataForAdminLogin, loadDataForDoctorLogin} from "../crudRequests";
import {scheduleReducer, init} from "../additional_files/reducer";
import {verifyUser} from "../authRequests";
import Navigation from "../components/Navigation";
import GiftCardsModule from "../components/GiftCards/GiftCardsModule";
import {useNavigate} from "react-router-dom";
export const DashStateContext = createContext();
export const PatientContext = createContext();
export const DispatchContext = createContext();

export default function GiftCards(props) {
  const {state} = useLocation();
  const navigate = useNavigate();
  const loadedRef = useRef(null);
  const reloadingDataRef = useRef(null);
  const [dashState, dispatch] = useReducer(scheduleReducer, state, init);
  const [prevState, setPrevstate] = useState(state);
  useEffect(
    function () {
      if (!state) {
        window.history.back();
      } else verifyUser(state ? state.jwt : "", loadedRef);
    },
    [state]
  );

  if (prevState !== state) {
    setPrevstate(state);
    dispatch({type: "UPDATE_STATE", payload: state});
  }

  const reloadState = function (destination, abortController, action) {
    action?.();
    if (dashState.userType === "admin")
      loadDataForAdminLogin(
        {
          ...dashState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
    else
      loadDataForDoctorLogin(
        {
          ...dashState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
  };

  reloadingDataRef.current = dashState.changingLocation;

  useEffect(() => {
    const abortController = new AbortController();
    if (!dashState?.firstLoading) {
      reloadingDataRef.current = true;
      reloadState("../giftCards", abortController, () => {
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
      });

      return () => {
        abortController.abort();
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
        reloadingDataRef.current = false;
      };
    }
  }, [dashState.selectedLocation]);

  useEffect(() => {
    if (dashState?.firstLoading)
      navigate("../giftCards", {
        state: {
          ...dashState,
          changingLocation: reloadingDataRef.current,
          firstLoading: false,
        },
      });
  }, []);
  return (
    <Navigation state={dashState} dispatch={dispatch}>
      {dashState && (
        <DashStateContext.Provider value={dashState}>
          <DispatchContext.Provider value={dispatch}>
            {
              <GiftCardsModule
                dispatch={dispatch}
                dashState={dashState}
                navigate={navigate}
              />
            }
          </DispatchContext.Provider>
        </DashStateContext.Provider>
      )}
    </Navigation>
  );
}
