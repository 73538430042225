import React, {useState, useEffect} from "react";
import CreateCharge from "./CreateCharge";
import {crud} from "../../../crudRequests";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
} from "@chakra-ui/react";
import {useToast} from "@chakra-ui/react";
export default function CreateChargeModal(props) {
  const {appointment, stateChanger, state, dispatch, socket} = props;
  const [patient, setPatient] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const response = await crud(state, [
          {
            db: state.db,
            collection: "patients",
            parameters: [{pid: appointment.pid}],
            method: "findOne",
          },
        ]);

        const patientData = response.data[0];
        setPatient(patientData);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };

    fetchPatientData();
  }, []);

  return (
    <Modal
      isOpen={true}
      size={"2xl"}
      onClose={() => stateChanger(false)}
      isCentered
      trapFocus={false}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader
          pl={10}
          pt={5}
          color="blue.500"
          fontSize="xl"
          fontWeight="semibold"
        >
          Payment
        </ModalHeader>
        <ModalBody>
          <div className="relative min-h-[30rem] mb-4   p-4    transition-all transform rounded-lg border">
            {patient ? (
              <CreateCharge
                state={state}
                patient={patient}
                stateChanger={stateChanger}
                appt={appointment}
                dispatch={dispatch}
                socket={socket}
                toast={toast}
              />
            ) : (
              <div className="flex justify-start items-center  px-8">
                <h1 className="text-[rgba(87,84,255,0.75)] font-semibold text-2xl leading-[25px]">
                  Loading...
                </h1>
              </div>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
