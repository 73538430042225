import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Textarea,
  Button,
  NumberInput,
  NumberInputField,
  FormControl,
  Grid,
  useToast,
  HStack,
} from "@chakra-ui/react";
import {v4 as uuidv4} from "uuid";
import {useState, useMemo} from "react";
import createDiscount from "./helpers/createDiscount";

export default function CreateDiscount({
  setCreateModal,
  createModal,
  dashState,
  dispatch,
  setDiscounts,
}) {
  const [waiting, setWaiting] = useState(false);
  const toast = useToast();
  const defaultData = useMemo(
    () => ({
      id: uuidv4(),
      name: "",
      description: "",
      discountRate: 0,
      oid: dashState.organization.oid,
    }),
    []
  );

  const [fields, setFields] = useState(defaultData);

  return (
    <Drawer
      isOpen={createModal}
      placement="right"
      size={"sm"}
      onClose={() => {
        setCreateModal(false);
      }}
      trapFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottomWidth="1px"
          color={"#1A202C"}
          fontWeight={600}
        >
          {"Create New Discount"}
        </DrawerHeader>

        <DrawerBody pt="7">
          <Box h={"full"} w="full" overflow={"auto"}>
            <form
              id="create-discount"
              onSubmit={async (e) => {
                e.preventDefault();

                if (!waiting) {
                  setWaiting(true);
                  const newDiscount = {
                    ...fields,
                    discountRate: Number(fields.discountRate),
                    createdAt: Date.now(),
                    updatedAt: Date.now(),
                  };

                  let action = () => {
                    setDiscounts((prev) => [newDiscount, ...prev]);
                    setCreateModal(false);
                    setWaiting(false);
                  };
                  await createDiscount({
                    dashState,
                    newDiscount,
                    toast,
                    action,
                  });
                }
              }}
              m="2"
              display="none"
            >
              <HStack w="full" justifyContent={"space-between"} gap={6}>
                {/* Row 1 */}
                <FormControl id="name" isRequired>
                  <FormLabel color={"gray.500"}>Name</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    value={fields.name}
                    onChange={(e) => {
                      setFields((prev) => {
                        return {
                          ...prev,
                          name: e.target.value,
                        };
                      });
                    }}
                  />
                </FormControl>
                <FormControl id="rate" isRequired>
                  <FormLabel color={"gray.500"}>Discount Rate (%)</FormLabel>
                  <Input
                    variant="flushed"
                    type="text"
                    value={fields.discountRate}
                    onBlur={() => {
                      if (
                        /^0.+/.test(String(fields.discountRate)) &&
                        !isNaN(Number(fields.discountRate))
                      )
                        setFields((prev) => {
                          return {
                            ...prev,
                            discountRate: String(Number(fields.discountRate)),
                          };
                        });
                    }}
                    onChange={(e) => {
                      const validNumber = /^\d+(\.\d{0,2})?$/;
                      let n = Number(e.target.value);
                      if (
                        (validNumber.test(e.target.value) ||
                          e.target.value === "") &&
                        n >= 0 &&
                        n <= 100
                      ) {
                        setFields((prev) => {
                          return {
                            ...prev,
                            discountRate: e.target.value,
                          };
                        });
                      }
                    }}
                  />
                </FormControl>
              </HStack>
              <FormControl id="description" mt="8">
                <FormLabel color={"gray.500"}>Description</FormLabel>
                <Textarea
                  variant="outline"
                  value={fields.description}
                  onChange={(e) => {
                    setFields((prev) => {
                      return {
                        ...prev,
                        description: e.target.value,
                      };
                    });
                  }}
                />
              </FormControl>
            </form>
          </Box>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <Button
            isDisabled={waiting}
            variant="outline"
            mr={3}
            px="10"
            onClick={() => {
              setCreateModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            isDisabled={waiting}
            type="submit"
            form="create-discount"
            mr={3}
            px="10"
            colorScheme="blue"
          >
            Create Discount
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
