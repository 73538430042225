import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import {bookNowCrud} from "../crudRequests";
import {useParams, useNavigate} from "react-router-dom";
import swal from "sweetalert";
import {
  BsPersonCircle,
  BsCheckCircleFill,
  BsFillInfoCircleFill,
  BsArrowLeft,
  BsArrowRight,
  BsCalendar2Week,
  BsCalendar2X,
} from "react-icons/bs";
import {MdOutlineArrowBackIos, MdOutlineArrowForwardIos} from "react-icons/md";

import {} from "react-icons/bs";
//import AppointmentInformation from "./Clinic.AppointmentInformation";
import BookNowAvailableBoard from "./BookNowAvailableBoard";

import {startOfWeek, endOfWeek, add, sub} from "date-fns";
import {getAvailable} from "../additional_files/available";
import {setToCurrentWeek} from "../additional_files/unavailable";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export default function Calendar({bookState, dispatch}) {
  let {selectedDoctor, selectedService} = bookState;

  const [appointmentRef, setAppointmentRef] = useState(false);
  const [date, setDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [availability, setAvailability] = useState(Array(7).fill(0));

  const selectedIntervalRef = useRef(bookState.selectedInterval);
  selectedIntervalRef.current = bookState.selectedInterval;

  const [view, setView] = useState("week");

  useEffect(() => {
    let f = (e) => setShowDatePicker(false);
    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, []);

  let getData = (signal) => {
    let requestObjs = [
      {
        db: bookState.organization.db,
        collection: "appointments",
        parameters: [
          {
            did: selectedDoctor.did,
            ISOdate: {
              $gte: startOfWeek(date, {weekStartsOn: 1}).getTime(),
              $lte: endOfWeek(date, {weekStartsOn: 1}).getTime(),
            },
          },
        ],
        method: "find",
      },
      {
        db: bookState.organization.db,
        collection: "doctors",
        parameters: [{did: selectedDoctor.did}],
        method: "findOne",
      },
    ];
    return bookNowCrud(null, requestObjs, null, signal);
  };

  const getenateAviabilities = async (data, doctor) => {
    let start = startOfWeek(date, {weekStartsOn: 1});
    let arr = [];

    for (let i = 0; i < 7; i++) {
      arr.push(
        getAvailable(
          add(start, {days: i}),
          {doctor, apptBydate: data},
          selectedService.defaultDuration?.split(" ")[0]
        ).filter((i) => i[0] >= Date.now())
      );
    }

    return arr;
  };
  //console.log(selectedIntervalRef.current);

  useEffect(() => {
    // if (!selectedIntervalRef.current) {
    const abortController = new AbortController();

    dispatch({type: "SHOW_LOADER", payload: true});

    getData(abortController.signal)
      .then(async (res) => {
        // console.log(res.data);
        let a = await getenateAviabilities(res.data[0], res.data[1]);
        setAvailability(a);
        dispatch({type: "SHOW_LOADER", payload: false});
      })
      .catch(function (error) {
        dispatch({type: "SHOW_LOADER", payload: false});
        console.log("client error", error.message);
        // if (error.code !== "ERR_CANCELED") window.location.href = "../login"
      });

    return () => {
      abortController.abort();
      dispatch({type: "SHOW_LOADER", payload: false});
    };
    // }
  }, [date]);

  function boardProps() {
    return {
      bookState,
      dispatch,
      date,
      showDatePicker,
      setShowDatePicker,
      view,
      setView,
      selectedDoctor,
      setDate,
      availability,
    };
  }
  //console.log(doctorsPointer)
  return (
    <div className="font-sans flex h-full flex-col overflow-hidden box-border w-full relative p-4 pt-0 pb-2">
      <header className="mx-10 space-y-1">
        <div className="flex justify-between items-center pt-2">
          <p
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => {
              dispatch({type: "SELECT_THREAD", payload: "selections"});
              dispatch({type: "SET_INTERVAL", payload: null});
            }}
          >
            <span className="h-9 w-9 flex text-[1.3rem] justify-center transition items-center rounded-full   bg-[#636363] text-[#ffff]">
              <BsArrowLeft />
            </span>
            <span className="text-[#636363]] font-bold  text-[1.5rem] ransition">
              Go back
            </span>
          </p>
          <p
            className="flex items-center space-x-2 cursor-pointer"
            style={{opacity: bookState.selectedInterval ? 1 : 0.5}}
            onClick={() => {
              bookState.selectedInterval &&
                dispatch({type: "SHOW_PATIENT_FORM", payload: true});
            }}
          >
            <span className="text-[#636363]] font-bold  text-[1.5rem] ransition">
              Book now
            </span>
            <span className="h-9 w-9 flex text-[1.3rem] justify-center transition items-center rounded-full   bg-[#636363] text-[#ffff]">
              <BsArrowRight />
            </span>
          </p>
        </div>
        <div>
          <ul className="w-full h-full flex items-center flex-wrap space-x-3">
            <li className=" relative bg-[#D7D7D7] w-[15rem] flex  items-center rounded-[2rem] min-h-[5.5rem]  p-5  space-x-2">
              <span className="h-4 w-4 absolute top-3 left-3 flex justify-center transition items-center rounded-full text-[1rem]  text-[#363636]">
                <BsCheckCircleFill />
              </span>

              <span className="h-4 w-4 absolute bottom-3 right-3 flex justify-center transition items-center rounded-full text-[1rem]  text-[#363636]">
                <BsFillInfoCircleFill />
              </span>
              <span className="flex justify-center h-14 w-14 items-center">
                {selectedDoctor.photo ? (
                  <img
                    src={selectedDoctor.photo}
                    alt=""
                    className="hover:cursor-pointer  h-full rounded-full drop-shadow-lg"
                  />
                ) : (
                  <BsPersonCircle className="text-[3rem] text-gray-400" />
                )}
              </span>
              <div className=" flex  flex-col text-[#636363] ">
                <p className="text-[12px] leading-4  font-semibold">
                  {selectedDoctor?.name}
                </p>
                <span className="font-light text-[9px]">
                  {selectedDoctor?.accreditations || ""}
                </span>
              </div>
            </li>
            <li className="">
              <div class="relative pb-4 space-y-1 bg-[#D7D7D7] flex items-center flex-col rounded-[2rem] w-full h-[9rem] text-[636363] text-xs m-1 p-1 lg:w-[17rem] min-w-[17rem] ">
                <div className="p-2 w-full h-[1.5rem] flex items-center mt-2">
                  <span className="h-4 w-4 flex justify-center transition items-center rounded-full text-[1rem]  text-[#363636]">
                    <BsCheckCircleFill />
                  </span>
                </div>

                <div class="flex-1 overflow-y-scroll sbar2 pr-[2px] w-full px-4">
                  <p class="font-regular text-sm px-2 leading-5">
                    {selectedService.description}.
                  </p>
                </div>

                <div class="w-full px-6 flex justify-between items-center">
                  <p class="font-regular text-white text-xs py-1 p-2 rounded-xl bg-[#565656]">
                    {`$${
                      selectedService.cost ||
                      selectedService.defaultCost ||
                      selectedService.costsByInsurer?.defaultCost ||
                      100
                    }/h`}
                  </p>

                  <p class="font-regular text-xs px-2 flex flex-col">
                    {
                      <span className="font-semibold">
                        {selectedService.defaultDuration
                          ? `${selectedService.defaultDuration}`
                          : ""}
                      </span>
                    }
                    {<span>{selectedService.attendance || "Virtual"}</span>}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </header>

      <section className="relative flex px-2  items-center justify-between  py-2">
        <div className="w-48">
          <h1 className="text-lg text-start font-semibold leading-6 text-gray-900">
            <time dateTime="2022-01-22" className="sm:hidden">
              {months[date.getMonth()].slice(0, 3)} {date.getDate()},{" "}
              {date.getFullYear()}
            </time>
            <time dateTime="2022-01-22" className="hidden sm:inline">
              {months[date.getMonth()]} {date.getDate()}, {date.getFullYear()}{" "}
              &nbsp;{" "}
              <span className="hidden 2xl:block text-off text-sm font-light">
                {weekday[date.getDay()]}
              </span>
            </time>
          </h1>
        </div>

        <div className="flex items-center justify-end space-x-2 w-[20rem] relative pl-6">
          {
            <span
              onClick={(e) => {
                setShowDatePicker((prev) => !prev);
                e.stopPropagation();
              }}
              className={
                showDatePicker
                  ? "  hover:cursor-pointer text-gray-400 text-2xl hover:text-red-400 rounded-sm"
                  : " hover:cursor-pointer hover:text-indigo-400 text-2xl bg-white rounded-sm text-gray-400"
              }
            >
              {showDatePicker ? <BsCalendar2X /> : <BsCalendar2Week />}
            </span>
          }
          {}
          <div className="relative w-[7.5rem] flex justify-between items-center rounded-md shadow-sm md:items-stretch bg-white p-1 py-2  border border-gray-300 overflow-hidden">
            <button
              type="button"
              className="flex items-center justify-center bg-white text-gray-400 hover:text-gray-500 focus:relative focus:outline-none "
              onClick={() => {
                setDate((prev) => sub(prev, {days: 7}));
              }}
            >
              <span className="sr-only">Previous day</span>
              <MdOutlineArrowBackIos className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="hidden border-gray-300 bg-white text-sm font-semibold text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:relative md:block"
              onClick={() => {
                setDate(new Date());
              }}
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              className="flex items-center justify-center  bg-white  text-gray-400 hover:text-gray-500  focus:outline-none "
              onClick={() => {
                setDate((prev) => add(prev, {days: 7}));
              }}
            >
              <MdOutlineArrowForwardIos
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </section>

      {
        <div className=" border-t bg-gray-50  w-full flex items-stretch relative rounded-t-3xl">
          {<SetDaysOfWeek {...{date, setDate}} />}
        </div>
      }

      <BookNowAvailableBoard {...boardProps()} />
    </div>
  );
}

function SetDaysOfWeek({date, setDate}) {
  let listOfWeekDays = [];
  let start = startOfWeek(date, {weekStartsOn: 1});
  for (let i = 0; i < 7; i++)
    listOfWeekDays.push(
      <div
        //onClick={() => setDate(add(start, {days: i}))}
        key={i}
        className="hover:cursor-pointer flex-1 space-x-1 py-2 border-l last:border-r last:rounded-tr-3xl first:rounded-tl-3xl flex justify-center items-center"
      >
        <span>{weekday[(i + 1) % 7].slice(0, 3)}</span>
        <span
          className={
            date.getDate() === add(start, {days: i}).getDate()
              ? "text-white font-bold p-2 w-8 h-8 flex justify-center items-center rounded-[50%] bg-indigo-700"
              : "text-black font-bold p-1 flex-none"
          }
        >
          {add(start, {days: i}).getDate()}
        </span>
      </div>
    );
  return listOfWeekDays;
}
