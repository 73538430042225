import React, {useState, useEffect, useRef} from "react";
import {View, Text, Image} from "@react-pdf/renderer";

export default function ({transaction, type, receiptAmount}) {
  let display = <></>;
  let tax = parseFloat(transaction.tax || 0);

  let {
    productSubtotal,
    productDiscountRate,
    productDiscountId,
    productTax,
    productAmount,
    amount,
    patientAmount,
    amountPaidByPatient,
  } = transaction;

  let otbp =
    parseFloat(patientAmount ?? 0) - parseFloat(amountPaidByPatient ?? 0);

  let totalCost = roundUpToTwoDecimals(
    parseFloat(transaction.patientAmount ?? 0)
  );

  let productDiscountAmount = roundUpToTwoDecimals(
    Number(productSubtotal) * (Number(productDiscountRate ?? 0) / 100)
  );
  let productRawAmount = productSubtotal - productDiscountAmount;
  let productTaxAmount = roundUpToTwoDecimals(
    (productRawAmount * Number(productTax ?? 0)) / 100
  );

  let style1 = {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 2,
    paddingBottom: 2,
  };
  switch (type) {
    case "FULL_RECEIPT":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",
            justifyContent: "flex-end",

            fontSize: 10,
          }}
        >
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${Number(productSubtotal).toFixed(2)}`}</Text>
          </View>
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Discount (${Number(productDiscountRate || 0).toFixed(2)}%)`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${Number(productDiscountAmount).toFixed(2)}`}</Text>
          </View>
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Product Tax (${Number(productTax).toFixed(2)}%)`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${Number(productTaxAmount).toFixed(2)}`}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`Total paid:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${roundUpToTwoDecimals(
              transaction.amountPaidByPatient ?? 0
            ).toFixed(2)}`}</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-end",
              justifyContent: "center",
              borderRadius: 5,
              backgroundColor: otbp === 0 ? "#d1fae5" : "#fee2e2",
              fontSize: 10,
              padding: 10,
              marginTop: 20,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                lignItems: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Outsanding Balance:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                  color: otbp === 0 ? "#10b981" : "#ef4444",
                }}
              >{`$${Number(otbp).toFixed(2)}`}</Text>
            </View>
          </View>
        </View>
      );

      break;
    case "FULL_INVOICE":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",
            paddingLeft: 60,
            fontSize: 10,
          }}
        >
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${Number(productSubtotal).toFixed(2)}`}</Text>
          </View>
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Discount (${productDiscountRate || 0}%)`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${productDiscountAmount}`}</Text>
          </View>
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Product Tax (${productTax}%)`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${productTaxAmount}`}</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`Total Cost:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${totalCost}`}</Text>
          </View>
        </View>
      );

      break;

    case "PATIENT_INVOICE":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",
            paddingLeft: 60,
            fontSize: 10,
          }}
        >
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${productSubtotal}`}</Text>
          </View>
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Discount (${Number(productDiscountRate || 0).toFixed(2)}%)`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${Number(productDiscountAmount).toFixed(2)}`}</Text>
          </View>
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Product Tax (${productTax}%)`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${Number(productTaxAmount).toFixed(2)}`}</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              lignItems: "center",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
              justifyContent: "flex-end",
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`Outsanding Balance:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${otbp}`}</Text>
          </View>
        </View>
      );
      break;

    case "PATIENT_RECEIPT":
      display = (
        <View
          style={{
            display: "flex",
            flexDirection: "col",
            paddingLeft: 60,
            fontSize: 10,
          }}
        >
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Subtotal`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${Number(productSubtotal).toFixed(2)}`}</Text>
          </View>
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Discount (${productDiscountRate || 0}%)`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${Number(productDiscountAmount).toFixed(2)}`}</Text>
          </View>
          <View style={style1}>
            <Text
              style={{
                fontWeight: 500,
                marginRight: 5,
              }}
            >
              {`Product Tax (${productTax}%)`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
              }}
            >{`$${productTaxAmount}`}</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              marginTop: 2,
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 4,
              borderTop: "1px solid black",
              paddingTop: 10,
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                marginRight: 5,
              }}
            >
              {`ReceiptAmount:`}
            </Text>
            <Text
              style={{
                fontWeight: 700,
                backgroundColor: "#e2e8f0",
                padding: 5,
                borderRadius: 5,
                fontSize: 14,
              }}
            >{`$${receiptAmount || 0}`}</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "col",
              alignItems: "flex-end",
              justifyContent: "center",
              borderRadius: 5,
              backgroundColor: otbp === 0 ? "#d1fae5" : "#fee2e2",
              fontSize: 10,
              padding: 10,
              marginTop: 20,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                lignItems: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                {`Outsanding Balance:`}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                  color: otbp === 0 ? "#10b981" : "#ef4444",
                }}
              >{`$${otbp}`}</Text>
            </View>
          </View>
        </View>
      );

      break;

    default:
      break;
  }

  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        flexDirection: "row",
        marginTop: 20,
        paddingHorizontal: 15,
      }}
    >
      {display}
    </View>
  );
}
function roundUpToTwoDecimals(num) {
  num = Number(num);
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
