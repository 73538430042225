import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Badge,
  Text,
  Button,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import {compensationRate} from "./valuesFormatter";
let fields = {
  Transaction: {
    "Transaction ID": SimpleTransactionValuesFormatter(
      "Transaction ID",
      "tid",
      "string",
      null,
      true
    ),
    Status: StatusFormatter("Status"),
    "Location Name": SimpleTransactionValuesFormatter(
      "Location Name",
      "locationName",
      "string",
      null
    ),

    "Patient Name": SimpleTransactionValuesFormatter(
      "Patient Name",
      "pName",
      "string"
    ),
    "Clinician Name": SimpleTransactionValuesFormatter(
      "Clinician Name",
      "dName",
      "string"
    ),
    "Service Name": SimpleTransactionValuesFormatter(
      "Service Name",
      "serviceType",
      "string"
    ),
    "Service Date & Time": SimpleTransactionValuesFormatter(
      "Service Name",
      "serviceDate",
      "date"
    ),

    "TPP Payment Status": SimpleTransactionValuesFormatter(
      "TPP Payment Status",
      "tppPaymentStatus",
      "string"
    ),
    "Patient Payment Status": SimpleTransactionValuesFormatter(
      "Patient Payment Status",
      "patientPaymentStatus",
      "string"
    ),
    "Clinician Compensation Rate": SimpleTransactionValuesFormatter(
      "Clinician Compensation Rate",
      "compensationRate",
      "number",
      "doctor",
      null,
      compensationRate
    ),
    "Claim Submitted?": SimpleTransactionValuesFormatter(
      "Claim Submitted",
      "claim",
      "boolean"
    ),
    "Payment Description": SimpleTransactionValuesFormatter(
      "Payment Description",
      "paymentDescription",
      "string"
    ),
    "TPP Name": SimpleTransactionValuesFormatter(
      "TPP Name",
      "name",
      "string",
      "insurer"
    ),

    "Service Tax": SimpleTransactionValuesFormatter(
      "Service Tax",
      "serviceTax",
      "string"
    ),
    "Cancellation Reason": SimpleTransactionValuesFormatter(
      "Cancellation Reason",
      "cancellationReason",
      "string"
    ),

    "Non Sessions Service": SimpleTransactionValuesFormatter(
      "Non Sessions Service",
      "nonSessionService",
      "boolean"
    ),

    "Transaction Type": (e) => {
      if (!e)
        return {
          uiTableHeader: "Transaction Type",
          detailedTableHeader: ["Transaction Type"],
        };

      let trType = e?.["type"];
      let colorScheme = "yellow";

      switch (trType) {
        case "appointment":
          colorScheme = "blue";
          break;
        case "product":
          colorScheme = "green";
          break;
        case "service":
          colorScheme = "yellow";
          break;

        default:
          colorScheme = "blue";
          break;
      }

      return {
        uiElements: (
          <Badge colorScheme={colorScheme} rounded="md" px="2" py="0.5">
            {e["type"] || "N/A"}
          </Badge>
        ),
        uiValue: String(e["type"] || "N/A"),
        exportTableEntries: [
          ["Transaction Type"],
          [{type: "string", value: e["type"] || ""}],
        ],
      };
    },

    "Receipt ID": SimpleTransactionValuesFormatter(
      "Receipt ID",
      "receiptId",
      "string",
      null,
      true
    ),

    "Invoice ID": SimpleTransactionValuesFormatter(
      "Invoice ID",
      "invoiceId",
      "string",
      null,
      true
    ),
  },
  "Transaction Amounts": {
    "Service Cost (including tax)": servicePaymentFormatter(
      "Service Cost (including tax)"
    ),
    "Cost to Patient (before tax)": servicePaymentFormatter(
      "Cost to Patient (before tax)"
    ),
    "Cost to Insurer (before tax)": servicePaymentFormatter(
      "Cost to Insurer (before tax)"
    ),
    "Service Cost (before tax)": servicePaymentFormatter(
      "Service Cost (before tax)"
    ),
    "Tax Cost to Patient": servicePaymentFormatter("Tax Cost to Patient"),
    "Tax Cost to Insurer": servicePaymentFormatter("Tax Cost to Insurer"),
    "Total Tax Cost": servicePaymentFormatter("Total Tax Cost"),
    "Patient Amount Paid": servicePaymentFormatter("Patient Amount Paid"),
    "Insurer Amount Paid": servicePaymentFormatter("Insurer Amount Paid"),
    "Patient Amount": servicePaymentFormatter(
      "Cost to Patient (including tax)"
    ),
    "TPP Amount": servicePaymentFormatter("Cost to Insurer (including tax)"),
    "Total Amount Paid": servicePaymentFormatter("Total Amount Paid"),
    "Patient Outstanding Balance": servicePaymentFormatter(
      "Patient Outstanding Balance"
    ),
    "Insurer Outstanding Balance": servicePaymentFormatter(
      "Insurer Outstanding Balance"
    ),
    "Total Outstanding Balance": servicePaymentFormatter(
      "Total Outstanding Balance"
    ),
  },
  Patient: {
    "Patient ID": SimpleTransactionValuesFormatter(
      "Patient ID",
      "pid",
      "string",
      "patient",
      true
    ),

    "Patient First Name": SimpleTransactionValuesFormatter(
      "Patient First Name",
      "fName",
      "string",
      "patient"
    ),
    "Patient Last Name": SimpleTransactionValuesFormatter(
      "Patient Last Name",
      "lName",
      "string",
      "patient"
    ),
    "Patient Preferred Name": SimpleTransactionValuesFormatter(
      "Patient Preferred Name",
      "preferredName",
      "string",
      "patient"
    ),
    "Patient Referral Via": SimpleTransactionValuesFormatter(
      "Patient Referral Via",
      "referralVia",
      "string",
      "patient"
    ),
    "Patient DOB": SimpleTransactionValuesFormatter(
      "Patient DOB",
      "dob",
      "string",
      "patient"
    ),
    "Patient Gender": SimpleTransactionValuesFormatter(
      "Patient Gender",
      "gender",
      "string",
      "patient"
    ),
    "Patient Height": SimpleTransactionValuesFormatter(
      "Patient Height",
      "height",
      "string",
      "patient"
    ),

    "Patient Email": SimpleTransactionValuesFormatter(
      "Patient Email",
      "email",
      "string",
      "patient"
    ),
    "Patient Phone": SimpleTransactionValuesFormatter(
      "Patient Phone",
      "phone",
      "string",
      "patient"
    ),

    "Patient Address 1": SimpleTransactionValuesFormatter(
      "Patient Address 1",
      "address1",
      "string",
      "patient"
    ),
    "Patient Address 2": SimpleTransactionValuesFormatter(
      "Patient Address 2",
      "address2",
      "string",
      "patient"
    ),
    "Patient City": SimpleTransactionValuesFormatter(
      "Patient City",
      "city",
      "string",
      "patient"
    ),
    "Patient Province": SimpleTransactionValuesFormatter(
      "Patient Province",
      "province",
      "string",
      "patient"
    ),
    "Patient Country": SimpleTransactionValuesFormatter(
      "Patient Country",
      "country",
      "string",
      "patient"
    ),
    "Patient Postal Code": SimpleTransactionValuesFormatter(
      "Patient Postal Code",
      "postalCode",
      "string",
      "patient"
    ),
    "Patient Referring Clinician Name": SimpleTransactionValuesFormatter(
      "Patient Referring Clinician Name",
      "referringClinicianName",
      "string",
      "patient"
    ),
    "Patient GP Name": SimpleTransactionValuesFormatter(
      "Patient GP Name",
      "gpName",
      "string",
      "patient"
    ),
  },
  Clinician: {
    "Clinician ID": SimpleTransactionValuesFormatter(
      "Clinician ID",
      "did",
      "string",
      "doctor",
      true
    ),
    "Clinician First Name": SimpleTransactionValuesFormatter(
      "Clinician First Name",
      "firstName",
      "string",
      "doctor"
    ),
    "Clinician Last Name": SimpleTransactionValuesFormatter(
      "Clinician Last Name",
      "lastName",
      "string",
      "doctor"
    ),
    "Clinician Registration #": SimpleTransactionValuesFormatter(
      "Clinician Registration #",
      "registrationId",
      "string",
      "doctor",
      true
    ),
    "Clinician Title": SimpleTransactionValuesFormatter(
      "Clinician Title",
      "title",
      "string",
      "doctor"
    ),
    "Clinician Accreditations": SimpleTransactionValuesFormatter(
      "Clinician Accreditations",
      "accreditations",
      "string",
      "doctor"
    ),
    "Clinician Education": SimpleTransactionValuesFormatter(
      "Clinician Education",
      "education",
      "string",
      "doctor"
    ),
    "Clinician Desired Hours": SimpleTransactionValuesFormatter(
      "Clinician Desired Hours",
      "desiredHours",
      "number",
      "doctor"
    ),

    "Clinician Description": SimpleTransactionValuesFormatter(
      "Clinician Description",
      "description",
      "string",
      "doctor"
    ),
    "Clinician Email": SimpleTransactionValuesFormatter(
      "Clinician Email",
      "email",
      "string",
      "doctor"
    ),
    "Clinician Phone": SimpleTransactionValuesFormatter(
      "Clinician Phone",
      "phone",
      "string",
      "doctor"
    ),
    "Clinician Fax": SimpleTransactionValuesFormatter(
      "Clinician Fax",
      "fax",
      "string",
      "doctor"
    ),
  },
  Appointment: {
    "Appointment ID": SimpleTransactionValuesFormatter(
      "Appointment ID",
      "aid",
      "string",
      "appointment",
      true
    ),

    "Appointment Date/Time": SimpleTransactionValuesFormatter(
      "Appointment Date/Time",
      "ISOdate",
      "date",
      "appointment"
    ),
    "Appointment Duration (min)": SimpleTransactionValuesFormatter(
      "Appointment Duration (min)",
      "duration",
      "number",
      "appointment"
    ),
    "Appointment Room": SimpleTransactionValuesFormatter(
      "Appointment Room",
      " roomName",
      "string",
      "appointment"
    ),
    "Patient ID": SimpleTransactionValuesFormatter(
      "Patient ID",
      "pid",
      "string",
      "appointment",
      true
    ),
    "Patient Name": SimpleTransactionValuesFormatter(
      "Patient Name",
      "pName",
      "string",
      "appointment"
    ),
    "Clinician ID": SimpleTransactionValuesFormatter(
      "Clinician ID",
      "did",
      "string",
      "appointment",
      true
    ),

    "Clinician Name": SimpleTransactionValuesFormatter(
      "Clinician Name",
      "dName",
      "string",
      "appointment"
    ),

    "Supervisor ID": SimpleTransactionValuesFormatter(
      "Supervisor ID",
      "supervisorId",
      "string",
      "appointment",
      true
    ),
    "Supervisor Name": SimpleTransactionValuesFormatter(
      "Supervisor Name",
      "supervisorName",
      "string",
      "appointment"
    ),
    "Assigned Professional": SimpleTransactionValuesFormatter(
      "Assigned Professional",
      "assignedProfessional",
      "string",
      "appointment"
    ),
    "Technician Name": SimpleTransactionValuesFormatter(
      "Technician Name",
      "technician",
      "string",
      "appointment"
    ),
    "Service ID": SimpleTransactionValuesFormatter(
      "Service ID",
      "serviceId",
      "string",
      "appointment",
      true
    ),
    "Service Name": SimpleTransactionValuesFormatter(
      "Service Name",
      "service",
      "string",
      "appointment"
    ),
    "Appointment Note": SimpleTransactionValuesFormatter(
      "Appointment Note",
      "note",
      "string",
      "appointment"
    ),
    "Appointment Notify Before (hr)": SimpleTransactionValuesFormatter(
      "Appointment Notify Before (hr)",
      "notifyBefore",
      "number",
      "appointment"
    ),
    "Appointment Cancelled": SimpleTransactionValuesFormatter(
      "Appointment Cancelled",
      "cancelled",
      "boolean",
      "appointment"
    ),
    "Appointment No Show": SimpleTransactionValuesFormatter(
      "Appointment No Show",
      "noShow",
      "boolean",
      "appointment"
    ),
    "Appointment Teleconference": SimpleTransactionValuesFormatter(
      "Appointment Teleconference",
      "teleconference",
      "boolean",
      "appointment"
    ),
    "Appointment Created By": SimpleTransactionValuesFormatter(
      "Appointment Created By",
      "apptCreatedBy",
      "string",
      "appointment"
    ),

    "First Time Appointment?": SimpleTransactionValuesFormatter(
      "First Time Appointment?",
      "firstTime",
      "boolean",
      "appointment"
    ),
  },
  Service: {
    "Service ID": SimpleTransactionValuesFormatter(
      "Service ID",
      "serviceId",
      "string",
      "service",
      true
    ),
    "Service Name": SimpleTransactionValuesFormatter(
      "Service Name",
      "serviceName",
      "string",
      "service"
    ),
    "Service Category": SimpleTransactionValuesFormatter(
      "Service Category",
      "category",
      "string",
      "service"
    ),
    "Service Default Cost": SimpleTransactionValuesFormatter(
      "Service Default Cost",
      "defaultCost",
      "number",
      "service"
    ),
    "Service Default Duration": SimpleTransactionValuesFormatter(
      "Service Default Duration",
      "defaultDuration",
      "string",
      "service"
    ),

    "Service Description": SimpleTransactionValuesFormatter(
      "Service Description",
      "description",
      "string",
      "service"
    ),
  },
  "Third Party Payer": {
    "TPP ID": SimpleTransactionValuesFormatter(
      "TPP ID",
      "iid",
      "string",
      "insurer",
      true
    ),
    "TPP Name": SimpleTransactionValuesFormatter(
      "TPP Name",
      "name",
      "string",
      "insurer"
    ),

    "TPP Description": SimpleTransactionValuesFormatter(
      "TPP Description",
      "description",
      "string",
      "insurer"
    ),
    "TPP Website": SimpleTransactionValuesFormatter(
      "TPP Website",
      "website",
      "string",
      "insurer"
    ),
    "TPP Email": SimpleTransactionValuesFormatter(
      "TPP Email",
      "email",
      "string",
      "insurer"
    ),
    "TPP Phone": SimpleTransactionValuesFormatter(
      "TPP Phone",
      "phone",
      "string",
      "insurer"
    ),
    "Insurance Type": SimpleTransactionValuesFormatter(
      "Insurance Type",
      "insuranceType",
      "string",
      null
    ),
  },
};

function SimpleTransactionValuesFormatter(
  fieldName,
  key,
  type = "string",
  objectReferenceKey,
  highlight,
  handler
) {
  return (e) => {
    if (!e)
      return {
        uiTableHeader: fieldName,
        detailedTableHeader: [fieldName],
        allowCalculations: type === "number",
      };

    if (handler && typeof handler === "function") {
      return handler(e);
    }

    if (
      objectReferenceKey &&
      e[objectReferenceKey] &&
      typeof e[objectReferenceKey] === "object"
    )
      e = e[objectReferenceKey];
    let value = "";
    let uiValue = "";
    try {
      switch (type) {
        case "string":
          value = uiValue = String(e[key] || "N/A");
          break;
        case "boolean":
          value = uiValue = Boolean(e[key]) ? "Yes" : "No";
          break;
        case "number":
          if (fieldName === "Appointment Duration (min)")
            value = uiValue = Number(e[key].split(" ")[0]);
          else value = uiValue = !isNaN(e[key]) ? Number(e[key]) : 0;
          break;
        case "date":
          let d = new Date(e[key]);
          // console.log(key, e[key]);
          if (d !== "Invalid Date") {
            value = d.toISOString();
            uiValue = d.toLocaleString();
          } else {
            value = uiValue = "N/A";
          }

          break;
        default:
          value = uiValue = String(e[key]);
          break;
      }
    } catch (error) {
      console.log(error);
    }

    return {
      uiElements: highlight ? (
        <Badge colorScheme="blue" rounded="md" px="2" py="0.5">
          {uiValue}
        </Badge>
      ) : type === "number" ? (
        <Text fontWeight="semibold" display="inline-block">
          {`${uiValue}`}
        </Text>
      ) : (
        uiValue
      ),
      uiValue: String(uiValue),
      exportTableEntries: [
        [fieldName],
        [
          {
            type: type === "boolean" ? "string" : type,
            value,
            timezoneOffset: new Date().getTimezoneOffset(),
          },
        ],
      ],
    };
  };
}

function StatusFormatter(fieldName) {
  return (e) => {
    if (!e)
      return {
        uiTableHeader: fieldName,
        detailedTableHeader: [fieldName],
      };

    const amountPaidByPatient = parseFloat(e.amountPaidByPatient);
    const patientAmount = parseFloat(e.patientAmount);
    const amountPaidByTpp = parseFloat(e.amountPaidByTpp);
    const tppAmount = parseFloat(e.tppAmount);

    let uiValue = e.invoiceAidClaimUuid
      ? "Sent to Clinic Aid"
      : e?.cancelled
      ? "Cancelled"
      : amountPaidByPatient >= patientAmount && amountPaidByTpp >= tppAmount
      ? "Fully Paid"
      : amountPaidByPatient > 0 || amountPaidByTpp > 0
      ? "Partially Paid"
      : new Date(e?.serviceDate) > new Date()
      ? "Due at Service"
      : "Overdue";

    let colorScheme = "green";

    switch (uiValue) {
      case "Fully Paid":
        colorScheme = "green";
        break;

      case "Partially Paid":
        colorScheme = "orange";
        break;

      case "Due at Service":
        colorScheme = "blue";
        break;

      case "Overdue":
        colorScheme = "yellow";
        break;
      case "Cancelled":
        colorScheme = "red";
        break;

      default:
        break;
    }

    return {
      uiElements: (
        <Badge colorScheme={colorScheme} rounded="md" px="2" py="0.5">
          {uiValue}
        </Badge>
      ),
      uiValue: String(uiValue),
      exportTableEntries: [
        [fieldName],
        [
          {
            type: "string",
            value: uiValue,
            timezoneOffset: new Date().getTimezoneOffset(),
          },
        ],
      ],
    };
  };
}

function servicePaymentFormatter(fieldName) {
  return (e) => {
    let data;
    if (!e)
      return {
        uiTableHeader: fieldName,
        detailedTableHeader: [fieldName],
        allowCalculations: true,
      };

    data = paymentDetails(e);
    switch (fieldName) {
      case "Service Cost (before tax)":
        return paymentDataFormatter({
          value: data?.totalCostBfTax,
          fieldName,
        });

      case "Cost to Patient (before tax)":
        return paymentDataFormatter({
          value: data?.patientCostBfTax,
          fieldName,
        });
      case "Cost to Insurer (before tax)":
        return paymentDataFormatter({
          value: data?.tppCostBfTax,
          fieldName,
        });

      case "Total Tax Cost":
        return paymentDataFormatter({
          value: data?.totalTaxCost,
          fieldName,
        });

      case "Tax Cost to Patient":
        return paymentDataFormatter({
          value: data?.patientTaxCost,
          fieldName,
        });

      case "Tax Cost to Insurer":
        return paymentDataFormatter({
          value: data?.tppTaxCost,
          fieldName,
        });

      case "Cost to Patient (including tax)":
        return paymentDataFormatter({
          value: data?.patientCost,
          fieldName,
        });

      case "Cost to Insurer (including tax)":
        return paymentDataFormatter({
          value: data?.tppCost,

          fieldName,
        });

      case "Service Cost (including tax)":
        return paymentDataFormatter({
          value: data?.totalCost,
          fieldName,
        });

      case "Patient Amount Paid":
        return paymentDataFormatter({
          value: data?.patientPaid,

          fieldName,
        });
      case "Insurer Amount Paid":
        return paymentDataFormatter({
          value: data?.tppPaid,

          fieldName,
        });
      case "Total Amount Paid":
        return paymentDataFormatter({
          value: data?.totalPaid,
          fieldName,
        });

      case "Patient Outstanding Balance":
        return paymentDataFormatter({
          value: data?.otbp,

          fieldName,
        });
      case "Insurer Outstanding Balance":
        return paymentDataFormatter({
          value: data?.otbi,

          fieldName,
        });
      case "Total Outstanding Balance":
        return paymentDataFormatter({
          value: data?.totalOtb,
          fieldName,
        });

      default:
        break;
    }
  };
}

function paymentDataFormatter({value, fieldName}) {
  return {
    uiElements: (
      <Text fontWeight="semibold" display="inline-block">
        {`$${value}`}
      </Text>
    ),
    uiValue: String(value),
    exportTableEntries: [[fieldName], [{type: "number", value: Number(value)}]],
  };
}

function paymentDetails(transaction) {
  let serviceTax = parseFloat(transaction.serviceTax || 0);
  let productTax = parseFloat(transaction.productTax || 0);

  let patientAmount = parseFloat(transaction.patientAmount ?? 0);
  let tppAmount = parseFloat(transaction.tppAmount ?? 0);
  let membershipAmount = parseFloat(transaction.membershipTaxAmount ?? 0);

  let amountPaidByPatient = parseFloat(transaction.amountPaidByPatient ?? 0);
  let amountPaidByTpp = parseFloat(transaction.amountPaidByTpp ?? 0);

  let serviceAmount = parseFloat(transaction.serviceAmount ?? 0);
  let productAmount = parseFloat(transaction.productAmount ?? 0);

  let totalPatientCost = patientAmount + membershipAmount;

  let otbp = totalPatientCost - amountPaidByPatient;
  let otbi = tppAmount - amountPaidByTpp;

  let productFraction = productAmount / (serviceAmount + productAmount);
  let serviceFraction = serviceAmount / (serviceAmount + productAmount);

  let patientProductTotal = patientAmount * productFraction;
  let patientServiceTotal = patientAmount * serviceFraction;

  let patientProductSubtotal = patientProductTotal / (1 + productTax / 100);
  let patientServiceSubtotal = patientServiceTotal / (1 + serviceTax / 100);

  let tppProductTotal = tppAmount * productFraction;
  let tppServiceTotal = tppAmount * serviceFraction;

  let tppProductSubtotal = tppProductTotal / (1 + productTax / 100);
  let tppServiceSubtotal = tppServiceTotal / (1 + serviceTax / 100);

  let totalCostBfTax = (
    serviceAmount / (1 + serviceTax / 100) +
    productAmount / (1 + productTax / 100)
  ).toFixed(2);
  let patientCostBfTax = patientProductSubtotal + patientServiceSubtotal;
  let tppCostBfTax = tppProductSubtotal + tppServiceSubtotal;

  let patientTaxCost = patientAmount - patientCostBfTax;
  let tppTaxCost = tppAmount - (tppProductSubtotal + tppServiceSubtotal);

  return {
    patientCostBfTax: patientCostBfTax.toFixed(2),
    tppCostBfTax: tppCostBfTax.toFixed(2),
    totalCostBfTax,

    patientTaxCost: patientTaxCost.toFixed(2),
    tppTaxCost: tppTaxCost.toFixed(2),
    totalTaxCost: (patientTaxCost + tppTaxCost).toFixed(2),

    patientCost: patientAmount.toFixed(2),
    tppCost: tppAmount.toFixed(2),
    totalCost: (patientAmount + tppAmount + membershipAmount).toFixed(2),

    patientPaid: amountPaidByPatient.toFixed(2),
    tppPaid: amountPaidByTpp.toFixed(2),
    totalPaid: (amountPaidByPatient + amountPaidByTpp).toFixed(2),

    otbp: otbp.toFixed(2),
    otbi: otbi.toFixed(2),
    totalOtb: (otbp + otbi).toFixed(2),
  };
}

export default fields;
