import {crud, sendInvRecEmail} from "../../../../crudRequests";
import {v4 as uuidv4} from "uuid";
export default async function createPaymentRecord({
  state,
  transaction,
  description,
  date,
  amount,
  payer,
  paymentMethod,
  type,
}) {
  try {
    const pymtId = uuidv4();

    let payload = {
      description,
      pymtId,
      date,
      tid: transaction.tid,
      aid: transaction.aid,
      pid: transaction.pid,
      did: transaction.did,
      dName: transaction.dName,
      pName: transaction.pName,
      amount: amount,
      lid: transaction.lid,
      serviceId: transaction.serviceId,
      serviceType: transaction.serviceType,
      tpp: transaction.tpp || "",
      payer,
      payerName: payer === "patient" ? transaction.pName : "",
      paymentMethod,
      type: type || transaction?.type,
    };

    let insurer, insuranceType;

    if (payload.payer === "tpp" && payload.tpp) {
      if (transaction.pid && transaction.tpp) {
        const patient = state.patients.find((p) => p.pid === transaction.pid);
        if (patient && patient.tpp) {
          const patientTppData = patient.tpp.find(
            (t) => t.insurerId === transaction.tpp
          );
          insuranceType = patientTppData.insuranceType;
        }
      }
      insurer = await crud(state, [
        {
          db: state.db,
          collection: "insurers",
          parameters: [{iid: payload.tpp}],
          method: "findOne",
        },
      ]);
      insurer = insurer.data[0];

      payload.payerName = insurer.name;
      payload.insuranceType = insuranceType;
    }

    let req = [
      {
        db: state.db,
        collection: "payments",
        parameters: [payload],
        method: "insertOne",
      },
    ];

    return crud(state, req);
  } catch (error) {
    console.error(error);
  }
}
