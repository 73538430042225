import {Button, Image, Text, useToast} from "@chakra-ui/react";
import {getFileData} from "../../appointments/payment/helpers/getFileData";
import {useState} from "react";
import {crudStorage} from "../../appointments/payment/helpers/crudStorage";

export const PrepaymentFileButton = ({
  prepayment,
  i,
  state,
  setPreview,
  patientId,
}) => {
  const [fetchingFileData, setFetchingFileData] = useState(false);
  const toast = useToast();

  const getPreviewFile = async () => {
    setFetchingFileData(true);
    const path = `${patientId}/Receipts/Prepayments/${prepayment?.id}/`;

    try {
      const files = await crudStorage(
        state,
        "view",
        "",
        "brightlighthealth",
        path
      );

      if (files?.data?.length > 0) {
        const file = files.data[0];
        await getFileData(
          prepayment,
          state,
          path,
          file.name,
          setPreview,
          toast,
          setFetchingFileData,
          "full",
          "prepayment"
        );
      } else {
        toast({
          title: "This file cannot be previewed.",
          description: "Pre-Payment document could not be found!",
          status: "warning",
          isClosable: true,
        });
        setFetchingFileData(false);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Could not load the prepayment document.",
        status: "error",
        isClosable: true,
      });
      setFetchingFileData(false);
    }
  };

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        getPreviewFile();
      }}
      key={i}
      bgColor="blue.500"
      color="gray.100"
      m="2px"
      rounded="lg"
      p="2px"
      px="4px"
      cursor="pointer"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      zIndex="1"
      _hover={{bgColor: "blue.600"}}
      style={{pointerEvents: "auto", margin: "0 auto"}}
      isLoading={fetchingFileData}
    >
      <Image alt="" src="/images/Clinic/inv.png" w="2rem" mt="-2px" />
      <Text fontSize="10px" mt="-2px" lineHeight="11px">
        view
      </Text>
    </Button>
  );
};
