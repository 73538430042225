import React, {useState, useRef, useEffect} from "react";

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Button,
  Avatar,
  Text,
  Flex,
  VStack,
  useToast,
  Card,
  CardHeader,
  CardBody,
  Heading,
  IconButton,
  useDisclosure,
  Tooltip,
  Badge,
  Stack,
  Collapse,
  HStack,
  Radio,
  RadioGroup,
  Icon,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

export default function ProductsInformation({appt, schState, dispatch}) {
  const [open, setOpen] = useState(false);
  const [prevAppt, setPrevAppt] = useState(appt);

  if (appt && prevAppt !== appt) {
    setPrevAppt(appt);
  }
  let p = appt?.products || [];
  return (
    <Accordion index={open ? [0] : []} allowToggle>
      <AccordionItem border="none">
        <h2>
          <AccordionButton
            color={"#5754FF"}
            borderBottom={"1px solid"}
            borderBottomColor={"#5754FF"}
            fontWeight={500}
            fontSize={"13px"}
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          >
            <Box as="span" flex="1" textAlign="left">
              Products
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} bg="white">
          <TableContainer w={"full"} maxH={"10rem"} overflowY="scroll">
            {p.length > 0 && (
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Quantity</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {p.map((e, i) => (
                    <Tr key={i}>
                      <Td whiteSpace={"pre-wrap"}>{e.name}</Td>
                      <Td textAlign={"center"}>{e.quantity}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </TableContainer>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
