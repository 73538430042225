import {DeleteIcon} from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, {useRef, useState} from "react"

export default function DeleteButton({
  onDelete,
  label = "Delete Item",
  confirmMessage = "Are you sure? You can't undo this action afterwards.",
  buttonColorScheme = "red",
  icon = <DeleteIcon />,
  isMobile = false,
}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const cancelRef = useRef();

  const renderButton = () => (
    <IconButton
      colorScheme={buttonColorScheme}
      icon={icon}
      isLoading={isLoading}
      size="sm"
      m={1}
      onClick={(e) => {
        e.stopPropagation();
        onOpen();
      }}
    />
  );

  return (
    <>
      {isMobile ? (
        renderButton()
      ) : (
        <Tooltip hasArrow label={label} rounded={"sm"}>
          {renderButton()}
        </Tooltip>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {label}
            </AlertDialogHeader>
            <AlertDialogBody>
              {confirmMessage}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme={buttonColorScheme}
                isLoading={isLoading}
                onClick={async() => {
                  setIsLoading(true)
                  await onDelete()
                  setIsLoading(false)
                  onClose()
                }}
                ml={3}
              >
                {label}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
