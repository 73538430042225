import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Stack,
  Skeleton,
  Flex,
  Input,
  Divider,
  Text,
  useToast,
  Badge,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip,
  Icon,
  VStack,
  HStack,
} from "@chakra-ui/react";
import {IoSearch} from "react-icons/io5";
import {AiOutlineDelete} from "react-icons/ai";
import React, {useState, useMemo, useEffect} from "react";
import getAmount from "../../payment/helpers/getAmount";
import {SearchableSelect} from "./SearchableSelect";
import SearchableDiscounts from "../../../Discounts/SearchableDiscounts";
import {
  findProductInActiveMemberships,
  populateProductMembershipData,
} from "../../../Products/SalesModal/index";

export default function ExternalSales({
  state,
  products = [],
  closeSalesModal,
  callback,
  productSalesInformation,
  selectedClinicianId,
  productsFromMemb,
}) {
  let tax = productSalesInformation?.tax || 0;
  let discountRate = productSalesInformation?.discountRate || 0;
  let discountId = productSalesInformation?.discountId || null;

  const [discount, setDiscount] = useState({
    id: discountId,
    value: discountRate,
  });

  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});
  const [searchText, setSearchText] = useState("");
  const [selectedProducts, setSelectedProducts] = useState(
    productSalesInformation?.products || []
  );

  const [productSaleClinicianId, setProductSaleClinicianId] = useState(
    selectedClinicianId || null
  );
  const [pdtIds, setpdtIds] = useState(
    new Set(selectedProducts.map((p) => p.id))
  );
  const productsMap = useMemo(
    () => new Map(products.map((p) => [p.id, p])),
    [products]
  );
  const [productTax, setProductTax] = useState(() => {
    let location = state.locations?.find(
      (l) => l.lid === state.selectedLocation
    );
    return tax ?? (location.productsTaxRate || 0);
  });

  const {tableEntries, selectionsEntries} = useMemo(() => {
    let selectionsEntries = {
      "Selected Product": (p) => p.name,
      "Covered by Membership/Package": (p) => {
        let productId = p.id;
        let qty = productsFromMemb[productId]?.availableQty ?? 0;
        return Math.min(qty, p.quantity);
      },
      Price: (p) => {
        return p.onSale ? p.salePrice : p.price;
      },
      Quantity: (p, key) => (
        <NumberInput
          key={key}
          size="sm"
          maxW={20}
          min={1}
          value={p.quantity}
          max={p.stock}
          onChange={(ele) => {
            setSelectedProducts((prev) => {
              let product = productsMap.get(p.id);
              return prev.map((e) =>
                e.id === p.id
                  ? {
                      ...(product ?? p),
                      quantity: ele === "" ? 0 : parseInt(ele),
                    }
                  : e
              );
            });
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      ),
    };

    let tableEntries = {
      "Product Name": (p) => p.name,
      "Available in Membership/Package": (p) => {
        let productId = p.id;
        let qty = productsFromMemb[productId]?.availableQty ?? 0;
        return qty;
      },
      "In-Stock": (p) => p.stock,
      Price: (p) => (p.onSale ? p.salePrice : p.price),
    };
    return {tableEntries, selectionsEntries};
  }, []);

  let filteredList = useMemo(() => {
    function filterText(product, text) {
      let regex = new RegExp(text, "i");
      if (text.length === 0) {
        return product;
      }
      return (
        product["name"]?.match(regex) || String(product["stock"]).match(regex)
      );
    }

    return products.filter((p) => filterText(p, searchText));
  }, [searchText, products, productsFromMemb]);

  let subtotal = selectedProducts.reduce((acc, ele) => {
    let productId = ele.id;
    let quantityFromMembership = productsFromMemb[productId]?.availableQty ?? 0;
    let remainingQuantity = Math.abs(
      Math.min(quantityFromMembership - Number(ele.quantity), 0)
    );

    return (
      acc +
      parseFloat(ele.onSale ? ele.salePrice : ele.price) *
        parseFloat(remainingQuantity)
    );
  }, 0);

  let amount = getAmount({
    subtotal,
    tax: productTax,
    discountRate: discount?.value,
  });

  const sortedDoctorOptions = useMemo(() => {
    return alphabeticalSort(
      [...state.doctors] ?? [state.doctor],
      "lastName"
    ).map((option) => ({
      value: option.did,
      label: `${option.lastName}, ${option.firstName}`,
    }));
  }, [state.doctor?.did, state.doctors?.length]);
  return (
    <Modal
      isCentered
      size={"4xl"}
      isOpen={isOpen}
      onClose={() => {
        closeSalesModal();
        onClose();
      }}
      closeOnOverlayClick={false}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent p={1}>
        <ModalHeader borderBottom={"1px"} borderColor={"gray.400"}>
          Sale Information
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="10">
          <form
            gap={2}
            id="buyerInfo"
            onSubmit={async (e) => {
              e.preventDefault();
            }}
            m="2"
          >
            <InputGroup p="1" w="60" mb="4">
              <InputLeftElement>
                <Icon as={IoSearch} color="gray.500" mt="2" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearchText(e.target.value.toLowerCase());
                }}
              />
            </InputGroup>
            <SearchableSelect
              options={sortedDoctorOptions}
              label="Select clinician"
              placeholder="-"
              isRequired={false}
              value={productSaleClinicianId}
              onChange={(e) => {
                setProductSaleClinicianId(e.value);
              }}
            />
            <TableContainer
              maxH="15rem"
              minH="8rem"
              mb={8}
              overflowY="auto"
              pb="2"
              borderBottom={"1px solid"}
              borderBottomColor={"gray.300"}
            >
              <Table size="md">
                <Thead position="sticky" top="0" bg="gray.100">
                  <Tr>
                    {Object.keys(tableEntries).map((field, i) => {
                      return (
                        <Th key={i} fontSize={"11px"}>
                          {field}
                        </Th>
                      );
                    })}
                    <Th fontSize={"11px"}>{"Action"}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredList?.map((product, i) => {
                    return (
                      <>
                        <Tr key={i}>
                          {Object.entries(tableEntries).map(([k, v]) => (
                            <Td
                              fontSize={"15px"}
                              textAlign={
                                k === "Available in Membership/Package"
                                  ? "center"
                                  : "start"
                              }
                            >
                              {k === "Description" ? (
                                <Text w={"15rem"} whiteSpace={"pre-wrap"}>
                                  {v(product) ?? "N/A"}
                                </Text>
                              ) : (
                                v(product) ?? "N/A"
                              )}
                            </Td>
                          ))}
                          <Td fontSize={"15px"}>
                            <Flex gap="2">
                              {!pdtIds.has(product.id) ? (
                                <Badge
                                  colorScheme="blue"
                                  cursor={
                                    product.stock > 0
                                      ? "pointer"
                                      : "not-allowed"
                                  }
                                  rounded="md"
                                  w="6rem"
                                  textAlign={"center"}
                                  p="1"
                                  onClick={() => {
                                    if (
                                      !pdtIds.has(product.id) &&
                                      product.stock > 0
                                    ) {
                                      setSelectedProducts((prev) =>
                                        prev.concat({...product, quantity: 1})
                                      );
                                      pdtIds.add(product.id);
                                    }
                                  }}
                                >
                                  Add to Sale
                                </Badge>
                              ) : (
                                <Badge
                                  colorScheme="green"
                                  w="6rem"
                                  textAlign={"center"}
                                  rounded="md"
                                  p="1"
                                >
                                  Selected
                                </Badge>
                              )}
                            </Flex>
                          </Td>
                        </Tr>
                      </>
                    );
                  })}
                </Tbody>
                {products.length === 0 && (
                  <TableCaption mb="4">No products to display.</TableCaption>
                )}
              </Table>
            </TableContainer>

            {selectedProducts.length > 0 && (
              <>
                <Flex
                  justify={"space-between"}
                  align={"flex-end"}
                  mx="1"
                  mb="2"
                >
                  <Text fontWeight={500} color={"blue.500"}>
                    Product Selection
                  </Text>
                  <Button
                    colorScheme="red"
                    variant="outline"
                    size={"xs"}
                    onClick={() => {
                      setSelectedProducts([]);
                      setpdtIds(new Set());
                      callback({
                        products: [],
                        tax: 0,
                        amount: 0,
                        subtotal: 0,
                        discountRate: discount.value,
                        discountId: null,
                      });
                    }}
                  >
                    Clear
                  </Button>
                </Flex>

                <TableContainer
                  overflowY="auto"
                  variant="simple"
                  maxHeight={"250px"}
                  mb="5"
                  rounded={"md"}
                  border={"1px solid"}
                  borderColor={"gray.300"}
                  p="2"
                  pt="0"
                >
                  <Table size="md">
                    <Thead position="sticky" zIndex={10} top="0" bg="white">
                      <Tr>
                        {Object.keys(selectionsEntries).map((field, i) => {
                          return (
                            <Th key={i} fontSize={"11px"}>
                              {field}
                            </Th>
                          );
                        })}
                        <Th fontSize={"11px"}>{"Action"}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedProducts?.map((product, i) => {
                        return (
                          <>
                            <Tr key={i}>
                              {Object.entries(selectionsEntries).map(
                                ([k, v], i) => (
                                  <Td
                                    key={i}
                                    fontSize={"15px"}
                                    textAlign={
                                      k === "Covered by Membership"
                                        ? "center"
                                        : "start"
                                    }
                                  >
                                    {v(product, i) ?? "N/A"}
                                  </Td>
                                )
                              )}
                              <Td fontSize={"15px"}>
                                <Badge
                                  colorScheme="red"
                                  rounded="md"
                                  p="1"
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setpdtIds((prev) => {
                                      prev.delete(product.id);
                                      return prev;
                                    });
                                    setSelectedProducts((prev) =>
                                      prev.filter((p) => p.id !== product.id)
                                    );
                                  }}
                                >
                                  <Icon
                                    as={AiOutlineDelete}
                                    fontSize="15px"
                                    mt="-1"
                                    mr="1"
                                  />
                                  Remove
                                </Badge>
                              </Td>
                            </Tr>
                          </>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </>
            )}
            <HStack
              w="full"
              justifyContent={"space-between"}
              alignItems={"self-start"}
            >
              <SearchableDiscounts
                selectOptions={{menuPlacement: "top"}}
                {...{
                  state,
                  selectedDiscount: discount,
                  setSelectedDiscount: (d) => {
                    setDiscount(d);
                  },
                }}
              />
              <VStack alignItems={"flex-end"}>
                <Flex align={"center"} gap={1} fontSize="1em">
                  <Text fontWeight={600}>{`Tax (%):`}</Text>

                  <Input
                    w="3rem"
                    fontWeight={600}
                    type="text"
                    value={productTax}
                    onBlur={(e) => {
                      if (
                        /^0.+/.test(String(productTax)) &&
                        !isNaN(Number(productTax))
                      )
                        setProductTax(String(Number(productTax)));
                    }}
                    onChange={(e) => {
                      let value = e.target.value;

                      if (!isNaN(value) && !value.match(/-/g)) {
                        if (value === "") value = 0;
                        setProductTax(value);
                      }
                    }}
                    textAlign={"right"}
                    size={"sm"}
                    p="2"
                    rounded={"md"}
                  />
                </Flex>
                <Flex align={"center"} gap={1} fontSize="1em">
                  <Text fontWeight={600}>{`Total Amount:`}</Text>
                  <Badge rounded={"md"} fontSize="1.2em">
                    {`$${amount}`}
                  </Badge>
                </Flex>
              </VStack>
            </HStack>
            <></>
          </form>
        </ModalBody>

        {
          <ModalFooter>
            <Button
              type="submit"
              form="buyerInfo"
              w="40"
              colorScheme="blue"
              mr={3}
              cursor={"pointer"}
              onClick={async () => {
                callback({
                  products: selectedProducts.map((p) => {
                    let productId = p.id;
                    let qty = productsFromMemb[productId]?.availableQty ?? 0;
                    let covered = Math.min(qty, p.quantity);
                    return {...p, covered};
                  }),
                  amount,
                  tax: Number(productTax),
                  productSaleClinicianId,
                  discountRate: discount?.value,
                  discountId: discount?.id,
                  subtotal,
                });
                closeSalesModal();
                onClose();
              }}
            >
              Apply
            </Button>
            <Button
              w="40"
              onClick={() => {
                closeSalesModal();
                onClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        }
      </ModalContent>
    </Modal>
  );
}

function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}

function alphabeticalSort(array, property) {
  const orderedArray = [...array];
  return orderedArray.sort((a, b) => {
    if (a[property].toLowerCase().trim() < b[property].toLowerCase().trim()) {
      return -1;
    }
    if (a[property].toLowerCase().trim() > b[property].toLowerCase().trim()) {
      return 1;
    }
    return 0;
  });
}
