import {crud} from "../../../crudRequests";

export default async function fetchDiscounts(state, setDiscounts, toast) {
  try {
    const res = await crud(state, [
      {
        db: state.db,
        collection: "discounts",
        parameters: [{}],
        method: "find",
        findCursor: [{method: "sort", arguments: [{name: 1}]}],
      },
    ]);

    setDiscounts(res.data[0]);
  } catch (err) {
    console.log("error", err);
    if (toast)
      toast({
        title: "Failed to fetch discounts",
        description:
          err?.message || "An error occurred while fetching discounts data.",
        status: "error",
        isClosable: true,
      });
  }
}
