import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
  Input,
  Text,
  useToast,
  Badge,
  HStack,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
} from "@chakra-ui/react";
import React, {useState, useMemo, useEffect} from "react";
import SelectionsTable from "./SelectionsTable";
import BuyerInformation from "./BuyerInformation.js";

import SelectPatient from "./SelectPatient.js";
import {ProductPaymentModal} from "../../appointments/payment/ProductPaymentModal.jsx";
import getAmount from "../../appointments/payment/helpers/getAmount";
import SearchableDiscounts from "../../Discounts/SearchableDiscounts";
import {crud} from "../../../crudRequests.js";

export function findProductInActiveMemberships(
  memberships,
  productId,
  reservedForAppt
) {
  const activeMemberships =
    memberships
      ?.sort((a, b) => {
        return (b.membership === true) - (a.membership === true);
      })
      ?.filter((m) => m.active) || [];
  let totalQuantity = reservedForAppt?.[productId] || 0;
  const membershipsWithProduct = [];

  activeMemberships.forEach((m) => {
    if (!m.selectedProducts) return;
    const membershipProductData = m.selectedProducts[productId];
    const productBalance =
      (membershipProductData?.quantity || 0) -
      (membershipProductData?.consumed || 0) -
      (membershipProductData?.reserved || 0);
    if (productBalance > 0) {
      totalQuantity += productBalance;
      membershipsWithProduct.push(m);
    }
  });

  return {
    totalQuantity,
    membershipsWithProduct,
  };
}

export function populateProductMembershipData(
  memberships,
  products,
  apptLogs = []
) {
  const reservedForThisAppointment = {};
  apptLogs.forEach((log) => {
    reservedForThisAppointment[log.productId] = reservedForThisAppointment[
      log.productId
    ]
      ? reservedForThisAppointment[log.productId] + log.quantity
      : log.quantity;
  });
  const updatedProductList = products.map((p) => {
    const productMembershipData = findProductInActiveMemberships(
      memberships,
      p.id,
      reservedForThisAppointment
    );
    return {...p, includedInMembership: productMembershipData};
  });
  return updatedProductList;
}

export default function SalesModal({
  selections,
  setSelections,
  action,
  state,
  setProducts,
  selectedPatient,
  dispatch,
  setPatient,
  setAllowSelection,
}) {
  const [discount, setDiscount] = useState({
    id: null,
    value: 0,
  });
  const toast = useToast();
  const {isOpen, onClose} = useDisclosure({defaultIsOpen: true});
  const [patientId, setPatientId] = useState(selectedPatient?.pid || null);
  const [selectedProducts, setSelectedProducts] = useState(
    [...selections.values()].map((e) => ({...e, quantity: 1}))
  );
  const [prepayments, setPrepayments] = useState([]);
  const [prepaymentTotal, setPrepaymentTotal] = useState(0);
  const [prepaymentsApplied, setPrepaymentsApplied] = useState(false);
  const patientsMap = useMemo(() => {
    return state.patients.reduce((acc, c) => {
      acc[c.pid] = c;
      return acc;
    }, {});
  }, []);
  const patient = selectedPatient || patientsMap[patientId];
  const productsWithMembershipData = populateProductMembershipData(
    patient?.memberships || [],
    selectedProducts
  );

  let subtotal = productsWithMembershipData.reduce((acc, el) => {
    let quantityFromMembership = Number(
      el.includedInMembership?.totalQuantity || 0
    );
    let remainingQuantity = Math.abs(
      Math.min(quantityFromMembership - Number(el.quantity), 0)
    );

    return (
      acc +
      parseFloat(el.onSale ? el.salePrice : el.price) *
        parseFloat(remainingQuantity)
    );
  }, 0);

  const [fields, setFields] = useState(() => {
    let location = state.locations?.find(
      (l) => l.lid === state.selectedLocation
    );

    return {
      pid: patient?.pid || null,
      firstName: patient?.fName || "",
      lastName: patient?.lName || "",
      pEmail: patient?.email || "",
      phone: patient?.phone || "",
      tax: location.productsTaxRate || 0,
      note: "",
      productSaleClinicianId: null,
    };
  });
  const amount = roundUpToTwoDecimals(
    getAmount({
      subtotal,
      tax: fields.tax,
      discountRate: discount?.value,
    })
  );
  const [resultedAmount, setResultedAmount] = useState(amount);

  const fetchPrepayments = async (patientId) => {
    const resPrepayments = await crud(state, [
      {
        db: state.db,
        collection: "prepayments",
        parameters: [{linkedPatientId: patientId, balanceAvailable: {$gt: 0}}],
        method: "find",
      },
    ]);
    const prepayments = resPrepayments?.data[0];
    setPrepayments(prepayments);
    setPrepaymentTotal(
      prepayments?.reduce(
        (acc, el) => parseFloat(acc) + parseFloat(el.balanceAvailable),
        0
      )
    );
  };

  const [loading, setLoading] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const handlePrepaymentPayment = async () => {
    setPrepaymentsApplied(true);
    setPaymentModalOpen(true);
    if (amount - prepaymentTotal <= 0) {
      setResultedAmount(0);
    } else {
      setResultedAmount(amount - prepaymentTotal);
    }
  };

  const updatePrepayments = async (receiptPath) => {
    const sortedPrepayments = [...prepayments].sort(
      (a, b) => new Date(a.createdAt || 0) - new Date(b.createdAt || 0)
    );
    let remainingAmount = amount;
    const updatedPrepayments = [];

    if (amount - prepaymentTotal <= 0) {
      for (const prepayment of sortedPrepayments) {
        if (remainingAmount <= 0) break;

        const amountToUse = Math.min(
          parseFloat(prepayment.balanceAvailable),
          remainingAmount
        );
        const newBalance = roundUpToTwoDecimals(
          parseFloat(prepayment.balanceAvailable) - amountToUse
        );

        updatedPrepayments.push({
          id: prepayment.id,
          balanceAvailable: newBalance,
          receiptsUrl: [...(prepayment.receiptsUrl || []), receiptPath],
        });

        remainingAmount -= amountToUse;
      }

      for (const prepayment of updatedPrepayments) {
        await crud(state, [
          {
            db: state.db,
            collection: "prepayments",
            parameters: [
              {id: prepayment.id},
              {
                $set: {
                  balanceAvailable: prepayment.balanceAvailable,
                  receiptsUrl: prepayment.receiptsUrl,
                },
              },
            ],
            method: "updateOne",
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (patient?.pid) {
      fetchPrepayments(patient?.pid);
    }
  }, [patient?.pid]);

  return (
    <Modal
      isCentered
      size={"4xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        action();
      }}
      closeOnOverlayClick={false}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent p={1} overflow={"hidden"}>
        <ModalHeader borderBottom={"1px"} borderColor={"gray.400"}>
          Sale Information
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="10">
          <form
            id="buyerInfo"
            onSubmit={async (e) => {
              e.preventDefault();
              setPaymentModalOpen(true);
              setPrepaymentsApplied(false);
            }}
            m="2"
          >
            <SelectPatient
              {...{patientId, setPatientId, setFields, state, patientsMap}}
            />
            <Flex w="full" direction={"column"} gap={6}>
              <BuyerInformation {...{fields, setFields, state}} />

              <SelectionsTable
                {...{
                  state,
                  selectedProducts: productsWithMembershipData,
                  setSelectedProducts,
                  loading,
                  setSelections,
                  closeAcion: () => {
                    action();
                    onClose();
                  },
                  patient: patient ? patient : patientsMap[patientId],
                }}
              />
            </Flex>
            <HStack
              w="full"
              justifyContent={"space-between"}
              alignItems={"self-start"}
            >
              <SearchableDiscounts
                selectOptions={{menuPlacement: "top"}}
                {...{
                  state,
                  selectedDiscount: discount,
                  setSelectedDiscount: (d) => {
                    setDiscount(d);
                  },
                }}
              />
              <VStack alignItems={"flex-end"}>
                <Flex align={"center"} gap={1} fontSize="1em">
                  <Text fontWeight={600}>{`Tax (%):`}</Text>

                  <Input
                    w="3rem"
                    fontWeight={600}
                    type="text"
                    value={fields.tax}
                    onBlur={(e) => {
                      if (
                        /^0.+/.test(String(fields.tax)) &&
                        !isNaN(Number(fields.tax))
                      )
                        setFields((prev) => {
                          return {
                            ...prev,
                            tax: String(Number(fields.tax)),
                          };
                        });
                    }}
                    onChange={(e) => {
                      let value = e.target.value;

                      if (!isNaN(value) && !value.match(/-/g)) {
                        if (value === "") value = 0;
                        setFields((prev) => {
                          return {
                            ...prev,
                            tax: value,
                          };
                        });
                      }
                    }}
                    textAlign={"right"}
                    size={"sm"}
                    p="2"
                    rounded={"md"}
                  />
                </Flex>
                <Flex align={"center"} gap={1} fontSize="1em">
                  <Text fontWeight={600}>{`Total Amount:`}</Text>
                  <Badge rounded={"md"} fontSize="1.2em">
                    {`$${amount}`}
                  </Badge>
                </Flex>
              </VStack>
            </HStack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Stat>
            <StatLabel>Pre-Payments Balance</StatLabel>
            <StatNumber>{`$${Number(prepaymentTotal).toFixed(2)}`}</StatNumber>
          </Stat>
          <Tooltip
            label={`Insufficient Pre-Payment Balance. Add more balance or use another payment method.`}
            isDisabled={prepaymentTotal > amount}
            hasArrow
            placement="top"
          >
            <Button
              w="40"
              colorScheme="blue"
              mr={3}
              isDisabled={prepaymentTotal <= amount}
              onClick={handlePrepaymentPayment}
            >
              Apply Pre-Payment
            </Button>
          </Tooltip>
          <Button
            type="submit"
            form="buyerInfo"
            w="40"
            colorScheme="blue"
            mr={3}
            cursor={!loading ? "pointer" : "not-allowed"}
          >
            Pay
          </Button>
          <ProductPaymentModal
            isOpen={paymentModalOpen}
            selectedProducts={productsWithMembershipData}
            onClose={() => {
              setPaymentModalOpen(false);
              onClose();
              setSelections(new Set());
              setAllowSelection("");
              action();
              setPrepaymentsApplied(false);
            }}
            amount={amount}
            subtotal={subtotal}
            state={state}
            toast={toast}
            patient={patient ? patient : patientsMap[patientId]}
            productSaleClinicianId={fields.productSaleClinicianId}
            discount={discount}
            productTax={Number(fields.tax)}
            dispatch={dispatch}
            setPatient={setPatient}
            prepaymentsApplied={prepaymentsApplied}
            updatePrepayments={updatePrepayments}
          />

          <Button
            w="40"
            onClick={() => {
              setPaymentModalOpen(true);
              onClose();
              action();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function roundUpToTwoDecimals(num) {
  num = Number(num);
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
