import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Divider,
  Text,
  Stack,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import {DeleteIcon} from "@chakra-ui/icons";
import {crud, deleteMembershipSubscription} from "../../../crudRequests";
import {useState} from "react";

export function CancelPatientMembership(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const serviceMap = {};
  const toast = useToast();

  for (const service of props.schState?.services) {
    if (
      Object.keys(props.membership.selectedServices).includes(service.serviceId)
    ) {
      serviceMap[service.serviceId] = service;
    }
  }
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const isFutureMembership = currentDate < new Date(props.membership.startDate);
  const isCurrentMembership =
    currentDate >= new Date(props.membership.startDate) &&
    currentDate <= new Date(props.membership.endDate);
  const displayCta =
    isFutureMembership ||
    isCurrentMembership ||
    props.membership.membership === false;
  if (props.membership.status === "Cancelled") {
    return null;
  }

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const pendingTransaction = props.transactions?.find(
        (transaction) =>
          transaction.patientPaymentStatus === "pending" &&
          transaction.cancelled === false
      );

      if (pendingTransaction) {
        await crud(props.schState, [
          {
            db: props.schState.db,
            collection: "billing",
            parameters: [
              {tid: pendingTransaction.tid},
              {
                $set: {
                  cancelled: true,
                },
              },
            ],
            method: "updateOne",
          },
        ]);
      }
      await crud(props.schState, [
        {
          db: props.schState.db,
          collection: "patients",
          parameters: [
            {
              pid: props.patient.pid,
              "memberships.uuid": props.membership.uuid,
            },
            {
              $set: {
                "memberships.$.status": "Cancelled",
                "memberships.$.active": false,
              },
            },
            {returnNewDocument: true},
          ],
          method: "findOneAndUpdate",
        },
      ]);
      if (props.membership.subscriptionId) {
        await deleteMembershipSubscription(
          props.schState,
          props.membership.subscriptionId,
          props.patient.pid,
          props.membership.uuid,
          props.schState.lid
        );
      }

      const updatedMemberships = props.patient.memberships.map((mData) => {
        return mData.uuid === props.membership.uuid
          ? {
              ...mData,
              status: "Cancelled",
              active: false,
            }
          : mData;
      });
      props.setPatient({
        ...props.patient,
        memberships: updatedMemberships,
      });

      props.dispatch({
        type: "UPDATE_PATIENT",
        pid: props.patient.pid,
        payload: {
          memberships: updatedMemberships,
        },
        options: null,
      });

      toast({
        title: "The membership has been cancelled.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      props.setPatientRefetch(true);
      onClose();
    } catch (error) {
      console.log("error: ", error);
      toast({
        title: "An error occurred while cancelling the membership.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack>
      {displayCta && (
        <IconButton
          icon={<DeleteIcon />}
          variant="outline"
          onClick={onOpen}
          bg="red.400"
          opacity="75%"
          boxShadow="lg"
          size="sm"
          isLoading={isLoading}
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.400">
            Confirm membership cancellation
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Please note this will remove any pending membership balances if
              the membership is active.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleDelete}
              colorScheme="red"
              mr={3}
              w="1/3"
              isLoading={isLoading}
              loadingText="Cancelling..."
            >
              Confirm
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onClose}
              w="1/3"
              isDisabled={isLoading}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
