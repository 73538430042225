import {useState, useRef, useEffect} from "react";
import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Input,
  FormControl,
  FormLabel,
  Select,
  Flex,
  PopoverFooter,
  ButtonGroup,
  InputLeftElement,
  InputGroup,
  useToast,
  Box,
  Icon,
  useDisclosure,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import {MdClose} from "react-icons/md";
import {patientApplyPayment, tppApplyPayment} from "./helpers/applayPayment";
export default function ApplyPayment({
  applyPayment,
  setApplyPayment,
  allowSelection,
  setAllowSelection,
  selections,
  setSelections,
  setPaymentType,
  paymentType,
  setTransactions,
  dispatch,
  dashState,
  waiting,
  setWaiting,
}) {
  const createClose = useRef(null);
  const toast = useToast();
  const {isOpen, onToggle, onClose} = useDisclosure();

  const handleApplyClick = () => {
    if (applyPayment.from && parseFloat(applyPayment.amount) > 0) {
      setAllowSelection("payment");
      createClose.current.click();
    } else
      toast({
        title: "Payment",
        description: "Please select valid values!",
        status: "warning",
        isClosable: true,
      });
  };

  let values = [...selections.values()];
  let acumulative = values.reduce(
    (ac, e) =>
      ac +
      (applyPayment.from === "tpp"
        ? e?.tppAmount - e?.amountPaidByTpp
        : e?.patientAmount - e?.amountPaidByPatient),
    0
  );

  let remanent = (parseFloat(applyPayment.amount || 0) - acumulative).toFixed(
    2
  );

  async function applayPayment() {
    let generatingToast;
    try {
      if (selections.size > 0 && applyPayment.from && !waiting) {
        const {value: paymentDescription} = await Swal.fire({
          input: "textarea",
          title: "Payment Description",
          inputPlaceholder: "Type the description...",
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value.match(/\w+/)) {
              return "You must write a description!";
            }
          },
        });
        const description = paymentDescription || "";
        if (selections.size <= 0) {
          toast({
            title: "Payment Seletions",
            description: "Please select the transactions to pay!",
            status: "warning",
            isClosable: true,
            duration: 2000,
          });
        } else {
          setWaiting(true);
          generatingToast = toast({
            title: `Executing ${
              applyPayment.from === "patient" ? "Patient" : "Third Party Payer"
            } Payment`,
            description: "Please wait a few seconds...",
            status: "loading",
            variant: "subtle",
            duration: 10000,
            isClosable: true,
          });
          let trs = [];
          if (applyPayment.from === "patient") {
            trs = await Promise.all(
              [...selections].map((tr) =>
                patientApplyPayment(
                  tr,
                  dashState,
                  dispatch,
                  null,
                  toast,
                  description,
                  remanent < 0 && parseFloat(applyPayment.amount || 0)
                )
              )
            );
          } else if (applyPayment.from === "tpp") {
            trs = await Promise.all(
              [...selections].map(async (tr) =>
                tppApplyPayment(
                  tr,
                  dashState,
                  dispatch,
                  null,
                  toast,
                  description,
                  remanent < 0 && parseFloat(applyPayment.amount || 0)
                )
              )
            );
          }

          if (applyPayment.from === "patient" || applyPayment.from === "tpp") {
            let trMap = trs.reduce((ac, e) => {
              ac[e.tid] = e;
              return ac;
            }, {});

            setApplyPayment({
              ...applyPayment,
              amount: remanent > 0 ? remanent : 0,
            });

            setTransactions((prev) => {
              return prev.map((tr) => trMap[tr.tid] || tr);
            });
            setSelections(new Set());
          }
          setAllowSelection("");

          setWaiting(false);
          toast.close(generatingToast);
        }
      }
    } catch (e) {
      console.log(e);
      setSelections(new Set());
      setAllowSelection("");
      toast.close(generatingToast);
      setWaiting(false);
    }
  }

  return (
    <>
      {allowSelection === "payment" ? (
        <Box>
          <Button
            minW={"12rem"}
            bg="green.400"
            color="white"
            ml="4"
            _hover={{bg: "green.500"}}
            cursor={!waiting ? "pointer" : "not-allowed"}
            onClick={async () => {
              if (remanent > 0)
                Swal.fire({
                  title: `Unused amount $${remanent}`,
                  text: `There is still an unused amount of $${remanent} left. Are you sure you want to proceed with payment for the selected transactions?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, proceed to pay.",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    applayPayment();
                  }
                });
              else {
                applayPayment();
              }
            }}
          >
            {`Apply Payment ($${remanent > 0 ? remanent : 0})`}
          </Button>
          <Icon
            as={MdClose}
            color={"gray.400"}
            _hover={{color: "gray.700"}}
            w={6}
            h={6}
            cursor={"pointer"}
            onClick={() => {
              setApplyPayment({amount: 0, from: ""});
              setAllowSelection("");
              setSelections(new Set());
            }}
          />
        </Box>
      ) : (
        <Popover
          placement="bottom"
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={() => {
            onClose();
          }}
          //closeOnBlur={false}
        >
          <PopoverTrigger>
            <Button
              px="12"
              width={"12rem"}
              bg="blue.700"
              color="white"
              ml="4"
              _hover={{bg: "blue.800"}}
              cursor={allowSelection === "" ? "pointer" : "not-allowed"}
              onClick={() => {
                if (allowSelection === "") onToggle();
              }}
            >
              Apply Payment
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader pt={4} fontWeight="bold" border="0">
                Apply Payment
              </PopoverHeader>
              <PopoverCloseButton ref={createClose} />
              <PopoverBody>
                <InputGroup>
                  <InputLeftElement fontWeight={"bold"} ml="-3">
                    $
                  </InputLeftElement>
                  <Input
                    variant="flushed"
                    type="number"
                    placeholder="Payment Amount"
                    pl="6"
                    value={applyPayment.amount}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setApplyPayment({
                          ...applyPayment,
                          amount: "",
                        });
                        return;
                      }

                      if (
                        !isNaN(e.target.value) &&
                        parseFloat(e.target.value) >= 0
                      ) {
                        setApplyPayment({
                          ...applyPayment,
                          amount: parseFloat(e.target.value),
                        });
                      }
                    }}
                  />
                </InputGroup>

                <FormControl id="symptom" mt="4">
                  <FormLabel>Payment From?</FormLabel>
                  <Select
                    variant="flushed"
                    type="text"
                    placeholder="-"
                    border="0"
                    borderBottom="1px"
                    borderColor="gray.200"
                    value={applyPayment.from}
                    onChange={(e) =>
                      setApplyPayment({...applyPayment, from: e.target.value})
                    }
                  >
                    <option value="tpp">Third Party Payer</option>
                    <option value="patient">Patient</option>
                  </Select>
                </FormControl>
              </PopoverBody>
              <PopoverFooter border="0">
                <FormControl id="symptom" mt="4">
                  <FormLabel>Payment Type</FormLabel>
                  <RadioGroup onChange={setPaymentType} value={paymentType}>
                    <Stack direction="row">
                      <Radio value="total">Tolal</Radio>
                      <Radio value="partial">Partial</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <Flex justifyContent="end">
                  <Stack direction="row">
                    <ButtonGroup size="sm">
                      <Button
                        colorScheme="blue"
                        variant="ghost"
                        onClick={() => {
                          createClose.current.click();
                        }}
                      >
                        Go Back
                      </Button>
                      <Button colorScheme="blue" onClick={handleApplyClick}>
                        Choose
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover>
      )}
    </>
  );
}
