import React, {useState, useEffect, useRef} from "react";
import {MdClose} from "react-icons/md";
import {
  BsPersonCircle,
  BsCheckCircleFill,
  BsFillInfoCircleFill,
} from "react-icons/bs";
export default function BookNowDoctorInfo({doctor, setDoctor}) {
  useEffect(() => {
    const handleEscapeKeyDown = (e) => {
      if (e.key === "Escape") {
        setDoctor(null);
      }
    };
    window.addEventListener("keydown", handleEscapeKeyDown);
    return () => {
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, []);
  return (
    <div
      className="fixed show inset-0 bg-black/40 z-[9999] flex justify-center items-center"
      onClick={() => setDoctor(null)}
    >
      <div className="h-80  w-[400px] relative flex flex-col  items-start p-7 bg-white rounded-[1rem] ">
        {
          <MdClose
            className="text-black cursor-pointer text-lg absolute right-4 top-4 "
            onClick={() => {
              setDoctor(null);
            }}
          />
        }
        <div className="relative bg-[#FFFFFF] sm:w-[15rem]  flex  items-center rounded-[1rem] min-h-[5.5rem]  p-5  space-x-2">
          <span className="flex justify-center h-14 w-14 items-center">
            {doctor.photo ? (
              <img
                src={doctor.photo}
                alt=""
                className="hover:cursor-pointer  h-full rounded-full drop-shadow-lg"
              />
            ) : (
              <BsPersonCircle className="text-[3rem] text-gray-400" />
            )}
          </span>
          <div className=" flex  flex-col text-black">
            <p className="text-[12px] leading-4  font-semibold">
              {doctor.name}
            </p>
            <span className="font-light text-[9px]">
              {doctor.accreditations || ""}
            </span>
          </div>
        </div>
        <div className=" h-14 w-full sbar2 flex-1 p-1 border-t overflow-hidden bg-white ">
          <div className=" py-2 h-full overflow-y-scroll sbar2">
            <p className="p-2 text-black text-sm font-medium">
              {doctor.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
