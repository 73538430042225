import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";

import {BiEdit} from "react-icons/bi";
import {BsPersonCircle, BsFillArrowUpCircleFill} from "react-icons/bs";
import {GiModernCity} from "react-icons/gi";
import swal from "sweetalert";
import {ImPlus} from "react-icons/im";
import {
  Box,
  IconButton,
  Avatar,
  Tooltip,
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
  Icon,
  useToast,
  MenuList,
  Image,
  Button,
} from "@chakra-ui/react";
export default function InstanceBar({dispatch, schState}) {
  const p = window.location.pathname;

  const [selectDoctor, setSelectDoctor] = useState(false);

  return (
    <div className="flex h-[5rem]">
      <div className="flex h-full p-7 justify-center items-center relative z-50  flex-1">
        {/* <div className="z-10 flex justify-center items-center shrink-0">
          schState?.location?.logo ? (
            <img
              src={schState?.location.logo}
              alt=""
              className="h-10 sm:h-16 z-20 p-1"
            />
          ) : null
        </div>*/}

        {schState?.organization?.logo && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Image
              w="180px"
              src={schState?.organization?.logo || "images/Clinic/logo.svg"}
            />
          </Box>
        )}
      </div>
    </div>
  );
}

////111111
function LocationsPicker({dispatch, schState}) {
  const fileRef = useRef(null);
  let loc = useMemo(() => {
    return schState.locations?.find((l) => l.lid === schState.selectedLocation);
  }, [schState.selectedLocation, schState.locations]);

  const [show, setShow] = useState(false);

  return (
    <div className="relative z-50 flex  min-w-[10rem] hover:cursor-pointer h-[3rem] ">
      <div className="full z-10 flex justify-center items-center">
        {loc && loc.logo ? (
          <img
            src={loc.logo}
            alt=""
            className="h-full z-20 p-1"
            onClick={() => setShow(!show)}
          />
        ) : (
          <span
            className="z-20 relative text-[#8A99DC] bg-[#8A99DC] p-2 rounded-full text-sm flex justify-center items-center space-x-2"
            onClick={() => setShow(!show)}
          >
            <GiModernCity className="text-[1rem] text-white relative" />

            <span className="text-white text-xs">Select location</span>
          </span>
        )}
      </div>
      {show && (
        <div className="show absolute top-[4rem] bg-white  rounded-2xl">
          <ul className="h-24 overflow-y-auto space-y-2 p-2 sbar">
            {schState.locations.map((l, i) => (
              <li
                key={i}
                className="h-[2rem] overflow-hidden hover:bg-slate-200/50 p-1 rounded-lg"
                onClick={() => {
                  setShow(false);
                  if (schState.selectedLocation !== l.lid) {
                    dispatch({type: "CHANGE_LOCATION", payload: l.lid});
                  }
                }}
              >
                {l.imgList ? (
                  <img src={l.imgList} alt="" className="h-full rounded-sm" />
                ) : (
                  <span>{l.name.slice(0, 10) + "..."}</span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
