import {
  useDisclosure,
  Button,
  Text,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Input,
  useToast,
  Accordion,
  Box,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import React, {useMemo} from "react";
import {crud} from "../../../crudRequests";
import {v4} from "uuid";
import {membershipInvoice} from "../../appointments/payment/uploads";
import {alphabeticalSort} from "../../Tasks/helpers/alphabeticalSort";
export function calculateMembershipEndDate(
  startDate,
  duration,
  durationPeriod
) {
  let endDate = new Date(startDate);
  if (durationPeriod === "monthly") {
    endDate.setMonth(endDate.getMonth() + parseInt(duration));
  } else if (durationPeriod === "yearly") {
    endDate.setFullYear(endDate.getFullYear() + parseInt(duration));
  } else {
    throw new Error("Invalid duration period. Must be 'monthly' or 'yearly'.");
  }

  return endDate.getTime();
}

function PatientMembershipAssignForm(props) {
  const isValidStartDate = (input) => {
    const inputMilis = new Date(input).getTime();
    const todayDate = new Date().toISOString().split("T")[0];
    const todayMilis = new Date(todayDate).getTime();
    if (inputMilis >= todayMilis) return false;
    return true;
  };

  const orderedMemberships = useMemo(
    () => alphabeticalSort(props.selectedLocation?.memberships, "name"),
    [props.selectedLocation?.memberships]
  );

  return (
    <>
      <Select
        placeholder="Select a membership"
        value={props.formValues.selectedMembership}
        onChange={(evt) => {
          props.setFormValues({
            ...props.formValues,
            selectedMembership: evt.target.value,
          });
        }}
      >
        {orderedMemberships
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
          .filter(
            (membership) =>
              membership.status === "Active" && membership.membership === true
          )
          .map((membership) => {
            return (
              <option key={membership.mid} value={membership.mid}>
                {membership.name} - {membership.price}
              </option>
            );
          })}
      </Select>
      <Text m="1rem">
        {props.formValues.selectedMembership
          ? orderedMemberships.find(
              (membership) =>
                membership.mid === props.formValues.selectedMembership
            )?.description
          : "Membership description"}
      </Text>
      <FormControl isInvalid={isValidStartDate(props.formValues.startDate)}>
        <FormLabel>Start date</FormLabel>
        <Input
          value={props.formValues.startDate}
          placeholder="startDate"
          type="date"
          onChange={(evt) => {
            props.setFormValues({
              ...props.formValues,
              startDate: evt.target.value,
            });
          }}
        />
        {!isValidStartDate(props.formValues.startDate) ? (
          <></>
        ) : (
          <FormErrorMessage>
            Please enter a future date or today.
          </FormErrorMessage>
        )}
      </FormControl>
    </>
  );
}

export function PatientMembershipModal(props) {
  const latestPatientState = props.patient;
  const [membershipData, setMembershipData] = React.useState(null);
  const [selectedLocation, setSelectedLocation] = React.useState(null);

  React.useEffect(() => {
    const getMemberships = async () => {
      const locationResult = await crud(props.schState, [
        {
          db: props.schState.db,
          collection: "locations",
          parameters: [{lid: props.schState.selectedLocation}],
          method: "findOne",
        },
      ]);

      const selectedLocation = locationResult.data[0];

      if (!selectedLocation) {
        console.error("Location not found");
        return;
      }
      setSelectedLocation(selectedLocation);
      const membershipMap = {};

      for (const membershipOption of selectedLocation.memberships) {
        membershipMap[membershipOption.mid] = membershipOption;
      }
      setMembershipData(membershipMap);
    };
    getMemberships();
  }, [props.schState.selectedLocation]);

  const {onClose} = useDisclosure();
  const toast = useToast();
  const initialFormState = {
    selectedMembership: "",
    startDate: new Date().toISOString().split("T")[0],
  };

  const [formValues, setFormValues] = React.useState(initialFormState);

  if (!membershipData) {
    return <></>;
  }
  const amount = parseFloat(
    membershipData[formValues.selectedMembership]?.price || 0
  );
  const patientAmount = amount;
  const existingPatientMemberships = {};
  for (const patientMembership of latestPatientState.memberships || []) {
    existingPatientMemberships[patientMembership.mid]
      ? existingPatientMemberships[patientMembership.mid].push(
          patientMembership
        )
      : (existingPatientMemberships[patientMembership.mid] = [
          patientMembership,
        ]);
  }

  function handleAssignMembership() {
    return async () => {
      const startDate = new Date(formValues.startDate).getTime();
      const endDate = calculateMembershipEndDate(
        formValues.startDate,
        membershipData[formValues.selectedMembership].duration,
        membershipData[formValues.selectedMembership].durationPeriod
      );
      if (existingPatientMemberships[formValues.selectedMembership]) {
        const foundActiveMembership = existingPatientMemberships[
          formValues.selectedMembership
        ].find((memb) => {
          if (!memb.active) return false;
          return memb.startDate <= endDate && memb.endDate >= startDate;
        });
        if (foundActiveMembership)
          return toast({
            title: "Membership is already active for the selected date.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
      }
      const newMembershipId = v4();
      let {selectedServices, selectedProducts} =
        membershipData[formValues.selectedMembership];
      selectedServices = Object.fromEntries(
        Object.entries(selectedServices || {}).filter(
          ([_, v]) => v.quantity > 0
        )
      );
      selectedProducts = Object.fromEntries(
        Object.entries(selectedProducts || {}).filter(
          ([_, v]) => v.quantity > 0
        )
      );

      let membershipTaxRate = null;
      let membershipTaxAmount = null;

      if (membershipData[formValues.selectedMembership].taxable) {
        membershipTaxRate = selectedLocation.membershipTaxRate || 0;
        membershipTaxAmount = ((amount * membershipTaxRate) / 100).toFixed(2);
      }

      const newMembershipData = {
        ...membershipData[formValues.selectedMembership],
        uuid: newMembershipId,
        startDate,
        endDate,
        status: "Pending",
        selectedServices,
        selectedProducts,
        membershipTaxAmount,
        acumulatedPrice:
          parseFloat(amount) + parseFloat(membershipTaxAmount || 0),
      };

      const invoiceId = id(8);
      const receiptId = id(8);
      let transaction = {
        type: "membership",
        tid: v4(),
        membershipUuid: newMembershipId,
        invoiceId: invoiceId,
        receiptId: receiptId,
        lid: selectedLocation.lid,
        locationName: selectedLocation.name,
        pid: props.patient.pid,
        pName: props.patient.fName + " " + props.patient.lName,
        pEmail: props.patient.email,
        claim: false,
        amount,
        memberships: [newMembershipData],
        membershipsAmount: amount,
        serviceAmount: 0,
        serviceTax: 0,
        products: [],
        productTax: 0,
        productAmount: 0,
        paid: 0,
        pending: true,
        cancelled: false,
        insurance: props.patient.insurance,
        dgCodes: [],
        billingCodes: [],
        tppAmount: 0,
        patientAmount: amount,
        amountPaidByPatient: 0,
        amountPaidByTpp: 0,
        tppPaymentStatus: "paid",
        patientPaymentStatus: patientAmount > 0 ? "pending" : "paid",
        paymentStatus: "pending",
        serviceDate: new Date().getTime(),
        membershipTaxRate,
        membershipTaxAmount,
      };

      await membershipInvoice({
        state: props.schState,
        transaction,
        patient: props.patient,
      });

      await crud(
        props.schState,
        [
          {
            db: props.schState.db,
            collection: "patients",
            parameters: [
              {pid: props.patient.pid},
              {
                $push: {
                  memberships: newMembershipData,
                },
              },
            ],
            method: "updateOne",
          },
          {
            db: props.schState.db,
            collection: "locations",
            parameters: [
              {lid: selectedLocation.lid},
              {
                $set: {
                  "memberships.$[membership].attachedToPatient": true,
                },
              },
              {
                arrayFilters: [
                  {"membership.mid": formValues.selectedMembership},
                ],
              },
            ],
            method: "updateOne",
          },
          {
            db: props.schState.db,
            collection: "billing",
            parameters: [transaction],
            method: "insertOne",
          },
        ],
        null
      );

      props.dispatch({
        type: "UPDATE_PATIENT",
        pid: props.patient.pid,
        payload: {
          memberships: (props.patient?.memberships || []).concat(
            newMembershipData
          ),
        },
        options: null,
      });
      props.setPatient((prev) => ({
        ...prev,
        memberships: (prev.memberships || []).concat(newMembershipData),
      }));
      props.setPatientMemberships((prev) => prev.concat(newMembershipData));
      setFormValues({...initialFormState});
      toast({
        title: "Membership assigned.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    };
  }

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <h2>
          <AccordionButton width="1/2">
            <Box as="span" flex="1" textAlign="left">
              Assign membership
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack>
            <PatientMembershipAssignForm
              formValues={formValues}
              setFormValues={setFormValues}
              selectedLocation={selectedLocation}
              membershipData={membershipData}
            />
            <Button
              m="1rem"
              onClick={handleAssignMembership()}
              isDisabled={!formValues.selectedMembership}
            >
              Assign
            </Button>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
export function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}
