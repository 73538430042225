import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Box,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
import {PrepaymentDetails} from "./PrepaymentDetails";

import {PrepaymentFileButton} from "./PrepaymentFileButton";
import {useState} from "react";
import PreviewFile from "../../appointments/payment/helpers/PreviewFile";

export const PrepaymentsTable = ({
  prepayments = [],
  patientId,
  state,
  isIndividualPrepayment = false,
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedPrepayment, setSelectedPrepayment] = useState(null);
  const [preview, setPreview] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const paymentsMethods = {
    cash: "Cash",
    creditCard: "Credit Card",
    check: "Check",
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPrepayments = prepayments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(prepayments.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  return (
    <Box>
      <TableContainer
        flex="1"
        borderColor="gray.50"
        borderRadius="md"
        boxShadow="md"
        overflowX="auto"
        height={isIndividualPrepayment ? "auto" : "420px"}
        overflowY="auto"
        position="relative"
      >
        <Table>
          <Thead bg="gray.100" position="sticky" top={0} zIndex={2}>
            <Tr>
              <Th textAlign="center">Number</Th>
              <Th textAlign="center">Date</Th>
              <Th textAlign="center">Amount</Th>
              <Th textAlign="center">Balance Available</Th>
              <Th textAlign="center">Payment Method</Th>
              <Th textAlign="center">Document</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentPrepayments.map((prepayment, index) => (
              <Tr
                key={prepayment.id}
                onClick={() => {
                  if (!isIndividualPrepayment) {
                    setSelectedPrepayment(prepayment);
                    onOpen();
                  }
                }}
                cursor={!isIndividualPrepayment ? "pointer" : "default"}
                _hover={!isIndividualPrepayment && {bg: "gray.50"}}
                position="relative"
              >
                <Td textAlign="center">
                  {prepayment.prepaymentNumber ||
                    String(indexOfFirstItem + index + 1).padStart(5, "0")}
                </Td>
                <Td textAlign="center">
                  {new Date(prepayment.createdAt).toLocaleDateString()}
                </Td>
                <Td textAlign="center">${prepayment.amount.toFixed(2)}</Td>
                <Td textAlign="center">
                  ${parseFloat(prepayment?.balanceAvailable)?.toFixed(2) || 0.0}
                </Td>
                <Td textAlign="center">
                  {paymentsMethods[prepayment.paymentMethod]}
                </Td>
                <Td textAlign="center" position="relative">
                  <Box position="static">
                    <PrepaymentFileButton
                      prepayment={prepayment}
                      patientId={patientId}
                      state={state}
                      setPreview={setPreview}
                    />
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {prepayments.length > 0 && !isIndividualPrepayment && (
        <Flex justifyContent="space-between" alignItems="center" mt={4} px={2}>
          <Text fontSize="sm">
            Showing {indexOfFirstItem + 1} to{" "}
            {Math.min(indexOfLastItem, prepayments.length)} of{" "}
            {prepayments.length} entries
          </Text>
          <Flex>
            <Button
              size="sm"
              onClick={goToPreviousPage}
              isDisabled={currentPage === 1}
              mr={2}
              colorScheme="blue"
              variant="outline"
            >
              Previous
            </Button>
            <Text mx={2} alignSelf="center">
              Page {currentPage} of {totalPages}
            </Text>
            <Button
              size="sm"
              onClick={goToNextPage}
              isDisabled={currentPage === totalPages}
              ml={2}
              colorScheme="blue"
              variant="outline"
            >
              Next
            </Button>
          </Flex>
        </Flex>
      )}

      <PrepaymentDetails
        prepayment={selectedPrepayment}
        isOpen={isOpen}
        onClose={onClose}
        patientId={patientId}
        state={state}
      />
      {preview && (
        <PreviewFile preview={preview} setPreview={setPreview} state={state} />
      )}
    </Box>
  );
};
