import React, {useState, useEffect, useMemo, useRef} from "react";
import {Flex, Center} from "@chakra-ui/react";
import {createHashTable} from "../../utils/createHashTable";
import AddTask from "./AddTask";
import {getTasks} from "./helpers/getTasks";
import {alphabeticalSort} from "./helpers/alphabeticalSort";
import GridTable from "./GridTable";
import FiltersTasks from "./FiltersTasks";
import {crud} from "../../crudRequests";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import {AddTasksMobile} from "./AddTasksMobile";
import TaskCardsMobile from "./TaskCardsMobile";

export default function TasksTable({dashState, tasks, setTasks}) {
  const [isLoading, setIsLoading] = useState(false);
  const [allDoctors, setAllDoctors] = useState([]);
  const [admins, setAdmins] = useState([]);

  const doctors = useMemo(
    () => createHashTable(allDoctors, "did"),
    [allDoctors]
  );
  const doctorsArr = useMemo(
    () => alphabeticalSort(allDoctors, "lastName"),
    [allDoctors]
  );
  const patients = useMemo(
    () => createHashTable(dashState.patients, "pid"),
    [dashState.patients]
  );
  const patientsArr = useMemo(
    () => alphabeticalSort(dashState.patients ?? [], "lName"),
    [dashState.patients]
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState({column: "due", direction: "asc"});
  const showCompletedRef = useRef(true);
  const isMobile = useCheckMobileScreen();

  const getDoctors = async (state) => {
    try {
      const requestObjs = [
        {
          db: state.db || "blh",
          collection: "doctors",
          parameters: [
            {
              $project: {
                did: 1,
                firstName: 1,
                lastName: 1,
              },
            },
          ],
          method: "aggregate",
        },
      ];

      const res = await crud(state, requestObjs, null, null);
      return res.data[0];
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const getAdmins = async (state) => {
    try {
      const requestObjs = [
        {
          db: state.db || "blh",
          collection: "admins",
          parameters: [
            {
              $project: {
                id: 1,
                name: 1,
                firstName: 1,
                lastName: 1,
              },
            },
          ],
          method: "aggregate",
        },
      ];

      const res = await crud(state, requestObjs, null, null);
      return res.data[0];
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const fetchTasks = async (
    isAppendingTasks = false,
    query = "",
    page = 1,
    showCompleted = true,
    column,
    order
  ) => {
    try {
      setIsLoading(true);
      const data = await getTasks(
        dashState,
        page,
        query,
        null,
        showCompleted,
        column,
        order
      );
      isAppendingTasks
        ? setTasks((prevTasks) => [...prevTasks, ...data])
        : setTasks(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  };

  const groupedOptions = useMemo(() => {
    return [
      {
        label: "Clinicians",
        options: doctorsArr.map((option) => ({
          value: option.did,
          label: `${option.lastName}, ${option.firstName}`,
        })),
      },
      {
        label: "Admins",
        options: admins.map((option) => ({
          value: option.id,
          label: `${option.lastName}, ${option.firstName}`,
        })),
      },
    ];
  }, [doctorsArr, admins]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const doctors = await getDoctors(dashState);
      setAllDoctors(doctors);

      const adminsData = await getAdmins(dashState);
      adminsData.map((admin) => {
        if (!admin.lastName) {
          admin.lastName = admin.name.split(" ")[1] || "";
          admin.firstName = admin.name.split(" ")[0];
        }
        return admin;
      });
      setAdmins(alphabeticalSort(adminsData, "lastName"));

      fetchTasks();
      setIsLoading(false);
    };

    fetchData();
  }, [dashState.selectedLocation]);

  return (
    <>
      <Flex
        px={isMobile ? "0" : "4"}
        gap={isMobile ? "4" : "8"}
        w="100%"
        pt="4"
        pb="2"
      >
        {isMobile ? (
          <AddTasksMobile
            dashState={dashState}
            patients={patients}
            setTasks={setTasks}
            doctors={doctors}
            groupedOptions={groupedOptions}
            patientsArr={patientsArr}
            admins={admins}
          />
        ) : (
          <AddTask
            dashState={dashState}
            patients={patients}
            setTasks={setTasks}
            doctors={doctors}
            groupedOptions={groupedOptions}
            patientsArr={patientsArr}
            admins={admins}
          />
        )}
        <FiltersTasks
          fetchTasks={fetchTasks}
          tasks={tasks}
          sort={sort}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          showCompletedRef={showCompletedRef}
          isPatient={false}
          isMobile={isMobile}
        />
      </Flex>
      {isMobile ? (
        <TaskCardsMobile
          dashState={dashState}
          fetchTasks={fetchTasks}
          searchQuery={searchQuery}
          isLoading={isLoading}
          tasks={tasks}
          setTasks={setTasks}
          sort={sort}
          setSort={setSort}
          doctors={doctors}
          groupedOptions={groupedOptions}
          patients={patients}
          patientsArr={patientsArr}
          showCompletedRef={showCompletedRef}
          admins={admins}
          isMobile={isMobile}
        />
      ) : (
        <GridTable
          dashState={dashState}
          fetchTasks={fetchTasks}
          searchQuery={searchQuery}
          isLoading={isLoading}
          tasks={tasks}
          setTasks={setTasks}
          sort={sort}
          setSort={setSort}
          doctors={doctors}
          groupedOptions={groupedOptions}
          patients={patients}
          patientsArr={patientsArr}
          showCompletedRef={showCompletedRef}
          admins={admins}
          isMobile={isMobile}
        />
      )}
    </>
  );
}
