import {Button, useToast} from "@chakra-ui/react";
import {useState} from "react";
import {RiFileExcel2Line} from "react-icons/ri";
import getExcel from "./helpers/getExcel";

export const GroupedTransactionExporter = ({
  dashState,
  groupings,
  fields,
  aggregatedFields,
  transactionMap,
  groupedTransactions,
}) => {
  const [exportLoading, setExportLoading] = useState(false);
  const toast = useToast();
  return (
    <Button
      ml="4"
      leftIcon={<RiFileExcel2Line />}
      isLoading={exportLoading}
      onClick={() => {
        setExportLoading(true);
        let location = dashState.locations.find(
          (loc) => (loc.lid = dashState.selectedLocation)
        );
        const tableHeaders = [];
        groupings.forEach((grouping) => {
          tableHeaders.push(grouping.label);
        });
        tableHeaders.push("Invoice ID");
        if (fields["Patient Amount"]) {
          tableHeaders.push("Patient amount");
        }
        if (fields["Patient Amount Paid"]) {
          tableHeaders.push("Paid by patient");
        }
        if (fields["Patient Outstanding Balance"]) {
          tableHeaders.push("Patient balance");
        }
        if (fields["TPP Amount"]) {
          tableHeaders.push("TPP amount");
        }
        if (fields["Insurer Amount Paid"]) {
          tableHeaders.push("Paid by TPP");
        }
        if (fields["Insurer Outstanding Balance"]) {
          tableHeaders.push("TPP balance");
        }
        if (fields["Tax Cost to Patient"]) {
          tableHeaders.push("Patient tax cost");
        }
        if (fields["Tax Cost to Insurer"]) {
          tableHeaders.push("TPP tax cost");
        }
        tableHeaders.push("Total balance");
        tableHeaders.push("Total paid");
        tableHeaders.push("Total amount");
        Object.keys(fields).forEach((key) => {
          if (groupings.some((grouping) => grouping.label === key)) {
            return;
          }
          if (aggregatedFields.includes(key)) {
            return;
          }
          const fieldLabel = key;
          tableHeaders.push(fieldLabel);
        });

        const tableData = [];
        const processRow = (row) => {
          const tableRow = [];
          const hasChildGroups = Object.keys(row.groups).length > 0;
          row.path.forEach((grouping) => {
            tableRow.push({type: "string", value: grouping});
          });
          const depthDifference = groupings.length + 1 - row.path.length;
          for (let i = 0; i < depthDifference; i++) {
            tableRow.push({
              type: "string",
              value: "",
            });
          }
          if (fields["Patient Amount"]) {
            const patientAmount = parseFloat(
              row.aggregatedData.patientAmount || 0
            );
            tableRow.push({
              type: "number",
              value: isNaN(patientAmount) ? 0 : patientAmount,
            });
          }
          if (fields["Patient Amount Paid"]) {
            const amountPaidByPatient = parseFloat(
              row.aggregatedData.amountPaidByPatient || 0
            );
            tableRow.push({
              type: "number",
              value: isNaN(amountPaidByPatient) ? 0 : amountPaidByPatient,
            });
          }
          if (fields["Patient Outstanding Balance"]) {
            const patientAmount = parseFloat(
              row.aggregatedData.patientAmount || 0
            );
            const amountPaidByPatient = parseFloat(
              row.aggregatedData.amountPaidByPatient || 0
            );
            const balance = patientAmount - amountPaidByPatient;
            tableRow.push({
              type: "number",
              value: isNaN(balance) ? 0 : balance,
            });
          }
          if (fields["TPP Amount"]) {
            const tppAmount = parseFloat(row.aggregatedData.tppAmount || 0);
            tableRow.push({
              type: "number",
              value: isNaN(tppAmount) ? 0 : tppAmount,
            });
          }
          if (fields["Insurer Amount Paid"]) {
            const amountPaidByTpp = parseFloat(
              row.aggregatedData.amountPaidByTpp || 0
            );
            tableRow.push({
              type: "number",
              value: isNaN(amountPaidByTpp) ? 0 : amountPaidByTpp,
            });
          }
          if (fields["Insurer Outstanding Balance"]) {
            const tppAmount = parseFloat(row.aggregatedData.tppAmount || 0);
            const amountPaidByTpp = parseFloat(
              row.aggregatedData.amountPaidByTpp || 0
            );
            const balance = tppAmount - amountPaidByTpp;
            tableRow.push({
              type: "number",
              value: isNaN(balance) ? 0 : balance,
            });
          }
          if (fields["Tax Cost to Patient"]) {
            const patientTaxCost = parseFloat(
              row.aggregatedData.patientTaxCost || 0
            );
            tableRow.push({
              type: "number",
              value: isNaN(patientTaxCost) ? 0 : patientTaxCost,
            });
          }
          if (fields["Tax Cost to Insurer"]) {
            const tppTaxCost = parseFloat(row.aggregatedData.tppTaxCost || 0);
            tableRow.push({
              type: "number",
              value: isNaN(tppTaxCost) ? 0 : tppTaxCost,
            });
          }
          const patientAmount = parseFloat(
            row.aggregatedData.patientAmount || 0
          );
          const amountPaidByPatient = parseFloat(
            row.aggregatedData.amountPaidByPatient || 0
          );
          const tppAmount = parseFloat(row.aggregatedData.tppAmount || 0);
          const amountPaidByTpp = parseFloat(
            row.aggregatedData.amountPaidByTpp || 0
          );
          const totalBalance =
            patientAmount - amountPaidByPatient + (tppAmount - amountPaidByTpp);
          const totalPaid = amountPaidByPatient + amountPaidByTpp;
          const totalAmount = patientAmount + tppAmount;
          tableRow.push({
            type: "number",
            value: isNaN(totalBalance) ? 0 : totalBalance,
          });
          tableRow.push({
            type: "number",
            value: isNaN(totalPaid) ? 0 : totalPaid,
          });
          tableRow.push({
            type: "number",
            value: isNaN(totalAmount) ? 0 : totalAmount,
          });
          tableData.push(tableRow);
          if (hasChildGroups) {
            Object.keys(row.groups).forEach((key) => {
              processRow(row.groups[key]);
            });
          } else {
            row.transactionIds.forEach((tid) => {
              const transaction = transactionMap[tid];
              const tableRow = [];
              groupings.forEach((grouping, index) => {
                tableRow.push({type: "string", value: row.path[index]});
              });
              tableRow.push({type: "string", value: transaction.invoiceId});
              if (fields["Patient Amount"]) {
                const patientAmount = parseFloat(
                  transaction.patientAmount || 0
                );
                tableRow.push({
                  type: "number",
                  value: isNaN(patientAmount) ? 0 : patientAmount,
                });
              }
              if (fields["Patient Amount Paid"]) {
                const amountPaidByPatient = parseFloat(
                  transaction.amountPaidByPatient || 0
                );
                tableRow.push({
                  type: "number",
                  value: isNaN(amountPaidByPatient) ? 0 : amountPaidByPatient,
                });
              }
              if (fields["Patient Outstanding Balance"]) {
                const patientAmount = parseFloat(
                  transaction.patientAmount || 0
                );
                const amountPaidByPatient = parseFloat(
                  transaction.amountPaidByPatient || 0
                );
                const balance = patientAmount - amountPaidByPatient;
                tableRow.push({
                  type: "number",
                  value: isNaN(balance) ? 0 : balance,
                });
              }
              if (fields["TPP Amount"]) {
                const tppAmount = parseFloat(transaction.tppAmount || 0);
                tableRow.push({
                  type: "number",
                  value: isNaN(tppAmount) ? 0 : tppAmount,
                });
              }
              if (fields["Insurer Amount Paid"]) {
                const amountPaidByTpp = parseFloat(
                  transaction.amountPaidByTpp || 0
                );
                tableRow.push({
                  type: "number",
                  value: isNaN(amountPaidByTpp) ? 0 : amountPaidByTpp,
                });
              }
              if (fields["Insurer Outstanding Balance"]) {
                const tppAmount = parseFloat(transaction.tppAmount || 0);
                const amountPaidByTpp = parseFloat(
                  transaction.amountPaidByTpp || 0
                );
                const balance = tppAmount - amountPaidByTpp;
                tableRow.push({
                  type: "number",
                  value: isNaN(balance) ? 0 : balance,
                });
              }
              if (fields["Tax Cost to Patient"]) {
                const patientTaxCost = parseFloat(
                  transaction.patientTaxCost || 0
                );
                tableRow.push({
                  type: "number",
                  value: isNaN(patientTaxCost) ? 0 : patientTaxCost,
                });
              }
              if (fields["Tax Cost to Insurer"]) {
                const tppTaxCost = parseFloat(transaction.tppTaxCost || 0);
                tableRow.push({
                  type: "number",
                  value: isNaN(tppTaxCost) ? 0 : tppTaxCost,
                });
              }
              const patientAmount = parseFloat(transaction.patientAmount || 0);
              const amountPaidByPatient = parseFloat(
                transaction.amountPaidByPatient || 0
              );
              const tppAmount = parseFloat(transaction.tppAmount || 0);
              const amountPaidByTpp = parseFloat(
                transaction.amountPaidByTpp || 0
              );
              const totalBalance =
                patientAmount -
                amountPaidByPatient +
                (tppAmount - amountPaidByTpp);
              const totalPaid = amountPaidByPatient + amountPaidByTpp;
              const totalAmount = patientAmount + tppAmount;
              tableRow.push({
                type: "number",
                value: isNaN(totalBalance) ? 0 : totalBalance,
              });
              tableRow.push({
                type: "number",
                value: isNaN(totalPaid) ? 0 : totalPaid,
              });
              tableRow.push({
                type: "number",
                value: isNaN(totalAmount) ? 0 : totalAmount,
              });
              Object.keys(fields).forEach((key) => {
                if (groupings.some((grouping) => grouping.label === key)) {
                  return;
                }
                if (aggregatedFields.includes(key)) {
                  return;
                }
                const fieldData = fields[key](transaction);
                tableRow.push({
                  type: "string",
                  value: fieldData.uiValue,
                });
              });
              tableData.push(tableRow);
            });
          }
        };
        Object.keys(groupedTransactions.groups).forEach((key) => {
          processRow(groupedTransactions.groups[key]);
        });
        tableData.unshift(tableHeaders);
        const currentDate = new Date();
        const formattedDate = currentDate
          .toISOString()
          .replace(/[-:]/g, "_")
          .replace("T", "_")
          .split(".")[0];
        const customName = `Billing_${formattedDate}`;
        getExcel({
          state: dashState,
          table: tableData,
          location,
          toast,
          customName,
        });
        setExportLoading(false);
      }}
      colorScheme="teal"
      variant="solid"
    >
      Export to excel
    </Button>
  );
};
