import {TiMessages} from "react-icons/ti";
import {MdNotifications, MdOutlineClose} from "react-icons/md";
import {GoCalendar} from "react-icons/go";
import {useEffect, useRef, useState} from "react";
import {FaWpforms} from "react-icons/fa";
import {crud} from "../crudRequests";
import {FiMenu, FiBell, FiChevronDown} from "react-icons/fi";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  Center,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import {
  startOfWeek,
  endOfWeek,
  isWithinInterval,
  isSameDay,
  lightFormat,
  startOfMonth,
  endOfMonth,
} from "date-fns";

export default function Notification({schState, dispatch}) {
  const cont = useRef(
    (schState.notifications || []).reduce((ac, e) => ac + (e.read ? 0 : 1), 0)
  );
  cont.current = (schState.notifications || []).reduce(
    (ac, e) => ac + (e.read ? 0 : 1),
    0
  );

  const onClose = () => {
    setShowList(false);
    if (cont.current > 0) {
      let userId =
        schState.userType === "admin"
          ? schState.selectedLocation + schState.admin?.oid
          : schState.doctor.did;
      dispatch({type: "READ_NOTIFICATIONS"});
      crud(schState, [
        {
          db: schState.db,
          collection: "notifications",
          parameters: [
            {userType: schState.userType},
            {$set: {[`${userId}.$[].read`]: true}},
          ],
          method: "findOneAndUpdate",
        },
      ]);
    }
  };
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    let f = (e) => {
      setShowList(false);
    };
    window.addEventListener("click", f);
    return () => {
      window.removeEventListener("click", f);
    };
  }, []);

  return (
    <Popover
      placement="bottom"
      zIndex={9}
      isOpen={showList}
      onClose={onClose}
      border="1px solid red"
      w="12rem"
    >
      <PopoverTrigger>
        <Center position="relative" h={10} w={8}>
          <IconButton
            fontSize="20px"
            onClick={(e) => {
              e.stopPropagation();
              schState.notifications.length > 0 && setShowList(!showList);
            }}
            _hover={{bg: "transparent"}}
            cursor="pointer"
            variant="ghost"
            aria-label="notifications"
            icon={<FiBell />}
          />

          {cont.current > 0 && (
            <span className="absolute top-[2px] left-[50%] h-[2px]  p-[7px] rounded-full flex justify-center items-center leading-[8px] font-medium bg-[#3182ce] text-[8px] text-white">
              {cont.current}
            </span>
          )}
        </Center>
      </PopoverTrigger>
      <PopoverContent
        p={0}
        bg="transparent"
        border={"none"}
        shadow={"none"}
        zIndex={9}
      >
        <PopoverBody
          p={0}
          zIndex={9}
          bg="transparent"
          shadow={"none"}
          border={"none"}
        >
          <NotificationList {...{schState, dispatch, cont, setShowList}} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function NotificationList({schState, dispatch, cont, setShowList}) {
  useEffect(() => {
    return () => {
      setShowList(false);
      if (cont.current > 0) {
        let userId =
          schState.userType === "admin"
            ? schState.selectedLocation + schState.admin?.oid
            : schState.doctor.did;
        dispatch({type: "READ_NOTIFICATIONS"});
        crud(schState, [
          {
            db: schState.db,
            collection: "notifications",
            parameters: [
              {userType: schState.userType},
              {$set: {[`${userId}.$[].read`]: true}},
            ],
            method: "findOneAndUpdate",
          },
        ]);
      }
    };
  }, []);

  return (
    <div className="show   flex items-center flex-col space-y-2">
      <p
        className="rounded-full self-end p-2 mr-8 leading-[5px] text-[10px] text-[#FFF] font-semibold bg-[#3182ce] cursor-pointer"
        onClick={(e) => {
          let userId =
            schState.userType === "admin"
              ? schState.selectedLocation + schState.admin?.oid
              : schState.doctor.did;
          e.stopPropagation();
          dispatch({type: "CLEAR_NOTIFICATIONS"});
          setShowList(false);
          crud(schState, [
            {
              db: schState.db,
              collection: "notifications",
              parameters: [
                {userType: schState.userType},
                {$set: {[userId]: []}},
              ],
              method: "updateOne",
            },
          ]);
        }}
      >
        Clear All
      </p>

      <ul className="overflow-y-scroll board p-2     max-h-60 space-y-2 rounded-lg  bg-white/50">
        {(schState.notifications || []).map((ntf, i) =>
          notificationsFrmatter(ntf, i, schState, dispatch)
        )}
      </ul>
    </div>
  );
}

let colorsMap = {
  "No Show": "#000000",
  Cancelled: "#FF0000",
  Confirmed: "#3182ce",
  Rescheduled: "#FF9900",
  Unconfirmed: "#8F8CFF",
};

function notificationsFrmatter(ntf, idx, schState, dispatch) {
  const onDelete = (e) => {
    e.stopPropagation();
    dispatch({type: "DELETE_NOTIFICATION", payload: {ntfId: ntf.ntfId}});
    let userId =
      schState.userType === "admin"
        ? schState.selectedLocation + schState.admin?.oid
        : schState.doctor.did;

    crud(schState, [
      {
        db: schState.db,
        collection: "notifications",
        parameters: [
          {userType: schState.userType},
          {$pull: {[userId]: {ntfId: ntf.ntfId}}},
        ],
        method: "updateOne",
      },
    ]);
  };

  switch (ntf.type) {
    case "New appointment":
      return (
        <li
          key={idx}
          style={{backgroundColor: !ntf.read ? "#eff6ff" : null}}
          className="bg-white h-14 relative shadow-ntf rounded-md flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#3182ce]/75">
            {lightFormat(new Date(ntf.creationDate), "h:mm aa")}
          </span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#3182ce] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>
          <div className="bg-[#3182ce] flex text-lg h-8 w-8 shrink-0 rounded-full text-white justify-center items-center">
            <GoCalendar />
          </div>

          <div className="text-[#3182ce]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Interaction with P. ${ntf.pName} `}</span>
              <span
                style={{color: colorsMap[ntf.status]}}
              >{`${ntf.status} `}</span>{" "}
              for
              <span className="font-semibold">{` ${dateFormatter(
                ntf.apptDate
              )}`}</span>
            </p>
          </div>
        </li>
      );

    case "Form Completed":
      return (
        <li
          key={idx}
          style={{backgroundColor: !ntf.read ? "#eff6ff" : null}}
          className="bg-white h-16 relative shadow-ntf rounded-md flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#3182ce]/75">
            {lightFormat(new Date(ntf.completedDate), "h:mm aa")}
          </span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#3182ce] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>
          <div className="bg-[#3182ce] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <FaWpforms />
          </div>

          <div className="text-[#3182ce]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Form`}</span>
              <span className="font-semibold">{` ${ntf.formTitle} `}</span>
              <span>{`completed by ${ntf.pName}. `}</span>
            </p>
          </div>
        </li>
      );

    case "Updated appointment":
      return (
        <li
          key={idx}
          style={{backgroundColor: !ntf.read ? "#eff6ff" : null}}
          className="bg-white  max-w-[16rem] relative shadow-ntf rounded-md flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] font-semibold right-4 text-[10px] text-[#3f3f46]">
            {lightFormat(new Date(ntf.creationDate), "h:mm aa")}
          </span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#3182ce] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>

          <div className="bg-[#3182ce] flex text-lg h-7 w-7 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <GoCalendar />
          </div>

          <div className="text-[#3f3f46] flex-1">
            <p className="text-[#3182ce] font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px] whitespace-pre-wrap">
              <span className="text-[#3182ce]">{`Interaction with P. ${ntf.pName} `}</span>
              <span
                style={{color: colorsMap[ntf.status]}}
              >{`${ntf.status} `}</span>{" "}
              for
              <span className="font-semibold">{` ${dateFormatter(
                ntf.apptDate
              )}`}</span>
            </p>
          </div>
        </li>
      );

    case "New chatbot intake":
      return (
        <li
          key={idx}
          style={{backgroundColor: !ntf.read ? "#eff6ff" : null}}
          className="bg-white h-14 relative shadow-ntf rounded-md flex text-sm items-center space-x-2 p-2 cursor-pointer group"
          onClick={(e) => e.stopPropagation()}
        >
          <span className="absolute top-[2px] right-4 text-[10px] text-[#3182ce]/75">
            {lightFormat(new Date(ntf.creationDate), "h:mm aa")}
          </span>

          <span
            className="hidden absolute show -top-1 -left-3 h-4 w-4 rounded-full  leading-[2px] p-[2px] text-[10px] group-hover:flex justify-center items-center bg-[#3182ce] text-white text-lg"
            onClick={onDelete}
          >
            <MdOutlineClose />
          </span>

          <div className="bg-[#3182ce] flex text-lg h-8 w-8 shrink-0 rounded-full text-[#DFDEFF] justify-center items-center">
            <TiMessages />
          </div>

          <div className="text-[#3182ce]/75">
            <p className="font-semibold">{ntf.type}</p>
            <p className="text-[10px] leading-[12px]">
              <span>{`Interaction registered for P. ${ntf.pName}`}</span>
            </p>
          </div>
        </li>
      );

    default:
      break;
  }
}
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function dateFormatter(date) {
  date = new Date(date);
  return `${months[date.getMonth()]} ${date.getDate()} at ${lightFormat(
    date,
    "h:mm aaa"
  )}`;
}
