import reportWebVitals from "./reportWebVitals";
import "./index.css";
import ReactDOM from "react-dom/client";

import {BrowserRouter, Routes, Route} from "react-router-dom";

import Schedule from "./pages/Schedule";
import Patients from "./pages/Patients";
import User from "./pages/User";
import Login from "./pages/Login";
import Billing from "./pages/Billing";
import Permissions from "./pages/Permissions";
import Clinic from "./pages/Clinic";
import Admin from "./pages/Admin";
import Faxes from "./pages/Faxes";
import Forms from "./pages/Forms";
import Services from "./pages/Services";
import Clinicians from "./pages/Clinicians";
import Locations from "./pages/Locations";
import Insurers from "./pages/Insurers";
import Rooms from "./pages/Rooms";
import ProvidersType from "./pages/ProviderTypes";
import BookNow from "./pages/bookNow/BookNow";
import AppointmentConfirmation from "./pages/AppointmentConfirmation";

import {ChakraProvider} from "@chakra-ui/react";
import Products from "./pages/Products";
import Memberships from "./pages/Memberships";
import Tasks from "./pages/Tasks";
import Reports from "./pages/Reports";
import GiftCards from "./pages/GiftCards";
import Discounts from "./pages/Discounts";
import React, {useEffect} from "react";
import {useToast} from "@chakra-ui/react";
import {setToastInstance} from "./toastService";
import PreventBackNavigationProvider from "./hooks/PreventBackNavigationProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ChakraProvider>
      <InitializeToast />
      <PreventBackNavigationProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route
              path="/confirmation"
              element={<AppointmentConfirmation />}
            ></Route>
            <Route
              path="/onlineBooking/:location"
              element={<BookNow />}
            ></Route>
            <Route path="/" element={<Schedule />}></Route>
            <Route path="/patients" element={<Patients />}></Route>
            <Route path="/user" element={<User />}></Route>
            <Route path="/tasks" element={<Tasks />}></Route>
            <Route path="/admin" element={<Admin />}></Route>
            <Route path="/forms" element={<Forms />}></Route>
            <Route path="/billing" element={<Billing />} />
            <Route path="/products" element={<Products />} />

            <Route path="/faxes" element={<Faxes />} />
            <Route path="/permissions" element={<Permissions />} />
            <Route path="/services" element={<Services />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/insurers" element={<Insurers />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/provider_types" element={<ProvidersType />} />
            <Route path="/clinicians" element={<Clinicians />} />
            <Route path="/clinic" element={<Clinic />} />
            <Route path="/memberships" element={<Memberships />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/giftCards" element={<GiftCards />} />
            <Route path="/discounts" element={<Discounts />} />
          </Routes>
        </BrowserRouter>
      </PreventBackNavigationProvider>
    </ChakraProvider>
  </>
);

function InitializeToast() {
  const toast = useToast();

  useEffect(() => {
    setToastInstance(toast);
  }, [toast]);

  return null;
}

reportWebVitals();
