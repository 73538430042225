import {FiMenu, FiBell, FiChevronDown} from "react-icons/fi";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  Icon,
  MenuList,
} from "@chakra-ui/react";
import {logoutUser} from "../../authRequests";
import {MdOutlineLogout} from "react-icons/md";
import Notifications from "../Notifications";
import InstanceBar from "../InstanceBar";
import {FaxNotifications} from "../FaxNotifications";
export default function TopBar({state, dispatch, onOpen, ...rest}) {
  let user = state?.userType === "admin" ? state?.admin : state?.doctor;
  return (
    <Flex
      ml={{base: 0, md: "12rem"}}
      px={{base: 4, md: 4}}
      height="6rem"
      alignItems="center"
      bg={"white"}
      zIndex={9}
      borderBottomWidth="1px"
      borderBottomColor={"gray.200"}
      justifyContent={{base: "space-between"}}
      {...rest}
    >
      <IconButton
        display={{base: "flex", md: "none"}}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <InstanceBar
        {...{
          dispatch,
          schState: state,
          style: {marginLeft: "0px"},
        }}
      />

      <HStack
        spacing={0}
        padding={0}

        //justifyContent={"space-between"}
      >
        <Notifications {...{dispatch, schState: state}} />
        <FaxNotifications {...{dispatch, schState: state}} />

        <Flex ml={2} alignItems={"center"}>
          <Menu>
            <MenuButton transition="all 0.3s" _focus={{boxShadow: "none"}}>
              <HStack>
                <Avatar
                  size={"sm"}
                  name={user?.name}
                  bg="blue.500"
                  src={user?.photo}
                />
                <VStack
                  display={{base: "none", md: "flex"}}
                  alignItems="flex-start"
                  spacing="0.5px"
                  ml="2"
                >
                  <Text fontSize="sm">
                    {state?.userType === "admin"
                      ? state?.admin?.name
                      : state?.doctor?.name}
                  </Text>
                  <Text fontSize="xs" color="gray.600">
                    {state?.userType === "admin"
                      ? state?.admin?.email || state?.admin?.phone
                      : state?.doctor?.email ||
                        state?.doctor?.phone ||
                        "No contact info"}
                  </Text>
                </VStack>
                <Box display={{base: "none", md: "flex"}}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList borderColor={"gray.200"} zIndex={100}>
              <Box bg="white" p="4" fontSize={"14px"}>
                <Text fontSize={"18px"}>Account</Text>
                <Flex color="gray.700" fontWeight={"medium"} mt="5">
                  ID #: &nbsp;
                  <Text fontWeight={"normal"}>
                    {state?.userType === "admin"
                      ? state?.admin?.id
                      : state?.doctor?.did}
                  </Text>
                </Flex>
                <Text fontWeight={"medium"} mt="2">
                  {state?.userType === "admin"
                    ? state?.admin?.super
                      ? "Super Administrator"
                      : "Administrator"
                    : "Clinician"}
                </Text>
                <Text fontWeight={"bold"} mt="2">
                  {state?.userType === "admin"
                    ? state?.admin?.title
                    : state?.doctor?.title}
                </Text>
              </Box>
              <Box
                onClick={logoutUser}
                px="4"
                pb="2"
                pt="3"
                bg="white"
                cursor="pointer"
                mt="2"
                borderTop={"1px solid"}
                borderColor={"gray.200"}
              >
                Sign out <Icon as={MdOutlineLogout} ml="2" mt="-1" />
              </Box>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
}
