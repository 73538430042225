export function scheduleReducer(state, action) {
  switch (action.type) {
    case "UPDATE_STATE": {
      return init(action.payload);
    }
    case "SET_PATIENT_TAB":
      return {...state, navigateToTab: action.payload};
    case "SELECT_DOCTOR": {
      return {...state, selectedDoctor: action.payload};
    }
    case "SELECT_PATIENT": {
      return {...state, selectedPatient: action.payload};
    }

    case "DELETE_DOCTOR": {
      let {doctorsPayload} = state;
      let newPayload = {...doctorsPayload};
      delete newPayload[action.payload];
      return {
        ...state,
        doctors: state.doctors.filter((d) => d.did !== action.payload),
        doctorsPayload: newPayload,
        selectedDoctor:
          state.selectedDoctor?.did === action.payload
            ? null
            : state.selectedDoctor,
      };
    }
    case "NEW_DOCTOR": {
      let {doctorsPayload, doctors} = state;
      let newPayload = {...doctorsPayload};
      newPayload[action.payload.did] = {
        unavailable: {},
        appointments: [],
        patients: [],
        services: state.services,
        index: doctors.length,
      };

      return {
        ...state,
        doctors: doctors.concat(action.payload),
        doctorsPayload: newPayload,
      };
    }
    case "UPDATE_DOCTOR": {
      if (state.userType === "admin") {
        let {doctors, selectedDoctor} = state;

        let updatedDoctors = doctors.map((d) => {
          return d.did === action.payload.did ? {...d, ...action.payload} : d;
        });

        return {
          ...state,
          doctors: updatedDoctors,
          selectedDoctor:
            selectedDoctor?.did === action.payload.did
              ? {...selectedDoctor, ...action.payload}
              : selectedDoctor,
        };
      } else {
        return {
          ...state,
          doctor: {...state.doctor, ...action.payload},
          selectedDoctor: {...state.selectedDoctor, ...action.payload},
        };
      }
    }
    case "UPDATE_APPOINTMENT": {
      if (state.userType === "admin") {
        if (action.payload.lid === state.selectedLocation) {
          let {doctorsPayload} = state;

          let appointments =
            doctorsPayload[action.changingDoctor || action.payload.did]
              ?.appointments;
          if (!appointments) return state;

          let apptIndex = appointments.findIndex(
            (a) => a.aid === action.payload.aid
          );

          if (apptIndex !== -1) {
            doctorsPayload[
              action.changingDoctor || action.payload.did
            ].appointments = [
              ...appointments.slice(0, apptIndex),
              ...(action.changingDoctor
                ? []
                : [{...appointments[apptIndex], ...action.payload}]),
              ...appointments.slice(apptIndex + 1),
            ];

            if (action.changingDoctor) {
              let newAppt = doctorsPayload[action.payload.did].appointments;
              if (doctorsPayload[action.payload.did])
                doctorsPayload[action.payload.did].appointments =
                  newAppt.concat({
                    ...appointments[apptIndex],
                    ...action.payload,
                  });
            }

            let rawAppointments = state.appointments;
            let rindex = rawAppointments.findIndex(
              (a) => a.aid === action.payload.aid
            );

            return {
              ...state,
              doctorsPayload: {...doctorsPayload},
              appointments: [
                ...rawAppointments.slice(0, rindex),
                {...rawAppointments[rindex], ...action.payload},
                ...rawAppointments.slice(rindex + 1),
              ],
              selectedAppointment:
                state.selectedAppointment?.aid === action.payload.aid
                  ? action.payload
                  : state.selectedAppointment,
            };
          } else return state;
        } else return state;
      }
      ///doctor login
      else {
        if (action.payload.lid === state.selectedLocation) {
          let appointments = state.appointments;
          let apptIndex = appointments.findIndex(
            (a) => a.aid === action.payload.aid
          );
          return {
            ...state,
            appointments:
              apptIndex !== -1
                ? [
                    ...appointments.slice(0, apptIndex),
                    ...(action.changingDoctor
                      ? []
                      : [{...appointments[apptIndex], ...action.payload}]),
                    ...appointments.slice(apptIndex + 1),
                  ]
                : appointments.concat(action.payload),

            selectedAppointment:
              state.selectedAppointment?.aid === action.payload.aid
                ? action.payload
                : state.selectedAppointment,
          };
        } else return state;
      }
    }

    case "SELECT_APPOINTMENT": {
      let {selectedAppointment} = state;
      if (selectedAppointment !== action.payload)
        return {...state, selectedAppointment: action.payload};
      else return state;
    }

    case "SET_CALENDAR_BY_APPOINTMENT": {
      return {
        ...state,
        setCalendarAtAppt: action.payload,
        selectedAppointment: action.payload,
      };
    }
    case "UPDATE_MULTIPLE_DOCTORS": {
      if (state.userType === "admin") {
        return {
          ...state,
          doctors: action.payload,
        };
      } else {
        return {
          ...state,
          doctor: {...state.doctor, ...action.payload},
          selectedDoctor: {...state.selectedDoctor, ...action.payload},
        };
      }
    }

    case "NEW_APPOINTMENT": {
      if (action.payload.lid === state.selectedLocation) {
        if (state.userType === "admin") {
          let {doctorsPayload, patients} = state;
          let appointments = doctorsPayload[action.payload.did].appointments;
          doctorsPayload[action.payload.did].appointments = appointments
            .filter((a) => a.aid !== action.payload?.aid)
            .concat(action.payload);

          if (action.updatePatient) {
            let index = patients.findIndex((e) => e.pid === action.payload.pid);
            if (index !== -1) {
              let patient = {
                ...patients[index],
                did: [
                  ...new Set([
                    ...patients[index].did,
                    ...action.updatePatient.did,
                  ]),
                ],
              };
              let arr = doctorsPayload[action.payload.did].patients;
              doctorsPayload[action.payload.did].patients = [...arr, patient];
              let updatedPatients = [
                ...patients.slice(0, index),
                patient,
                ...patients.slice(index + 1),
              ];

              return {
                ...state,
                patients: updatedPatients,
                unassignedPatients: state.unassignedPatients.filter(
                  (p) => p.pid !== action.payload.pid
                ),
                doctorsPayload,
                appointments: state.appointments
                  .filter((a) => a.aid !== action.payload?.aid)
                  .concat(action.payload),
              };
            }
          }

          if (action.newPatient) {
            let index = patients.findIndex(
              (e) => e.pid === action.newPatient.pid
            );

            if (index === -1) {
              let updatedPatients = [...patients, action.newPatient];
              let arr = doctorsPayload[action.payload.did].patients;
              doctorsPayload[action.payload.did].patients = [
                ...arr,
                action.newPatient,
              ];

              return {
                ...state,
                patients: updatedPatients,
                unassignedPatients: state.unassignedPatients.filter(
                  (p) => p.pid !== action.payload.pid
                ),
                doctorsPayload,
                appointments: state.appointments
                  .filter((a) => a.aid !== action.payload?.aid)
                  .concat(action.payload),
              };
            } else {
              let patient = patients[index];
              if (patient) {
                let dids = null;
                if (Array.isArray(patient.did)) {
                  if (!patient.did.includes(action.payload.did)) {
                    dids = patient.did.concat(action.payload.did);
                  }
                }

                if (dids) {
                  let arr = doctorsPayload[action.payload.did]?.patients.filter(
                    (p) => p.pid !== patient.pid
                  );
                  doctorsPayload[action.payload.did].patients = [
                    ...arr,
                    patient,
                  ];
                  patient.did = dids;
                }

                return {
                  ...state,
                  doctorsPayload,
                  patients: [...patients],
                  appointments: state.appointments
                    .filter((a) => a.aid !== action.payload?.aid)
                    .concat(action.payload),
                  unassignedPatients: state.unassignedPatients.filter(
                    (p) => p.pid !== action.payload.pid
                  ),
                };
              }
            }
          }

          return {
            ...state,
            doctorsPayload,
            appointments: state.appointments
              .filter((a) => a.aid !== action.payload?.aid)
              .concat(action.payload),
            unassignedPatients: state.unassignedPatients.filter(
              (p) => p.pid !== action.payload.pid
            ),
          };
        } else {
          let patients = state.patients;
          if (action.newPatient) {
            patients = patients
              .filter((p) => p.pid !== action.newPatient.pid)
              .concat(action.newPatient);
          }

          return {
            ...state,
            patients,
            appointments: state.appointments
              .filter((a) => a.aid !== action.payload?.aid)
              .concat(action.payload),
          };
        }
      }
    }
    case "NEW_RECURRENCE": {
      if (action.lid === state.selectedLocation) {
        if (state.userType === "admin") {
          let {doctorsPayload} = state;
          let appointments = doctorsPayload[action.did].appointments;
          doctorsPayload[action.did].appointments = appointments.concat(
            action.payload
          );

          return {
            ...state,
            doctorsPayload,
            appointments: state.appointments.concat(action.payload),
          };
        } else {
          return {
            ...state,

            appointments: state.appointments.concat(action.payload),
          };
        }
      }
      return state;
    }
    case "DELETE_RECURRENCE": {
      if (action.lid === state.selectedLocation) {
        if (state.userType === "admin") {
          let {doctorsPayload} = state;
          let appointments = doctorsPayload[action.did].appointments;
          doctorsPayload[action.did].appointments = appointments.filter(
            action.criteria
          );

          return {
            ...state,
            doctorsPayload,
            appointments: state.appointments.filter(action.criteria),
          };
        } else {
          return {
            ...state,
            appointments: state.appointments.filter(action.criteria),
          };
        }
      }
      return state;
    }

    case "UPDATE_PATIENT": {
      if (state.userType === "admin") {
        let {doctorsPayload, patients} = state;

        let patient = patients.find((e) => e.pid === action.pid);
        if (patient) {
          for (let [key, value] of Object.entries(action.payload)) {
            patient[key] = value;
          }

          if (
            action.options?.newDoctor &&
            doctorsPayload[action.options.newDoctor]
          ) {
            let arr = doctorsPayload[action.options.newDoctor]?.patients.filter(
              (p) => p.pid !== patient.pid
            );
            doctorsPayload[action.options.newDoctor].patients = [
              ...arr,
              patient,
            ];
            let updatedPatients = [...patients];

            return {
              ...state,
              patients: updatedPatients,
              doctorsPayload,
            };
          }
        }
        return {
          ...state,
          patients: [...patients],
          doctorsPayload,
        };
      } else {
        let index = state.patients.findIndex((e) => e.pid === action.pid);
        if (index !== -1) {
          let patient = {...state.patients[index], ...action.payload};
          let updatedPatients = [
            ...state.patients.slice(0, index),
            patient,
            ...state.patients.slice(index + 1),
          ];

          return {
            ...state,
            patients: updatedPatients,
          };
        } else {
          console.log(action.payload);
          if (action.payload.lid.includes(state.selectedLocation)) {
            return {
              ...state,
              patients: state.patients.concat(action.payload),
            };
          }
        }
        return state;
      }
    }

    case "ADD_UNAVAILABLE": {
      if (state.userType === "admin") {
        let {doctorsPayload} = state;
        let dc = doctorsPayload[action.payload.did];
        dc = {...dc, unavailable: action.payload.unavailable};
        doctorsPayload = {...doctorsPayload, [action.payload.did]: dc};

        let docIndex = state.doctors.findIndex(
          (d) => d.did === action.payload.did
        );
        let doc = state.doctors[docIndex];
        doc = {...doc, unavailable: action.payload.unavailable};
        //console.log(action.payload.unavailable)
        let updatedDoctors = [
          ...state.doctors.slice(0, docIndex),
          doc,
          ...state.doctors.slice(docIndex + 1),
        ];

        return {
          ...state,
          doctorsPayload,
          doctors: updatedDoctors,
          selectedDoctor: state.selectedDoctor ? doc : null,
        };
      } else {
        let doc = state.doctor;
        doc = {...doc, unavailable: action.payload.unavailable};
        return {...state, selectedDoctor: doc, doctor: doc};
      }
    }

    case "UPDATE_USER": {
      let user = state.userType === "admin" ? state.admin : state.doctor;
      let newUser = {...user};

      for (let [key, value] of Object.entries(action.payload))
        newUser[key] = value;

      return {
        ...state,
        [state.userType]: newUser,
      };
    }

    case "NEW_NOTIFICATION": {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    }

    case "NEW_LOCATION": {
      return {
        ...state,
        locations: [...state.locations, action.payload],
      };
    }
    case "UPDATE_LOCATIONS": {
      return {
        ...state,
        locations: action.payload,
      };
    }
    case "READ_NOTIFICATIONS": {
      return {
        ...state,
        notifications: state.notifications.map((e) => ({...e, read: true})),
      };
    }
    case "CLEAR_NOTIFICATIONS": {
      return {...state, notifications: []};
    }
    case "DELETE_NOTIFICATION": {
      return {
        ...state,
        notifications: state.notifications.filter(
          (e) => e.ntfId !== action.payload.ntfId
        ),
      };
    }
    case "CHANGE_LOCATION": {
      return {...state, selectedLocation: action.payload};
    }
    case "CHANGING_LOCATION_LOADER": {
      return {...state, changingLocation: action.payload};
    }
    case "UPDATE_LOCATION": {
      let {locations} = state;
      let locIndex = state.locations.findIndex(
        (d) => d.lid === action.payload.lid
      );
      let updatedLocations = [
        ...locations.slice(0, locIndex),
        {...locations[locIndex], ...action.payload},
        ...locations.slice(locIndex + 1),
      ];
      return {
        ...state,
        locations: updatedLocations,
      };
    }
    case "UPDATE_MEMBERSHIP": {
      if (state.userType === "admin") {
        let {locations} = state;
        let locIndex = state.locations.findIndex(
          (d) => d.lid === state.selectedLocation
        );
        const updatedMemberships = locations[locIndex].memberships.map(
          (membership) => {
            if (membership.mid === action.payload.mid) {
              return {...membership, ...action.payload.membership};
            } else return membership;
          }
        );
        let updatedLocations = [
          ...locations.slice(0, locIndex),
          {...locations[locIndex], memberships: updatedMemberships},
          ...locations.slice(locIndex + 1),
        ];
        return {
          ...state,
          locations: updatedLocations,
        };
      }
      return state;
    }

    case "ADD_SERVICE": {
      //  console.log({...state,services: [...state.services,action.payload]})
      return {...state, services: [...state.services, action.payload]};
    }

    case "ADD_PATIENT": {
      if (state.userType === "admin") {
        let {doctorsPayload} = state;
        let did = action.payload?.did[0] ?? "";
        if (doctorsPayload[did]) {
          return {
            ...state,
            doctorsPayload: {
              ...doctorsPayload,
              [did]: {
                ...doctorsPayload[did],
                patients:
                  doctorsPayload[did]?.patients.concat(action.payload) || [],
              },
            },
            patients: state.patients.concat(action.payload),
          };
        }
        return {
          ...state,
          patients: state.patients.concat(action.payload),
          unassignedPatients: state.unassignedPatients.concat(action.payload),
        };
      }
      return {...state, patients: state.patients.concat(action.payload)};
    }

    case "DELETE_SERVICE": {
      return {
        ...state,
        services: state.services.filter((s) => s.serviceId !== action.payload),
      };
    }

    case "UPDATE_SERVICE": {
      return {
        ...state,
        services: state.services.map((s) => {
          if (s.serviceId === action.payload.serviceId)
            return {...s, ...action.payload};
          return s;
        }),
      };
    }
    case "UPDATE_ADMIN": {
      return {
        ...state,
        admin: {...state.admin, ...action.payload},
      };
    }
    case "UPDATE_ORGANIZATION": {
      return {
        ...state,
        organization: {...state.organization, ...action.payload},
      };
    }

    case "DELETE_INSURER": {
      let {insurers} = state;
      return {
        ...state,
        insurers: insurers.filter((i) => i.iid !== action.payload),
      };
    }
    case "NEW_INSURER": {
      let {insurers} = state;
      return {
        ...state,
        insurers: insurers.concat(action.payload),
      };
    }
    case "UPDATE_INSURER": {
      let {insurers} = state;

      return {
        ...state,
        insurers: insurers.map((i) => {
          return i.iid === action.payload.iid ? {...i, ...action.payload} : i;
        }),
      };
    }

    case "NEW_PROVIDER_TYPE": {
      let {providerTypes} = state;
      return {
        ...state,
        providerTypes: providerTypes.concat(action.payload),
      };
    }
    case "UPDATE_PROVIDER_TYPE": {
      let {providerTypes} = state;
      return {
        ...state,
        providerTypes: providerTypes.map((i) => {
          return i.typeId === action.payload.typeId
            ? {...i, ...action.payload}
            : i;
        }),
      };
    }
    case "DELETE_PROVIDER_TYPE": {
      let {providerTypes} = state;
      return {
        ...state,
        providerTypes: providerTypes.filter((i) => {
          return i.typeId !== action.payload;
        }),
      };
    }
    case "SELECT_CLINICIANS": {
      return {
        ...state,
        ...(state.admin
          ? {
              admin: {
                ...state.admin,
                defaultCliniciansInCalendar: [...action.payload],
              },
            }
          : {}),
        selectedClinicians: action.payload,
      };
    }
    default:
      return state;
  }
}

export function init(state) {
  //console.log(state,"INITIAL")

  if (!state)
    return {
      admin: null,
      doctor: null,
      organization: null,
      doctors: [],
      patients: [],
      appointments: [],
      services: [],
      notifications: [],
      selectedLocation: "",
      selectedPatient: null,
      selectedDoctor: null,
      locations: [],
      insurers: [],
      serviceCriteria: [],
      providerTypes: [],
      firstLoading: true,
      changingLocation: true,
      selectedClinicians: new Set(),
      tasks: [],
      parse: true,
    };

  if (state.parsed) return state;
  if (state.admin) {
    let initial = {...state};
    initial.parsed = true;
    initial.selectedPatient = null;
    initial.userType = "admin";
    initial.selectedAppointment = null;
    initial.selectedDoctor = null;
    initial.changingLocation = !!state.changingLocation;
    initial.unassignedPatients = [];
    initial.locations = state.locations || [];
    initial.selectedClinicians = new Set(
      state.admin.defaultCliniciansInCalendar ||
        state.doctors?.map((d) => d.did) ||
        []
    );
    let doctorsPayload = {};

    state.doctors.forEach((doctor, i) => {
      let doctorPayload = {};
      doctor.unavailable ??= {};
      doctorPayload.appointments = [];
      doctorPayload.patients = [];
      doctorPayload.services = state.services;
      doctorPayload.unavailable = doctor.unavailable || {};
      doctorPayload.index = i;
      doctorPayload.name = doctor.name;
      doctorsPayload[doctor.did] = doctorPayload;
    });

    state.appointments.forEach((appt) => {
      if (doctorsPayload.hasOwnProperty(appt.did)) {
        doctorsPayload[appt.did].appointments.push(appt);
      }
    });

    state.patients.forEach((pt) => {
      if (typeof pt.did === "string") {
        if (doctorsPayload.hasOwnProperty(pt.did)) {
          doctorsPayload[pt.did].patients.push(pt);
        } else initial.unassignedPatients.push(pt);
      } else if (Array.isArray(pt.did)) {
        let pushed = false;
        pt.did.forEach((did) => {
          if (doctorsPayload.hasOwnProperty(did)) {
            doctorsPayload[did].patients.push(pt);
            pushed = true;
          }
        });
        if (!pushed) initial.unassignedPatients.push(pt);
      }
    });

    initial.doctorsPayload = doctorsPayload;
    initial.notifications = (state.notifications || []).reverse();

    return initial;
  } else {
    state.userType = "doctor";
    state.selectedClinicians = new Set();
    if (state.doctor) {
      state.doctor.unavailable ??= {};
      state.selectedClinicians = new Set([state.doctor.did] || []);
    }
    state.selectedPatient = null;
    state.selectedDoctor = state.doctor;
    state.notifications = (state.notifications || []).reverse();
    state.locations = state.locations || [];
    state.changingLocation = !!state.changingLocation;
    state.parsed = true;
    return state;
  }
}
