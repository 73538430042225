import React, {useState, useEffect, useContext} from "react";
import {
  sendCardReqLink,
  getPaymentMethods,
  executeMembershipPayment,
  executeMonthlyMembershipPayment,
  crud,
} from "../../../crudRequests";
import createPaymentRecord from "../../appointments/payment/helpers/createPaymentRecord";
import {loadStripe} from "@stripe/stripe-js";
import {BiLink} from "react-icons/bi";
import {
  useToast,
  useDisclosure,
  FormControl,
  FormLabel,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";
import {membershipReceipt} from "../../appointments/payment/uploads";
import {monthsBetween} from "../helpers/getMonths";
import {PatientContext} from "../../../pages/Patients";

export default function CreateCharge({
  state,
  patient,
  stateChanger,
  appt,
  dispatch,
  socket,
  setErrorMessage,
  setSuccess,
  fetchData,
  transaction,
  setPatientRefetch,
}) {
  const [sendingLink, setSendingLink] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(function () {
    if (state.db) {
      getPaymentMethods(state, patient.pid)
        .then((paymentMethods) => {
          setCards(paymentMethods.data);
          setSelectedCard(paymentMethods.data.find((c) => c?.default));
        })
        .catch((error) => {
          setErrorMessage(error.response?.data || error.message);
          console.log(error);
        });
    }
  }, []);

  return (
    <div className="relative   rounded-[10px] py-4  flex flex-col items-start space-y-4">
      <div className="flex justify-start items-center  px-8">
        <h1 className="text-blue-500 font-semibold text-2xl leading-[25px]">
          Payment
        </h1>
      </div>
      <CardsList {...{cards, selectedCard, setSelectedCard}} />
      <div
        className="hover:text-yellow-400 cursor-pointer self-end text-blue-500 mr-9 flex justify-end items-center"
        onClick={() => {
          setSendingLink(true);
          setErrorMessage(false);
          setSuccess(false);
          sendCardReqLink(state, patient)
            .then(() => {
              setSendingLink(false);
              setSuccess("The card request link has been sent!");
            })
            .catch((error) => {
              setSendingLink(false);
              setErrorMessage(error.response?.data?.toString());
              setSuccess(false);
            });
        }}
      >
        <span>
          <BiLink />
        </span>
        <span className="text-xs">Send Card Request</span>
        {sendingLink && (
          <span className="">
            <svg
              className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25 stroke-[4px]"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </span>
        )}
      </div>
      <SendFormChakra
        state={state}
        patient={patient}
        appt={appt}
        transaction={transaction}
        stateChanger={stateChanger}
        dispatch={dispatch}
        socket={socket}
        setErrorMessage={setErrorMessage}
        setSuccess={setSuccess}
        selectedCard={selectedCard}
        fetchData={fetchData}
        setPatientRefetch={setPatientRefetch}
      />
    </div>
  );
}

function SendFormChakra(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [paymentType, setPaymentType] = useState("full");
  const [patient, setPatient] = useContext(PatientContext);
  const toast = useToast();
  const remainingAmount = Number(props.transaction?.amount || 0).toFixed(2);
  const membershipTaxAmount = Number(
    props.transaction?.membershipTaxAmount || 0
  ).toFixed(2);
  const startDatePayment =
    props.transaction.memberships[0].startDatePayment ||
    props.transaction.memberships[0].startDate;

  const months = monthsBetween(
    startDatePayment,
    props.transaction.memberships[0].endDate
  );
  const dividedAmount = (Number(remainingAmount) / months).toFixed(2);

  const handleSubmit = async () => {
    setIsLoading(true);
    const stripe = await loadStripe(
      props.state.organization?.stpPublicKey || "Invalid key"
    );
    if (props.patient && stripe) {
      props.setSuccess(false);
      props.setErrorMessage(false);

      const handleServerResponse = async (response) => {
        try {
          if (response.error) {
            props.setErrorMessage(response.error);
          } else if (response.requires_action) {
            const {error: errorAction, paymentIntent} =
              await stripe.handleCardAction(
                response.payment_intent_client_secret
              );
            if (errorAction) {
              props.setErrorMessage(errorAction.message);
            } else {
              let paymentResponse;
              if (paymentType === "full") {
                paymentResponse = await executeMembershipPayment(
                  props.state,
                  props.appt,
                  props.patient.pid,
                  null,
                  paymentIntent.id
                );
              } else {
                paymentResponse = await executeMonthlyMembershipPayment(
                  props.state,
                  props.appt,
                  props.patient.pid,
                  props.selectedCard,
                  props.transaction.memberships[0].uuid,
                  dividedAmount,
                  startDatePayment,
                  props.transaction.memberships[0].endDate,
                  months,
                  props.transaction.memberships[0].name,
                  membershipTaxAmount
                );
              }
              handleServerResponse(paymentResponse.data);
            }
          } else {
            if (response?.billing && response?.success) {
              await createPaymentRecord({
                state: props.state,
                transaction: response?.billing,
                description: description || "",
                date: Date.now(),
                amount: response?.amountPaid,
                payer: "patient",
              });
            }

            if (response?.id && paymentType === "monthly") {
              const updatedMemberships = props.patient.memberships.map(
                (mData) => {
                  if (mData.uuid === props.transaction.membershipUuid) {
                    return {
                      ...mData,
                      subscriptionId: response.id,
                      subscriptionStatus: response.status,
                      paymentMethod: props.selectedCard?.id,
                      monthlyAmount: dividedAmount,
                      totalMonths: months,
                      remainingMonths: months,
                      lastPaymentDate: new Date().toISOString(),
                      nextPaymentAmount: dividedAmount,
                      membershipName: props.transaction.memberships[0].name,
                    };
                  }
                  return mData;
                }
              );

              props.dispatch({
                type: "UPDATE_PATIENT",
                pid: props.transaction.pid,
                payload: {
                  memberships: updatedMemberships,
                },
              });

              setPatient((prev) => ({
                ...prev,
                memberships: updatedMemberships,
              }));

              props.setPatientRefetch(true);

              props.fetchData();

              if (response?.billing) {
                const subscriptionBilling = {
                  ...response.billing,
                  subscriptionId: response.id,
                  membershipUuid: props.transaction.membershipUuid,
                  monthlyAmount: dividedAmount,
                  totalMonths: months,
                  remainingMonths: months,
                  paymentMethod: props.selectedCard?.id,
                  status: "active",
                };

                await crud(props.state, [
                  {
                    db: props.state.db,
                    collection: "billing",
                    parameters: [subscriptionBilling],
                    method: "insertOne",
                  },
                ]);
              }
            } else {
              const updatedMemberships = props.patient.memberships.map(
                (mData) => {
                  return mData.uuid === props.transaction.membershipUuid
                    ? {
                        ...mData,
                        status: "Paid",
                      }
                    : mData;
                }
              );
              membershipReceipt({
                state: props.state,
                transaction: response?.billing,
                patient: props.patient,
              });
              props.dispatch({
                type: "UPDATE_PATIENT",
                pid: props.transaction.pid,
                payload: {
                  memberships: updatedMemberships,
                },
              });
            }

            props.fetchData();
            props.setSuccess(
              paymentType === "full"
                ? "Successful payment!"
                : "Subscription created successfully! First payment processed."
            );

            toast({
              title:
                paymentType === "full"
                  ? "Successful payment!"
                  : "Subscription created successfully!",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            props.stateChanger(false);
          }
        } catch (error) {
          props.setErrorMessage(error.response?.data || error.message);
        } finally {
          setIsLoading(false);
        }
      };

      let paymentResponse;
      if (paymentType === "full") {
        paymentResponse = await executeMembershipPayment(
          props.state,
          props.appt,
          props.patient.pid,
          props.selectedCard
        );
      } else {
        paymentResponse = await executeMonthlyMembershipPayment(
          props.state,
          props.appt,
          props.patient.pid,
          props.selectedCard,
          props.transaction.memberships[0].uuid,
          dividedAmount,
          startDatePayment,
          props.transaction.memberships[0].endDate,
          months,
          props.transaction.memberships[0].name,
          membershipTaxAmount
        );
      }
      handleServerResponse(paymentResponse.data);
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2} w="full" px={8}>
        <Button
          colorScheme="blue"
          fontWeight="medium"
          onClick={() => {
            if (props.selectedCard) {
              setPaymentType("full");
              onOpen();
            } else {
              toast({
                title: "Please select a card",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }}
          w="full"
        >
          Generate Charge
          {` ($${remainingAmount} CAD${
            membershipTaxAmount > 0 ? ` + $${membershipTaxAmount} CAD Tax` : ""
          })`}
        </Button>
        {props.transaction.memberships[0].membership && (
          <Button
            colorScheme="blue"
            fontWeight="medium"
            onClick={() => {
              if (props.selectedCard) {
                setPaymentType("monthly");
                onOpen();
              } else {
                toast({
                  title: "Please select a card",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
            }}
            w="full"
          >
            Generate {months} Monthly Charges
            {` ($${dividedAmount} CAD each${
              membershipTaxAmount > 0
                ? ` + $${membershipTaxAmount} CAD Tax`
                : ""
            })`}
          </Button>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment description</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                value={description || ""}
                onChange={(evt) => {
                  setDescription(evt.target.value);
                }}
                type="textarea"
                placeholder="Type the description..."
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              variant="solid"
              m="1"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Generate Charge
            </Button>
            <Button
              m="1"
              variant="outline"
              colorScheme="red"
              width="auto"
              onClick={onClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function CardsList({cards, selectedCard, setSelectedCard}) {
  return (
    <div className="space-y-2 mt-4 w-full px-8">
      <div
        className="rounded-[10px] py-2 text-background font-semibold text-center mr-2"
        style={{backgroundColor: "#4A90E2"}}
      >
        <ul className="flex justify-center items-center  lg:text-base">
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Card
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Exp Date
          </li>
          <li className="flex justify-center items-center flex-1 text-[16px]">
            Selected Card
          </li>
          {/*<li className="flex justify-center items-center flex-1 text-[16px]">Action</li>*/}
        </ul>
      </div>
      <div className="overflow-hidden flex justify-center max-h-[6rem]">
        <div className="overflow-y-scroll  w-full space-y-2 pr-2 pb-2 sbar2">
          {cards.length > 0 ? (
            cards.map((c, i) => {
              return (
                <div
                  key={i}
                  className=" rounded-[10px] mt-0 py-2 bg-[#DFDEFF] text-sm text-gray-600 text-opacity-75 font-semibold text-center h-fit"
                  style={{
                    backgroundColor: c === selectedCard ? "#A6C8F0" : "#D0E4F7",
                  }}
                >
                  <ul className="flex justify-center items-center md:text-sm w-full">
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {"****" + c.card.last4}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      {`${c.card.exp_month}/${c.card.exp_year}`}
                    </li>
                    <li className=" flex justify-center items-center flex-1  text-[16px] ">
                      <div
                        className="bg-[#5754FF] rounded-full p-2 w-20 cursor-pointer transition duration-500"
                        onClick={() => {
                          setSelectedCard(c);
                        }}
                        style={{
                          backgroundColor:
                            c === selectedCard ? "#1E88E5" : "#FFFFFF",
                          boxShadow:
                            c !== selectedCard && "0 0 0 2px inset #1E88E5",
                        }}
                      >
                        <p
                          className="w-3 h-3 rounded-full bg-white transition duration-500"
                          style={{
                            transform:
                              c === selectedCard
                                ? "translateX(55px)"
                                : "translateX(0)",
                            backgroundColor:
                              c === selectedCard ? "#FFFFFF" : "#1E88E5",
                          }}
                        ></p>
                      </div>
                    </li>
                  </ul>
                </div>
              );
            })
          ) : (
            <div className=" rounded-[10px] mt-0 py-2 bg-[#D0E4F7] text-sm text-gray-600 text-opacity-75 font-semibold text-center h-fit">
              <span>No registered card</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
