import {
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Tooltip,
} from "@chakra-ui/react";
import {lightFormat} from "date-fns";
export default function UnavailableList({
  intervals,
  track,
  groups,
  type,
  selected,
  setSelected,
  radioValue,
  untilRemovedGroup,
}) {
  return (
    <Box mt={"4"}>
      <Text
        color={"blue.600"}
        mb="1"
        ml="1"
        fontSize={"lg"}
        textAlign={"center"}
        fontWeight="600"
      >
        Unavailable intervals
      </Text>
      <TableContainer
        rounded={"lg"}
        border={"1px solid"}
        borderColor={"gray.100"}
        overflowY="auto"
        h="20rem"
        w={"full"}
        fontSize={"xs"}
        pb="0.5rem"
      >
        <Table variant="unstyled" size="xs">
          <Tbody p="2" pb="4">
            {type === "individual"
              ? radioValue === "weekdays"
                ? untilRemovedGroup.map((u, i) => {
                    let trc = u.indexSet;
                    let format = u.format;
                    const groupNote = u.note;
                    return (
                      <Tr
                        key={i}
                        onClick={() => {
                          setSelected((prev) => {
                            let n = new Set(prev);
                            n.has(trc) ? n.delete(trc) : n.add(trc);
                            return n;
                          });
                        }}
                      >
                        <Td bg="white" key={i} textAlign={"center"}>
                          <Box
                            p="2"
                            mt="1"
                            mx="1"
                            borderRadius={"30px"}
                            bg={selected.has(trc) ? "blue.700" : "blue.300"}
                            color={"white"}
                            cursor={"pointer"}
                            rounded={"md"}
                            width="280px"
                            openDelay={1000}
                          >
                            {format}
                            {groupNote && (
                              <Tooltip
                                label={groupNote}
                                isDisabled={groupNote.length <= 30}
                              >
                                <Text
                                  fontSize="xs"
                                  fontStyle="italic"
                                  mt="1"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  width="100%"
                                >
                                  {groupNote}
                                </Text>
                              </Tooltip>
                            )}
                          </Box>
                        </Td>
                      </Tr>
                    );
                  })
                : intervals.map((p, i) => {
                    // if (track.has(i)) return null;
                    const intervalNote = Array.isArray(p) && p[2] ? p[2] : "";
                    return (
                      <Tr
                        key={i}
                        onClick={() => {
                          setSelected((prev) => {
                            let n = new Set(prev);
                            n.has(i) ? n.delete(i) : n.add(i);
                            return n;
                          });
                        }}
                      >
                        <Td bg="white" key={i} textAlign={"center"}>
                          <Box
                            p="2"
                            mt="1"
                            mx="1"
                            borderRadius={"30px"}
                            bg={selected.has(i) ? "blue.700" : "blue.300"}
                            color={"white"}
                            cursor={"pointer"}
                            rounded={"md"}
                            width="280px"
                          >
                            {ListItemFromat({type, data: p})}
                            {intervalNote && (
                              <Tooltip
                                label={intervalNote}
                                isDisabled={intervalNote.length <= 30}
                                openDelay={1000}
                              >
                                <Text
                                  fontSize="xs"
                                  fontStyle="italic"
                                  mt="1"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  width="100%"
                                >
                                  {intervalNote}
                                </Text>
                              </Tooltip>
                            )}
                          </Box>
                        </Td>
                      </Tr>
                    );
                  })
              : groups.map((e, i) => {
                  let ntv = e[0];
                  let trc = e[1];
                  const firstInterval = ntv[0];
                  const rangeNote =
                    Array.isArray(firstInterval) && firstInterval[2]
                      ? firstInterval[2]
                      : "";
                  return (
                    <Tr
                      key={i}
                      onClick={() => {
                        setSelected((prev) => {
                          let n = new Set(prev);
                          n.has(trc) ? n.delete(trc) : n.add(trc);
                          return n;
                        });
                      }}
                    >
                      <Td bg="white" key={i} textAlign={"center"}>
                        <Box
                          p="2"
                          mt="1"
                          mx="1"
                          borderRadius={"30px"}
                          bg={selected.has(trc) ? "blue.700" : "blue.300"}
                          color={"white"}
                          cursor={"pointer"}
                          rounded={"md"}
                          width="280px"
                        >
                          {ListItemFromat({type, data: ntv})}
                          {rangeNote && (
                            <Tooltip
                              label={rangeNote}
                              isDisabled={rangeNote.length <= 30}
                              openDelay={1000}
                            >
                              <Text
                                fontSize="xs"
                                fontStyle="italic"
                                mt="1"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                width="100%"
                              >
                                {rangeNote}
                              </Text>
                            </Tooltip>
                          )}
                        </Box>
                      </Td>
                    </Tr>
                  );
                })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function ListItemFromat({type, data}) {
  const safeFormat = (date, formatStr) => {
    try {
      return lightFormat(date, formatStr);
    } catch (error) {
      console.error("Error formatting date:", error, date);
      return "Invalid date";
    }
  };

  if (Array.isArray(data)) {
    if (
      type === "range" &&
      Array.isArray(data[0]) &&
      Array.isArray(data[data.length - 1])
    ) {
      const start = data[0];
      const end = data[data.length - 1];

      return `${safeFormat(start[0], "dd/MM/yyyy")} - ${safeFormat(
        end[0],
        "dd/MM/yyyy"
      )} (${safeFormat(start[0], "HH:mm")} - ${safeFormat(end[1], "HH:mm")})`;
    }

    if (type === "individual" && data[0] && data[1]) {
      return `${safeFormat(data[0], "dd/MM/yyyy")} (${safeFormat(
        data[0],
        "HH:mm aaa"
      )} - ${safeFormat(data[1], "HH:mm")})`;
    }
  }

  return "Invalid format";
}
