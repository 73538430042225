import {useState, useCallback, useEffect} from "react";
import {debounce} from "lodash";

import {
  Text,
  Flex,
  Stack,
  Checkbox,
  Input,
  Table,
  Thead,
  InputLeftElement,
  InputGroup,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
} from "@chakra-ui/react";
import {SortableColumnHeader} from "../SortableColumnHeader";
import {MembershipsCreateModal} from "./MembershipsCreateModal";
import {SearchIcon} from "@chakra-ui/icons";
import {MembershipsDeleteModal} from "./MembershipsDeleteModal";
import {MembershipsEditModal} from "./MembershipsEditModal";
import {crud} from "../../crudRequests";

export default function MembershipsTable({schState, dispatch}) {
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({active: true, inactive: false});
  const [membershipsData, setMembershipsData] = useState([]);
  const [sort, setSort] = useState({column: "name", direction: "asc"});
  const [selectedLocation, setSelectedLocation] = useState(null);

  const fetchData = async (searchValue, filters, sort) => {
    try {
      const locationResult = await crud(schState, [
        {
          db: schState.db,
          collection: "locations",
          parameters: [{lid: schState.selectedLocation}],
          method: "findOne",
        },
      ]);

      const selectedLocation = locationResult.data[0];
      setSelectedLocation(selectedLocation);

      if (!selectedLocation) {
        console.error("Location not found");
        return;
      }

      const memberships = selectedLocation.memberships || [];

      const responseData = memberships
        .filter((membership) => {
          if (!filters.active && membership.status === "Active") return false;
          if (!filters.inactive && membership.status === "Inactive")
            return false;

          if (membership.status === "Deleted") return false;
          if (
            membership.name.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return true;
          }
          if (
            membership.description
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            return true;
          }
          return false;
        })
        .sort((a, b) => {
          if (["price"].includes(sort.column)) {
            if (sort.direction === "asc") {
              return b[sort.column] - a[sort.column];
            } else {
              return a[sort.column] - b[sort.column];
            }
          }
          try {
            if (sort.direction === "asc") {
              return (a[sort.column] || "").localeCompare(b[sort.column] || "");
            } else {
              return (b[sort.column] || "").localeCompare(a[sort.column] || "");
            }
          } catch (e) {}
        });

      setMembershipsData(responseData);
    } catch (error) {
      console.error("Error fetching memberships:", error);
    }
  };

  const handleSearch = useCallback(
    debounce((value) => {
      fetchData(value, filters, sort);
    }, 300),
    [filters, sort]
  );

  useEffect(() => {
    fetchData(searchValue, filters, sort);
  }, [sort]);

  return (
    <Stack
      direction="column"
      mt={4}
      spacing={4}
      alignItems="center"
      w="100%"
      h="full"
    >
      <Flex justify={"space-between"} w="full" align={"center"}>
        <Text color={"blue.400"} fontWeight={"bold"} fontSize={"2rem"}>
          Memberships/Packages
        </Text>
      </Flex>
      <Stack
        w="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <InputGroup w="25%">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            value={searchValue}
            onChange={(evt) => {
              setSearchValue(evt.target.value);
              handleSearch(evt.target.value);
            }}
            placeholder="Search memberships/packages"
          />
        </InputGroup>

        <Checkbox
          color="#8F8CFF"
          isChecked={filters.active}
          size="lg"
          fontWeight={600}
          onChange={(evt) => {
            setFilters((prev) => ({...prev, active: evt.target.checked}));
            fetchData(
              searchValue,
              {...filters, active: evt.target.checked},
              sort
            );
          }}
        >
          Active
        </Checkbox>
        <Checkbox
          color="#8F8CFF"
          isChecked={filters.inactive}
          size="lg"
          fontWeight={600}
          onChange={(evt) => {
            setFilters((prev) => ({
              ...prev,
              inactive: evt.target.checked,
            }));
            fetchData(
              searchValue,
              {...filters, inactive: evt.target.checked},
              sort
            );
          }}
        >
          Inactive
        </Checkbox>

        <MembershipsCreateModal
          schState={schState}
          selectedLocation={selectedLocation}
          dispatch={dispatch}
          membershipsData={membershipsData}
          setMembershipsData={setMembershipsData}
          setSearchValue={setSearchValue}
          setFilters={setFilters}
        />
      </Stack>
      <Box overflowY="scroll" maxHeight="70%" width="full">
        <Table size="lg">
          <Thead bg="gray.100">
            <Tr>
              <SortableColumnHeader
                label="Membership/Package name"
                currentSort={sort}
                column="name"
                onSort={setSort}
              />
              <SortableColumnHeader
                label="Type"
                currentSort={sort}
                column="type"
                onSort={setSort}
              />
              <SortableColumnHeader
                label="Description"
                currentSort={sort}
                column="description"
                onSort={setSort}
              />
              <SortableColumnHeader
                label="Price"
                currentSort={sort}
                column="price"
                onSort={setSort}
              />
              <SortableColumnHeader
                label="Status"
                currentSort={sort}
                column="status"
                onSort={setSort}
              />
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {membershipsData.map((membership) => {
              return (
                <Tr key={membership.mid}>
                  <Td>
                    <Text>{membership.name}</Text>
                  </Td>
                  <Td>
                    <Text>
                      {membership.membership ? "Membership" : "Package"}
                    </Text>
                  </Td>
                  <Td>
                    <Text>{membership.description}</Text>
                  </Td>
                  <Td>
                    <Text>{`$${membership.price}`}</Text>
                  </Td>
                  <Td>
                    <Text>{membership.status}</Text>
                  </Td>
                  <Td>
                    <Flex
                      gap={2}
                      w="full"
                      h="full"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <MembershipsEditModal
                        schState={schState}
                        membership={membership}
                        selectedLocation={selectedLocation}
                        dispatch={dispatch}
                        membershipsData={membershipsData}
                        setMembershipsData={setMembershipsData}
                      />
                      <MembershipsDeleteModal
                        schState={schState}
                        membership={membership}
                        selectedLocation={selectedLocation}
                        dispatch={dispatch}
                        fetchData={() => fetchData(searchValue, filters, sort)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Stack>
  );
}
