import {Button, useToast} from "@chakra-ui/react";
import React from "react";
import {crud} from "../../../crudRequests";
import {membershipReceipt} from "../../appointments/payment/uploads";
import MarkAsPaidModal from "../../appointments/payment/MarkAsPaidModal";

export function PatientMembershipPaymentModal({
  transaction,
  patient,
  state,
  dispatch,
  fetchData,
  setPatient,
  isDisabled,
}) {
  const [markAsPaid, setmarkAsPaid] = React.useState(false);

  const toast = useToast();
  return (
    <>
      <Button
        onClick={() => {
          setmarkAsPaid(true);
        }}
        fontWeight="light"
        variant="outline"
        textColor="blue.400"
        borderColor="blue.400"
        isDisabled={isDisabled}
      >
        Mark as paid
      </Button>

      <MarkAsPaidModal
        {...{
          markAsPaid,
          setmarkAsPaid,
          transaction,
          state,
          dispatch,
          toast,
          handlePayment: handleFullPayment,
          onlyFullPayment: true,
        }}
      />
    </>
  );

  async function handleFullPayment({paymentMethod, selectedGiftCard}) {
    const generatingToast = toast({
      title: "Updating payment status.",
      status: "loading",
      variant: "subtle",
      duration: null,
      isClosable: true,
    });
    const requestObj = [];
    if (paymentMethod === "giftCard") {
      if (!selectedGiftCard) {
        toast.close(generatingToast);
        toast({
          title: "Payment Error",
          description: "Select a valid gift card!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      } else {
        const giftCardBalance = parseFloat(selectedGiftCard.balance || 0);
        const ob =
          (transaction?.patientAmount || 0) +
          (transaction?.membershipTaxAmount || 0) -
          (transaction?.amountPaidByPatient || 0);
        const amountToPay = parseFloat(ob);
        if (amountToPay > giftCardBalance) {
          toast.close(generatingToast);
          toast({
            title: "Payment Error",
            description: "Insufficient balance to make this payment!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      }
      const giftCardBalance = parseFloat(selectedGiftCard.balance);
      const ob =
        (transaction?.patientAmount || 0) +
        (transaction?.membershipTaxAmount || 0) -
        (transaction?.amountPaidByPatient || 0);
      const amountToPay = parseFloat(ob);
      requestObj.push({
        db: state.db,
        collection: "giftCards",
        parameters: [
          {gcId: selectedGiftCard.gcId},
          {
            $set: {
              balance: Number((giftCardBalance - amountToPay).toFixed(2)),
            },
          },
        ],
        method: "findOneAndUpdate",
      });
    }
    let patientAmount = parseFloat(transaction.patientAmount ?? 0);
    let membershipTaxAmount = parseFloat(transaction.membershipTaxAmount ?? 0);
    let tppAmount = parseFloat(transaction.tppAmount ?? 0);
    let amountPaidByPatient = parseFloat(transaction.amountPaidByPatient ?? 0);
    let amountPaidByTpp = parseFloat(transaction.amountPaidByTpp ?? 0);
    let otbp = patientAmount + membershipTaxAmount - amountPaidByPatient;
    let otbi = tppAmount - amountPaidByTpp;
    const updateBody = {
      ...transaction,
      patientPaymentStatus: "paid",
      paymentStatus: parseFloat(otbi) <= 0 ? "paid" : "pending",
    };
    let receiptAmount =
      patientAmount + membershipTaxAmount - amountPaidByPatient;
    let {_id, ...data} = updateBody;

    crud(state, [
      {
        db: state.db,
        collection: "billing",
        parameters: [
          {tid: transaction.tid},
          {
            $set: {
              patientPaymentStatus: "paid",
              amountPaidByPatient: patientAmount + membershipTaxAmount,
              paymentStatus:
                parseFloat(otbp) <= 0 && parseFloat(otbi) <= 0
                  ? "paid"
                  : "pending",
            },
          },
          {returnNewDocument: true},
        ],
        method: "findOneAndUpdate",
      },
    ])
      .then(async (res) => {
        await crud(state, [
          {
            db: state.db,
            collection: "billing",
            parameters: [
              {aid: data.aid},
              {
                $set: {
                  paid: (res.data[1]?.paid || 0) + receiptAmount,
                },
              },
              {returnNewDocument: true},
            ],
            method: "findOneAndUpdate",
          },
          {
            db: state.db,
            collection: "patients",
            parameters: [
              {
                pid: transaction.pid,
                "memberships.uuid": transaction.membershipUuid,
              },
              {
                $set: {
                  "memberships.$.status": "Paid",
                  "memberships.$.active": true,
                },
              },
              {returnNewDocument: true},
            ],
            method: "findOneAndUpdate",
          },
        ]);
        membershipReceipt({
          state: state,
          transaction: transaction,
          patient: patient,
        });
        toast.close(generatingToast);
        toast({
          title: "The patient amount has been paid!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        const updatedMemberships = patient.memberships.map((mData) => {
          return mData.uuid === transaction.membershipUuid
            ? {
                ...mData,
                status: "Paid",
                active: true,
              }
            : mData;
        });
        setPatient({
          ...patient,
          memberships: updatedMemberships,
        });
        dispatch({
          type: "UPDATE_PATIENT",
          pid: transaction.pid,
          payload: {
            memberships: updatedMemberships,
          },
        });
        fetchData();
      })
      .catch(function (error) {
        toast({
          title: "Payment Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.log(error);
      });
  }
}
