import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import {useRef} from "react";
import {FiDownload} from "react-icons/fi";
import {downloadFolder} from "./helpers/downloadFolder";

export const DownloadDialog = ({dashState, path, toast}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const cancelRef = useRef();

  const handleDownload = async () => {
    await downloadFolder(dashState, path, "", toast);
    onClose();
  };

  return (
    <>
      <Badge
        onClick={onOpen}
        fontSize="12px"
        rounded="md"
        p="1"
        px="2"
        ml="2"
        colorScheme="orange"
        cursor={"pointer"}
      >
        <Icon as={FiDownload} fontSize="15px" mt="-0.5" /> Download All Files
      </Badge>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Download All Files
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? This will download all folders and files from the
              current path.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleDownload} ml={3}>
                Download
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
