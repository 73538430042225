import React, {useState, useEffect, useRef, useReducer, useMemo} from "react";
import {bookNowCrud, bookNowData} from "../../crudRequests";
import {BsPersonCircle} from "react-icons/bs";
import InstanceBar from "./BookNowHeader";
import {
  bookNowReducer,
  init,
} from "../../additional_files/bookNow/bookNowReducer";
import Calendar from "../../components/BKCalendar";
import BookNowHome from "../../components/BookNowHome";
import BookNowSelections from "../../components/BookNowSelections";
import BookNowPatientInfo from "../../components/BookNowPatientInfo";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {createPortal} from "react-dom";
export default function BookNow() {
  const navigate = useNavigate();
  const [bookState, dispatch] = useReducer(bookNowReducer, null, init);
  const {state} = useLocation();
  const [loading, setLoading] = useState();
  const loadedRef = useRef(null);
  const sessionStart = useRef(true);

  useEffect(() => {
    if (!sessionStart.current) {
      dispatch({type: "UPDATE_STATE", payload: state});
    }
  }, [state, dispatch]);

  useEffect(() => {
    const abortController = new AbortController();

    dispatch({type: "SHOW_LOADER", payload: true});
    bookNowData(
      {
        ...bookState,
        destination: `..${window.location.pathname}`,
      },
      navigate,
      null,
      abortController.signal
    );

    return () => {
      abortController.abort();
      dispatch({type: "SHOW_LOADER", payload: false});
    };
  }, []);

  useEffect(() => {
    sessionStart.current = false;
  }, []);

  let servicesList = bookState.servicesPayload ? bookState.services : [];
  let doctorList = bookState.doctorsPayload ? bookState.doctors : [];

  if (bookState.selectedDoctor) {
    servicesList =
      bookState.doctorsPayload[bookState.selectedDoctor.did].services;
  }

  if (bookState.selectedService) {
    doctorList =
      bookState.servicesPayload[bookState.selectedService.serviceId].doctors;
  }

  let display = null;

  switch (bookState.selectedThread) {
    case "home":
      display = <BookNowHome bookState={bookState} dispatch={dispatch} />;
      break;
    case "selections":
      display = (
        <BookNowSelections
          bookState={bookState}
          dispatch={dispatch}
          servicesList={servicesList}
          doctorList={doctorList}
        />
      );
      break;
    case "calendar":
      display = <Calendar bookState={bookState} dispatch={dispatch} />;
      break;
  }

  return (
    <>
      {createPortal(
        <div className="w-full h-screen bg-[#F9F9F9] p-4 show absolute inset-0">
          {bookState.loader && (
            <div className="h-full w-full  inset-0 bg-white/80 z-[10] absolute flex justify-center items-center">
              <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
            </div>
          )}
          <div className="relative w-full h-full p-4 border rounded-md flex flex-col   max-w-5xl mx-auto ">
            <InstanceBar
              {...{
                dispatch,
                schState: bookState,
              }}
            />

            <main
              ref={loadedRef}
              style={{opacity: 1}}
              className="relative  flex-1 w-full  overflow-hidden overflow-y-scroll sbar2 "
            >
              {display}
              {bookState.showPatientForm && (
                <BookNowPatientInfo bookState={bookState} dispatch={dispatch} />
              )}
            </main>
          </div>
        </div>,
        document.body
      )}
    </>
  );
}
