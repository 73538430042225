import React, {useReducer, useEffect} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Box,
  Text,
  Tag,
  TagLabel,
  TagCloseButton,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {MdEmail} from "react-icons/md";
import {
  supplementaryCrud,
  sendAltMeetingLinkEmail,
} from "../../../crudRequests";

export function TempLinkDialog(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const clinicName =
    props.state.organization?.name || props.appointment.locationName;
  const [state, dispatch] = useReducer(
    (state, action) => {
      if (action.type === "SET-LOADING") {
        return {...state, loading: action.payload};
      }
      if (action.type === "SET-EMAIL") {
        return {...state, email: action.payload};
      }
      if (action.type === "ADD-EMAIL") {
        if (state.emails.includes(action.payload) || !action.payload) {
          return state;
        }
        return {
          ...state,
          emails: [...state.emails, action.payload],
          email: "",
        };
      }
      if (action.type === "REMOVE-EMAIL") {
        return {
          ...state,
          emails:
            state.emails.filter((email) => email !== action.payload) || [],
        };
      }
      if (action.type === "SET-MESSAGE") {
        return {...state, message: action.payload};
      }
      if (action.type === "RESET-EMAILS") {
        return {...state, emails: []};
      }
    },
    {
      loading: true,
      email: "",
      emails: [props.appointment.pEmail],
      message: "",
    }
  );

  useEffect(() => {
    dispatch({type: "RESET-EMAILS"});
    dispatch({type: "ADD-EMAIL", payload: props.appointment.pEmail || ""});
  }, [props.appointment]);

  useEffect(() => {
    async function getTemplateData() {
      try {
        let req = [
          {
            db: "metadata",
            collection: "notifications",
            parameters: [
              {
                type: "alt_appointment_link",
              },
            ],
            method: "findOne",
          },
        ];
        const res = await supplementaryCrud(props.state, req);
        let smap = {
          "{{patientName}}": `${props.appointment?.pName || ""}`,
          "{{appointmentDate}}": `${props.appointment?.date || ""}`,
          "{{appointmentTime}}": `${props.appointment?.time || ""}`,
          "{{clinicianName}}": `${props.appointment?.dName || ""}`,
          "{{clinicName}}": `${clinicName || ""}`,
          "{{link}}": `${props.appointment?.patientApptLink || ""}`,
        };
        dispatch({
          type: "SET-MESSAGE",
          payload: res.data[0]?.emailContent
            .replace(/{{\w+}}/g, (s) => smap[s])
            .replace(/<br\s*\/?>/gi, "\n")
            .replace(/<[^>]*>/g, ""),
        });
      } catch (error) {
        console.error(error);
      }
    }
    getTemplateData();
  }, [props.state]);

  return (
    <>
      <Button
        leftIcon={<MdEmail />}
        colorScheme="blue"
        variant="outline"
        size="sm"
        onClick={onOpen}
        w="full"
      >
        Send Temp. Appointment Link
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Alternative Appointment Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Email recipient(s)</Text>
            {state.emails.map((thisEmail) => (
              <Tag
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
                key={thisEmail}
                my="0.5rem"
              >
                <TagLabel>{thisEmail}</TagLabel>
                <TagCloseButton
                  onClick={() => {
                    dispatch({type: "REMOVE-EMAIL", payload: thisEmail});
                  }}
                />
              </Tag>
            ))}
            <Box flexDirection="row">
              <Input
                w="80%"
                mr="1rem"
                placeholder="Add email..."
                value={state.email}
                onChange={(e) =>
                  dispatch({type: "SET-EMAIL", payload: e.target.value})
                }
              />
              <Button
                isDisabled={!state.email.length}
                onClick={() => {
                  dispatch({type: "ADD-EMAIL", payload: state.email});
                }}
              >
                Add
              </Button>
            </Box>
            <Text pt="1rem">Email body</Text>
            <Textarea
              minHeight={"150px"}
              placeholder="Write the message..."
              value={state.message}
              onChange={(e) =>
                dispatch({type: "SET-MESSAGE", payload: e.target.value})
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={!state.emails.length}
              onClick={async () => {
                try {
                  await sendAltMeetingLinkEmail(props.state, {
                    emails: state.emails,
                    text: state.message,
                    sender: clinicName,
                  });
                  toast({
                    title: "Email sent.",
                    description:
                      "The alternative appointment link has been sent successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                  onClose();
                } catch (error) {
                  console.error(error);
                  toast({
                    title: "Error.",
                    description: "There was an error sending the email.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
