import React, {useState, useEffect, useRef, useReducer} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForAdminLogin, loadDataForDoctorLogin} from "../crudRequests";
import Navbar from "../components/Navbar";
import InstanceBar from "../components/InstanceBar";
import {scheduleReducer, init} from "../additional_files/reducer";
import LocationsTable from "../components/LocationsTable";
import ToastNotification from "../components/toastNotification/ToastNotification";
import {Flex} from "@chakra-ui/react";
import Navigation from "../components/Navigation";
export default function Locations(props) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const loadRef = useRef(null);
  const [loading, setLoading] = useState();
  const [schState, dispatch] = useReducer(scheduleReducer, state, init);

  useEffect(
    function () {
      if (!state) {
        window.history.back();
      } else verifyUser(state ? state.jwt : "", loadRef);
    },
    [state]
  );

  const [prevState, setPrevstate] = useState(state);

  if (prevState !== state) {
    setPrevstate(state);
    dispatch({type: "UPDATE_STATE", payload: state});
  }

  const reloadingDataRef = useRef(null);

  function reloadState(destination, abortController, action) {
    action?.();

    if (schState.userType === "admin")
      loadDataForAdminLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
    else
      loadDataForDoctorLogin(
        {
          ...schState,
          destination,
        },
        navigate,
        null,
        abortController.signal
      );
  }

  useEffect(() => {
    const abortController = new AbortController();
    if (!schState?.firstLoading) {
      reloadingDataRef.current = true;
      reloadState("../locations", abortController, () => {
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: true});
      });

      return () => {
        abortController.abort();
        dispatch({type: "CHANGING_LOCATION_LOADER", payload: false});
        reloadingDataRef.current = false;
      };
    }
  }, [schState.selectedLocation]);

  useEffect(() => {
    // console.log(schState.changingLocation, 2);
    if (schState?.firstLoading)
      navigate("../locations", {
        state: {
          ...schState,
          changingLocation: reloadingDataRef.current,
          firstLoading: false,
        },
      });
  }, []);

  return (
    <Navigation state={schState} dispatch={dispatch}>
      <Flex
        position="relative"
        w="full"
        h="full"
        overflowY="hidden"
        overflowX="auto"
        flexDirection="column"
        ref={loadRef}
        style={{opacity: 0}}
      >
        <ToastNotification appointments={schState.appointments || []} />

        {schState.changingLocation && (
          <div className="fixed  inset-0 bg-white/80 z-[99999]  flex justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
          </div>
        )}
        {state && <LocationsTable schState={schState} dispatch={dispatch} />}
      </Flex>
    </Navigation>
  );
}
