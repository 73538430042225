import React, {useState, useRef, useEffect} from "react";
import CreateCharge from "./CreateCharge";
import {crud} from "../../../crudRequests";
import {
  Box,
  Image,
  IconButton,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
export default function (props) {
  const {
    appointment,
    stateChanger,
    state,
    dispatch,
    socket,
    transaction,
    fetchData,
    setPatientRefetch,
  } = props;
  const [patient, setPatient] = useState(null);

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const response = await crud(state, [
          {
            db: state.db,
            collection: "patients",
            parameters: [{pid: appointment.pid}],
            method: "findOne",
          },
        ]);

        const patientData = response.data[0];
        setPatient(patientData);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };

    fetchPatientData();
  }, []);

  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");

  return (
    <Modal
      isOpen={true}
      size={"3xl"}
      onClose={() => stateChanger(false)}
      isCentered
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent p="2">
        <ModalCloseButton />
        <ModalBody className="relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg sm:my-8 sm:w-full sm:p-6 sm:align-middle">
          {errorMessage && (
            <div className="mx-auto flex justify-center items-center w-full">
              <div className="mb-5 flex w-full overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-red-500">
                  <svg
                    className="w-6 h-6 mx-2 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>
                <div className="px-4 py-2">
                  <div className="mx-3">
                    <span className="font-semibold text-red-500 dark:text-red-400">
                      Error
                    </span>
                    <p className="text-sm whitespace-pre-wrap text-gray-600 dark:text-gray-200">
                      {errorMessage}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {success && (
            <div className="mx-auto flex justify-center items-center w-full">
              <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                <div className="flex items-center justify-center w-12 bg-green-500">
                  <svg
                    className="w-6 h-6 mx-2 text-white fill-current"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                  </svg>
                </div>
                <div className="px-4 py-2 -mx-3">
                  <div className="mx-3">
                    <span className="font-semibold text-green-500 dark:text-green-400">
                      Card Payment
                    </span>
                    <p className="text-sm text-gray-600 dark:text-gray-200">
                      {success}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {patient ? (
            <CreateCharge
              state={state}
              patient={patient}
              stateChanger={stateChanger}
              appt={appointment}
              dispatch={dispatch}
              socket={socket}
              setErrorMessage={setErrorMessage}
              setSuccess={setSuccess}
              transaction={transaction}
              fetchData={fetchData}
              setPatientRefetch={setPatientRefetch}
            />
          ) : (
            <div className="flex justify-start items-center  px-8">
              <h1 className="text-blue-500 font-semibold text-2xl leading-[25px]">
                Loading...
              </h1>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
