import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  Icon,
  Text,
  VStack,
  Textarea,
  FormControl,
  FormLabel,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import {useRef, useState, useEffect, useCallback} from "react";
import {TbReportMoney} from "react-icons/tb";
import {crud} from "../../../crudRequests";
import {nanoid} from "nanoid";
import {prepaymentDocument} from "../../appointments/payment/uploads";
import PrepaymentCharge from "./PrepaymentCharge";
import {roundUpToTwoDecimals} from "../../../additional_files/helpers";
export const AddPrepaymentModal = ({
  isOpen,
  onClose,
  patient,
  state,
  prepayments,
  setPrepayments,
  setTotalBalance,
  defaultAmount,
}) => {
  const [formValues, setFormValues] = useState({
    amount: "0.00",
    description: "",
    paymentMethod: "cash",
    selectedGiftCard: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const {
    isOpen: isCardModalOpen,
    onOpen: onCardModalOpen,
    onClose: onCardModalClose,
  } = useDisclosure();
  const initialRef = useRef(null);
  const toast = useToast();

  const createPrepayment = async () => {
    const totalPrepaymentsCount =
      prepayments.open.length + prepayments.consumed.length;
    const nextNumber = String(totalPrepaymentsCount + 1).padStart(5, "0");

    const prepaymentPayload = {
      ...formValues,
      amount: parseFloat(formValues.amount),
      linkedPatientId: patient.pid,
      id: nanoid(10),
      createdAt: new Date().getTime(),
      balanceAvailable: parseFloat(formValues.amount),
      prepaymentNumber: nextNumber,
      receiptsUrl: [],
    };

    try {
      await crud(state, [
        {
          db: state.db,
          collection: "prepayments",
          parameters: [prepaymentPayload],
          method: "insertOne",
        },
      ]);
      await prepaymentDocument({
        state,
        prepayment: prepaymentPayload,
        patient,
      });
      setPrepayments((prev) => ({
        ...prev,
        open: [...prev.open, prepaymentPayload],
      }));
      setTotalBalance((prev) => prev + prepaymentPayload.amount);
      setFormValues({
        amount: "0.00",
        description: "",
        paymentMethod: "cash",
        selectedGiftCard: null,
      });
      onClose();
      toast({
        title: "Pre-Payment created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAmountChange = useCallback((e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^\d]/g, "");
    const limitedValue = numericValue.slice(-8);

    let cents;
    if (limitedValue.length <= 2) {
      cents = parseInt(limitedValue) || 0;
    } else {
      const dollars = limitedValue.slice(0, -2) || "0";
      const lastTwoCents = limitedValue.slice(-2);
      cents = parseInt(dollars) * 100 + parseInt(lastTwoCents);
    }

    const dollars = (cents / 100).toFixed(2);
    setFormValues((prev) => ({...prev, amount: dollars}));
  }, []);

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      amount:
        roundUpToTwoDecimals(defaultAmount) > 0
          ? roundUpToTwoDecimals(defaultAmount)
          : "0.00",
    }));
  }, [defaultAmount]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValues.amount !== "0.00" && formValues.paymentMethod !== "") {
      if (formValues.paymentMethod === "creditCard") {
        onCardModalOpen();
      } else {
        setIsLoading(true);
        await createPrepayment();
        setIsLoading(false);
      }
    } else {
      toast({
        title: "Please fill in all fields",
        status: "error",
        duration: 3000,
      });
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb="0">
          <HStack gap={1}>
            <Icon
              className="show-icon"
              boxSize={6}
              color="blue.500"
              as={TbReportMoney}
            />
            <Text
              fontSize="2xl"
              color="gray.800"
              textAlign="center"
              fontWeight={600}
            >
              Pre-Payment Registration
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt="0">
          <form id="prepayment-form" onSubmit={handleSubmit}>
            <VStack gap={3}>
              <FormControl id="method" isRequired flex={1}>
                <FormLabel fontSize={"sm"} ml={"1"} color={"gray.700"}>
                  Amount
                </FormLabel>
                {/* <Input type="number" /> */}
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.400"
                    fontSize="1.2em"
                    children="$"
                  />
                  <Input
                    placeholder="0.00"
                    value={formValues.amount}
                    onChange={handleAmountChange}
                    type="text"
                    inputMode="numeric"
                    ref={initialRef}
                  />
                </InputGroup>
              </FormControl>
              <FormControl id="description" flex={1}>
                <Textarea
                  resize={"vertical"}
                  value={formValues.description || ""}
                  onChange={(e) =>
                    setFormValues({...formValues, description: e.target.value})
                  }
                  placeholder={"Type the description..."}
                  size="sm"
                />
              </FormControl>
              <HStack w="full" align={"self-start"} gap={4}>
                <FormControl id="method" isRequired flex={1}>
                  <FormLabel fontSize={"sm"} ml={"1"} color={"gray.700"}>
                    Method
                  </FormLabel>
                  <Select
                    variant={"outline"}
                    size={"sm"}
                    rounded={"md"}
                    w={"full"}
                    value={formValues.paymentMethod}
                    onChange={(e) => {
                      let mtd = e.target.value;
                      setFormValues({...formValues, paymentMethod: mtd});
                    }}
                  >
                    <option value={"cash"}>Cash</option>
                    <option value={"check"}>Check</option>
                    <option value={"creditCard"}>Credit Card</option>
                  </Select>
                </FormControl>
              </HStack>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            type="submit"
            form="prepayment-form"
            colorScheme="blue"
            mr={3}
            loadingText="Creating Pre-Payment..."
          >
            Confirm Pre-Payment
          </Button>
          <Button
            onClick={() => {
              setFormValues({
                amount: "0.00",
                description: "",
                paymentMethod: "cash",
                selectedGiftCard: null,
              });
              onClose();
            }}
            variant="ghost"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
      <PrepaymentCharge
        state={state}
        formValues={formValues}
        isOpen={isCardModalOpen}
        onClose={onCardModalClose}
        patient={patient}
        createPrepayment={createPrepayment}
      />
    </Modal>
  );
};
