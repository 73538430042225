import {
  Box,
  Flex,
  Spinner,
  Text,
  Badge,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {crud} from "../../../crudRequests";
import {PatientBalance} from "./PatientBalance";
import {PatientPendingPayment} from "./PatientPendingPayment";
import {BillingTotal} from "./BillingTotal";
import {RelatedGiftCards} from "./RelatedGiftCards";

export const PatientAccount = ({
  patient,
  state,
  browseToBilling,
  handleBrowseToTab,
}) => {
  const toast = useToast();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [balanceData, setBalanceData] = useState({
    prePaymentsTotal: 0,
    giftCardsTotal: 0,
    membershipsTotal: 0,
    packagesTotal: 0,
  });
  const [pendingPaymentData, setPendingPaymentData] = useState({
    appointmentTotal: 0,
    membershipTotal: 0,
    packageTotal: 0,
  });
  const [billingTotal, setBillingTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [relatedGiftCards, setRelatedGiftCards] = useState([]);

  const fetchData = async () => {
    try {
      const res = await crud(state, [
        {
          db: state.db,
          collection: "giftCards",
          parameters: [{email: patient.email}],
          method: "find",
        },
        {
          db: state.db,
          collection: "billing",
          parameters: [{pid: patient.pid}],
          method: "find",
        },
        {
          db: state.db,
          collection: "prepayments",
          parameters: [{linkedPatientId: patient.pid}],
          method: "find",
        },
        {
          db: state.db,
          collection: "products",
          parameters: [{lid: state.selectedLocation}],
          method: "find",
        },
        {
          db: state.db,
          collection: "patients",
          parameters: [{pid: patient.pid}],
          method: "findOne",
        },
      ]);
      const [
        giftCardData,
        billingData,
        prePaymentData,
        productData,
        patientData,
      ] = res.data;
      return {
        giftCardData,
        billingData,
        prePaymentData,
        productData,
        patientData,
      };
    } catch (error) {
      console.error("Error fetching account data:", error);
      toast({
        title: "Error fetching account data",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const calculateBalanceData = (
    giftCardData = [],
    prePaymentData = [],
    patientData = null,
    productData = [],
    state
  ) => {
    const selectedPatient = patientData ?? patient;
    const membershipAndPackageData = selectedPatient?.memberships || [];
    const membershipData =
      membershipAndPackageData?.filter(
        (membership) =>
          membership.membership === true &&
          membership.status !== "Cancelled" &&
          membership.active
      ) || [];
    const packageData =
      membershipAndPackageData?.filter(
        (membership) =>
          membership.membership === false && membership.status !== "Cancelled"
      ) || [];
    const productMap = {};
    productData?.forEach((product) => {
      productMap[product.id] = product;
    });
    const serviceMap = {};
    state.services?.forEach((service) => {
      serviceMap[service.serviceId] = service;
    });

    return {
      prePaymentsTotal:
        prePaymentData?.reduce(
          (acc, curr) => acc + (curr?.balanceAvailable || 0),
          0
        ) || 0,
      giftCardsTotal:
        giftCardData?.reduce((acc, curr) => acc + (curr?.balance || 0), 0) || 0,
      membershipsTotal:
        membershipData?.reduce((acc, currM) => {
          if (!currM?.selectedProducts || !currM?.selectedServices) return acc;

          const productIds = Object.keys(currM.selectedProducts || {});
          const productTotal =
            productIds?.reduce((acc, currP) => {
              const currProduct = currM.selectedProducts[currP];
              if (!currProduct || !productMap[currP]) return acc;

              const productBalance =
                currProduct.quantity -
                (currProduct.reserved || 0) -
                (currProduct.consumed || 0);
              return acc + (productMap[currP].price || 0) * productBalance;
            }, 0) || 0;

          const serviceTotal =
            Object.keys(currM.selectedServices || {})?.reduce((acc, currS) => {
              const currService = currM.selectedServices[currS];
              if (!currService || !serviceMap[currS]) return acc;

              const serviceBalance =
                currService.quantity -
                (currService.reserved || 0) -
                (currService.consumed || 0);
              return (
                acc + (serviceMap[currS]?.defaultCost || 0) * serviceBalance
              );
            }, 0) || 0;

          return acc + productTotal + serviceTotal;
        }, 0) || 0,
      packagesTotal:
        packageData?.reduce((acc, currM) => {
          if (!currM?.selectedProducts || !currM?.selectedServices) return acc;

          const productIds = Object.keys(currM.selectedProducts || {});
          const productTotal =
            productIds?.reduce((acc, currP) => {
              const currProduct = currM.selectedProducts[currP];
              if (!currProduct || !productMap[currP]) return acc;

              const productBalance =
                currProduct.quantity -
                (currProduct.reserved || 0) -
                (currProduct.consumed || 0);
              return acc + (productMap[currP].price || 0) * productBalance;
            }, 0) || 0;

          const serviceTotal =
            Object.keys(currM.selectedServices || {})?.reduce((acc, currS) => {
              const currService = currM.selectedServices[currS];
              if (!currService || !serviceMap[currS]) return acc;

              const serviceBalance =
                currService.quantity -
                (currService.reserved || 0) -
                (currService.consumed || 0);
              return (
                acc + (serviceMap[currS]?.defaultCost || 0) * serviceBalance
              );
            }, 0) || 0;

          return acc + productTotal + serviceTotal;
        }, 0) || 0,
    };
  };

  useEffect(() => {
    const fetchAccountData = async () => {
      setLoading(true);
      try {
        const {
          giftCardData,
          billingData,
          prePaymentData,
          productData,
          patientData,
        } = await fetchData();
        setRelatedGiftCards(giftCardData);

        const newBalanceData = calculateBalanceData(
          giftCardData,
          prePaymentData,
          patientData,
          productData,
          state
        );

        const billingTotal =
          billingData?.reduce((acc, curr) => {
            let total = parseFloat(curr?.amount || 0);

            if (curr?.type === "membership" || curr?.type === "package") {
              total += parseFloat(curr?.membershipTaxAmount || 0);
            }

            if (curr?.type === "product") {
              total += parseFloat(curr?.productTax || 0);
            }

            return acc + total;
          }, 0) || 0;

        const newPendingPaymentData = {
          appointmentTotal: 0,
          membershipTotal: 0,
          packageTotal: 0,
        };

        billingData?.forEach((transaction) => {
          if (
            transaction?.patientPaymentStatus === "pending" &&
            !transaction?.cancelled
          ) {
            if (transaction?.type === "appointment") {
              newPendingPaymentData.appointmentTotal += parseFloat(
                transaction?.amount || 0
              );
            } else if (transaction?.type === "membership") {
              const taxAmount = parseFloat(
                transaction?.membershipTaxAmount || 0
              );
              const amountPaidByPatient = transaction?.amountPaidByPatient || 0;
              newPendingPaymentData.membershipTotal += parseFloat(
                (transaction?.amount || 0) + taxAmount
              );
              newPendingPaymentData.membershipTotal -= amountPaidByPatient;
            } else if (transaction?.type === "package") {
              const taxAmount = parseFloat(
                transaction?.membershipTaxAmount || 0
              );
              newPendingPaymentData.packageTotal += parseFloat(
                (transaction?.amount || 0) + taxAmount
              );
            }
          }
        });

        setBalanceData(newBalanceData);
        setPendingPaymentData(newPendingPaymentData);
        setBillingTotal(billingTotal);
      } catch (error) {
        console.error("Error fetching account data:", error);
        toast({
          title: "Error fetching account data",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchAccountData();
  }, [patient?.pid]);

  if (loading) {
    return (
      <Flex justify="center" align="center" h="100%" minH="400px" w="full">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const totalBalance = (
    (balanceData?.prePaymentsTotal || 0) +
    (balanceData?.membershipsTotal || 0) +
    (balanceData?.packagesTotal || 0) +
    (balanceData?.giftCardsTotal || 0)
  ).toFixed(2);

  const totalPendingPayment = (
    (pendingPaymentData?.appointmentTotal || 0) +
    (pendingPaymentData?.membershipTotal || 0) +
    (pendingPaymentData?.packageTotal || 0)
  ).toFixed(2);

  return (
    <Flex direction={{base: "column", md: "row"}} gap={6} w="full" mx="auto">
      <Box width="70%">
        <PatientBalance
          balanceData={balanceData}
          totalBalance={totalBalance}
          handleBrowseToTab={handleBrowseToTab}
          onOpen={onOpen}
        />
        <PatientPendingPayment
          pendingPaymentData={pendingPaymentData}
          totalPendingPayment={totalPendingPayment}
          handleBrowseToTab={handleBrowseToTab}
        />
        <Box
          flex={3}
          p={5}
          borderRadius="lg"
          boxShadow="md"
          bg="white"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <Flex justify="space-between" align="center">
            <Text fontWeight="bold">Net Balance</Text>
            <Badge
              colorScheme={
                totalBalance - totalPendingPayment > 0 ? "green" : "red"
              }
              fontSize="lg"
              p={2}
              borderRadius="md"
            >
              ${(totalBalance - totalPendingPayment).toFixed(2)}
            </Badge>
          </Flex>
        </Box>
      </Box>
      <BillingTotal
        billingTotal={billingTotal}
        browseToBilling={browseToBilling}
      />
      <RelatedGiftCards
        relatedGiftCards={relatedGiftCards}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};
