import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack,
  Flex,
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  TableContainer,
  Table,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {crud} from "../crudRequests";

const ProductBalancesTable = ({availableProducts, productMap}) => {
  const productIds = Object.keys(availableProducts);
  if (!productIds.length) return null;
  return (
    <TableContainer w="100%">
      <Table variant="simple">
        <Thead backgroundColor="gray.100">
          <Tr>
            <Th w="100%">Product Name</Th>
            <Th>Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          {productIds?.map((productId) => (
            <Tr key={productId}>
              <Td>{productMap[productId]?.name || "N/A"}</Td>
              <Td>{availableProducts[productId]}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
const ServiceBalancesTable = ({availableServices, serviceMap}) => {
  const serviceIds = Object.keys(availableServices);
  if (!serviceIds.length) return null;
  return (
    <TableContainer w="100%">
      <Table variant="simple">
        <Thead backgroundColor="gray.100">
          <Tr>
            <Th w="100%">Service Name</Th>
            <Th>Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          {serviceIds?.map((serviceId) => (
            <Tr key={serviceId}>
              <Td>{serviceMap[serviceId]?.serviceName || "N/A"}</Td>
              <Td>{availableServices[serviceId]}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const PaymentMethodsSummary = ({serviceMap, productMap, paymentMethodData}) => {
  if (!paymentMethodData) return <Spinner />;
  return (
    <VStack>
      <Box width="100%">
        <Flex justifyContent="space-between">
          <Text>Pre-payment Balance:</Text>
          <Text>${Number(paymentMethodData.prePaymentBalance).toFixed(2)}</Text>
        </Flex>
      </Box>
      <Box width="100%">
        <Flex justifyContent="space-between">
          <Text>Gift Cards Balance:</Text>
          <Text>${Number(paymentMethodData.giftCardBalance).toFixed(2)}</Text>
        </Flex>
      </Box>
      <Box width="100%">
        <ServiceBalancesTable
          serviceMap={serviceMap}
          availableServices={paymentMethodData.membershipBalances.services}
        />
        <ProductBalancesTable
          productMap={productMap}
          availableProducts={paymentMethodData.membershipBalances.products}
        />
      </Box>
    </VStack>
  );
};

export const PatientBalancesModal = ({patient, state, products}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const productMap = products.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});
  const [serviceMap, setServiceMap] = useState({});
  const [paymentMethodData, setPaymentMethodData] = useState(null);
  useEffect(() => {
    const fetchPaymentMethodData = async () => {
      const res = await crud(state, [
        {
          db: state.db,
          collection: "giftCards",
          parameters: [{email: patient.email}],
          method: "find",
        },

        {
          db: state.db,
          collection: "prepayments",
          parameters: [{linkedPatientId: patient.pid}],
          method: "find",
        },
        {
          db: state.db,
          collection: "patients",
          parameters: [{pid: patient.pid}],
          method: "findOne",
        },
        {
          db: state.db,
          collection: "services",
          parameters: [{}],
          method: "find",
        },
      ]);
      const [giftCardData, prePaymentData, latestPatientData, servicesData] =
        res.data;
      const activeMembOrPkgs =
        (latestPatientData.memberships || []).filter((membOrPkg) => {
          const apptDate = new Date();
          const startDate = new Date(membOrPkg.startDate);
          const endDate = new Date(membOrPkg.endDate);
          return (
            membOrPkg.active &&
            (!membOrPkg.membership ||
              (apptDate >= startDate && apptDate <= endDate))
          );
        }) || [];
      const membershipBalances = {
        services: {},
        products: {},
      };
      for (const membOrPkg of activeMembOrPkgs) {
        const availableProductIds = Object.keys(
          membOrPkg.selectedProducts || {}
        );
        const availableServiceIds = Object.keys(
          membOrPkg.selectedServices || {}
        );
        for (const productId of availableProductIds) {
          const membProductData = membOrPkg.selectedProducts[productId];
          const membProductBalance =
            membProductData.quantity -
            (membProductData.consumed || 0) -
            (membProductData.reserved || 0);
          membershipBalances.products[productId] =
            (membershipBalances.products[productId] || 0) + membProductBalance;
        }
        for (const serviceId of availableServiceIds) {
          const membServiceData = membOrPkg.selectedServices[serviceId];
          const membServiceBalance =
            membServiceData.quantity -
            (membServiceData.consumed || 0) -
            (membServiceData.reserved || 0);
          membershipBalances.services[serviceId] =
            (membershipBalances.services[serviceId] || 0) + membServiceBalance;
        }
      }
      const prePaymentBalance =
        prePaymentData?.reduce((acc, curr) => acc + curr.balanceAvailable, 0) ||
        0;
      const giftCardBalance =
        giftCardData?.reduce((acc, curr) => acc + curr.balance, 0) || 0;
      setPaymentMethodData({
        giftCardData,
        prePaymentData,
        prePaymentBalance,
        giftCardBalance,
        membershipData: latestPatientData.memberships,
        membershipBalances,
      });
      setServiceMap(
        servicesData.reduce((acc, curr) => {
          acc[curr.serviceId] = curr;
          return acc;
        }, {})
      );
    };
    setPaymentMethodData(null);
    if (patient?.pid) {
      fetchPaymentMethodData();
    }
  }, [patient?.pid]);
  if (!patient) return null;
  return (
    <>
      <Button
        onClick={onOpen}
        backgroundColor="#9F9DFA"
        opacity={0.8}
        _hover={{opacity: 1}}
        color="white"
        fontWeight="light"
        width="100%"
        marginY="10px"
        isDisabled={!paymentMethodData}
      >
        {paymentMethodData ? "View Items Available to Consume" : <Spinner />}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Available Items to Consume</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PaymentMethodsSummary
              patient={patient}
              state={state}
              productMap={productMap}
              serviceMap={serviceMap}
              paymentMethodData={paymentMethodData}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              color="black"
              fontWeight="light"
              marginTop="10px"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const PatientBalancesModalWithoutProducts = ({
  patient,
  state,
  services,
}) => {
  const [products, setProducts] = useState();
  useEffect(() => {
    const fetchProducts = async () => {
      const res = await crud(state, [
        {
          db: state.db,
          collection: "products",
          method: "find",
          parameters: [{lid: state.selectedLocation}],
        },
      ]);
      setProducts(res.data[0]);
    };
    fetchProducts();
  }, []);
  if (!products) return null;
  return (
    <PatientBalancesModal
      patient={patient}
      state={state}
      products={products}
      services={services}
    />
  );
};
