import {useEffect} from "react";

/**
 * Custom hook to globally prevent unwanted back navigation when scrolling horizontally
 * with a trackpad on Mac devices.
 */

const useGlobalPreventBackNavigation = () => {
  useEffect(() => {
    const preventBackNavigation = (e) => {
      let element = e.target;

      while (element && element !== document.body) {
        const hasHorizontalScroll =
          element.scrollWidth > element.clientWidth &&
          getComputedStyle(element).overflowX !== "hidden";

        if (hasHorizontalScroll) {
          if (element.scrollLeft === 0 && e.deltaX < 0) {
            e.preventDefault();
            return;
          }
          break;
        }

        element = element.parentElement;
      }
    };

    window.addEventListener("wheel", preventBackNavigation, {
      passive: false,
    });

    return () => {
      window.removeEventListener("wheel", preventBackNavigation);
    };
  }, []);
};

export default useGlobalPreventBackNavigation;
