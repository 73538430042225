import {
  useDisclosure,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerFooter,
  Button,
  Box,
} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";
import AddTaskDrawer from "./AddTaskDrawer";

export const AddTasksMobile = ({
  dashState,
  patients,
  setTasks,
  doctors,
  groupedOptions,
  patientsArr,
  admins,
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <Box w="10%">
      <IconButton colorScheme="blue" onClick={onOpen} icon={<AddIcon />} />
      <Drawer isOpen={isOpen} size="full" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent p={4}>
          <DrawerCloseButton />
          <DrawerHeader>Add Task</DrawerHeader>
          <AddTaskDrawer
            dashState={dashState}
            patients={patients}
            setTasks={setTasks}
            doctors={doctors}
            groupedOptions={groupedOptions}
            onClose={onClose}
            patientsArr={patientsArr}
            admins={admins}
          />
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
