import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
} from "@chakra-ui/react";

import Swal from "sweetalert2";
import NotesOnHover from "./appointments/calendar/NotesOnHover";
import {useDebouncedCallback} from "use-debounce";
import {
  startOfWeek,
  differenceInDays,
  startOfMonth,
  endOfMonth,
  add,
  sub,
  isSameDay,
  lightFormat,
  endOfWeek,
  isWithinInterval,
  eachDayOfInterval,
  differenceInMinutes,
  startOfDay,
  format,
} from "date-fns";
import PartialPaidIcon from "./appointments/AppointmentModal.js/PartialPaidIcon";
import ApptType from "./appointments/AppointmentModal.js/ApptType";
import {canDrop, dropUpdatePayload} from "../additional_files/dragAndDrop";
import {notificationsData} from "../additional_files/notifications";
import {DatePickerModal} from "./DatePicker";
import DatePicker from "./DatePicker";
import {SetTimeLineIndicator, SetTimeLine} from "./calendarElements/timeLines";
import {
  isoToApptDate,
  appointmentsInDays,
  appointmentAvailable,
} from "../additional_files/helpers";
import {crud} from "../crudRequests";
import {SocketContext} from "../additional_files/context";

import swal from "sweetalert";
import {Box, Icon, Text, Tooltip} from "@chakra-ui/react";
import verifyAccess from "../utils/verifyAccess";
import renderBlurredText from "../utils/renderBlurredText";
import {createHashTable} from "../utils/createHashTable";
import {GiHealthNormal} from "react-icons/gi";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const timesIndicators = [
  "12AM",
  "1AM",
  "2AM",
  "3AM",
  "4AM",
  "5AM",
  "6AM",
  "7AM",
  "8AM",
  "9AM",
  "10AM",
  "11AM",
].concat([
  "12PM",
  "1PM",
  "2PM",
  "3PM",
  "4PM",
  "5PM",
  "6PM",
  "7PM",
  "8PM",
  "9PM",
  "10PM",
  "11PM",
]);
const dayOfWeekIndicators = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function AppointmentBoard(props) {
  const {
    date,
    schState,
    showDatePicker,
    setDate,
    setGridRef,
    doctorsFilterMap,
    appointmentsToShow: appointments,
    appointmentsByDoctor,
    view,
    setView,
    setBookRef,
    calendarContainerRef,
    setShowDatePicker,
    windowWidth,
  } = props;

  const gridRef = useRef(null);
  const dropRef = useRef(null);
  const dropData = useRef(null);
  const [lineData, setLineData] = useState(null);
  const [cursorData, setCursorData] = useState(null);
  const droppedAppt = useRef(null);
  const blockDrop = useRef(false);
  const defaultData = useRef(false);
  const [deltaY, setDeltaY] = useState({value: 0});
  const [availableSlots, setAvailableSlots] = useState([]);
  const patients = useMemo(
    () => createHashTable(schState.patients, "pid"),
    [schState.patients.length]
  );

  const socket = useContext(SocketContext);

  const setDropData = (geometry, ap) => {
    if (!blockDrop.current) {
      dropData.current = geometry;
      droppedAppt.current = ap;
    }
  };

  const InsurersMap = useMemo(() => {
    return (schState.insurers || []).reduce((acc, el) => {
      acc[el.iid] = el;
      return acc;
    }, {});
  }, []);

  function setAppointmentCards(date, appointments) {
    let lengthPerMinute = 192 / 1440;
    let start = startOfWeek(date, {weekStartsOn: 1});
    let res = appointments.map((appt, i) => {
      if (!patients[appt.pid]) return null;
      return (
        <AppointmentCard
          key={i}
          {...{
            ...props,
            colorsByStatus,
            appt,
            startOfWeek: start,
            lengthPerMinute,
            dropRef,
            setDropData,
            view,
            schState,
            InsurersMap,
            patients,
            calendarContainerRef,
          }}
          setGridRef={() => setGridRef(gridRef.current)}
        />
      );
    });

    return res;
  }

  function setUnavailableSlots(date, unavailableSlots) {
    let lengthPerMinute = 192 / 1440;
    let start = startOfWeek(date, {weekStartsOn: 1});

    return unavailableSlots.map((slotCard, i) => (
      <UnavaibleSlotCard
        key={i}
        {...{
          ...props,
          startOfWeek: start,
          lengthPerMinute,
          slotCard,
        }}
      />
    ));
  }
  /*function AvailableSlots(date, availableSlots) {
    let lengthPerMinute = 192 / 1440;
    let start = startOfWeek(date, { weekStartsOn: 1 });

    return availableSlots.map((slotCard, i) => (
      <AvaibleSlotCard
        key={i}
        {...{
          ...props,
          startOfWeek: start,
          lengthPerMinute,
          slotCard,
        }}
      />
    ));
  }*/

  function colorsByStatus(appt) {
    let colorsMap = {
      noShow: "#000000",
      cancelled: "#FF0000",
      arrived: "#2dd4bf",
      pConfirmed: "#5754FF",
      unconfirmed: "#8F8CFF",
      rescheduled: "#FF9900",
    };

    for (let [key, val] of Object.entries(colorsMap)) {
      if (appt[key]) return val;
    }
    return "#8F8CFF";
  }

  let vDivisions = (n) =>
    Array(n)
      .fill(0)
      .map((e, i) => (
        <div
          key={i}
          className="division h-full flex-1 border-l last:border-r"
        ></div>
      ));

  useLayoutEffect(() => {
    try {
      let pixPerMin = gridRef.current.scrollHeight / 1470;
      let start = startOfDay(new Date());
      let s = startOfDay(new Date());
      let calendarSartsAt = schState.selectedDoctor
        ? schState.selectedDoctor.calendarSartsAt || "07:00"
        : schState.admin?.calendarSartsAt || "07:00";

      s.setHours(...calendarSartsAt.split(":").concat(0));
      let diff = differenceInMinutes(s, start, {
        roundingMethod: "floor",
      });

      gridRef.current.scrollTop = pixPerMin * diff;
    } catch (e) {
      console.log(e);
    }
  }, [view, props.selectedDoctor, schState.admin]);

  useEffect(() => {
    let id = setTimeout(() => {
      if (deltaY.value > 0) setDate((d) => add(d, {months: 1}));
      else if (deltaY.value < 0) setDate((d) => sub(d, {months: 1}));
    }, 200);
    return () => clearTimeout(id);
  }, [deltaY]);

  useLayoutEffect(() => {
    let id = setTimeout(() => {
      setLineData(cursorData);
    }, 10);
    return () => {
      clearTimeout(id);
      //setLineData(null)
    };
  }, [cursorData]);

  const onBoardClick = (e) => {
    if (!schState.selectedAppointment) {
      setBookRef(defaultData.current);
    }
  };

  useEffect(() => {
    if ((view === "week" && props.selectedDoctor) || view === "day") {
      let dragover = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = canDrop(
          dropData.current,
          dropRef.current,
          view === "day" && !props.selectedDoctor
        )
          ? "move"
          : "none";
      };

      let dragenter = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
      };

      let drop = async (e) => {
        if (!blockDrop.current) {
          blockDrop.current = true;
          let ap = droppedAppt.current;
          let payload = dropUpdatePayload(
            dropData.current,
            dropRef.current,
            ap,
            view,
            view === "day" && !props.selectedDoctor,
            schState,
            doctorsFilterMap
          );
          let {_id, ...data} = payload;

          if (
            new Date(ap.ISOdate).getTime() !==
              new Date(payload.ISOdate).getTime() ||
            ap.did !== payload.did
          ) {
            if (ap?.recurrenceId) {
              const result = await Swal.fire({
                title: "Recurring event",
                text: "This event belongs to a series of recurring appointments, are you sure you want to reschedule it?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
              });
              if (!result?.isConfirmed) return;
              if (result?.isConfirmed) {
                data.recurrenceId = payload.recurrenceId = "";
              }
            }

            const org = props.schState?.organization;
            let pt = schState.patients.find((p) => p.pid === payload.pid);
            let dids = null;
            async function update_appt() {
              let location = props.schState?.locations.find(
                (l) => l.lid === props.schState?.selectedLocation
              );
              let sender = location?.name;
              const emailOption = org?.emailNotification
                ? {
                    type: "update_appointment",
                    content: data,
                    options: {
                      reschedule: payload.ISOdate,
                    },
                    sender,
                    org: props.schState.organization,
                    location,
                  }
                : null;

              const smsOption = org?.textNotification
                ? {
                    to: payload.pPhone,
                    sms:
                      "Dear " +
                      payload.pName +
                      ", Your appointment with " +
                      payload.dName +
                      " has been rescheduled" +
                      " for " +
                      new Date(payload.ISOdate)
                        .toString()
                        .replace(/\(.+\)/g, "") +
                      ". Sincerely, Brightlight Health Inc.",
                  }
                : null;

              crud(
                props.schState,
                [
                  {
                    db: props.schState.db,
                    collection: "appointments",
                    parameters: [{aid: payload.aid}, {$set: data}],
                    method: "updateOne",
                  },
                  {
                    db: props.schState.db,
                    collection: "billing",
                    parameters: [
                      {aid: payload.aid},
                      {
                        $set: {
                          serviceDate: data.ISOdate,
                          did: data.did,
                          dName: data.dName,
                          dEmail: data.dEmail,
                        },
                      },
                    ],
                    method: "updateOne",
                  },
                ],
                {
                  apptReminderNotification: {
                    appt: data,
                    sender,
                    org: props.schState.organization,
                    location,
                  },
                  email: emailOption,
                  sms: smsOption,
                }
              )
                .then((res) => {
                  socket?.current.emit?.("update_appt", payload, {
                    ntf,
                    changingDoctor: ap.did !== payload.did && ap.did,
                  });

                  let requestObjs = [
                    {
                      db: props.schState.db,
                      collection: "notifications",
                      parameters: [
                        {userType: ntfList[0].userType},
                        {$push: {[ntfList[0].id]: ntfList[0].notification}},
                        {upsert: true},
                      ],
                      method: "findOneAndUpdate",
                    },
                    {
                      db: props.schState.db,
                      collection: "notifications",
                      parameters: [
                        {userType: ntfList[1].userType},
                        {$push: {[ntfList[1].id]: ntfList[0].notification}},
                        {upsert: true},
                      ],
                      method: "findOneAndUpdate",
                    },
                  ];
                  crud(props.schState, requestObjs);

                  blockDrop.current = false;
                })
                .catch(function (error) {
                  blockDrop.current = false;
                  props.dispatch({
                    type: "UPDATE_APPOINTMENT",
                    payload: ap,
                    changingDoctor: ap.did !== payload.did && payload.did,
                  });
                });
            }

            function update_pt() {
              crud(schState, [
                {
                  db: props.schState.db,
                  collection: "patients",
                  parameters: [{pid: payload.pid}, {$set: {did: dids}}],
                  method: "updateOne",
                },
              ]);

              props.dispatch({
                type: "UPDATE_PATIENT",
                pid: payload.pid,
                payload: {...pt, did: dids},
                options: {newDoctor: payload.did},
              });

              socket?.current.emit?.(
                "update_pt",
                payload.pid,
                {...pt, did: dids},
                {newDoctor: payload.did},
                [payload.did, ap.did, payload.lid + payload.oid, ap.pid]
              );
            }

            async function checkDid() {
              if (Array.isArray(pt.did)) {
                if (!pt.did.includes(payload.did)) {
                  dids = pt.did.concat(payload.did);
                  return Swal.fire({
                    title: "Confirm new clinician!",
                    text: `${payload.pName} is not currently a patient of ${payload.dName}. Are you sure you want to assign this appointment to a different doctor?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Confirm",
                  });
                }
              } else {
                if (pt.did !== payload.did) {
                  dids = [pt.did, payload.did];
                  return Swal.fire({
                    title: "Confirm new clinician!",
                    text: `${payload.pName} is not currently a patient of ${payload.dName}. Are you sure you want to assign this appointment to a different doctor?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Confirm",
                  });
                }
              }
              return null;
            }

            async function confirmationModal() {
              const formattedDate = format(
                new Date(payload.ISOdate),
                "EEEE, MMMM d, yyyy, h:mm a"
              );
              const dataDoctor = `Dr. ${data?.dName}`;
              const apDoctor = `Dr. ${ap?.dName}`;

              const message =
                ap.dName !== data?.dName && ap.ISOdate !== payload.ISOdate
                  ? `The appointment has been reassigned from ${dataDoctor} to ${apDoctor} and rescheduled for patient ${ap.pName} to ${formattedDate}. Do you want to commit to this change?`
                  : ap.dName !== data?.dName
                  ? `The appointment has been reassigned from ${dataDoctor} to ${apDoctor} for patient ${ap.pName}. Do you want to commit to this change?`
                  : `The appointment for ${dataDoctor} and patient ${ap.pName} has been rescheduled to ${formattedDate}. Do you want to commit to this change?`;

              return Swal.fire({
                title: "Appointment Reschedule Confirmation",
                text: message,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
              });
            }

            let unavaibleIntervals = props.unavailableSlots
              .filter((u) => {
                if (!props.selectedDoctor) return u.did === payload.did;
                return true;
              })
              .map((e) => e.interval);

            props.dispatch({
              type: "UPDATE_APPOINTMENT",
              payload,
              changingDoctor: ap.did !== payload.did && ap.did,
            });

            let {ntf, ntfList} = notificationsData(
              props.schState.userType,
              "Updated appointment",
              payload,
              schState
            );

            if (!appointmentAvailable(unavaibleIntervals, payload))
              swal({
                title:
                  "Are you sure you want to reschedule this appointment for an unavailable time?",
                text: `This appointment will be rescheduled for ${payload.date} at ${payload.time} in your local time.`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (value) => {
                if (value) {
                  let check = await checkDid();
                  if (check) {
                    if (check.isConfirmed) {
                      await update_appt();
                      update_pt();
                    } else {
                      blockDrop.current = false;
                      props.dispatch({
                        type: "UPDATE_APPOINTMENT",
                        payload: {...ap, rescheduled: ap.rescheduled ?? false},
                        changingDoctor: ap.did !== payload.did && payload.did,
                      });
                    }
                  } else await update_appt();
                } else {
                  blockDrop.current = false;
                  props.dispatch({
                    type: "UPDATE_APPOINTMENT",
                    payload: {...ap, rescheduled: ap.rescheduled ?? false},
                    changingDoctor: ap.did !== payload.did && payload.did,
                  });
                }
              });
            else {
              let check = null;
              if (org?.confirmationMessage) {
                const confirmation = await confirmationModal();
                if (confirmation.isConfirmed) {
                  check = await checkDid();
                } else {
                  blockDrop.current = false;
                  props.dispatch({
                    type: "UPDATE_APPOINTMENT",
                    payload: {...ap, rescheduled: ap.rescheduled ?? false},
                    changingDoctor: ap.did !== payload.did && payload.did,
                  });
                }
              } else {
                check = await checkDid();
              }

              if (check) {
                if (check.isConfirmed) {
                  await update_appt();
                  update_pt();
                } else {
                  blockDrop.current = false;
                  props.dispatch({
                    type: "UPDATE_APPOINTMENT",
                    payload: {...ap, rescheduled: ap.rescheduled ?? false},
                    changingDoctor: ap.did !== payload.did && payload.did,
                  });
                }
              } else await update_appt();
            }
          } else {
            blockDrop.current = false;
          }
        }
      };

      const mousemove = (e) => {
        if (
          e.target === dropRef.current &&
          ((view === "week" && props.selectedDoctor) || view === "day")
        ) {
          setCursorData({
            x: e.offsetX,
            y: e.offsetY,
          });
        }

        // else setCursorData(null)
      };

      dropRef.current.addEventListener("dragover", dragover);
      dropRef.current.addEventListener("dragenter", dragenter);
      dropRef.current.addEventListener("drop", drop);
      dropRef.current.addEventListener("mousemove", mousemove);
      // dropRef.current.addEventListener("click", onBoardClick);

      let a = dropRef.current;
      return () => {
        a.removeEventListener("dragover", dragover);
        a.removeEventListener("dragenter", dragenter);
        a.removeEventListener("drop", drop);
        a.removeEventListener("mousemove", mousemove);
        // a.removeEventListener("click", onBoardClick);
      };
    }
  }, [view, props.selectedDoctor, props.unavailableSlots, socket]);

  let display = <></>;

  switch (view) {
    case "day":
      display = (
        <div
          className="show flex flex-1 flex-col border-b overflow-y-auto z-60 border-r border-l  pr-[52px] board -mt-[2rem]"
          ref={(e) => {
            gridRef.current = e;
            setGridRef(gridRef.current);
          }}
        >
          <div className="relative flex w-full flex-1  border-b pl-14">
            <div className="flex flex-col flex-1 relative">
              <div
                className="absolute inset-0 z-10 overflow-hidden"
                ref={dropRef}
                onMouseLeave={() => setCursorData(null)}
                onClick={() => {
                  ((view === "week" && props.selectedDoctor) ||
                    view === "day") &&
                    onBoardClick();
                }}
              >
                {setAppointmentCards(date, appointments)}
                {setUnavailableSlots(date, props.unavailableSlots)}
                <SetTimeLineIndicator />
                <SetTimeLine
                  {...{
                    date,
                    view,
                    schState,
                    selectedDoctor: props.selectedDoctor,
                    doctorsFilterMap,
                    lineData,
                    boardRef: dropRef.current,
                    defaultData,
                  }}
                />
              </div>

              <div className="h-16  border-t flex">
                {props.selectedDoctor ? vDivisions(1) : vDivisions(7)}
              </div>
              {timesIndicators.map((timeIndicator, i) => {
                return (
                  <div key={i} className="relative">
                    <div className="h-16  border-t  flex hover:cursor-pointer">
                      <div className=" sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {timeIndicator}
                      </div>
                      <div className=" absolute -right-9 -top-2 text-right text-xs leading-5 text-gray-400">
                        {timeIndicator}
                      </div>
                      {props.selectedDoctor ? vDivisions(1) : vDivisions(7)}
                    </div>
                    <div className="h-16  border-t flex">
                      {props.selectedDoctor ? vDivisions(1) : vDivisions(7)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );

      break;

    case "week":
      display = (
        <div
          className={
            props.selectedDoctor
              ? "show flex flex-1 flex-col border-b overflow-auto border-r border-l  pr-[52px] board -mt-[2rem]"
              : "show flex flex-1 flex-col border-b overflow-y-scroll border-r border-l  pr-[52px] board"
          }
          ref={gridRef}
        >
          <div className="relative flex flex-1 w-full  border-b">
            <div className="w-14"></div>

            <div className="flex flex-col flex-1 relative">
              <div
                className="absolute inset-0 z-10 overflow-hidden"
                ref={dropRef}
                onClick={() => {
                  ((view === "week" && props.selectedDoctor) ||
                    view === "day") &&
                    onBoardClick();
                }}
                onMouseLeave={() => setCursorData(null)}
              >
                {props.selectedDoctor ? (
                  setAppointmentCards(date, appointments)
                ) : (
                  <WeeklymultiDoctorCards
                    {...{
                      date,
                      setView,
                      setDate,
                      appointments,
                      colorsByStatus,
                      doctorsFilterMap: props.doctorsFilterMap,
                      dispatch: props.dispatch,
                      doctors: props.doctors,
                      colorSetter: props.colorSetter,
                    }}
                  />
                )}

                {props.selectedDoctor &&
                  setUnavailableSlots(date, props.unavailableSlots)}

                {props.selectedDoctor && (
                  <SetTimeLine
                    {...{
                      date,
                      view,
                      schState,
                      selectedDoctor: props.selectedDoctor,
                      doctorsFilterMap,
                      lineData,
                      boardRef: dropRef.current,
                      defaultData,
                    }}
                  />
                )}
                {props.selectedDoctor && <SetTimeLineIndicator />}
              </div>

              {props.selectedDoctor && (
                <div className="h-16  border-t flex">{vDivisions(7)}</div>
              )}
              {(props.selectedDoctor
                ? timesIndicators
                : dayOfWeekIndicators
              ).map((Indicator, i) => {
                return (
                  <div
                    key={i}
                    className={
                      props.selectedDoctor
                        ? "relative"
                        : "relative flex-1  min-h-[4rem]"
                    }
                  >
                    <div
                      className={
                        props.selectedDoctor
                          ? "h-16  border-t  flex"
                          : "h-full  border-t  flex justify-center items-center"
                      }
                    >
                      <div
                        className={
                          "sticky left-0 -mt-2.5 -ml-14 w-14 pr-2.5 text-right text-xs leading-5 text-gray-400"
                        }
                      >
                        {Indicator}
                      </div>
                      <div
                        className={
                          props.selectedDoctor
                            ? "absolute -right-9 -top-2.5 text-right text-xs leading-5 text-gray-400"
                            : "absolute -right-9 text-right text-xs leading-5 text-gray-400"
                        }
                      >
                        {Indicator}
                      </div>
                      {vDivisions(7)}
                    </div>
                    {props.selectedDoctor && (
                      <div className="h-16  border-t flex">{vDivisions(7)}</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );

      break;

    case "month":
      display = (
        <div
          className="flex flex-1 flex-col  overflow-auto  board  border "
          ref={gridRef}
        >
          <div className="relative flex w-full h-full flex-1">
            <div
              className={
                "isolate flex-1 h-full grid grid-cols-7 gap-px auto-rows-fr bg-gray-200 text-sm"
              }
              onWheel={(e) => {
                setDeltaY({value: e.deltaY});
              }}
            >
              <DaysOfMonth
                {...{
                  date,
                  setView,
                  setDate,
                  appointments,
                  colorsByStatus,
                  colorSetter: props.colorSetter,
                }}
              />
            </div>
          </div>
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <div className="flex flex-1 z-[7] overflow-hidden bg-white relative w-full ">
      {display}
      {view === "day" && props.selectedDoctor && windowWidth > 1024 ? (
        <div className="hidden w-1/2 max-w-md flex-none  py-5  px-5 lg:flex">
          <DatePicker
            {...{
              schState,
              date,
              setDate,
              showDatePicker,
              view,
              appointmentsByDoctor,
              selectedDoctor: props.selectedDoctor,
            }}
          />
        </div>
      ) : (
        showDatePicker && (
          <DatePickerModal
            isOpen={showDatePicker}
            onClose={() => setShowDatePicker(false)}
          >
            <DatePicker
              {...{
                schState,
                date,
                setDate,
                showDatePicker,
                view,
                appointmentsByDoctor,
                selectedDoctor: props.selectedDoctor,
              }}
            />
          </DatePickerModal>
        )
      )}
    </div>
  );
}

function WeeklymultiDoctorCards({
  date,
  setView,
  setDate,
  appointments,
  colorsByStatus,
  doctorsFilterMap,
  dispatch,
  doctors,
  colorSetter,
}) {
  let start = startOfWeek(date, {weekStartsOn: 1});
  let apptsInDaysMap = appointmentsInDays(appointments);
  let apptsInDayByDoctorsMap = {};
  for (let did of Object.keys(doctorsFilterMap)) {
    apptsInDayByDoctorsMap[did] = {};
    for (let [key, value] of apptsInDaysMap.entries())
      apptsInDayByDoctorsMap[did][key] = value.filter((e) => e.did === did);
  }

  let result = Object.entries(apptsInDayByDoctorsMap).reduce(
    (ac, [did, apptsDaysObj]) => {
      for (let [dateString, apptsAr] of Object.entries(apptsDaysObj)) {
        if (apptsAr.length !== 0) {
          let top = `${
            differenceInDays(new Date(apptsAr[0].ISOdate), start) * 14.2857
          }%`;
          let left = `calc(${doctorsFilterMap[did] * 14.2857}%)`;
          let style = {
            height: "14.2857%",
            top,
            left,
            // backgroundColor: colorsByStatus(appt),
            width: "14.2857%",
          };
          ac.push(
            <div
              className="absolute z-10 hover:cursor-pointer  rounded-md text-sm text-white flex justify-center items-center"
              style={style}
              onClick={() => {
                dispatch({
                  type: "SELECT_DOCTOR",
                  payload: doctors.find((e) => e.did === did),
                });
              }}
              key={dateString + did}
            >
              <div className="h-full flex-1 overflow-hidden w-full">
                {
                  <ul
                    className="max-h-full w-full overflow-auto space-y-[1px] sbar p-[2px]"
                    onWheel={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {apptsAr.map((a, i) => {
                      let {date, time} = a.ISOdate
                        ? isoToApptDate(a)
                        : {date: a.date, time: a.time};
                      let st = {
                        backgroundColor: colorSetter
                          ? colorSetter(a) || colorsByStatus(a)
                          : colorsByStatus(a),
                      };
                      return (
                        <li
                          key={i}
                          style={st}
                          className="space-y-1 text-white bg-slate-500 rounded-md px-[2px]"
                        >
                          <p className={"space-x-1 text-center text-[0.6rem]"}>
                            <span>{time}</span>
                            <span>{a.pName}</span>
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                }
              </div>
            </div>
          );
        }
      }
      return ac;
    },
    []
  );

  return result;
}

function UnavaibleSlotCard(props) {
  let {
    lengthPerMinute,
    startOfWeek,
    view,
    interval,
    doctorsFilterMap,
    slotCard,
  } = props;

  let start = new Date(slotCard.interval[0]);
  let end = new Date(slotCard.interval[1]);
  const note = slotCard.interval[2] || "";

  let height = `${
    (parseInt((end.getTime() - start.getTime()) / 60000) / 147) * 10
  }%`;
  let top = `${
    differenceInMinutes(start, startOfDay(start)) * lengthPerMinute + 4
  }rem`;

  let marging = 2;
  let left = props.selectedDoctor
    ? `calc(${
        differenceInDays(start, startOfWeek) *
        (props.view === "day" ? 0 : 14.2857)
      }% + ${1}px)`
    : `calc(${props.doctorsFilterMap[slotCard.did] * 14.2857}% + ${0}px)`;

  let width = props.selectedDoctor
    ? `calc(${view === "day" ? 100 : 14.2857}% - ${0}px)`
    : `calc(${14.2857}% - ${0}px)`;

  let style = {
    height,
    top,
    left,
    width,
  };

  return (
    <div
      style={style}
      className="absolute z-1 bg-[#ebe9fd] rounded-md text-sm text-gray-500 p-3 flex flex-col"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-center items-center h-full w-full">
        <Tooltip label={note} isDisabled={note.length <= 30}>
          <div style={{maxWidth: "100%", width: "100%"}}>
            <p
              className="text-base font-medium text-center"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                width: "100%",
                maxHeight: "7.5rem",
                fontSize: "1rem",
              }}
            >
              {note}
            </p>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

function AvaibleSlotCard(props) {
  let {
    lengthPerMinute,
    startOfWeek,
    view,
    interval,
    doctorsFilterMap,
    slotCard,
  } = props;

  let start = new Date(slotCard.interval[0]);
  let end = new Date(slotCard.interval[1]);

  let height = `${
    (parseInt((end.getTime() - start.getTime()) / 60000) / 147) * 10
  }%`;
  let top = `${
    differenceInMinutes(start, startOfDay(start)) * lengthPerMinute + 4
  }rem`;

  let marging = 2;
  let left = props.selectedDoctor
    ? `calc(${
        differenceInDays(start, startOfWeek) *
        (props.view === "day" ? 0 : 14.2857)
      }% + ${1}px)`
    : `calc(${props.doctorsFilterMap[slotCard.did] * 14.2857}% + ${0}px)`;

  let width = props.selectedDoctor
    ? `calc(${view === "day" ? 100 : 14.2857}% - ${0}px)`
    : `calc(${14.2857}% - ${0}px)`;

  let style = {
    height,
    top,
    left,
    width,
  };

  return (
    <div
      style={style}
      className="absolute z-1 bg-[#eae469]  rounded-md text-sm text-white p-3 pr-1"
      onClick={(e) => e.stopPropagation()}
    ></div>
  );
}

function AppointmentCard(props) {
  let {
    appt,
    lengthPerMinute,
    startOfWeek,
    setApptPosition,
    setGridRef,
    setAppointmentRef,
    setDropData,
    view,
    schState,
    colorSetter,
    colorsByStatus,
    colorCriteria,
    InsurersMap,
    patients,
    calendarContainerRef,
  } = props;

  const targetRef = useRef();
  const [showNotes, setShowNotes] = useState(false);

  const onLeaveNotes = useDebouncedCallback(() => {
    if (targetRef.current !== "appt") setShowNotes(false);
  }, 300);

  const showNotesDebounce = useDebouncedCallback(() => {
    setShowNotes(true);
  }, 500);
  const hideNotesDebounce = useDebouncedCallback(() => {
    showNotesDebounce.cancel();
    setShowNotes(false);
  }, 300);

  const apptCardRef = useRef(null);

  const blurredName = useMemo(
    () => renderBlurredText(appt.pName),
    [appt.pName]
  );
  let {date, time} = appt.ISOdate
    ? isoToApptDate(appt)
    : {date: appt.date, time: appt.time};
  let month = date.split(",")[1].split(" ")[1];
  let day = parseInt(date.split(",")[1].split(" ")[2]);
  let year = parseInt(date.split(",")[2]);
  let apptDate = new Date(
    year,
    months.findIndex((m) => m === month),
    day
  );
  let height =
    parseInt(appt.duration.split(" ")[0]) >= 15
      ? `${(parseInt(appt.duration.split(" ")[0]) / 147) * 10}%`
      : null;
  let PM_AM = time.split(":")[1].split(" ")[1];
  let h =
    PM_AM === "AM" && parseInt(time.split(":")[0]) === 12
      ? 0
      : parseInt(time.split(":")[0]);
  let m = parseInt(time.split(":")[1].split(" ")[0]);
  let top = `${
    h * 8 +
    (PM_AM === "PM" && parseInt(time.split(":")[0]) !== 12 ? 12 * 8 : 0) +
    m * lengthPerMinute +
    4
  }rem`;

  let marging = 0;
  let left = props.selectedDoctor
    ? `calc(${
        differenceInDays(new Date(appt.ISOdate) || apptDate, startOfWeek) *
        (props.view === "day" ? 0 : 14.2857)
      }% + ${1}px)`
    : `calc(${props.doctorsFilterMap[appt.did] * 14.2857}% + ${1}px)`;

  let width = props.selectedDoctor
    ? `calc(${props.view === "day" ? 100 : 14.2857}% - ${2}px)`
    : `calc(${14.2857}% - ${2}px)`;

  useEffect(() => {
    let l = 0;
    let t = 0;

    let dragstart = (e) => {
      l = e.offsetX;
      t = e.offsetY;
      e.dataTransfer.setData("text", JSON.stringify(appt));
    };

    let drag = (e) => {
      setDropData(
        {
          x: e.target.offsetLeft + e.offsetX - l,
          y: e.target.offsetTop + e.offsetY - t,
          height: apptCardRef.current.offsetHeight,
          width: apptCardRef.current.offsetWidth,
          vUnit: props.doctorsFilterMap[appt.did],
          doctorsCount: Object.keys(props.doctorsFilterMap).length,
        },
        appt
      );
    };
    apptCardRef.current.addEventListener("dragstart", dragstart);
    apptCardRef.current.addEventListener("drag", drag);

    let c = apptCardRef.current;
    return () => {
      c.removeEventListener("dragstart", dragstart);
      c.removeEventListener("drag", drag);
    };
  }, [appt]);

  const popoverContentRef = useRef(null);

  const setPopoverContentWith = (w) => {
    if (popoverContentRef.current) {
      popoverContentRef.current.style.width = w;
    }
  };

  let style = {
    height,
    top,
    left,
    width,
  };
  const patient = patients[appt.pid];
  const is30minAppt =
    (schState.doctor || schState.selectedDoctor) &&
    appt.duration === "30 min" &&
    props.view === "day";

  const pHeight = calendarContainerRef.current?.offsetHeight;

  return (
    <Popover isLazy placement="right-start" isOpen={showNotes}>
      <PopoverAnchor>
        <div
          onMouseEnter={() => {
            targetRef.current = "appt";
            showNotesDebounce();
            hideNotesDebounce.cancel();
          }}
          onMouseLeave={() => {
            hideNotesDebounce();
          }}
          ref={apptCardRef}
          onClick={(e) => {
            e.stopPropagation();
            setApptPosition(left);
            setGridRef();
            setAppointmentRef(appt);
          }}
          style={style}
          className="absolute z-10 hover:cursor-pointer "
          draggable={
            !appt?.cancelled &&
            ((view === "week" && props.selectedDoctor) || view === "day")
              ? "true"
              : "false"
          }
        >
          {parseInt(appt.duration.split(" ")[0]) <= 15 ? (
            <div
              style={{
                backgroundColor: colorSetter
                  ? colorSetter(appt) || colorsByStatus(appt)
                  : colorsByStatus(appt),
                height: "32px",
              }}
              className="relative flex justify-center items-center text-white bg-slate-500 rounded-md p-1 "
            >
              <PartialPaidIcon appt={appt} />
              <ApptType appt={appt} />
              <p
                className={
                  "space-x-1 flex justify-center items-center text-center text-[0.7rem] overflow-hidden"
                }
              >
                <span>{time}</span>
                <span>
                  {appt?.pName.length >= 15
                    ? appt?.pName.slice(0, 15) + "..."
                    : appt?.pName}
                </span>
              </p>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: colorSetter
                  ? colorSetter(appt) || colorsByStatus(appt)
                  : colorsByStatus(appt),
              }}
              className={
                is30minAppt
                  ? "h-full w-full relative rounded-md text-sm bg-slate-500 text-white p-3 pt-2"
                  : "h-full w-full relative rounded-md text-sm bg-slate-500 text-white p-3 pt-4"
              }
            >
              <PartialPaidIcon appt={appt} />
              {appt?.invoiceAidClaimUuid && (
                <Tooltip
                  hasArrow
                  label={"ClinicAid Claim Submitted"}
                  rounded={"md"}
                >
                  <Box
                    w={4}
                    h={4}
                    borderWidth="2px"
                    borderRadius="full"
                    position="absolute"
                    top="0.25rem"
                    left="1.5rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderColor="green.500"
                  >
                    <Icon as={GiHealthNormal} w={2} h={2} color="green.500" />
                  </Box>
                </Tooltip>
              )}
              <ApptType appt={appt} />
              <div
                className={
                  "space-y-1 h-full items-center   overflow-auto sbar flex flex-col mt-1"
                }
              >
                <div
                  className={
                    is30minAppt &&
                    "h-1/2 w-full items-center justify-evenly sbar flex pr-10"
                  }
                >
                  <p
                    className={
                      props.view === "day"
                        ? "space-x-1 text-center text-[0.8rem]"
                        : "space-x-1 text-start text-[0.8rem]"
                    }
                  >
                    <span>{time}</span>
                    <span>({appt.duration})</span>
                  </p>

                  <p className={"text-center"}>
                    <span className="font-bold text-background">
                      {verifyAccess(schState, "fName")
                        ? `${patient.lName}, ${patient.fName}`
                        : blurredName}
                      <br />
                    </span>
                    {!is30minAppt && (
                      <span className="font-normal">{appt.service || ""}</span>
                    )}
                  </p>

                  {is30minAppt && (
                    <p className="text-center">
                      <span className="font-normal">{appt.service || ""}</span>
                    </p>
                  )}
                </div>

                {InsurersMap[appt.tpp] && (
                  <p
                    className={
                      props.view === "day" ? "text-center" : "text-start"
                    }
                  >
                    <span className="font-normal text-xs">
                      <em>TPP:</em>{" "}
                      <em className="font-semibold">
                        {InsurersMap[appt.tpp]?.name || "N/A"}
                      </em>
                    </span>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </PopoverAnchor>
      <Portal containerRef={calendarContainerRef}>
        <PopoverContent
          ref={popoverContentRef}
          bg="blue.50"
          maxW={"18rem"}
          p="1"
          zIndex={99999}
        >
          <PopoverArrow bg="blue.50" />

          <PopoverBody
            maxH={pHeight ? `${pHeight / 2}px` : "20rem"}
            className="sbar2"
            overflow={"auto"}
            p="0"
            onMouseEnter={() => {
              targetRef.current = "note";
              hideNotesDebounce.cancel();
            }}
            onMouseLeave={() => onLeaveNotes()}
          >
            <NotesOnHover
              setPopoverContentWith={setPopoverContentWith}
              appointment={appt}
              state={schState}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

function DaysOfMonth({
  date,
  setView,
  setDate,
  appointments,
  colorsByStatus,
  colorSetter,
}) {
  let apptsInDaysMap = appointmentsInDays(appointments);

  let listOfMonthDays = [];
  let Mstart = startOfMonth(date);
  let Mend = endOfMonth(date);
  let Wstart = startOfWeek(Mstart, {weekStartsOn: 1});
  let Wend = endOfWeek(Mend, {weekStartsOn: 1});
  let Days = eachDayOfInterval({
    start: Wstart,
    end: Wend,
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  listOfMonthDays = Days.map((day, dayIdx) => {
    let dateString = `${weekday[day.getDay()]}, ${
      months[day.getMonth()]
    } ${day.getDate()}, ${day.getFullYear()}`;
    let isCurrentMonth = isWithinInterval(day, {start: Mstart, end: Mend});
    let isSelected = isSameDay(day, date);
    let isToday = isSameDay(day, new Date());
    return (
      <div
        key={dayIdx}
        onClick={() => {
          setDate(day);
          setView("day");
        }}
        className={classNames(
          "p-[2px] hover:bg-gray-100 show hover:cursor-pointer focus:z-10 relative flex items-center flex-col text-xs overflow-hidden",
          isCurrentMonth ? "bg-white" : "bg-gray-50",
          (isSelected || day.isToday) && "font-semibold",
          isSelected && "text-white",
          !isSelected && isCurrentMonth && !isToday && "text-gray-900",
          !isSelected && !isCurrentMonth && !isToday && "text-gray-400",
          isToday && !isSelected && "text-indigo-600"
        )}
      >
        {dayIdx < 7 && (
          <span className="text-gray-500 font-medium">
            {weekday[day.getDay()].slice(0, 3)}
          </span>
        )}
        <time
          dateTime={lightFormat(day, "yyyy-MM-dd")}
          className={classNames(
            "mx-auto flex h-5 w-5 items-center justify-center rounded-full mb-1",
            isSelected && isToday && "bg-indigo-600",
            isSelected && !isToday && "bg-gray-900"
          )}
        >
          {day.getDate()}
        </time>

        <div className="h-full flex-1 overflow-hidden w-full">
          {
            <ul
              className="max-h-full w-full overflow-auto space-y-[1px] sbar pb-1"
              onWheel={(e) => {
                e.stopPropagation();
              }}
            >
              {apptsInDaysMap.get(dateString)?.map((a, i) => {
                let {date, time} = a.ISOdate
                  ? isoToApptDate(a)
                  : {date: a.date, time: a.time};
                let style = {
                  backgroundColor: colorSetter
                    ? colorSetter(a) || colorsByStatus(a)
                    : colorsByStatus(a),
                };
                return (
                  <li
                    key={i}
                    style={style}
                    className="space-y-1 text-white rounded-md bg-slate-500"
                  >
                    <p className={"space-x-1 text-center text-[0.6rem]"}>
                      <span>{time}</span>
                      <span>{a.pName}</span>
                    </p>
                  </li>
                );
              })}
            </ul>
          }
        </div>
      </div>
    );
  });

  return listOfMonthDays;
}
