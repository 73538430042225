import {
  Box,
  Text,
  useToast,
  Flex,
  Button,
  Icon,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import {useContext, useState, useEffect, useMemo} from "react";
import {HiOutlinePlus} from "react-icons/hi";
import {IoSearch} from "react-icons/io5";
import {DashStateContext, DashDispatchContext} from "../../pages/Products";
import fetchProducts from "./helpers/fetchProducts";
import fetchCategories from "./helpers/fetchCategories";
import fetchSales from "./helpers/fetchSales";
import ProductsTable from "./ProductsTable";
import CategoriesCard from "./CategoriesCard";
import ProductsCard from "./ProductsCard";
import EditOrCreate from "./EditOrCreate";
import {GiReceiveMoney} from "react-icons/gi";
import {MdClose} from "react-icons/md";
import SalesModal from "./SalesModal";
export default function Inventory() {
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DashDispatchContext);
  const toast = useToast();
  const [showEditOrCreateModal, setShowEditOrCreateModal] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [prevLocation, setPrevLocation] = useState(dashState.selectedLocation);
  const [waiting, setWaiting] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sales, setSales] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [allowSelection, setAllowSelection] = useState(false);

  const [selections, setSelections] = useState(new Set());
  useEffect(() => {
    fetchProducts(dashState, setIsLoading, setProducts, toast);
    fetchCategories(dashState, setIsLoading, setCategories, toast);
    fetchSales(dashState, setIsLoading, setSales, toast); // Number of products sales
  }, []);

  const {categoriesMap, fields} = useMemo(() => {
    let categoriesMap = categories.reduce((acc, c) => {
      acc[c.id] = c;
      return acc;
    }, {});

    let fields = {
      SKU: (p) => p.sku,
      "Product Name": (p) => p.name,
      Category: (p) => categoriesMap[p.categoryId]?.name || "N/A",
      Description: (p) => p.description,
      Quantity: (p) => p.stock,
      Price: (p) => p.price,
      "Sale Price": (p) => (p.onSale ? p.salePrice : "N/A"),
    };
    return {categoriesMap, fields};
  }, [categories]);

  if (prevLocation !== dashState.selectedLocation) {
    setPrevLocation(dashState.selectedLocation);
    fetchProducts(dashState, setIsLoading, setProducts, toast);
  }

  let filteredList = useMemo(() => {
    function filterText(product, text) {
      let regex = new RegExp(text, "i");
      if (text.length === 0) {
        return product;
      }
      return (
        product["sku"]?.match(regex) ||
        product["name"]?.match(regex) ||
        product["description"]?.match(regex) ||
        product["category"]?.match(regex) ||
        String(product["stock"]).match(regex)
      );
    }

    return products.filter((p) => filterText(p, searchText));
  }, [searchText, products]);

  return (
    <>
      {showSalesModal && (
        <SalesModal
          {...{
            selections,
            setSelections,
            action: () => setShowSalesModal(false),
            state: dashState,
            dispatch,
            setSales,
            setProducts,
            setAllowSelection,
          }}
        />
      )}
      {showEditOrCreateModal && (
        <EditOrCreate
          {...{
            showEditOrCreateModal,
            setShowEditOrCreateModal,
            selectedItem,
            setSelectedItem,
            dashState,
            dispatch,
            setProducts,
            setSelections,
            categories,
            categoriesMap,
            toast,
            waiting,
            setWaiting,
          }}
        />
      )}
      <Box
        w="full"
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        overflowY={"hidden"}
        p={4}
      >
        <Flex w="full" gap="4" overflowY={"hidden"} mt={2} justify="center">
          <CategoriesCard categories={categories} />
          <ProductsCard products={products} />
          {/*<SalesCard sales={sales} />*/}
        </Flex>
        <Box
          w="full"
          flex={1}
          display={"flex"}
          flexDirection={"column"}
          overflowY={"hidden"}
          mt={-8}
        >
          <Flex
            justify={"space-between"}
            w="full"
            align={"center"}
            p="2"
            pt="0"
          >
            <Text color={"blue.500"} fontWeight={"bold"} fontSize={"2rem"}>
              Stock
            </Text>
          </Flex>
          <Flex
            justify={"space-between"}
            w="full"
            borderY={"1px solid "}
            borderColor={"gray.200"}
            align={"center"}
            p="5"
            pl="0"
          >
            <Flex align={"center"} gap={4}>
              <InputGroup p="1" w="60">
                <InputLeftElement>
                  <Icon as={IoSearch} color="gray.500" mt="2" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearchText(e.target.value.toLowerCase());
                  }}
                />
              </InputGroup>
              <Flex align={"center"} gap={1}>
                <Button
                  w="180px"
                  leftIcon={<GiReceiveMoney fontSize={"1.5rem"} />}
                  colorScheme={
                    allowSelection && selections.size > 0 ? "green" : "teal"
                  }
                  cursor={!waiting ? "pointer" : "not-allowed"}
                  onClick={() => {
                    if (allowSelection && selections.size > 0)
                      setShowSalesModal(true);
                    else setAllowSelection(true);
                  }}
                >
                  {allowSelection && selections.size > 0
                    ? "Proceed to Sale"
                    : allowSelection
                    ? "Select Products"
                    : "Sell Products"}
                </Button>
                {allowSelection && (
                  <Icon
                    as={MdClose}
                    color={"gray.500"}
                    _hover={{color: "gray.700"}}
                    w={6}
                    h={6}
                    cursor={"pointer"}
                    onClick={() => {
                      setAllowSelection("");
                      setSelections(new Set());
                    }}
                  />
                )}
              </Flex>
            </Flex>

            <Button
              leftIcon={<HiOutlinePlus />}
              colorScheme="blue"
              pl={2}
              onClick={() => setShowEditOrCreateModal(true)}
            >
              Create
            </Button>
          </Flex>
          <ProductsTable
            {...{
              dashState,
              products: filteredList,
              setProducts,
              fields,
              searchText,
              selectedItem,
              setSelectedItem,
              setShowEditOrCreateModal,
              toast,
              allowSelection,
              selections,
              setSelections,
            }}
          />
        </Box>
      </Box>
    </>
  );
}
