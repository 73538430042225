import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Divider,
  Text,
  Stack,
  InputGroup,
  InputLeftElement,
  Table,
  Tr,
  Td,
  Thead,
  Th,
  Select,
  Checkbox,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
  Tbody,
  useToast,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";
import React from "react";
import {AddIcon} from "@chakra-ui/icons";
import {useState} from "react";
import {SearchIcon} from "@chakra-ui/icons";
import {crud} from "../../crudRequests";
import {v4} from "uuid";
import fetchProducts from "../Products/helpers/fetchProducts";

const format = (val) => `$` + val;
const parse = (val) => val.replace(/^\$/, "");
export function MembershipForm(props) {
  const [sortAndFilteredServices, setSortAndFilteredServices] = useState(
    props.schState.services
  );
  const [activeTab, setActiveTab] = useState(0);
  const [productList, setProductList] = useState([]);
  React.useEffect(() => {
    fetchProducts(
      props.schState,
      () => {},
      (products) => {
        setProductList(
          products.map((product) => ({...product, type: "product"}))
        );
      },
      () => {}
    );
  }, []);
  React.useEffect(() => {
    if (activeTab === 2) {
      setSortAndFilteredServices(
        productList.filter((product) => {
          if (
            product.name
              .toLowerCase()
              .includes(props.formValues.searchValue.toLowerCase())
          ) {
            return true;
          }
          if (
            product.description
              .toLowerCase()
              .includes(props.formValues.searchValue.toLowerCase())
          ) {
            return true;
          }
          return false;
        })
      );
    } else {
      const serviceList = [...props.schState.services];
      setSortAndFilteredServices(
        serviceList
          .filter((svc) => {
            if (activeTab === 0) {
              return !svc["non-sessions"];
            } else if (activeTab === 1) {
              return svc["non-sessions"];
            }
            return true;
          })
          .filter((svc) => {
            if (
              svc.serviceName
                .toLowerCase()
                .includes(props.formValues.searchValue.toLowerCase())
            ) {
              return true;
            }
            if (
              svc.description
                .toLowerCase()
                .includes(props.formValues.searchValue.toLowerCase())
            ) {
              return true;
            }
            return false;
          })
          .map((svc) => ({...svc, type: "service"}))
      );
    }
  }, [props.formValues.searchValue, activeTab, productList]);
  return (
    <Stack
      direction="column"
      spacing={4}
      alignItems="center"
      h="68vh"
      overflow="scroll"
    >
      <Stack boxShadow="xl" p="1rem">
        <Stack direction="row" spacing={4} w="90%" mt="1rem">
          <Input
            placeholder="Membership/package name"
            value={props.formValues.name}
            onChange={(evt) =>
              props.setFormValues({
                ...props.formValues,
                name: evt.target.value.replace(/[\\/:*?"<>|]/g, ""),
              })
            }
          />
          <Text mb="8px">Price</Text>
          <NumberInput
            onChange={(valueString) =>
              props.setFormValues({
                ...props.formValues,
                price: parse(valueString),
              })
            }
            value={format(props.formValues.price)}
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Checkbox
            isChecked={props.formValues.active}
            onChange={(evt) =>
              props.setFormValues({
                ...props.formValues,
                active: evt.target.checked,
              })
            }
          >
            Active
          </Checkbox>
        </Stack>
        <Stack direction="row">
          <Input
            placeholder="Description"
            w="78%"
            value={props.formValues.description}
            onChange={(evt) =>
              props.setFormValues({
                ...props.formValues,
                description: evt.target.value,
              })
            }
          />
          <Checkbox
            isChecked={props.formValues.membership}
            onChange={(evt) =>
              props.setFormValues({
                ...props.formValues,
                membership: evt.target.checked,
              })
            }
            marginLeft={".35rem"}
            isDisabled={props.isEdit}
          >
            Membership
          </Checkbox>
        </Stack>

        <Stack direction="row" spacing={4}>
          <Text>Duration</Text>
          <NumberInput
            w="50%"
            min={0}
            value={props.formValues.duration}
            onChange={(evt) => {
              props.setFormValues({...props.formValues, duration: evt});
            }}
            isDisabled={
              !props.formValues.membership ||
              (props.formValues.membership && props.isEdit)
            }
          >
            <NumberInputField />
            <NumberInputStepper padding="5px">
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            placeholder="Select option"
            value={props.formValues.durationPeriod}
            onChange={(evt) => {
              props.setFormValues({
                ...props.formValues,
                durationPeriod: evt.target.value,
              });
            }}
            isDisabled={
              !props.formValues.membership ||
              (props.formValues.membership && props.isEdit)
            }
          >
            <option value="monthly">Month(s)</option>
            <option value="yearly">Year(s)</option>
            <option value="">-</option>
          </Select>
          <Checkbox
            isChecked={props.formValues.taxable}
            onChange={(evt) =>
              props.setFormValues({
                ...props.formValues,
                taxable: evt.target.checked,
              })
            }
            marginLeft={".35rem"}
          >
            Taxable
          </Checkbox>
        </Stack>

        <Stack direction="row" spacing={4} w="100%">
          <InputGroup w="100%">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              value={props.formValues.searchValue}
              onChange={(evt) =>
                props.setFormValues({
                  ...props.formValues,
                  searchValue: evt.target.value,
                })
              }
              placeholder="Search"
            />
          </InputGroup>
        </Stack>
      </Stack>
      <Tabs
        mt="1rem"
        variant="soft-rounded"
        colorScheme="blue"
        onChange={(index) => setActiveTab(index)}
      >
        <TabList>
          <Tab>Services</Tab>
          <Tab>Non-session services</Tab>
          <Tab>Products</Tab>
        </TabList>
      </Tabs>
      <Stack boxShadow="xl" m="1rem">
        <Table>
          <Thead bg="gray.100">
            <Th>Selection</Th>
            <Th>Service</Th>
            <Th>Description</Th>
            <Th>Category</Th>
            <Th>Quantity</Th>
          </Thead>
          <Tbody overflow="scroll">
            {sortAndFilteredServices.map((item) => {
              const isProduct = item.type === "product";
              const selectedItems = isProduct
                ? props.selectedProducts
                : props.selectedServices;
              const setSelectedItems = isProduct
                ? props.setSelectedProducts
                : props.setSelectedServices;
              const itemId = isProduct ? item.id : item.serviceId;
              return (
                <Tr key={itemId}>
                  <Td>
                    <Checkbox
                      isChecked={selectedItems[itemId]?.checked}
                      onChange={(evt) =>
                        setSelectedItems({
                          ...selectedItems,
                          [itemId]: {
                            checked: evt.target.checked,
                            quantity:
                              evt.target.checked === false
                                ? 0
                                : parseInt(selectedItems[itemId]?.quantity) ||
                                  1,
                          },
                        })
                      }
                    />
                  </Td>
                  <Td>{item.serviceName || item.name}</Td>
                  <Td>{item.description}</Td>
                  <Td>{item.category}</Td>
                  <Td>
                    <NumberInput
                      value={selectedItems[itemId]?.quantity || 0}
                      onChange={(evt) => {
                        setSelectedItems({
                          ...selectedItems,
                          [itemId]: {
                            ...selectedItems[itemId],
                            quantity: parseInt(evt),
                          },
                        });
                      }}
                      min={1}
                      disabled={!selectedItems[itemId]?.checked}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Stack>
    </Stack>
  );
}
export function MembershipsCreateModal(props) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const [selectedServices, setSelectedServices] = useState({});
  const [selectedProducts, setSelectedProducts] = useState({});
  const locationData = props.schState.locations.find(
    (loc) => loc.lid === props.schState.selectedLocation
  );
  const initialFormState = {
    duration: 6,
    durationPeriod: "monthly",
    searchValue: "",
    description: "",
    name: "",
    price: 0,
    active: true,
    membership: true,
    taxable: true,
  };
  const [formValues, setFormValues] = useState(initialFormState);
  return (
    <>
      <Button
        colorScheme="blue"
        leftIcon={<AddIcon />}
        onClick={onOpen}
        p="1rem"
        w="auto"
      >
        Create
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="blue.400">
            Create a new membership/package
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody>
            <MembershipForm
              schState={props.schState}
              formValues={formValues}
              setFormValues={setFormValues}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              locationData={locationData}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              variant="solid"
              onClick={async () => {
                const membershipData = {
                  name: String(formValues.name).trim(),
                  description: String(formValues.description).trim(),
                  price: Number(formValues.price),
                  status: formValues.active ? "Active" : "Inactive",
                  duration: formValues.membership ? formValues.duration : "",
                  durationPeriod: formValues.membership
                    ? formValues.durationPeriod
                    : "",
                  membership: formValues.membership,
                  active: formValues.membership ? formValues.active : true,
                  selectedServices,
                  selectedProducts,
                  mid: v4(),
                  taxable: formValues.taxable,
                  createdAt: Date.now(),
                };
                await crud(
                  props.schState,
                  [
                    {
                      db: props.schState.db,
                      collection: "locations",
                      parameters: [
                        {lid: props.selectedLocation.lid},
                        {
                          $push: {
                            memberships: membershipData,
                          },
                        },
                      ],
                      method: "updateOne",
                    },
                  ],
                  null
                );
                props.setMembershipsData([
                  membershipData,
                  ...props.membershipsData,
                ]);
                let {locations} = props.schState;
                let locIndex = props.schState.locations.findIndex(
                  (d) => d.lid === props.selectedLocation.lid
                );
                const updatedMemberships = [
                  ...locations[locIndex].memberships,
                  membershipData,
                ];
                await props.dispatch({
                  type: "UPDATE_LOCATION",
                  payload: {
                    lid: props.selectedLocation.lid,
                    memberships: updatedMemberships,
                  },
                });
                setFormValues({...initialFormState});
                setSelectedProducts({});
                setSelectedServices({});
                toast({
                  title: `${
                    formValues.membership ? "Membership" : "Package"
                  } created!`,
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
                onClose();
              }}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
