import React, {useState, useRef} from "react";
import {url, addBookNowPatient, addPatient} from "../crudRequests";
import {
  fullInvoice,
  patientInvoice,
  insurerInvoice,
} from "./appointments/payment/uploads";
//import bcrypt from "bcryptjs";
import swal from "sweetalert";
import {nanoid} from "nanoid";
import {v4 as uuidv4} from "uuid";
import {
  bookNowCrud,
  updateApptMemb,
  selfBookingNotification,
} from "../crudRequests";
import {isoToApptDate} from "../additional_files/helpers";
import {lightFormat} from "date-fns";
import {useToast} from "@chakra-ui/react";
import {notificationsData} from "../additional_files/notifications";
import {
  criteriaFormatKey,
  roundUpToTwoDecimals,
} from "../additional_files/helpers";
import getAmount from "./appointments/payment/helpers/getAmount";
import {ServicesInMemberships} from "./appointments/boockAppointment/products/helpers.js";
let initial = {
  fName: "",
  lName: "",
  email: "",
  phone: "",
  emailInvitation: true,
  phoneInvitation: true,
};
export default function InvitePatient({bookState, dispatch}) {
  const state = bookState;
  const inviteFirstNameRef = useRef(null);
  const inviteLastNameRef = useRef(null);
  const inviteEmailRef = useRef(null);
  const invitePhoneRef = useRef(null);
  const [fields, setFields] = useState(initial);

  const [inviteError, setInviteError] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function invitePatient() {
    try {
      if (bookState?.organization?.db) {
        setInviteError("");
        setLoading(true);
        const pid = nanoid(10);

        if (fields.email.trim() === "" && fields.phone.trim() === "") {
          setInviteError("Email or phone required!");
          setLoading(false);
          return;
        }

        if (
          fields.emailInvitation &&
          fields.email &&
          !fields.email
            .trim()
            .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        ) {
          setInviteError("Invalid email format!");
          setLoading(false);
          return;
        }

        if (fields.fName.trim() === "" || fields.lName.trim() === "") {
          setInviteError(
            "Please complete the required fields correctly to schedule your appointment!"
          );
          setLoading(false);
          return;
        }

        {
          let clinician = bookState.selectedDoctor;
          let location = bookState.location;
          let patient = {
            pid: pid,
            fName: fields.fName.trim(),
            lName: fields.lName.trim(),
            phone: fields.phone.trim(),
            email: fields.email.trim(),
            scheduleBeforeIntake: false,
            oid: bookState.organization.oid,
            lid: [bookState.location.lid],
            did: [bookState.selectedDoctor.did],
            referralVia: "Online Booking Page",
            emailPermission: fields.emailInvitation,
            textPermission: fields.phoneInvitation,
          };
          let service = bookState.selectedService;
          let isNewPatient = true;

          try {
            await addPatient(
              state,
              patient,
              {
                emailInvitation: fields.emailInvitation,
                phoneInvitation: fields.phoneInvitation,
              },
              state.location
            );
          } catch (error) {
            if (error.response?.status === 401) {
              console.log(error);
              isNewPatient = false;
              patient = error.response.data;
            } else {
              throw error;
            }
          }

          const currentDate = new Date();
          let memberships = (patient?.memberships || []).filter((memb) => {
            const startDate = new Date(memb.startDate);
            const endDate = new Date(memb.endDate);
            return (
              memb.active &&
              (!memb.membership ||
                (currentDate >= startDate && currentDate <= endDate))
            );
          });
          const {servicesFromMemb} = ServicesInMemberships(
            memberships,
            null,
            null
          );
          const membForService = servicesFromMemb[
            service.serviceId
          ]?.relatedMemberships.find((data) => {
            const serviceInfo = data.selectedServices[service.serviceId];
            const serviceBalance =
              (serviceInfo?.quantity || 0) -
              (serviceInfo?.consumed || 0) -
              (serviceInfo?.reserved || 0);
            return serviceInfo && serviceBalance > 0;
          });
          let patientTppInfo = null;
          let tpp = "";

          if (Array.isArray(patient?.tpp)) {
            let insr = patient?.tpp.find(
              (ins) => parseInt(ins.noOfInsuredSessions ?? 0) > 0
            );

            insr =
              insr ||
              patient?.tpp.find((ins) => ins.noOfInsuredSessions === undefined);
            patientTppInfo = insr ? insr : null;
            tpp = insr ? insr.insurerId : "";
          }
          let initialCriteriaIdValues = {};
          if (tpp) {
            initialCriteriaIdValues["Insurers"] = tpp;
          }
          if (clinician.providerType) {
            initialCriteriaIdValues["Provider Types"] = clinician.providerType;
          }
          initialCriteriaIdValues["Clinicians"] = clinician.did;

          const aid = nanoid(10);
          const invoiceId = id(8);
          const receiptId = id(8);
          let {date, time} = isoToApptDate(bookState.selectedInterval[0]);

          let duration = `${Math.floor(
            (bookState.selectedInterval[1] - bookState.selectedInterval[0]) /
              60000
          )} min`;

          let serviceCriteriaKey = criteriaFormatKey(initialCriteriaIdValues);
          const serviceAvailableInMembership = membForService;
          let costsByCriteria = service.defaultCost || 100;

          if (serviceCriteriaKey && !service?.isfixedCost)
            costsByCriteria =
              service.costsByCriteria?.[serviceCriteriaKey] ?? costsByCriteria;
          let hours = Number.parseFloat(duration.split(" ")[0]) / 60;
          let serviceTax = 0;
          if (service?.taxable === true) {
            let typeId = initialCriteriaIdValues?.["Provider Types"];

            if (typeId) {
              let providerType = bookState.providerTypes.find(
                (t) => t.typeId === typeId
              );
              serviceTax = parseFloat(providerType?.tax || 0);
            }
          }
          let serviceAmount = 0;
          let serviceSubtotal = 0;
          if (!serviceAvailableInMembership) {
            serviceSubtotal = Number.parseFloat(
              (service?.isfixedCost ? 1 : hours) *
                Number.parseFloat(costsByCriteria)
            );
            serviceAmount = getAmount({
              subtotal: serviceSubtotal,
              tax: serviceTax,
              discountRate: 0,
            });
          }
          let amount = serviceAmount;
          let productSubtotal = 0;
          let productTax = 0;
          let productAmount = 0;

          let productDiscountRate = 0;
          let tppAmount = tpp ? amount : 0;
          if (
            patientTppInfo &&
            !serviceAvailableInMembership &&
            !isNaN(patientTppInfo.sessionDeductionAmount) &&
            parseFloat(patientTppInfo.sessionDeductionAmount) >= 0
          ) {
            let sessionDeductionAmount = parseFloat(
              patientTppInfo.sessionDeductionAmount
            );
            if (sessionDeductionAmount >= amount) {
              tppAmount = roundUpToTwoDecimals(amount);
            } else
              tppAmount = roundUpToTwoDecimals(
                patientTppInfo.sessionDeductionAmount
              );
          }

          let patientAmount = amount - tppAmount;
          let supervisor = bookState.doctors.find(
            (d) => d.did === clinician?.supervisor
          );
          let supervisorId = supervisor?.did;
          let supervisorName = supervisor?.name || clinician?.name;
          let supervisorEmail = supervisor?.email || clinician?.email;
          let amountData = {
            serviceSubtotal,
            serviceDiscountRate: 0,
            serviceDiscountId: null,
            serviceTax: parseFloat(serviceTax),
            serviceAmount: roundUpToTwoDecimals(serviceAmount),
            products: [],
            productSubtotal,
            productDiscountRate,
            productDiscountId: null,
            productTax,
            productAmount,
            amount,
            tppAmount,
            patientAmount,
          };
          let paymentStatus = amount > 0 ? "pending" : "paid";
          let paymentMethod =
            paymentStatus === "paid" ? "Padi by Memberships" : "";

          const payload = {
            aid: aid,
            invoiceId,
            receiptId,
            confirmationMeans: {
              text: patient.textPermission,
              email: patient.emailPermission,
            },
            lid: location.lid,
            db: bookState.organization.db,
            locationName: location.name,
            pid: patient.pid,
            pName: patient.fName + " " + patient.lName,
            pEmail: patient.email,
            pPhone: patient.phone,
            pConfirmed: false,
            did: clinician.did,
            oid: bookState.organization.oid,
            dName: clinician.name,
            dEmail: clinician.email,
            supervisorId: supervisorId,
            supervisorName,
            supervisorEmail,
            teleconference: true,
            telephone: false,
            appointmentType: "teleconference",
            address: location.address,
            patientApptLink:
              clinician.zoomLink ||
              `https://teleconference.brightlight.ai/${encodeURIComponent(
                aid
              )}`,
            clinicApptLink:
              bookState.selectedDoctor.zoomLink ||
              `https://teleconference.brightlight.ai/${encodeURIComponent(
                aid
              )}`,

            date: date,
            time: time,
            duration,
            note: "Appointment self-scheduled by the patient.",
            summary: "",
            cancelled: false,
            noShow: false,
            service: bookState.selectedService.serviceName,
            serviceId: bookState.selectedService.serviceId,
            ISOdate: new Date(bookState.selectedInterval[0]).getTime(),
            createdBy: `patient|${patient.pid}`,
            paymentStatus,
            paymentMethod,
            notifyBefore: [24],
            serviceCriteriaKey,
            claim: false,
            amount,
            tpp,
            amountPaidByPatient: 0,
            amountPaidByTpp: 0,
            tppPaymentStatus: tppAmount > 0 ? "pending" : "paid",
            patientPaymentStatus: patientAmount > 0 ? "pending" : "paid",
            apptBookingMedia: "online",
            firstTime: isNewPatient,
            apptCreatedBy: "patient",
            assignedProfessional: "clinician",
            technician: "",
            paidByMembership: membForService?.uuid || null,
            clinicianSelectedByPatient: true,
            ...amountData,
          };

          let transaction = {
            type: "appointment",
            tid: uuidv4(),
            invoiceId: payload.invoiceId,
            receiptId: payload.receiptId,
            aid: payload.aid,
            oid: payload.oid,
            lid: payload.lid,
            db: bookState.organization.db,
            locationName: payload.locationName,
            pid: patient.pid,
            pName: patient.fName + " " + patient.lName,
            pEmail: patient.email,
            did: payload.did,
            dName: payload.dName,
            dEmail: payload.dEmail,
            time: payload.duration,
            claim: payload.claim,
            supervisorId: payload.supervisorId,
            supervisorName: payload.supervisorName,
            paid: 0,
            pending: true,
            cancelled: false,
            serviceId: payload.serviceId,
            serviceType: payload.service.trim(),
            serviceDescription: payload.serviceDescription,
            serviceDate: payload.ISOdate,
            insurance: patient.insurance,
            dgCodes: [],
            serviceCodes: payload.serviceCodes,
            billingCodes: [],
            tpp,
            amountPaidByPatient: 0,
            amountPaidByTpp: 0,
            tppPaymentStatus: payload.tppPaymentStatus,
            patientPaymentStatus: payload.patientPaymentStatus,
            paymentStatus,
            paymentMethod,
            assignedProfessional: "clinician",
            technician: "",
            paidByMembership: membForService?.uuid || null,
            date: `${lightFormat(new Date(), "dd/MM/yy HH:mm")}`,
            ...amountData,
          };

          const updateInsuredSessions = async () => {
            if (Array.isArray(patient?.tpp)) {
              let insr = patient?.tpp.find((ins) => ins.insurerId === tpp);

              if (insr && insr.noOfInsuredSessions) {
                let noOfInsuredSessions =
                  parseInt(insr.noOfInsuredSessions) - 1;

                await bookNowCrud(bookState, [
                  {
                    db: bookState.organization.db,
                    collection: "patients",
                    parameters: [
                      {pid: payload.pid, "tpp.insurerId": tpp},
                      {
                        $inc: {
                          "tpp.$.noOfInsuredSessions": -1,
                        },
                      },
                    ],
                    method: "updateOne",
                  },
                ]);
              }
            }
          };
          let sendInvEmail = async (schState, transaction) => {
            try {
              let invData = {
                state: bookState,
                location,
                transaction,
                doctor: bookState.selectedDoctor,
                patient,
                tpp: tpp
                  ? bookState?.insurers.find((insurer) => insurer.iid === tpp)
                  : null,
                supervisor,
              };
              if (transaction.tpp) await insurerInvoice(invData);
              await patientInvoice(invData);
              await fullInvoice(invData);
            } catch (error) {
              throw error;
            }
          };
          if (membForService) {
            await updateApptMemb({
              state: bookState,
              appointmentId: payload.aid,
              serviceId: payload.serviceId,
              membershipForServiceId: payload.paidByMembership || null,
              patientId: patient.pid,
              productsToAssign: [],
            });
          }

          await Promise.all([
            updateInsuredSessions(),
            sendInvEmail(bookState, transaction),
            selfBookingNotification(bookState, payload, location),
          ]);

          let requestObjs = [
            {
              db: bookState.organization.db,
              collection: "appointments",
              parameters: [payload],
              method: "insertOne",
            },
            {
              db: bookState.organization.db,
              collection: "billing",
              parameters: [transaction],
              method: "insertOne",
            },
            {
              db: bookState.organization.db,
              collection: "patients",
              parameters: [
                {pid: patient.pid},
                {
                  $addToSet: {
                    lid: state.location.lid,
                    did: bookState.selectedDoctor.did,
                  },
                },
              ],
              method: "updateOne",
            },
          ];

          await bookNowCrud(bookState, requestObjs, {});
          if (isNewPatient) {
            toast({
              title: "Information Saved Successfully!",
              description:
                "Your data has been saved successfully. Please check your email to complete your registration!",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          } else {
            toast({
              title: "The appointment has been successfully booked!",
              description: `You are being redirected to the patient portal.`,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            await new Promise((resolve) => {
              setTimeout(resolve, 3000);
            });
            window.location.href = "https://www.patient.brightlight.ai/login";
          }
          dispatch({type: "SHOW_PATIENT_FORM", payload: false});
          dispatch({type: "SET_INTERVAL", payload: null});
          dispatch({type: "SELECT_THREAD", payload: "selections"});
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setInviteError(error.response?.data || error.message);
    }
  }

  return (
    <>
      <div
        className="fixed z-[70] top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        style={{backgroundColor: "rgb(0,0,0,0.5)"}}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
            style={{backgroundColor: "#F6F5FF"}}
          >
            {inviteError && (
              <div className>
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-red-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-red-500 dark:text-red-400">
                        Error
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200">
                        {inviteError}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {inviteSuccess && (
              <div className>
                <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                  <div className="flex items-center justify-center w-12 bg-green-500">
                    <svg
                      className="w-6 h-6 mx-2 text-white fill-current"
                      viewBox="0 0 40 40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                    </svg>
                  </div>
                  <div className="px-4 py-2 -mx-3">
                    <div className="mx-3">
                      <span className="font-semibold text-green-500 dark:text-green-400">
                        Booked appointment
                      </span>
                      <p className="text-sm text-gray-600 dark:text-gray-200">
                        Your appointment has been successfully booked!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex -mb-7">
              <h3
                className="text-3xl font-medium  text-gray-800 m-4 mb-6"
                id="modal-title"
                style={{color: "#C0BFFF"}}
              >
                Personal Information
              </h3>
              <div className="pl-1.5">
                <button
                  type="button"
                  className="- text-gray-400 bg-transparent rounded-lg text-sm  ml-auto inline-flex items-center"
                  data-modal-toggle="small-modal"
                  style={{boxShadow: "none"}}
                  onClick={() => {
                    dispatch({type: "SHOW_PATIENT_FORM", payload: false});
                  }}
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <form className="mt-6" action="#">
              <label className="block mx-4 mt-3" htmlFor="fName">
                <span className="text-[11px] text-off ml-2">
                  Enter your First Name
                </span>
                <input
                  onChange={(e) => {
                    setInviteError("");
                    setFields((prev) => ({
                      ...prev,
                      fName: e.target.value,
                    }));
                  }}
                  value={fields.fName}
                  type="fName"
                  name="fName"
                  id="fName"
                  className="drop-shadow block w-[19rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <label className="block mx-4 mt-3" htmlFor="lName">
                <span className="text-[11px] text-off ml-2">
                  Enter your Last Name
                </span>
                <input
                  onChange={(e) => {
                    setInviteError("");
                    setFields((prev) => ({
                      ...prev,
                      lName: e.target.value,
                    }));
                  }}
                  value={fields.lName}
                  type="lName"
                  name="lName"
                  id="lName"
                  className="drop-shadow block w-[19rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <label className="block mx-4 mt-3" htmlFor="email">
                <span className="text-[11px] text-off ml-2">
                  Enter your Email
                </span>
                <input
                  onChange={(e) => {
                    setInviteError("");
                    setFields((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  value={fields.email}
                  type="email"
                  name="email"
                  id="email"
                  className="drop-shadow block w-[19rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <label className="block mx-4 mt-3" htmlFor="phone">
                <span className="text-[11px] text-off ml-2">
                  Enter yor Phone
                </span>
                <input
                  onChange={(e) => {
                    if (e.target.value.match(/^\+?\d*$/)) {
                      setInviteError("");
                      setFields((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }));
                    }
                  }}
                  value={fields.phone}
                  type="phone"
                  name="phone"
                  id="phone"
                  className="drop-shadow block w-[19rem] px-4 py-3 text-sm text-off font-medium bg-white border border-gray-200 rounded-2xl focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </label>
              <div className="flex space-x-1 mx-4">
                <label className="block  mt-1">
                  <span className="text-[11px] text-off ml-2">Send email?</span>
                  <AutoSendInvitation
                    {...{field: "emailInvitation", fields, setFields}}
                  />
                </label>
                <label className="block  mt-1  ">
                  <span className="text-[11px] text-off ml-2">Send text?</span>
                  <AutoSendInvitation
                    {...{field: "phoneInvitation", fields, setFields}}
                  />
                </label>
              </div>
              <div className="mt-6 w-[19rem] mb-4 mx-4 rounded-lg text-background bg-med">
                <button
                  disabled={loading}
                  type="button"
                  className="w-full py-2 space-x-1 rounded-lg font-medium flex justify-center items-center"
                  onClick={() => {
                    if (!loading) invitePatient();
                  }}
                >
                  Book an appointment
                  {loading && (
                    <span className="">
                      <svg
                        className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25 stroke-[4px]"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

function AutoSendInvitation({field, fields, setFields}) {
  return (
    <div
      className="bg-[#9F9DFA] ml-2 rounded-full p-2 w-20 cursor-pointer transition duration-500"
      onClick={() => {
        setFields((prev) => ({
          ...prev,
          [field]: !prev[field],
        }));
      }}
      style={{
        backgroundColor: fields[field] ? "#9F9DFA" : "#FFFFFF",
        boxShadow: !fields[field] && "0 0 0 2px inset #9F9DFA",
      }}
    >
      <p
        className="w-3 h-3 rounded-full bg-white transition duration-500"
        style={{
          transform: fields[field] ? "translateX(55px)" : "translateX(0)",
          backgroundColor: fields[field] ? "#FFFFFF" : "#9F9DFA",
        }}
      ></p>
    </div>
  );
}
function id(n) {
  return Array.from({length: n})
    .map(() => `${Math.round(Math.random() * 9)}`)
    .join("");
}
