import PatientMembershipTable from "./PatientMembershipTable";
import {PatientMembershipModal} from "./PatientMembershipModal";
import {useContext, useState, useEffect} from "react";
import {Stack, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import {
  DashStateContext,
  PatientContext,
  DispatchContext,
} from "../../../pages/Patients";
import {crud} from "../../../crudRequests";
import {PatientPackagesModal} from "./PatientPackagesModal";
import PatientPackagesTable from "./PatientPackagesTable";

export function PatientMembership({subtabIndex}) {
  const [patient, setPatient] = useContext(PatientContext);
  const dashState = useContext(DashStateContext);
  const dispatch = useContext(DispatchContext);
  const [refetch, setRefetch] = useState(true);
  const [patientMemberships, setPatientMemberships] = useState(
    patient?.memberships?.filter((memb) => memb.membership === true) || []
  );
  const [patientPackages, setPatientPackages] = useState(
    patient?.memberships?.filter((memb) => memb.membership === false) || []
  );
  const [tabIndex, setTabIndex] = useState(
    subtabIndex !== null ? subtabIndex : 0
  );
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (subtabIndex !== null) {
      setTabIndex(subtabIndex);
    }
  }, [subtabIndex]);

  const refetchPatientData = async () => {
    const newPatientData = await crud(dashState, [
      {
        db: dashState.db,
        collection: "patients",
        parameters: [{pid: patient.pid}],
        method: "findOne",
      },
    ]);
    setPatientMemberships(
      newPatientData.data[0].memberships?.filter(
        (memb) => memb.membership === true
      ) || []
    );
    setPatientPackages(
      newPatientData.data[0].memberships?.filter(
        (memb) => memb.membership === false
      ) || []
    );
  };
  useEffect(() => {
    if (refetch) {
      refetchPatientData();
      setRefetch(false);
    }
  }, [refetch]);

  return (
    <Tabs
      index={tabIndex}
      onChange={handleTabsChange}
      align="center"
      variant="enclosed"
    >
      <TabList>
        <Tab width="full">Memberships</Tab>
        <Tab width="full">Packages</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stack direction="column">
            <PatientMembershipModal
              schState={dashState}
              defaultOpen={!patientMemberships.length}
              w="50%"
              patient={patient}
              dispatch={dispatch}
              setPatient={setPatient}
              setPatientMemberships={setPatientMemberships}
              patientMemberships={patientMemberships}
              setRefetch={setRefetch}
            />
            <PatientMembershipTable
              patientMemberships={patientMemberships}
              schState={dashState}
              patient={patient}
              dispatch={dispatch}
              setPatientRefetch={setRefetch}
            />
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack direction="column">
            <PatientPackagesModal
              schState={dashState}
              defaultOpen={!patientPackages.length}
              w="50%"
              patient={patient}
              dispatch={dispatch}
              setPatient={setPatient}
              setPatientMemberships={setPatientPackages}
              patientMemberships={patientPackages}
              setRefetch={setRefetch}
            />
            <PatientPackagesTable
              patientMemberships={patientPackages}
              schState={dashState}
              patient={patient}
              dispatch={dispatch}
              setPatientRefetch={setRefetch}
            />
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
