import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import {
  startOfWeek,
  differenceInDays,
  startOfMonth,
  endOfMonth,
  add,
  sub,
  isSameDay,
  lightFormat,
  endOfWeek,
  isWithinInterval,
  eachDayOfInterval,
  differenceInMinutes,
  startOfDay,
} from "date-fns";
import {
  canDrop,
  dropUpdatePayload,
  SetTimeLine,
} from "../additional_files/dragAndDrop";
import {notificationsData} from "../additional_files/notifications";
import DatePicker from "./DatePicker";
import {DatePickerModal} from "./DatePicker";
import {
  isoToApptDate,
  appointmentsInDays,
  appointmentAvailable,
} from "../additional_files/helpers";
import {getAvailable} from "../additional_files/available";
import {crud} from "../crudRequests";
import {SocketContext} from "../additional_files/context";

import swal from "sweetalert";

import verifyAccess from "../utils/verifyAccess";
import renderBlurredText from "../utils/renderBlurredText";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const timesIndicators = [
  "12AM",
  "1AM",
  "2AM",
  "3AM",
  "4AM",
  "5AM",
  "6AM",
  "7AM",
  "8AM",
  "9AM",
  "10AM",
  "11AM",
].concat([
  "12PM",
  "1PM",
  "2PM",
  "3PM",
  "4PM",
  "5PM",
  "6PM",
  "7PM",
  "8PM",
  "9PM",
  "10PM",
  "11PM",
]);
const dayOfWeekIndicators = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function BookNowAvailableBoard(props) {
  const {
    date,
    bookState,
    dispatch,
    showDatePicker,
    setShowDatePicker,
    setDate,
    view,
    selectedDoctor,
    availability,
  } = props;

  return (
    <div className="show  border-b flex-1 bg-white overflow-hidden w-full flex items-stretch relative">
      {availability.map((e, i) => (
        <div
          key={i}
          className="hover:cursor-pointer flex-1 space-x-1 h-full overflow-hidden py-1 border-l last:border-r flex"
        >
          <AvailableList
            intervals={e}
            bookState={bookState}
            dispatch={dispatch}
          />
        </div>
      ))}
      {showDatePicker && (
        <DatePickerModal
          isOpen={showDatePicker}
          onClose={() => setShowDatePicker(false)}
        >
          <DatePicker
            {...{
              date,
              setDate,
              showDatePicker,
              view,
              appointmentsByDoctor: [],
            }}
          />
        </DatePickerModal>
      )}
    </div>
  );
}

function AvailableList({intervals, dispatch, bookState}) {
  return (
    <div className="w-full h-full flex justify-center items-center overflow-hidden ">
      <ul className="overflow-y-scroll flex flex-col shrink  items-center h-full w-full sbar4  py-2 p-1 space-y-2">
        {intervals.length > 0 &&
          intervals.map((e, i) => (
            <li
              onClick={() => {
                dispatch({type: "SET_INTERVAL", payload: e});
              }}
              key={i}
              style={{
                backgroundColor:
                  e === bookState.selectedInterval ? "#565656" : null,
                color: e === bookState.selectedInterval ? "#FFF" : null,
              }}
              className="[box-shadow:_0px_4px_4px_rgba(0,0,0,0.25)] w-full shrink hover:cursor-pointer bg-[#D7D7D7]  flex justify-center  items-center rounded-full p-2.5 py-3 "
            >
              <span
                className="text-[#636363] font-semibold text-[11px] "
                style={{
                  color: e === bookState.selectedInterval ? "#FFF" : null,
                }}
              >
                {lightFormat(new Date(intervals[i][0]), "h:mmaaa")} -{" "}
                {lightFormat(new Date(intervals[i][1]), "h:mmaaa")}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
}
