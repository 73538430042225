import axios from "axios";

import {url} from "../../../../crudRequests";

export async function createPaymentMethod(dashState, pid, paymentId, lid) {
  return axios({
    method: "POST",
    url: `${url}/createPaymentMethod`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + dashState.jwt,
    },
    data: JSON.stringify({
      id: dashState.doctor?.did || dashState.admin?.id,
      pid,
      db: dashState.db,
      paymentId,
      lid,
    }),
  }).then((res) => {
    return res;
  });
}
