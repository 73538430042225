import React, {useState, useEffect, useContext} from "react";
import {
  Text,
  Switch,
  Td,
  Tr,
  Stack,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import {EditIcon} from "@chakra-ui/icons";
import {crud} from "../../../crudRequests";
import {CancelPatientMembership} from "./CancelPatientMembership";
import {PatientMembershipBillingAside} from "./PatientMembershipBillingAside";
import {PatientContext} from "../../../pages/Patients";

export default function PatientPackageRow({
  membership,
  schState,
  dispatch,
  setPatientRefetch,
  setSelectedMembership,
  selectedMembership,
  showActiveColumn,
}) {
  const [transactions, setTransactions] = useState(null);
  const [patient, setPatient] = useContext(PatientContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [totalAmountPaidByPatient, setTotalAmountPaidByPatient] = useState(0);

  async function fetchData() {
    let billing = await crud(schState, [
      {
        db: schState.db,
        collection: "billing",
        parameters: [{membershipUuid: membership.uuid}],
        method: "find",
      },
    ]);
    setTransactions(billing.data[0]);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const existingPatientMemberships = {};
  const latestPatientState = schState?.patients.find(
    (pt) => pt.pid === patient.pid
  );
  for (const patientMembership of latestPatientState.memberships || []) {
    existingPatientMemberships[patientMembership.mid]
      ? existingPatientMemberships[patientMembership.mid].push(
          patientMembership
        )
      : (existingPatientMemberships[patientMembership.mid] = [
          patientMembership,
        ]);
  }

  useEffect(() => {
    const amountPaidByPatient = transactions
      ? transactions
          .reduce(
            (sum, trans) => sum + (parseFloat(trans.amountPaidByPatient) || 0),
            0
          )
          .toFixed(2)
      : 0;
    setTotalAmountPaidByPatient(amountPaidByPatient);
  }, [transactions]);
  return (
    <Tr
      key={membership.uuid}
      bg={selectedMembership === membership.uuid ? "blue.100" : ""}
    >
      <Td fontSize={"15px"}>
        <Text>{membership.name}</Text>
      </Td>
      <Td fontSize={"15px"}>
        <Text>
          {new Date(membership.startDate).toLocaleDateString("en-CA")}
        </Text>
      </Td>
      <Td fontSize={"15px"}>
        <Text>
          ${" "}
          {parseFloat(membership.acumulatedPrice || membership.price).toFixed(
            2
          )}
        </Text>
      </Td>
      {showActiveColumn && (
        <Td fontSize="15px">
          <Switch isChecked={membership.active} isDisabled={true} />
        </Td>
      )}
      <Td fontSize={"15px"}>
        <Text>$ {totalAmountPaidByPatient}</Text>
      </Td>
      <Td fontSize={"15px"}>
        <Text>
          ${" "}
          {(
            (membership.acumulatedPrice
              ? membership.acumulatedPrice - totalAmountPaidByPatient
              : membership.price - totalAmountPaidByPatient) || 0
          ).toFixed(2)}
        </Text>
      </Td>
      <Td>
        <Stack direction="row">
          <IconButton
            variant="outline"
            aria-label="open menu"
            icon={<EditIcon />}
            bg="blue.400"
            opacity="75%"
            boxShadow="lg"
            onClick={() => {
              onOpen();
            }}
            size="sm"
          />
          <CancelPatientMembership
            membership={membership}
            schState={schState}
            dispatch={dispatch}
            patient={patient}
            setPatientRefetch={setPatientRefetch}
            setPatient={setPatient}
            transactions={transactions}
          />
          <PatientMembershipBillingAside
            patient={patient}
            membership={membership}
            membUuid={membership.uuid}
            schState={schState}
            dispatch={dispatch}
            setSelectedMembership={setSelectedMembership}
            setPatientRefetch={setPatientRefetch}
            transactions={transactions}
            setTransactions={setTransactions}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            totalAmountPaidByPatient={totalAmountPaidByPatient}
          />
        </Stack>
      </Td>
    </Tr>
  );
}
