import React from "react";

import {
  Document,
  Page,
  PDFViewer,
  View,
  Text,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import InterRegular from "../../../additional_files/Inter/static/Inter-Regular.ttf";
import InterMedium from "../../../additional_files/Inter/static/Inter-Medium.ttf";
import InterSemibold from "../../../additional_files/Inter/static/Inter-SemiBold.ttf";
import InterBold from "../../../additional_files/Inter/static/Inter-Bold.ttf";

import Header from "./Header";
import Body from "./Body";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: InterRegular,
    },
    {
      src: InterMedium,
      fontWeight: 500,
    },
    {
      src: InterSemibold,
      fontWeight: 600,
    },
    {
      src: InterBold,
      fontWeight: 700,
    },
  ],
});

export default function ({
  transaction,
  doctor,
  patient,
  tpp,
  supervisor,
  state,
  type,
  returnDoc,
  receiptAmount,
  nonSessionService,
}) {
  let locSrc = state.locations?.find((l) => l.lid === state.selectedLocation);
  const styles = StyleSheet.create({
    page: {
      padding: 25,
      fontFamily: "Inter",
      color: "#1f2937",
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "col",
    },
  });

  let Doc = (
    <Document>
      <Page style={styles.page} size={"A4"}>
        <View style={{width: "100%", flex: 1}}>
          <Header location={locSrc} />
          <Body
            {...{
              transaction,
              doctor,
              patient,
              tpp,
              supervisor,
              state,
              type,
              returnDoc,
              receiptAmount,
              nonSessionService,
              location: locSrc,
            }}
          />
        </View>
        {transaction.cancelled && (
          <View
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
            }}
          >
            <Text
              style={{
                color: "#dc2626",
                fontWeight: 900,
                fontSize: "40px",
                padding: "15px",
                paddingTop: "25px",
                paddingLeft: "25px",
                textAlign: "center",
                border: "8px solid #dc2626",
                transform: "rotate(-45deg) skewX(-10deg)",
                lineHeight: "0px",
                opacity: 0.8,
                borderRadius: "8px",
              }}
            >
              CANCELLED
            </Text>
          </View>
        )}
      </Page>
    </Document>
  );

  if (returnDoc) return Doc;

  return (
    <PDFViewer width={"100%"} height={"100%"}>
      {Doc}
    </PDFViewer>
  );
}
