import React, {useState} from "react";

import {
  Stack,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";

import PatientPackageRow from "./PatientPackageRow";

export default function PatientPackagesTable({
  patientMemberships,
  schState,
  dispatch,
  patient,
  setPatientRefetch,
}) {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const filteredPackages = patientMemberships?.filter((membership) => {
    const isCurrentMembership = membership.status !== "Cancelled";
    const isCancelledMembership = membership.status === "Cancelled";
    if (activeTab === 0) {
      return isCurrentMembership && !isCancelledMembership;
    } else {
      return isCancelledMembership;
    }
  });

  return (
    <>
      <Stack direction="column" spacing={4} alignItems="center" w="100%">
        <Tabs
          mt="1rem"
          variant="soft-rounded"
          colorScheme="blue"
          onChange={(index) => setActiveTab(index)}
        >
          <TabList>
            <Tab>Current</Tab>
            <Tab>Cancelled</Tab>
          </TabList>
        </Tabs>
        <Table height="100%">
          <TableCaption>Patient's package history</TableCaption>
          <Thead bg="gray.100">
            <Tr>
              <Th>Package name</Th>
              <Th>Start date</Th>
              <Th>Price</Th>
              {activeTab === 0 && <Th>Active</Th>}
              <Th>Paid</Th>
              <Th>Pending</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredPackages.map((membership) => (
              <PatientPackageRow
                key={membership.uuid}
                membership={membership}
                schState={schState}
                patient={patient}
                dispatch={dispatch}
                setPatientRefetch={setPatientRefetch}
                setSelectedMembership={setSelectedPackage}
                selectedMembership={selectedPackage}
                showActiveColumn={activeTab === 0}
              />
            ))}
          </Tbody>
        </Table>
      </Stack>
    </>
  );
}
