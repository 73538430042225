import React, {
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Icon,
  Checkbox,
  Center,
  Button,
} from "@chakra-ui/react";
import {IoSearch} from "react-icons/io5";
import {debounce} from 'lodash'

export default function FiltersTasks({
  fetchTasks,
  tasks,
  sort,
  searchQuery,
  setSearchQuery,
  showCompletedRef,
  isPatient,
  isMobile,
}) {
  const inputRef = useRef(null);
  const widthPercentage = {
    searchInput: isMobile ? "60%" : isPatient ? "70%" : "63%",
    completedCheckbox: isMobile ? "30%" : isPatient ? "30%" : "17%",
  };

  const debouncedFetchTasks = useCallback(
    debounce((query, showCompleted, column, order) => {
      fetchTasks(false, query, 1, showCompleted, column, order);
    }, 800),
    []
  );

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedFetchTasks(
      query,
      showCompletedRef.current,
      sort.column,
      sort.direction
    );
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [tasks])

  return (
    <>
      <Center w={widthPercentage.searchInput}>
        <InputGroup>
          <InputLeftElement>
            <Icon as={IoSearch} color="gray.500" />
          </InputLeftElement>
          <Input
            type="text"
            ml="1"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            ref={!isMobile ? inputRef : null}
          />
        </InputGroup>
      </Center>
      {isMobile ? (
        <Button
          colorScheme="green"
          w="30%"
          variant={showCompletedRef.current ? "solid" : "outline"}
          onClick={() => {
            showCompletedRef.current = !showCompletedRef.current;
            fetchTasks(
              false,
              searchQuery,
              1,
              showCompletedRef.current,
              sort.column,
              sort.direction
            );
          }}
        >
          Completed
        </Button>
      ) : (
        <Center w={widthPercentage.completedCheckbox}>
          <Checkbox
            colorScheme="green"
            defaultChecked
            onChange={(e) => {
              showCompletedRef.current = e.target.checked;
              fetchTasks(
                false,
                searchQuery,
                1,
                e.target.checked,
                sort.column,
                sort.direction
              );
            }}
          >
            <Text as="span" fontSize="sm">
              Show Completed
            </Text>
          </Checkbox>
        </Center>
      )}
    </>
  );
}
