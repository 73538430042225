import {useEffect, useState} from "react";
import {getNotificationFaxes} from "../crudRequests";
import {IoPrint} from "react-icons/io5";
import useFindPhaxioNumber from "../hooks/useFindPhaxioNumber";
import {useNavigate} from "react-router-dom";
import {LiaFaxSolid} from "react-icons/lia";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  Center,
} from "@chakra-ui/react";
export const FaxNotifications = ({schState}) => {
  const [faxes, setFaxes] = useState([]);
  const [faxNumber] = useFindPhaxioNumber(schState, () => {});
  const navigate = useNavigate();

  useEffect(() => {
    let isCancelled = false;

    const fetchAndModifyFaxes = async () => {
      try {
        const fetchedFaxes = await getNotificationFaxes(
          schState,
          schState.db,
          faxNumber,
          null,
          null
        );
        const allFaxes = fetchedFaxes?.data?.data || [];

        if (!isCancelled) {
          setFaxes(allFaxes);
        }
      } catch (error) {
        console.error("Error fetching and modifying faxes:", error);
      }
    };

    fetchAndModifyFaxes();

    return () => {
      isCancelled = true;
    };
  }, [faxNumber]);

  return (
    <Center
      position="relative"
      h={10}
      w={10}
      onClick={() =>
        navigate("/faxes", {
          state: {
            ...schState,
          },
        })
      }
    >
      <IconButton
        _hover={{bg: "transparent"}}
        cursor="pointer"
        fontSize="25px"
        onClick={(e) => {
          e.stopPropagation();
          navigate("/faxes", {
            state: {
              ...schState,
            },
          });
        }}
        variant="ghost"
        aria-label="open menu"
        icon={<LiaFaxSolid />}
      />
      {faxes?.length > 0 && (
        <span className="absolute top-[2px] left-[50%] h-[2px]  p-[7px] rounded-full flex justify-center items-center leading-[8px] font-medium bg-[#3182ce] text-[8px] text-white">
          {faxes.length}
        </span>
      )}
    </Center>
  );
};
