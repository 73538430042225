import {Center, Icon, Tooltip, Text, Flex, Button} from "@chakra-ui/react";
import {BsFillTelephoneFill, BsPersonWorkspace} from "react-icons/bs";

import {FaRegCalendarAlt} from "react-icons/fa";
export default function ViewNextAvailable({onClick}) {
  return (
    <Tooltip hasArrow label={"Next Available"} rounded={"md"}>
      <Center
        display={{base: "none", md: "flex"}}
        position={"absolute"}
        top={0.5}
        right={0.5}
        color={"white"}
      >
        <Icon
          as={FaRegCalendarAlt}
          boxSize={4}
          onClick={(e) => {
            onClick(e);
          }}
        />
      </Center>
    </Tooltip>
  );
}
export function ViewNextAvailableMultipleClinicians({onClick}) {
  return (
    <Button
      bg={"#3b82f6"}
      color={"white"}
      height="2.2rem"
      onClick={(e) => onClick(e)}
    >
      Next Available
    </Button>
  );
}
