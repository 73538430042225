import React, {useState, useRef, useEffect} from "react";
import CreateCharge from "./ProductCardCharge";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
export function ProductCardPaymentModal(props) {
  const {
    appointment,
    state,
    dispatch,
    socket,
    transaction,
    refetch,
    patient,
    open,
    setOpen,
    amountPaidSoFar,
    setAmountPaidSoFar,
    onClose,
    setPatient,
  } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");

  return (
    <Modal
      isOpen={open}
      size={"3xl"}
      onClose={() => setOpen(false)}
      isCentered
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent p="2">
        <ModalCloseButton />
        <ModalBody>
          <div className="relative flex h-[85vh] max-h-[800px] min-h-[500px] items-center p-2 py-4 bg-white overflow-y-hidden rounded-3xl  sbar2 justify-center w-[700px]">
            <div className="relative h-full w-full  overflow-y-scroll rounded-3xl p-5 sbar">
              <div
                className="relative inline-block  px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full  sm:p-6 sm:align-middle"
                style={{backgroundColor: "#F6F5FF"}}
              >
                {
                  <div className="right-2 top-2 absolute">
                    <button
                      type="button"
                      className="- text-gray-400 bg-transparent rounded-lg text-sm  ml-auto inline-flex items-center"
                      data-modal-toggle="small-modal"
                      style={{boxShadow: "none"}}
                      onClick={() => setOpen(false)}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                }

                {errorMessage && (
                  <div className="mx-auto flex justify-center items-center w-full">
                    <div className="mb-5 flex w-full overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                      <div className="flex items-center justify-center w-12 bg-red-500">
                        <svg
                          className="w-6 h-6 mx-2 text-white fill-current"
                          viewBox="0 0 40 40"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                        </svg>
                      </div>
                      <div className="px-4 py-2">
                        <div className="mx-3">
                          <span className="font-semibold text-red-500 dark:text-red-400">
                            Error
                          </span>
                          <p className="text-sm whitespace-pre-wrap text-gray-600 dark:text-gray-200">
                            {errorMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {success && (
                  <div className="mx-auto flex justify-center items-center w-full">
                    <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
                      <div className="flex items-center justify-center w-12 bg-green-500">
                        <svg
                          className="w-6 h-6 mx-2 text-white fill-current"
                          viewBox="0 0 40 40"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                        </svg>
                      </div>
                      <div className="px-4 py-2 -mx-3">
                        <div className="mx-3">
                          <span className="font-semibold text-green-500 dark:text-green-400">
                            Card Payment
                          </span>
                          <p className="text-sm text-gray-600 dark:text-gray-200">
                            {success}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {patient ? (
                  <CreateCharge
                    state={state}
                    patient={patient}
                    appt={appointment}
                    dispatch={dispatch}
                    socket={socket}
                    setErrorMessage={setErrorMessage}
                    setSuccess={setSuccess}
                    transaction={transaction}
                    refetch={refetch}
                    amountPaidSoFar={amountPaidSoFar}
                    setAmountPaidSoFar={setAmountPaidSoFar}
                    setOpen={setOpen}
                    onClose={onClose}
                    setPatient={setPatient}
                  />
                ) : (
                  <div className="flex justify-start items-center  px-8">
                    <h1 className="text-[rgba(87,84,255,0.75)] font-semibold text-2xl leading-[25px]">
                      Loading...
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
